import React from "react";
import { IonButton, IonLabel } from "@ionic/react";

import "./WebForgotEmailSent.scss";
import { EWPCOLORS } from "../../constants/config";

interface WebForgotEmailSentProps {
  emailAddress: string;
  onTryAgain: () => void;
}

export const WebForgotEmailSent = (props: WebForgotEmailSentProps) => {
  const { emailAddress, onTryAgain } = props;
  return (
    <div className="forgot-password-form-container">
      <IonLabel className="forgot-password-label ewp-h1 ion-no-margin ion-no-padding">
        Check Email
      </IonLabel>
      <IonLabel className="forgot-password-description ewp-paragraph ion-no-margin ion-no-padding">
        If {emailAddress} is associated with a EWP Truck Hire account, you
        should receive an email containing instructions on how to create a new
        password soon.
      </IonLabel>

      <IonLabel className="forgot-password-didnt-receive-label ewp-h4 ion-no-margin ion-no-padding">
        Didn’t receive the email?
      </IonLabel>
      <span className="forgot-password-didnt-receive-description ewp-paragraph ion-no-margin ion-no-padding">
        {`Check spam or bulk folders for a message or `}
        <IonButton
          fill="clear"
          color={EWPCOLORS.primary}
          onClick={onTryAgain}
          className="ewp-links ion-text-lowercase"
        >
          <u>try again</u>
        </IonButton>
        .
      </span>
    </div>
  );
};
