import * as _ from "lodash";
import React from "react";
import { IonButton } from "@ionic/react";

import "./EWPButton.scss";

export const EWPButton = ({
  title,
  routerLink = "#",
  onClick,
  type,
  disabled = false,
  size = "large",
  border = false,
  color = "primary",
  fill = "solid",
  className,
  underlined = false,
}: {
  title: string;
  routerLink?: string;
  onClick?: (event: any) => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  size?: "large" | "default" | "small";
  border?: boolean;
  color?:
    | "primary"
    | "warning"
    | "success"
    | "danger"
    | "medium"
    | "light"
    | null;
  fill?: "solid" | "clear" | "outline";
  className?: string;
  underlined?: boolean;
}) => (
  <IonButton
    mode="ios"
    size={size}
    fill={fill}
    onClick={!!onClick ? onClick : () => {}}
    routerLink={routerLink}
    expand="block"
    className={`ewp-large-button ion-no-margin ewp-h3 ${size}  ${
      className !== undefined ? className : ""
    }`}
    disabled={disabled}
    {...(!!type ? { type } : { type: "button" })}
    {...(!_.isNull(color) ? { color } : {})}
  >
    {underlined ? <u>{title}</u> : title}
  </IonButton>
);
