import * as _ from "lodash";
import Bugsnag from "@bugsnag/js";
import React, { useState, DragEvent } from "react";
import { close, add } from "ionicons/icons";
import {
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonRippleEffect,
  IonLabel,
  IonIcon,
  IonThumbnail,
} from "@ionic/react";

import "./ClosedFaultsAndDefectsDialog.scss";
import * as services from "../../services";
import {
  formatString,
  uploadAttachments,
  removedUploadedAttachements,
  isTablet,
} from "../../functions/common";
import { AttachmentDialog } from "../Web/AttachmentDialog/AttachmentDialog";
import { AttachmentWithIndex } from "../Web/EWPNoteCard/EWPNoteCard";
import { CompletionDetails } from "../../models";
import { EWPInput } from "../EWPInput/EWPInput";
import { EWPWebDialog } from "../EWPWebDialog/EWPWebDialog";
import { EWPProps, FAULT_DEFECT_STATUS } from "../../config/global";
import { EWPCOLORS } from "../../constants/config";
import { MSGS_COMMON, MSGS_TRUCK_REGISTER } from "../../constants/messages";

interface ClosedFaultsAndDefectsDialogProps extends EWPProps {
  onDidDismiss: () => void;
  isOpen: boolean;
  toCloseFaultDefectId: string; // use in upload photo path
  readonlyData?: CompletionDetails;
  isEdit?: boolean;
}

export const ClosedFaultsAndDefectsDialog = (
  props: ClosedFaultsAndDefectsDialogProps
) => {
  const { isOpen, onDidDismiss, toCloseFaultDefectId, readonlyData } = props;
  const [description, setDescription] = useState(
    !_.isEmpty(readonlyData) ? readonlyData!.description : ""
  );
  const [partsUsed, setPartsUsed] = useState(
    !_.isEmpty(readonlyData) ? readonlyData!.partsUsed : ""
  );
  const [hoursToComplete, setHoursToComplete] = useState(
    !_.isEmpty(readonlyData) ? readonlyData!.hoursToComplete : 0
  );
  const [kilometers, setKilometers] = useState(
    !_.isEmpty(readonlyData) ? readonlyData!.kilometers : ""
  );
  const [hours, setHours] = useState(
    !_.isEmpty(readonlyData) ? readonlyData!.hours : ""
  );

  const [removedAttachments, setRemovedAttachments] = useState([] as string[]);
  const [listOfAttachment, setListOfAttachment] = useState(
    !_.isEmpty(readonlyData) ? readonlyData!.attachments : []
  ) as any[];

  const [errorMsgDescription, setErrorMsgDescription] = useState("");
  const [errorMsgPartsUsed, setErrorMsgPartsUsed] = useState("");
  const [errorMsgHoursToComplete, setErrorMsgHoursToComplete] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [attachmentDialogOpen, setAttachmentDialogOpen] = useState(false);
  const [attachmentIndexToOpen, setAttachmentIndexToOpen] = useState(
    null as null | number
  );

  const ref: React.RefObject<HTMLInputElement> = React.createRef();

  const onClickUploadPhoto = () => {
    ref.current!.click(); // ref for input field file
  };

  const clearFields = () => {
    setDescription("");
    setPartsUsed("");
    setHoursToComplete(0);
    setKilometers("");
    setHours("");
    setListOfAttachment([]);
  };

  const clearErrorMsgs = () => {
    setErrorMsgDescription("");
    setErrorMsgPartsUsed("");
    setErrorMsgHoursToComplete("");
  };

  const closeFaultAndDefect = async (event: React.FormEvent) => {
    event.preventDefault();

    let attachmentUrlList = [] as string[];

    const formattedDescription = formatString(description);
    const formattedPartsUsed = formatString(partsUsed);

    let errorMsg = "";
    if (_.isEmpty(formattedDescription)) {
      errorMsg = "Description is required";
      setErrorMsgDescription(errorMsg);
    }

    if (_.isEmpty(formattedPartsUsed)) {
      errorMsg = "Parts used is required";
      setErrorMsgPartsUsed(errorMsg);
    }
    if (_.isEmpty(errorMsg)) {
      setLoading(true);
      try {
        if (listOfAttachment.length > 0) {
          const urlList = await uploadAttachments(
            listOfAttachment,
            "closed-tickets",
            toCloseFaultDefectId,
            "faults-and-defects"
          );
          attachmentUrlList = urlList;
        }

        // await services.addTruckServiceHistory(
        //   uploaderId,
        //   faultDefectTruck,
        //   getServerTimestamp(serviceDate),
        //   attachmentUrlList
        // );
        await services.updateFaultAndDefects(toCloseFaultDefectId, {
          status: FAULT_DEFECT_STATUS.closed.id,
          completionDetails: {
            description: formattedDescription,
            attachments: attachmentUrlList,
            partsUsed: formattedPartsUsed,
            hoursToComplete: hoursToComplete,
            kilometers: kilometers || "",
            hours: hours || "",
          },
        });
        setLoading(false);
        setSuccess(MSGS_COMMON.success);
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        //remove the file in our storage
        await removedUploadedAttachements(attachmentUrlList);
        setLoading(false);
        setError(error);
        Bugsnag.notify(new Error(error));
      }
    }
  };

  const onAttachPhoto = async (file: any) => {
    // if (
    //   listOfAttachment.length + file.target.files.length <=
    //   LIMITS.serviceTypeAttachments.count
    // ) {
    //   if (!!file && file.target.files.length) {
    //     const attachments = file.target.files;
    //     const fileSizes = Object.values(attachments).map((attachment: any) => {
    //       return attachment.size <= LIMITS.serviceTypeAttachments.size;
    //     });
    //     const hasExceededFileSize = _.includes(fileSizes, false);
    //     if (!hasExceededFileSize) {
    //       const toBeUploadedAttachments = _.concat(
    //         Object.values(attachments),
    //         listOfAttachment
    //       );
    //       setListOfAttachment(toBeUploadedAttachments as []);
    //     } else {
    //       setError(MSGS_TRUCK_REGISTER.attachmentFileSizeExceed);
    //     }
    //   }
    // } else {
    //   setError(MSGS_TRUCK_REGISTER.serviceTypeAttachmentsExceed);
    // }
    const attachments = file.target.files;
    const toBeUploadedAttachments = _.concat(
      Object.values(attachments),
      listOfAttachment
    );
    setListOfAttachment(toBeUploadedAttachments as []);
  };

  const onDrop = (event: DragEvent) => {
    event.preventDefault();
    // if (listOfAttachment.length <= LIMITS.serviceTypeAttachments.count) {
    //   if (event.dataTransfer.files) {
    //     // Use DataTransferItemList interface to access the file(s)
    //     if (
    //       event.dataTransfer.files.length <= LIMITS.serviceTypeAttachments.count
    //     ) {
    //       const files = Object.values(event.dataTransfer.files);
    //       const fileSizes = Object.values(files).map((attachment: any) => {
    //         return attachment.size <= LIMITS.serviceTypeAttachments.size;
    //       });
    //       const hasExceededFileSize = _.includes(fileSizes, false);
    //       if (!hasExceededFileSize) {
    //         files.forEach((file) => {
    //           const imageType = /image.*/;
    //           if (file && file.type.match(imageType)) {
    //             const attachments = Object.values(event.dataTransfer.files);
    //             const toBeUploadedAttachments = _.concat(
    //               attachments,
    //               listOfAttachment
    //             );
    //             setListOfAttachment(toBeUploadedAttachments as []);
    //           } else {
    //             setError(MSGS_TRUCK_REGISTER.invalidFileType);
    //           }
    //         });
    //       } else {
    //         setError(MSGS_TRUCK_REGISTER.attachmentFileSizeExceed);
    //       }
    //     } else {
    //       setError(MSGS_TRUCK_REGISTER.serviceTypeAttachmentsExceed);
    //     }
    //   }
    // } else {
    //   setError(MSGS_TRUCK_REGISTER.serviceTypeAttachmentsExceed);
    // }
    const files = Object.values(event.dataTransfer.files);
    files.forEach((file) => {
      const imageType = /image.*/;
      if (
        file &&
        (file.type.match(imageType) || file.type === "application/pdf")
      ) {
        const attachments = Object.values(event.dataTransfer.files);
        const toBeUploadedAttachments = _.concat(attachments, listOfAttachment);
        setListOfAttachment(toBeUploadedAttachments as []);
      } else {
        setError(MSGS_TRUCK_REGISTER.invalidFileType);
      }
    });
  };

  const onRemoveAttachment = (attachment: any) => {
    const updatedListOfAttachment = _.remove(listOfAttachment, (item) => {
      return item !== attachment;
    });

    if (typeof attachment === "string") {
      setRemovedAttachments([...removedAttachments, attachment]);
    }
    setListOfAttachment(updatedListOfAttachment);
  };

  const attachmentsWithIndexList = (
    !_.isEmpty(readonlyData) ? readonlyData!.attachments : []
  ).map((attachment, index) => {
    return { attachment, index } as AttachmentWithIndex;
  });

  return (
    <>
      <EWPWebDialog
        headerTitle="Close Ticket"
        isOpen={isOpen}
        onDidDismiss={() => {
          onDidDismiss();
        }}
      >
        <div className="close-ticket-faults-defects-form-container">
          <form onSubmit={closeFaultAndDefect}>
            <IonGrid className="ion-no-padding">
              <IonRow className="close-ticket-faults-defects-row ion-no-padding ion-no-margin">
                <IonCol
                  size="12"
                  className="close-ticket-faults-defects-only-col ion-no-padding"
                >
                  <EWPInput
                    mode="textArea"
                    readOnly={!_.isEmpty(readonlyData)}
                    errorMessage={errorMsgDescription}
                    inputLabel="Description"
                    inputValue={description}
                    inputType="text"
                    inputMode="text"
                    name="description"
                    onInputChange={(description: string) => {
                      clearErrorMsgs();
                      setDescription(description);
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow className="close-ticket-faults-defects-row ion-no-padding ion-no-margin">
                <IonCol
                  size="12"
                  className="close-ticket-faults-defects-only-col ion-no-padding"
                >
                  <EWPInput
                    readOnly={!_.isEmpty(readonlyData)}
                    mode="textArea"
                    errorMessage={errorMsgPartsUsed}
                    inputLabel="Parts Used"
                    inputValue={partsUsed}
                    inputType="text"
                    inputMode="text"
                    name="partsUsed"
                    onInputChange={(partsUsed: string) => {
                      clearErrorMsgs();
                      setPartsUsed(partsUsed);
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow className="close-ticket-faults-defects-row ion-no-padding ion-no-margin">
                <IonCol
                  size="4"
                  className="close-ticket-faults-defects-start-col ion-no-padding"
                >
                  <EWPInput
                    readOnly={!_.isEmpty(readonlyData)}
                    errorMessage={errorMsgHoursToComplete}
                    inputLabel="Hours to Complete"
                    inputValue={hoursToComplete}
                    inputType="number"
                    inputMode="text"
                    name="hoursToComplete"
                    onInputChange={(hoursToComplete: string) => {
                      clearErrorMsgs();
                      setHoursToComplete(parseInt(hoursToComplete));
                    }}
                  />
                </IonCol>
                <IonCol
                  size="4"
                  className="close-ticket-faults-defects-only-col ion-no-padding"
                >
                  <EWPInput
                    readOnly={!_.isEmpty(readonlyData)}
                    inputLabel="Kilometers"
                    inputValue={kilometers}
                    inputType="text"
                    inputMode="text"
                    name="kilometers"
                    onInputChange={(kilometers: string) => {
                      clearErrorMsgs();
                      setKilometers(kilometers);
                    }}
                  />
                </IonCol>
                <IonCol
                  size="4"
                  className="close-ticket-faults-defects-end-col ion-no-padding"
                >
                  <EWPInput
                    readOnly={!_.isEmpty(readonlyData)}
                    inputLabel="Hours"
                    inputValue={hours}
                    inputType="text"
                    inputMode="text"
                    name="hours"
                    onInputChange={(hours: string) => {
                      clearErrorMsgs();
                      setHours(hours);
                    }}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>

            {_.isEmpty(readonlyData) ? (
              <IonGrid className="ion-no-padding">
                <IonRow className="close-ticket-faults-defects-row ion-no-padding ion-no-margin">
                  <IonCol size="12" className="ion-no-padding">
                    <div
                      className={`close-ticket-faults-defects-upload-container ${
                        isTablet() && "is-tablet"
                      }`}
                    >
                      <div className="close-ticket-faults-defects-title-button-container">
                        <IonLabel className="close-ticket-faults-defects-upload-title-label ewp-h3 ion-no-padding ion-no-margin">
                          Upload Images
                        </IonLabel>
                        {!_.isEmpty(listOfAttachment) && (
                          <IonButton
                            mode="ios"
                            className="close-ticket-faults-defects-attachments-add-another-button ewp-h6 ion-no-margin ion-no-padding"
                            color={EWPCOLORS.gray}
                            onClick={onClickUploadPhoto}
                          >
                            <IonIcon
                              className="close-ticket-faults-defects-small-add-icon"
                              icon={add}
                            />
                            Add Another
                          </IonButton>
                        )}
                      </div>
                      {!_.isEmpty(listOfAttachment) ? (
                        <div className="web-upload-list-attachment-container">
                          <IonGrid className="ion-no-margin ion-no-padding">
                            {_.chunk(listOfAttachment, 8).map(
                              (row: any, index: any) => {
                                return (
                                  <IonRow
                                    key={`row_${index}`}
                                    className="ion-no-margin ion-no-padding"
                                  >
                                    {row.map((attachment: any, index: any) => {
                                      const fileUrl =
                                        typeof attachment === "string"
                                          ? attachment
                                          : "";
                                      const fileSplit = fileUrl
                                        .split("?")[0]
                                        .split(".");
                                      const fileTypeAttachment =
                                        fileSplit[
                                          fileSplit.length - 1
                                        ].toLowerCase();
                                      return (
                                        <IonCol
                                          key={`col_${index}`}
                                          size="1.5"
                                          className="ion-no-margin ion-no-padding"
                                        >
                                          <div className="web-upload-attachment-container">
                                            <IonButton
                                              className="remove-attachment-button ion-no-margin ion-no-padding"
                                              shape="round"
                                              color="primary"
                                              onClick={() =>
                                                onRemoveAttachment(attachment)
                                              }
                                            >
                                              <IonIcon
                                                className="web-upload-attachment-close-icon ion-no-margin ion-no-padding"
                                                icon={close}
                                              />
                                            </IonButton>
                                            {typeof attachment !== "string" &&
                                            !!attachment.type.match(
                                              "image/*"
                                            ) ? (
                                              <IonThumbnail
                                                className="web-upload-attachment-avatar ion-no-margin ion-no-padding"
                                                slot="start"
                                              >
                                                <img
                                                  id="attachmentPhoto"
                                                  src={
                                                    typeof attachment ===
                                                    "string"
                                                      ? attachment
                                                      : URL.createObjectURL(
                                                          attachment
                                                        )
                                                  }
                                                  alt="attachmentPhoto"
                                                />
                                              </IonThumbnail>
                                            ) : fileTypeAttachment !== "pdf" &&
                                              typeof attachment === "string" ? (
                                              <IonThumbnail
                                                className="web-upload-attachment-avatar ion-no-margin ion-no-padding"
                                                slot="start"
                                              >
                                                <img
                                                  id="attachmentPhoto"
                                                  src={
                                                    typeof attachment ===
                                                    "string"
                                                      ? attachment
                                                      : URL.createObjectURL(
                                                          attachment
                                                        )
                                                  }
                                                  alt="attachmentPhoto"
                                                />
                                              </IonThumbnail>
                                            ) : (
                                              <div className="attachment-file-link-container">
                                                <a
                                                  target="blank"
                                                  href={
                                                    typeof attachment ===
                                                    "string"
                                                      ? attachment
                                                      : URL.createObjectURL(
                                                          attachment
                                                        )
                                                  }
                                                >
                                                  View File
                                                </a>
                                              </div>
                                            )}
                                          </div>
                                        </IonCol>
                                      );
                                    })}
                                  </IonRow>
                                );
                              }
                            )}
                          </IonGrid>
                        </div>
                      ) : (
                        <IonItem
                          lines="none"
                          className="close-ticket-faults-defects-upload-item ewp-body ion-no-margin"
                          onClick={onClickUploadPhoto}
                          onDragOver={(event) => {
                            event.preventDefault();
                          }}
                          onDrop={onDrop}
                        >
                          <div className="web-upload-icon-label-container">
                            <IonIcon className="close-ticket-faults-defects-upload-icon" />
                            <IonLabel className="close-ticket-faults-defects-upload-label ewp-paragraph ion-no-margin ion-no-padding">
                              Drop files here to upload or <u>choose file</u>
                            </IonLabel>
                          </div>
                        </IonItem>
                      )}
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            ) : attachmentsWithIndexList.length > 0 ? (
              <IonGrid className="ion-no-margin ion-no-padding">
                {_.chunk(attachmentsWithIndexList, 6).map((row) => {
                  return (
                    <IonRow>
                      {row.map(
                        (attachment: AttachmentWithIndex, indexCol: any) => {
                          return (
                            <IonCol
                              key={`ticket_attachment_col_${indexCol}`}
                              size="2"
                              className="ion-no-margin ion-no-padding"
                            >
                              <div
                                key={indexCol}
                                className="ticket-attachment-container"
                              >
                                <IonButton
                                  className="ticket-attachment-avatar-button ion-no-margin ion-no-padding"
                                  fill="clear"
                                  color={EWPCOLORS.light}
                                >
                                  <IonThumbnail
                                    className="ticket-attachment-avatar ion-no-margin ion-no-padding"
                                    slot="start"
                                    onClick={() => {
                                      console.log(attachment);
                                      setAttachmentIndexToOpen(
                                        attachment.index
                                      );
                                      setAttachmentDialogOpen(true);
                                    }}
                                  >
                                    <img
                                      id="attachmentPhoto"
                                      src={attachment.attachment}
                                      alt="attachmentPhoto"
                                    />
                                  </IonThumbnail>
                                </IonButton>
                              </div>
                            </IonCol>
                          );
                        }
                      )}
                    </IonRow>
                  );
                })}
              </IonGrid>
            ) : (
              <></>
            )}

            <IonItem
              lines="none"
              className="ewp-item-no-horizontal-padding ewp-button-padding-top"
            >
              <IonButton
                mode="ios"
                slot="start"
                className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
                fill="clear"
                color={EWPCOLORS.medium}
                onClick={() => {
                  if (onDidDismiss) {
                    onDidDismiss();
                    clearFields();
                  }
                }}
              >
                {!_.isEmpty(readonlyData) ? "Close" : "Cancel"}
              </IonButton>
              {_.isEmpty(readonlyData) && (
                <IonButton
                  mode="md"
                  slot="end"
                  className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
                  color={EWPCOLORS.primary}
                  type="submit"
                >
                  Close Ticket
                  <IonRippleEffect />
                </IonButton>
              )}
            </IonItem>
          </form>
          <input
            hidden
            type="file"
            ref={ref}
            accept="image/*,.pdf"
            onChange={onAttachPhoto}
            multiple
          />
        </div>
      </EWPWebDialog>

      {attachmentsWithIndexList.length > 0 && (
        <AttachmentDialog
          attachments={readonlyData!.attachments.map((url) => {
            return { attachmentUrl: url };
          })}
          showModal={attachmentDialogOpen}
          isTitleHTML={true}
          {...(!_.isNull(attachmentIndexToOpen) && {
            selectedIndex: attachmentIndexToOpen,
          })}
          onModalDidDismiss={() => {
            setAttachmentDialogOpen(false);
          }}
        />
      )}

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
          onDidDismiss();
          clearFields();
        }}
        color={EWPCOLORS.success}
      />
    </>
  );
};
