import _ from "lodash";
import { close } from "ionicons/icons";
import React, { useState } from "react";
import {
  IonModal,
  IonContent,
  IonLabel,
  IonButton,
  IonIcon,
  IonCard,
  IonCardContent,
  IonItem,
  IonRippleEffect,
  IonLoading,
  IonToast,
} from "@ionic/react";

import "./DeleteExpiryDialog.scss";
import * as services from "../../services";
import { DriverExpiry, TruckExpiry } from "../../models/drivers";
import { EWPCOLORS } from "../../constants/config";
import { MSGS_COMMON } from "../../constants/messages";
import Bugsnag from "@bugsnag/js";

interface DeleteExpiryDialogProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  onSuccess: () => void;
  mode: "driver" | "truck";
  expiry: TruckExpiry | DriverExpiry;
}

export const DeleteExpiryDialog = (props: DeleteExpiryDialogProps) => {
  const { mode, isOpen, onDidDismiss, onSuccess, expiry } = props;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const softDeleteJob = async () => {
    const { expiry } = props;

    if (!!expiry) {
      setLoading(true);
      try {
        // await services.DeleteExpiryDialog(jobSchedule.docId || "");
        if (mode === "truck") {
          await services.deleteTruckExpiry(expiry.docId || "", expiry.name);
        } else {
          await services.deleteDriverExpiry(expiry.docId || "", expiry.name);
        }

        setLoading(false);
        setSuccess("Expiry successfully Deleted!");
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        setError(error);
        setLoading(false);
        Bugsnag.notify(new Error(error));
      }
    }
  };

  return (
    <IonModal
      className="delete-expiry-dialog"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <IonContent
        scrollY={false}
        className="delete-expiry-content ion-no-padding"
      >
        <IonButton
          className="delete-expiry-close-button-dialog ion-no-padding ion-no-margin"
          color={EWPCOLORS.light}
          fill="clear"
          onClick={onDidDismiss}
        >
          <IonIcon color={EWPCOLORS.light} icon={close} />
        </IonButton>
        <div className="delete-expiry-title-container">
          <IonLabel className="ewp-h1 ion-text-center">
            {`Are you sure you want to delete this ${_.startCase("expiry")}`}?
          </IonLabel>
          <IonLabel className="delete-expiry-subtitle ewp-paragraph ion-text-center">
            {`Remember, If you delete this global ${_.startCase(
              "expiry"
            )}, it will no longer be available and will be permanently removed from all ${_.startCase(
              mode
            )} profiles`}
          </IonLabel>
        </div>

        <IonCard
          className="delete-expiry-card ion-no-padding ion-no-margin"
          color={EWPCOLORS.tertiary}
        >
          <IonCardContent className="delete-expiry-card-content ion-no-padding">
            <div className="delete-expiry-card-item">
              <IonIcon className={`delete-expiry-icon ion-no-padding`} />
              <div className="delete-expiry-dialog-detail-container">
                <IonLabel className="ewp-h2 ion-text-start">
                  {expiry.name}
                </IonLabel>
                <IonLabel className="ewp-h4 primary ion-text-start">
                  {expiry.expiryReminder > 0
                    ? `Reminder: ${expiry.expiryReminder} ${
                        expiry.expiryReminder === 1 ? "Week" : "Weeks"
                      }`
                    : "N/A"}
                </IonLabel>
              </div>
            </div>
          </IonCardContent>
        </IonCard>
        <IonItem
          lines="none"
          className="delete-expiry-action-buttons-item ewp-item-no-horizontal-padding"
        >
          <IonButton
            mode="ios"
            slot="start"
            className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
            fill="clear"
            color={EWPCOLORS.medium}
            onClick={onDidDismiss}
          >
            Cancel
          </IonButton>

          <IonButton
            mode="ios"
            slot="end"
            className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
            color={EWPCOLORS.danger}
            onClick={softDeleteJob}
          >
            Yes, Delete
            <IonRippleEffect />
          </IonButton>
        </IonItem>
      </IonContent>

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => {
          setError("");
        }}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={1000}
        onDidDismiss={() => {
          setSuccess("");
          onSuccess();
        }}
        color={EWPCOLORS.success}
      />
    </IonModal>
  );
};
