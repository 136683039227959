import { IonIcon } from "@ionic/react";
import React from "react";

import "./EWPFormPageTitleDescription.scss";

export const EWPFormPageTitleDescription = ({
  title,
  description,
  icon,
  subtitle = "",
  alignment = "ion-text-center",
  className,
  htmlMode,
  clamp,
}: {
  title: string;
  icon: "client" | "truck";
  description?: string;
  subtitle?: string;
  alignment?: "ion-text-center" | "ion-text-left" | "ion-text-right";
  className?: string;
  htmlMode?: boolean;
  clamp?: boolean;
}) => (
  <div className="ewp-form-page-title-icon-container">
    <IonIcon
      className={`ewp-form-page-icon ${icon} ion-no-padding ion-no-margin`}
    />
    <div
      className={`ewp-form-page-title-and-description-container ${
        className !== undefined ? className : ""
      }`}
    >
      <div className="ewp-form-page-title-div ewp-h2">{title}</div>
      {!!subtitle && (
        <div className="ewp-form-page-title-div ewp-h4 primary bold">
          {subtitle}
        </div>
      )}
    </div>
  </div>
);
