import _ from "lodash";
import { close, key } from "ionicons/icons";
import React, { useState } from "react";
import {
  IonModal,
  IonContent,
  IonLabel,
  IonButton,
  IonIcon,
  IonCard,
  IonCardContent,
  IonItem,
  IonRippleEffect,
  IonToast,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";

import "./UserInviteSuccessDialog.scss";
import { EWPCOLORS } from "../../constants/config";
import {
  AdminAccess,
  ADMIN_ACCESS,
  ADMIN_ACCESS_KEYS,
} from "../../config/global";

interface UserInviteSuccessDialogProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  onSuccess: () => void;
  name: string;
  access: AdminAccess;
  emailAddress: string;
  password: string;
}

export const UserInviteSuccessDialog = (
  props: UserInviteSuccessDialogProps
) => {
  const {
    isOpen,
    onDidDismiss,
    onSuccess,
    name,
    access,
    emailAddress,
    password,
  } = props;
  const [success, setSuccess] = useState("");

  return (
    <IonModal
      className="user-invite-success-dialog"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <IonContent
        scrollY={false}
        className="user-invite-success-content ion-no-padding"
      >
        <IonButton
          className="user-invite-success-close-button-dialog ion-no-padding ion-no-margin"
          color={EWPCOLORS.light}
          fill="clear"
          onClick={onDidDismiss}
        >
          <IonIcon color={EWPCOLORS.light} icon={close} />
        </IonButton>
        <div className="user-invite-success-title-container">
          <IonLabel className="ewp-h1 ion-text-center">
            This user was successfully created
          </IonLabel>
          <IonLabel className="user-invite-success-subtitle ewp-paragraph ion-text-center">
            Please take note of the email and password for the newly created
            user.
          </IonLabel>
        </div>

        <IonCard
          className="user-invite-success-dialog-container ion-no-padding ion-no-margin"
          color={EWPCOLORS.tertiary}
        >
          <IonCardContent className="user-invite-success-details-car-dialog ion-no-padding">
            <IonItem
              lines="none"
              className="user-invite-success-details-item-dialog"
            >
              <IonIcon className="user-invite-success-profile-icon ion-no-padding" />
              <IonGrid className="ion-no-padding">
                <IonRow>
                  <IonCol
                    size="4"
                    className="user-invite-success-item-details-dialog ion-no-padding"
                  >
                    <IonLabel className="ewp-h2 ion-text-capitalize">
                      {name}
                    </IonLabel>
                    <IonLabel className="ewp-paragraph ion-text-capitalize">
                      {ADMIN_ACCESS[ADMIN_ACCESS_KEYS[access]].access}
                    </IonLabel>
                  </IonCol>

                  <IonCol size="8" className="ion-no-padding ">
                    <IonItem
                      className="user-invite-success-action-item-dialog-container"
                      lines="none"
                    >
                      <div
                        slot="start"
                        className="user-invite-success-contact-info-dialog"
                      >
                        <IonLabel className="user-invite-success-contact-info-email ewp-paragraph">
                          <IonIcon className="user-invite-success-details-message-icon" />
                          {emailAddress}
                        </IonLabel>
                        <IonLabel className="user-invite-success-contact-info-email ewp-paragraph">
                          <IonIcon
                            icon={key}
                            color={EWPCOLORS.primary}
                            className="user-invite-success-details-key-icon"
                          />
                          {password}
                        </IonLabel>
                      </div>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          </IonCardContent>
        </IonCard>
        <IonButton
          mode="ios"
          className="user-invite-done-button ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
          color={EWPCOLORS.primary}
          onClick={onSuccess}
        >
          Done
          <IonRippleEffect />
        </IonButton>
      </IonContent>

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={1000}
        onDidDismiss={() => {
          setSuccess("");
          onSuccess();
        }}
        color={EWPCOLORS.success}
      />
    </IonModal>
  );
};
