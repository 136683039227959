import * as _ from "lodash";
import { trashBin } from "ionicons/icons";
import React, { useState } from "react";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonToast,
} from "@ionic/react";

import "./AdminList.scss";
import * as services from "../../../services";
import { useEffectOnlyOnce } from "../../../functions/common";
import { Depot } from "../../../models";
import { EWPDataTable } from "../../EWPDataTable/EWPDataTable";
import { EWPDepotForm } from "../../EWPDepotForm/EWPDepotForm";
import { EWPExpiryReminderForm } from "../../EWPExpiryReminderForm/EWPExpiryReminderForm";
import { EWPWebDialog } from "../../EWPWebDialog/EWPWebDialog";
import { EWPTableProps, EWPProps } from "../../../config/global";
import { EWPCOLORS } from "../../../constants/config";
import { DriverExpiry, TruckExpiry } from "../../../models/drivers";
import { ArchiveOrRestoreTruckDialog } from "../../ArchiveOrRestoreTruckDialog/ArchiveOrRestoreTruckDialog";

interface AdminListProps extends EWPProps {
  depots: Depot[];
  trucks: any[];
  drivers: any[];
}

interface SortKeys {
  name: "asc" | "desc";
  [key: string]: "asc" | "desc";
}

export const AdminList = (props: AdminListProps) => {
  const { depots } = props;

  const [error, setError] = useState("");
  const [filteredDriverExpiryList, setFilteredDriverExpiryList] = useState(
    null as null | DriverExpiry[]
  );

  const [driverExpiryList, setDriverExpiryList] = useState(
    null as null | DriverExpiry[]
  );
  const [truckExpiryList, setTruckExpiryList] = useState(
    null as null | TruckExpiry[]
  );
  const [filteredTruckData, setFilteredTruckData] = useState(
    null as null | TruckExpiry[]
  );

  const [filteredDepotData, setFilteredDepotData] = useState(
    null as null | Depot[]
  );
  const [sortLabelsAndDirection, setSortLabelsAndDirection] = useState({
    name: "asc",
  } as SortKeys);
  const [sortTruckLabelsAndDirection, setTruckSortLabelsAndDirection] =
    useState({
      name: "asc",
    } as SortKeys);
  const [sortDriverLabelsAndDirection, setDriverSortLabelsAndDirection] =
    useState({
      name: "asc",
    } as SortKeys);

  const [showAddNewEditDepot, setShowAddNewEditDepot] = useState(false);
  const [showTruckExpiry, setShowTruckExpiry] = useState(false);
  const [showDriverExpiry, setShowDriverExpiry] = useState(false);
  const [showDeleteDepotDialog, setShowDeleteDepotDialog] = useState(false);

  const [toEditDepot, setToEditDepot] = useState({} as Depot);
  const [toDeleteDepot, setToDeleteDepot] = useState({} as Depot);
  const [toEditTruckExpiryReminder, setToEditTruckExpiryReminder] = useState(
    {} as TruckExpiry
  );
  const [toEditDriverExpiryReminder, setToEditDriverExpiryReminder] = useState(
    {} as DriverExpiry
  );

  const sortDepot = (key: string) => {
    console.log("key --", key);
    const sortBy = sortLabelsAndDirection[key] === "desc" ? "asc" : "desc";
    const updateSort = {} as SortKeys;
    updateSort[key] = sortBy;
    const sortedData = _.orderBy(depots, key, sortBy);
    setFilteredDepotData(sortedData);

    setSortLabelsAndDirection({
      ...sortLabelsAndDirection,
      ...updateSort,
    });
  };

  const sortTruck = (key: string) => {
    const sortBy = sortTruckLabelsAndDirection[key] === "desc" ? "asc" : "desc";
    const updateSort = {} as SortKeys;
    updateSort[key] = sortBy;
    const sortedData = _.orderBy(truckExpiryList, key, sortBy);
    setFilteredTruckData(sortedData);

    setTruckSortLabelsAndDirection({
      ...sortTruckLabelsAndDirection,
      ...updateSort,
    });
  };

  const sortDriver = (key: string) => {
    const sortBy =
      sortDriverLabelsAndDirection[key] === "desc" ? "asc" : "desc";
    const updateSort = {} as SortKeys;
    updateSort[key] = sortBy;
    const sortedData = _.orderBy(driverExpiryList, key, sortBy);
    setFilteredDriverExpiryList(sortedData);

    setDriverSortLabelsAndDirection({
      ...sortDriverLabelsAndDirection,
      ...updateSort,
    });
  };

  const getTruckTableDef = () => {
    let headers = [
      { label: "Name", key: "name", sortable: true },
      {
        label: "Expiry Reminder",
        key: "expiryReminder",
        sortable: true,
      },
    ];

    let columns = ["name", "expiryReminder"];

    return { headers, columns } as EWPTableProps;
  };

  const getDriverTableDef = () => {
    let headers = [
      { label: "Name", key: "name", sortable: true },
      {
        label: "Expiry Reminder",
        key: "expiryReminder",
        sortable: true,
      },
    ];

    let columns = ["name", "expiryReminder"];

    return { headers, columns } as EWPTableProps;
  };

  const getTableDef = () => {
    let headers = [
      { label: "Depo", key: "name", sortable: true },
      {
        label: "Address",
        key: "address",
        sortable: true,
      },
      { label: "Contact Person", key: "contactPerson", sortable: true },
      { label: "Contact Number", key: "contactNumber", sortable: true },
    ];

    let columns = ["name", "address", "contactPerson", "contactNumber"];

    return { headers, columns } as EWPTableProps;
  };

  const renderDepotCell = (value: string, rowData: Depot) => {
    const columnName = value as keyof Depot;
    switch (value) {
      case "name":
        return (
          <IonLabel className="ewp-h5 bold light">
            {rowData[columnName]}
          </IonLabel>
        );
      case "address":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "contactPerson":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "contactNumber":
        return (
          <div className="table-label-with-button-container">
            <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
            <div>
              <IonButton
                fill="clear"
                mode="ios"
                className="delete-button ion-no-padding ion-no-margin"
                onClick={() => {
                  setToDeleteDepot(rowData);
                  setShowDeleteDepotDialog(true);
                }}
              >
                <IonIcon
                  className="delete-depot-icon"
                  icon={trashBin}
                  color={EWPCOLORS.light}
                />
              </IonButton>
              <IonButton
                fill="clear"
                mode="ios"
                className="edit-button ewp-web-gray-button ion-no-padding ion-no-margin"
                onClick={() => {
                  setToEditDepot(rowData);
                  setShowAddNewEditDepot(true);
                }}
              >
                <IonIcon className="edit-icon" />
              </IonButton>
            </div>
          </div>
        );
      default:
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
    }
  };

  const renderTruckCell = (value: string, rowData: TruckExpiry) => {
    const columnName = value as keyof TruckExpiry;
    switch (value) {
      case "name":
        return (
          <IonLabel className="no-button-label ewp-h5 bold light">
            {rowData[columnName]}
          </IonLabel>
        );
      case "expiryReminder":
        const week = rowData[columnName];
        return (
          <div className="table-label-with-button-container">
            <IonLabel className="ewp-paragraph">
              {week !== 0
                ? week !== 1
                  ? `${week} Weeks`
                  : `${week} Week`
                : "N/A"}
            </IonLabel>
            <IonButton
              fill="clear"
              mode="ios"
              className="edit-button ewp-web-gray-button ion-no-padding ion-no-margin"
              onClick={() => {
                setToEditTruckExpiryReminder(rowData);

                setShowTruckExpiry(true);
              }}
            >
              <IonIcon className="edit-icon" />
            </IonButton>
          </div>
        );
      default:
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
    }
  };

  const renderDriverCell = (value: string, rowData: DriverExpiry) => {
    const columnName = value as keyof DriverExpiry;
    switch (value) {
      case "name":
        return (
          <IonLabel className="no-button-label ewp-h5 bold light">
            {rowData[columnName]}
          </IonLabel>
        );
      case "expiryReminder":
        const week = rowData[columnName];
        return (
          <div className="table-label-with-button-container">
            <IonLabel className="ewp-paragraph">
              {week !== 0
                ? week !== 1
                  ? `${week} Weeks`
                  : `${week} Week`
                : "N/A"}
            </IonLabel>
            <IonButton
              fill="clear"
              mode="ios"
              className="edit-button ewp-web-gray-button ion-no-padding ion-no-margin"
              onClick={() => {
                setToEditDriverExpiryReminder(rowData);
                setShowDriverExpiry(true);
              }}
            >
              <IonIcon className="edit-icon" />
            </IonButton>
          </div>
        );
      default:
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
    }
  };

  const getDriverExpiryList = () => {
    services.getDriverExpiryReminders((driverExpiryList, error) => {
      setDriverExpiryList(driverExpiryList);
      setError(error || "");
    });
  };

  const getTruckExpiryList = () => {
    services.getTruckExpiryReminders((truckExpiryList, error) => {
      setTruckExpiryList(truckExpiryList);
      setError(error || "");
    });
  };

  useEffectOnlyOnce(() => {
    getDriverExpiryList();
    getTruckExpiryList();
  });

  return (
    <>
      <div className="admin-container">
        <div className="admin-register-header-container">
          <IonLabel className="admin-title-label ewp-h2 ion-no-padding ion-no-margin">
            Admin
          </IonLabel>
        </div>
        <div className="admin-tables-container">
          <IonGrid className="admin-table-grid ion-no-padding ion-no-margin">
            <IonRow className="admin-table-row ion-no-padding ion-no-margin">
              <IonCol className="admin-table-depot-col ion-no-padding ion-no-margin">
                <div className="admin-table-depot-header-container">
                  <IonLabel className="ewp-h3 ion-no-padding ion-no-margin">
                    Depots
                  </IonLabel>
                  <IonButton
                    mode="ios"
                    color={EWPCOLORS.primary}
                    className="admin-table-add-depot-button ewp-h6"
                    onClick={() => {
                      setShowAddNewEditDepot(true);
                    }}
                  >
                    Add Depot
                  </IonButton>
                </div>
                <EWPDataTable
                  tableDef={getTableDef()}
                  tableData={depots}
                  tableFilteredData={filteredDepotData}
                  renderCell={renderDepotCell}
                  sortLabelsAndDirection={sortLabelsAndDirection}
                  onSort={sortDepot}
                  isComponentPage={true}
                />
              </IonCol>
            </IonRow>
            <IonRow className="admin-table-row ion-no-padding ion-no-margin">
              <IonCol
                className="admin-table-truck-col ion-no-padding ion-no-margin"
                size="6"
              >
                <div className="admin-table-truck-header-container">
                  <IonLabel className="ewp-h3 ion-no-padding ion-no-margin">
                    Truck Expiries
                  </IonLabel>
                  <IonButton
                    mode="ios"
                    color={EWPCOLORS.primary}
                    className="admin-table-add-truck-button ewp-h6"
                    onClick={() => {
                      setShowTruckExpiry(true);
                    }}
                  >
                    Add Expiry
                  </IonButton>
                </div>
                <EWPDataTable
                  tableDef={getTruckTableDef()}
                  tableData={truckExpiryList}
                  tableFilteredData={filteredTruckData}
                  renderCell={renderTruckCell}
                  sortLabelsAndDirection={sortTruckLabelsAndDirection}
                  onSort={sortTruck}
                  isComponentPage={true}
                />
              </IonCol>
              <IonCol
                className="admin-table-driver-col ion-no-padding ion-no-margin"
                size="6"
              >
                <div className="admin-table-driver-header-container">
                  <IonLabel className="ewp-h3 ion-no-padding ion-no-margin">
                    Driver Certifications that Expire
                  </IonLabel>
                  <IonButton
                    mode="ios"
                    color={EWPCOLORS.primary}
                    className="admin-table-add-driver-button ewp-h6"
                    onClick={() => {
                      setShowDriverExpiry(true);
                    }}
                  >
                    Add Expiry
                  </IonButton>
                </div>
                <EWPDataTable
                  tableDef={getDriverTableDef()}
                  tableData={driverExpiryList}
                  tableFilteredData={filteredDriverExpiryList}
                  renderCell={renderDriverCell}
                  sortLabelsAndDirection={sortDriverLabelsAndDirection}
                  onSort={sortDriver}
                  isComponentPage={true}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>

      {/* {showTruckExpiry && ( */}
      <EWPWebDialog
        headerTitle={`${
          !_.isEmpty(toEditTruckExpiryReminder) ? "Edit" : "Add"
        } Truck Expiry`}
        isOpen={showTruckExpiry}
        onDidDismiss={() => {
          setShowTruckExpiry(false);
          setToEditTruckExpiryReminder({} as TruckExpiry);
        }}
      >
        <EWPExpiryReminderForm
          isEdit={!_.isEmpty(toEditTruckExpiryReminder)}
          onModalDidDismiss={() => {
            setShowTruckExpiry(false);
            setToEditTruckExpiryReminder({} as TruckExpiry);
          }}
          isDriverExpiry={false}
          editData={toEditTruckExpiryReminder}
        />
      </EWPWebDialog>
      {/* )} */}
      {toDeleteDepot !== undefined && !_.isEmpty(toDeleteDepot) && (
        <ArchiveOrRestoreTruckDialog
          mode="depot"
          isOpen={showDeleteDepotDialog}
          data={toDeleteDepot as Depot}
          onDidDismiss={() => {
            setShowDeleteDepotDialog(false);
          }}
          onSuccess={() => {
            setShowDeleteDepotDialog(false);
          }}
        />
      )}
      {/* {showDriverExpiry && ( */}
      <EWPWebDialog
        headerTitle={`${
          !_.isEmpty(toEditDriverExpiryReminder) ? "Edit" : "Add"
        } Driver Certification that Expire`}
        isOpen={showDriverExpiry}
        onDidDismiss={() => {
          setShowDriverExpiry(false);
          setToEditDriverExpiryReminder({} as DriverExpiry);
        }}
      >
        <EWPExpiryReminderForm
          isEdit={!_.isEmpty(toEditDriverExpiryReminder)}
          onModalDidDismiss={() => {
            setShowDriverExpiry(false);
            setToEditDriverExpiryReminder({} as DriverExpiry);
          }}
          isDriverExpiry={true}
          editData={toEditDriverExpiryReminder}
        />
      </EWPWebDialog>
      {/* )} */}

      <EWPWebDialog
        headerTitle={_.isEmpty(toEditDepot) ? "Create New Depot" : "Edit Depot"}
        isOpen={showAddNewEditDepot}
        onDidDismiss={() => {
          setShowAddNewEditDepot(false);
        }}
      >
        <EWPDepotForm
          {...(props as any)}
          {...(!_.isEmpty(toEditDepot) && { editData: toEditDepot })}
          isEdit={!_.isEmpty(toEditDepot)}
          onModalDidDismiss={() => {
            setShowAddNewEditDepot(false);
          }}
        />
      </EWPWebDialog>

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />
    </>
  );
};
