import * as _ from "lodash";
import React, { useState } from "react";
import {
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonRippleEffect,
} from "@ionic/react";

import "./EWPExpiryReminderForm.scss";
import * as services from "../../services";
import { formatString } from "../../functions/common";
import { EWPExpiryReminderFormProps } from "./EWPExpiryReminderForm.interface";
import { MSGS_COMMON } from "../../constants/messages";
import {
  EWPCOLORS,
  NON_REMOVEABLE_DRIVER_EXPIRIES,
  NON_REMOVEABLE_TRUCK_EXPIRIES,
} from "../../constants/config";
import { EWPInput } from "../EWPInput/EWPInput";
import { DriverExpiry, TruckExpiry } from "../../models/drivers";
import {
  DropdownItem,
  EWPWebDropdownSelect,
} from "../EWPWebDropdownSelect/EWPWebDropdownSelect";
import { DeleteExpiryDialog } from "../DeleteExpiryDialog/DeleteExpiryDialog";
import Bugsnag from "@bugsnag/js";

export const EWPExpiryReminderForm = (props: EWPExpiryReminderFormProps) => {
  const { isEdit, editData, onModalDidDismiss, isDriverExpiry } = props;
  let existingData = {
    name: "",
    expiryReminder: -1,
  } as DriverExpiry | TruckExpiry;

  if (!_.isEmpty(editData) && !!editData) {
    existingData = _.clone(editData!) as DriverExpiry | TruckExpiry;
  }

  const [expiryName, setExpiryName] = useState(existingData.name);
  const [reminder, setReminder] = useState(
    existingData.expiryReminder !== -1
      ? existingData.expiryReminder.toString()
      : undefined
  );

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [errorMsgDepotName, setErrorMsgDepotName] = useState("");
  const [errorMsgReminder, setErrorMsgReminder] = useState("");

  const reminderOptions: DropdownItem[] = [
    { id: "0", name: "N/A" },
    { id: "1", name: "1 week" },
    { id: "2", name: "2 weeks" },
    { id: "3", name: "3 weeks" },
    { id: "4", name: "4 weeks" },
    { id: "5", name: "5 weeks" },
    { id: "6", name: "6 weeks" },
    { id: "7", name: "7 weeks" },
    { id: "8", name: "8 weeks" },
    { id: "9", name: "9 weeks" },
    { id: "10", name: "10 weeks" },
  ];

  const submitForm = async (event: React.FormEvent) => {
    event.preventDefault();
    console.log("RENDERED submit");
    const formattedName = formatString(expiryName || "");
    let errorMessage = null;

    if (formattedName.length === 0) {
      errorMessage = "Expiry Name is required";
      setErrorMsgDepotName(errorMessage);
    }

    if (reminder === undefined) {
      errorMessage = "Expiry reminder is required.";
      setErrorMsgReminder(errorMessage);
    }

    if (_.isEmpty(errorMessage)) {
      setSaving(true);
      try {
        if (isEdit && !_.isEmpty(editData)) {
          if (isDriverExpiry) {
            await services.updateDriverExpiry(
              editData!.docId || "",
              formattedName,
              +reminder!
            );
          } else {
            await services.updateTruckExpiry(
              editData!.docId || "",
              formattedName,
              +reminder!
            );
          }
          setSaving(false);
          setSuccess("Update Success");
        } else {
          if (isDriverExpiry) {
            await services.createDriverExpiry(formattedName, +reminder!);
          } else {
            await services.createTruckExpiry(formattedName, +reminder!);
          }
          setSaving(false);
          setSuccess("Create Success");
        }
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        setSaving(false);
        setError(error);
        Bugsnag.notify(new Error(error));
      }
    }
  };

  const clearFields = () => {
    setExpiryName("");
    setReminder(undefined);
  };

  const isRemovable =
    !_.isEmpty(editData) &&
    _.isEmpty(
      _.find(
        NON_REMOVEABLE_DRIVER_EXPIRIES,
        (expiry) => expiry.docId === editData!.docId
      )
    ) &&
    _.isEmpty(
      _.find(
        NON_REMOVEABLE_TRUCK_EXPIRIES,
        (expiry) => expiry.docId === editData!.docId
      )
    );

  console.log(
    "SHOULD BE EDITTABLE? --",
    !_.isEmpty(expiryName) && isEdit ? true : false
  );

  return (
    <>
      <form onSubmit={submitForm}>
        <IonGrid className="ion-no-padding ion-no-margin">
          {isEdit && isRemovable && (
            <IonRow className="ewp-expiry-row ion-no-padding ion-no-margin">
              <IonCol
                className="ewp-expiry-start-col ion-no-padding"
                size="9"
              ></IonCol>
              <IonCol
                className="ewp-expiry-end-col delete ion-no-padding"
                size="3"
              >
                <IonButton
                  mode="ios"
                  className="delete-expiry-button ewp-web-gray-button ewp-h6 bold small ion-no-padding ion-no-margin"
                  onClick={() => {
                    setShowDeleteDialog(true);
                  }}
                >
                  Delete Expiry
                </IonButton>
              </IonCol>
            </IonRow>
          )}
          <IonRow className="ewp-expiry-row ion-no-padding ion-no-margin">
            <IonCol size="6" className="ewp-expiry-start-col ion-no-padding">
              <EWPInput
                errorMessage={errorMsgDepotName}
                inputLabel={
                  isDriverExpiry ? "Certification Name" : "Expiry Name"
                }
                inputValue={expiryName}
                inputType="text"
                name="firstName"
                onInputChange={(depotName: string) => {
                  setExpiryName(depotName);
                }}
                disabled={isEdit && !isRemovable}
              />
            </IonCol>
            <IonCol size="6" className="ewp-expiry-end-col ion-no-padding">
              {/* <EWPInput
                errorMessage={errorMsgReminder}
                inputLabel="Expiry Reminder"
                inputValue={reminder}
                inputType="number"
                name="reminder"
                onInputChange={(reminder: string) => {
                  setReminder(parseInt(reminder));
                }}
              /> */}

              <EWPWebDropdownSelect
                dropdownItems={reminderOptions || []}
                onSelectItem={(id) => {
                  setReminder(id.toString());
                  setErrorMsgReminder("");
                }}
                placeholder="Choose Reminder"
                value={reminder !== undefined ? reminder : ""}
                errorMsg={errorMsgReminder}
                hasSearch={false}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonItem
          lines="none"
          className="ewp-item-no-horizontal-padding ewp-button-padding-top"
        >
          <IonButton
            mode="ios"
            slot="start"
            className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
            fill="clear"
            color={EWPCOLORS.medium}
            onClick={() => {
              clearFields();
              props.onModalDidDismiss();
            }}
          >
            Cancel
          </IonButton>
          <IonButton
            mode="md"
            slot="end"
            className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
            color={EWPCOLORS.primary}
            type="submit"
          >
            {isEdit ? "Save" : "Add Expiry"}
            <IonRippleEffect />
          </IonButton>
        </IonItem>
      </form>
      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={saving}
        message={MSGS_COMMON.saving}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
          clearFields();
          onModalDidDismiss();
        }}
        color={EWPCOLORS.success}
      />

      {showDeleteDialog && !_.isEmpty(editData) && isEdit && (
        <DeleteExpiryDialog
          isOpen={showDeleteDialog}
          mode={isDriverExpiry ? "driver" : "truck"}
          expiry={editData!}
          onDidDismiss={() => {
            setShowDeleteDialog(false);
          }}
          onSuccess={() => {
            onModalDidDismiss();
          }}
        />
      )}
    </>
  );
};
