import * as _ from "lodash";
import Bugsnag from "@bugsnag/js";
import React from "react";
import {
  IonContent,
  IonItem,
  IonSlides,
  IonSlide,
  IonLoading,
  IonLabel,
} from "@ionic/react";

import "./ProfileSettings.scss";
import * as services from "../../../services";
import { User } from "../../../models/global";
import { auth, storage } from "../../../firebase";
import WebChangePassword from "../WebChangePassword/WebChangePassword";
import { EWPDivider } from "../../../components/EWPDivider/EWPDivider";
import { EWPProps } from "../../../config/global";
import { EWPUserAccountForm } from "../../../components/EWPUserAccountForm/EWPUserAccountForm";
import { EWPProfilePhoto } from "../../../components/EWPProfilePhoto/EWPProfilePhoto";

interface ProfileSettingProps extends EWPProps {
  setPhotoURL: (photoUrl: string) => void;
}

class ProfileSettings extends React.Component<ProfileSettingProps> {
  sliderRef: React.RefObject<HTMLIonSlidesElement>;
  contentRef: React.RefObject<HTMLIonContentElement>;
  state = {
    currentPage: 0,
    editData: {},
    toUploadProfilePicture: {} as any,
    temporaryProfilePicture: {} as any,
    // userID: "", //todo: enable this once user model is okay
    removeProfilePictureFlag: false,
    photoUploading: false,
    previousProfilePhoto: auth.currentUser!.photoURL!,
  };

  page = {
    accountSetting: 0,
    changePassword: 1,
    changeNumber: 2,
  };

  constructor(props: ProfileSettingProps) {
    super(props);
    this.sliderRef = React.createRef();
    this.contentRef = React.createRef();
  }

  onEditProfilePic = async (file: any) => {
    if (!!file && file.target.files.length > 0) {
      const attachment = file.target.files[0];

      this.setState({
        toUploadProfilePicture: attachment,
        temporaryProfilePicture: URL.createObjectURL(attachment),
      });
    } else {
      // if null activate remove flag
      this.setState({
        temporaryProfilePicture: "",
        removeProfilePictureFlag: true,
      });
    }
  };

  componentDidMount = () => {
    if (!!this.sliderRef && !!this.sliderRef.current) {
      this.sliderRef.current.lockSwipes(true);
    }
    if (
      !_.isEmpty(this.props.authUser) &&
      (!this.props.authUser.phoneNumber ||
        !this.props.authUser.phoneNumberVerified)
    ) {
      this.setState({ userID: this.props.authUser.docId });
    }
  };

  saveProfilePictureCallback = async () => {
    this.setState({ photoUploading: true });
    const { toUploadProfilePicture, removeProfilePictureFlag } = this.state;
    const currentProfilePhoto = auth.currentUser!.photoURL;
    try {
      if (!!toUploadProfilePicture && !removeProfilePictureFlag) {
        const { authUser } = this.props;
        const ref = storage.ref();
        const profilePicturePath = `profilePictures/${authUser.lastName
          .replace(" ", "")
          .toLowerCase()}_${
          authUser.docId
        }/profile_picture_${new Date().valueOf()}`;
        const uploadProfilePictureSnapshot = await ref
          .child(profilePicturePath)
          .put(toUploadProfilePicture);
        const newProfilePictureUrl =
          await uploadProfilePictureSnapshot.ref.getDownloadURL();
        await services.updateFirebaseUserPhotoUrl(newProfilePictureUrl);
        this.props.setPhotoURL(newProfilePictureUrl);
        this.setState({
          previousProfilePhoto: auth.currentUser!.photoURL,
          photoUploading: false,
        });
        if (!!currentProfilePhoto) {
          const refFromUrl = storage.refFromURL(currentProfilePhoto);
          await refFromUrl.delete();
        }
      } else {
        this.setState({ photoUploading: true });
        await services.removedFirebaseUserPhotoUrl();
        this.props.setPhotoURL("");
        if (!!currentProfilePhoto) {
          // delete existing photo
          const refFromUrl = storage.refFromURL(currentProfilePhoto);
          if (!!refFromUrl && !_.isEmpty(refFromUrl)) await refFromUrl.delete();
          this.props.setPhotoURL("");
          this.setState({
            previousProfilePhoto: auth.currentUser!.photoURL,
            removeProfilePictureFlag: false,
            photoUploading: false,
          });
        }
      }
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      this.setState({ photoUploading: false });
      Bugsnag.notify(new Error(error));
    }
  };
  goToPage = (page: number) => {
    if (!!this.sliderRef && !!this.sliderRef.current) {
      this.sliderRef.current.lockSwipes(false);
      this.sliderRef.current.slideTo(page);
      this.sliderRef.current.lockSwipes(true);
    }
  };

  render = () => {
    let editData;
    const { authUser } = this.props;
    const {
      currentPage,
      previousProfilePhoto,
      temporaryProfilePicture,
      removeProfilePictureFlag,
      photoUploading,
    } = this.state;

    // const initials = (
    //   authUser.firstName[0] + authUser.lastName[0]
    // ).toUpperCase();

    const initials = "AT"; //!todo: replace this once model is okay

    if (!_.isEmpty(authUser)) {
      editData = {
        userId: authUser.docId,
        /**
         * Invoking currentUser to be not-null since this component
         * is inside an authenticated route thus will automatically
         * redirect to signup page if currentUser is null
         */
        emailAddress: auth.currentUser!.email!,
        isEmailVerified: auth.currentUser!.emailVerified!,
        /**
         * END
         */
        onUpdateCallback: this.saveProfilePictureCallback,
        data: {
          firstName: authUser.firstName,
          lastName: authUser.lastName,
          phoneNumber: authUser.phoneNumber!,
        } as User,
      };
    }

    const profileToDisplay = !removeProfilePictureFlag
      ? !_.isEmpty(temporaryProfilePicture)
        ? temporaryProfilePicture
        : previousProfilePhoto
      : "";
    return (
      <>
        <IonContent
          className="profile-settings-content-container"
          ref={this.contentRef}
        >
          <IonSlides
            ref={this.sliderRef}
            onIonSlideDidChange={async () => {
              if (!!this.contentRef && !!this.contentRef.current) {
                this.contentRef.current.scrollToTop();
              }

              const activeIndex =
                await this.sliderRef.current!.getActiveIndex();
              this.setState({ currentPage: activeIndex });
            }}
            onIonSlidesDidLoad={async () => {
              this.sliderRef.current!.lockSwipes(true);
              const activeIndex =
                await this.sliderRef.current!.getActiveIndex();

              this.setState({ currentPage: activeIndex });
            }}
            scrollbar={false}
          >
            <IonSlide
              className="profile-settings-slide-container noselect"
              tabIndex={this.page.accountSetting}
            >
              <div
                className={`profile-settings-slide-content-container ${
                  currentPage !== this.page.accountSetting ? "hidden" : ""
                }`}
              >
                <IonLabel className="profile-settings-title ion-text-start ion-no-padding">
                  Account Settings
                </IonLabel>
                <div className="profile-settings-container">
                  <IonItem
                    lines="none"
                    className="profile-settings-header-container ion-no-padding"
                  >
                    <EWPProfilePhoto
                      imgSrc={profileToDisplay || ""}
                      onEditProfilePic={this.onEditProfilePic}
                      initials={initials}
                    />
                    {/* //todo: make this dynamic once user model is okay */}
                    <IonLabel className="profile-settings-name-label ewp-h2 ion-text-capitalize">
                      {`${authUser.firstName} ${authUser.lastName}`}
                    </IonLabel>
                  </IonItem>
                  <EWPDivider />
                  <div className="profile-settings-details-container">
                    <IonLabel className="ion-no-padding ion-padding-bottom ion-text-start">
                      Personal Details
                    </IonLabel>
                    <EWPUserAccountForm
                      isEdit={true}
                      editData={editData}
                      isPhotoStillUploading={photoUploading}
                      onChangePassword={() => {
                        this.goToPage(this.page.changePassword); //redirects to profile TODO: create interface
                      }}
                      onChangePhoneNumber={() => {
                        this.goToPage(this.page.changeNumber); //Redirects to change number TODO: create interface
                      }}
                    />
                  </div>
                </div>
              </div>
            </IonSlide>
            <IonSlide
              className="profile-settings-slide-container noselect"
              tabIndex={this.page.changePassword}
            >
              <div
                className={`profile-settings-slide-content-container ${
                  currentPage !== this.page.changePassword ? "hidden" : ""
                }`}
              >
                <WebChangePassword
                  gotoProfileSettings={() => {
                    this.goToPage(this.page.accountSetting);
                  }}
                  {...this.props}
                />
              </div>
            </IonSlide>
            <IonSlide
              className="profile-settings-slide-container noselect"
              tabIndex={this.page.changeNumber}
            ></IonSlide>
          </IonSlides>
        </IonContent>
        <IonLoading
          spinner="circular"
          translucent={true}
          mode="ios"
          isOpen={photoUploading}
          message={"Loading..."}
        />
      </>
    );
  };
}

export default ProfileSettings;
