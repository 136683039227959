import _ from "lodash";
import moment from "moment";
import React from "react";
import {
  IonButton,
  IonIcon,
  IonLabel,
  IonLoading,
  IonPopover,
  IonToast,
} from "@ionic/react";

import "./JobScheduleMainPage.scss";
import * as api from "../../../api";
import * as capacitorStorage from "../../../functions/localStorageCapacitor";
import * as routes from "../../../constants/routes";
import * as services from "../../../services";
import {
  getDateFromTimezoneIgnoredTimestamp,
  getDateRange,
  isTablet,
  TimezoneIgnoredTimeStamp,
} from "../../../functions/common";
import { EWPProps } from "../../../config/global";
import { JobScheduleCalendar } from "../../../components/JobScheduleCalendar/JobScheduleCalendar";
import { JobScheduleDetailDialog } from "../../../components/JobScheduleDetailDialog/JobScheduleDetailDialog";
import { JobScheduleView, NotifiedDriverNotes } from "../../../models";
import { EWPCOLORS } from "../../../constants/config";
import { LOCAL_STORAGE } from "../../../constants/localStorage";
import { AttachmentDialog } from "../../../components/Web/AttachmentDialog/AttachmentDialog";
import { SoftDeleteJobSchedule } from "../../../components/SoftDeleteJobSchedule/SoftDeleteJobSchedule";
import { UncancelJobSchedule } from "../../../components/UncancelJobSchedule/UncancelJobSchedule";
import { notificationsSharp } from "ionicons/icons";
import { EWPDivider } from "../../../components/EWPDivider/EWPDivider";
import qs from "qs";
import { GlobalContext, GlobalStateModel } from "../../../context/GlobalState";

class JobScheduleMainPage extends React.Component<EWPProps> {
  state = {
    jobSchedules: null as JobScheduleView[] | null,
    unsubscribeJobSchdules: () => {},
    lastDataUpdate: new Date().valueOf(),
    loading: true,
    selectedSchdule: {} as JobScheduleView,
    showSiteDocketsDialog: false,
    showJobScheduleDetail: false,
    attachmentDialogOpen: false,
    attachmentIndexToOpen: null as null | number,
    jobToCancel: null as null | JobScheduleView,
    showCancelDialog: false,
    jobToUncancel: null as null | JobScheduleView,
    showUncancelDialog: false,
    scrollToDriverNotes: false,

    showNotificationPopOver: false,
    notificationDropdownEvent: undefined,

    startDate: new Date(),
    endDate: new Date(),

    error: "",
  };

  componentDidMount = () => {
    // const newDate = _.cloneDeep(new Date());
    // const { startDate, endDate } = getDateRange(
    //   newDate,
    //   "week" // default
    // );
    const { startDate, endDate } = getDateRange(
      new Date(),
      "week" // default
    );

    this.setState({
      startDate,
      endDate,
    });
    this.getJobSchedules(startDate, endDate);

    this.initXero();
  };

  initXero = async () => {
    try {
      const propSearch = qs.parse(this.props.location.search);
      if (
        Object.keys(propSearch).indexOf("scope") !== -1 &&
        Object.keys(propSearch).indexOf("session_state") !== -1
      ) {
        const tokenSet = await api.xeroAuthCallback(this.props.location.search);
        console.log("GOT TOKEN SET -- ", tokenSet);
        const {
          access_token,
          expires_at,
          id_token,
          scope,
          session_state,
          token_type,
          refresh_token,
        } = tokenSet.tokenSet;
        await capacitorStorage.setItem(
          LOCAL_STORAGE.XERO.access_token,
          access_token
        );

        await capacitorStorage.setItem(
          LOCAL_STORAGE.XERO.refresh_token,
          refresh_token
        );

        await capacitorStorage.setItem(
          LOCAL_STORAGE.XERO.refresh_token_expiry,
          moment(new Date()).add(59, "days").toDate().valueOf().toString()
        );

        await capacitorStorage.setItem(
          LOCAL_STORAGE.XERO.expires_at,
          expires_at
        );

        await capacitorStorage.setItem(LOCAL_STORAGE.XERO.id_token, id_token);
        await capacitorStorage.setItem(LOCAL_STORAGE.XERO.scope, scope);
        await capacitorStorage.setItem(
          LOCAL_STORAGE.XERO.session_state,
          session_state
        );
        await capacitorStorage.setItem(
          LOCAL_STORAGE.XERO.token_type,
          token_type
        );
        this.props.history.replace({
          search: "",
        });
      }
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("ERROR UPDATING CREDS ", error);
      this.setState({
        error: error.message || error,
      });
    }
  };

  componentWillUnmount = () => {
    this.state.unsubscribeJobSchdules();
  };

  getJobSchedules = async (startDateJob: Date, endDateJob: Date) => {
    const { match } = this.props;
    const { startDate, endDate } = this.state;
    const jobSchedId = (match.params as any).pageParam;

    if (
      _.isNull(this.state.jobSchedules) ||
      startDate.getMonth() !== startDateJob.getMonth() ||
      endDate.getMonth() !== endDateJob.getMonth()
    ) {
      this.setState({
        startDate: startDateJob,
        loading: !_.isNull(this.state.jobSchedules),
        endDate: endDateJob,
      });
      this.state.unsubscribeJobSchdules();

      // TO paginate, use sstart and end date in create job schedule, the one not parsed to unreadable date

      const unsubscribeJobSchdules =
        await services.getJobSchedulesRangeRealTimeView(
          (jobSchedules, error) => {
            this.setState({
              jobSchedules,
              loading: false,
              lastDataUpdate: new Date().valueOf(),
              error,
            });

            // jobSchedThrottled = jobSchedules;
            // errorThrottled = error;
            // lastDateUpdateThrottled = new Date().valueOf();
            // if (!_.isNull(timerId)) {
            //   clearTimeout(timerId);
            // }

            // if (new Date().valueOf() - lastUserUpdate < 1000) {
            //   this.setState({
            //     jobSchedules: jobSchedThrottled,
            //     error: errorThrottled,
            //   });
            //   this.setState({
            //     lastDataUpdate: lastDateUpdateThrottled,
            //   });
            // } else {
            //   console.log("WILL RUN AFTER 5 SECS");
            //   timerId = setTimeout(() => {
            //     this.setState({
            //       jobSchedules: jobSchedThrottled,
            //       error: errorThrottled,
            //     });
            //     this.setState({
            //       lastDataUpdate: lastDateUpdateThrottled,
            //     });
            //   }, 5000);
            // }

            if (!_.isEmpty(jobSchedules)) {
              if (!_.isEmpty(match.params) && jobSchedId !== undefined) {
                this.showSelectedScheduleDetail(jobSchedId);
              }
            }
          },
          startDateJob.getMonth(),
          endDateJob.getMonth()
        );

      this.setState({
        unsubscribeJobSchdules,
      });
    }
  };
  showSelectedScheduleDetail = async (scheduleId: string) => {
    this.setState({ loading: true });
    const { jobSchedules } = this.state;
    const { refreshInvoices } = this.context as GlobalStateModel;
    const selectedSchdule = _.find(
      jobSchedules,
      (jobSchedule) => jobSchedule.docId === scheduleId
    );
    if (!!selectedSchdule) {
      const listOfBookedTrucks = await services.getBookedTrucks(
        getDateFromTimezoneIgnoredTimestamp(
          selectedSchdule.startDateNoTimeZone
        ),
        getDateFromTimezoneIgnoredTimestamp(selectedSchdule.endDateNoTimeZone)
        // toDateTimeFromSecs(selectedSchdule.startDate.seconds) as Date,
        // toDateTimeFromSecs(selectedSchdule.endDate.seconds) as Date
      );
      const numberOfTruckBookings = _.filter(
        listOfBookedTrucks,
        (bookedTruck) => bookedTruck.docId === selectedSchdule.truckId
      ).length;
      refreshInvoices();
      this.setState({
        selectedSchdule: {
          ...selectedSchdule,
          truckHasBooking: numberOfTruckBookings > 1, //if truck already have more than 1 booking, this display warning
        },
        showJobScheduleDetail: true,
        loading: false,
      });
    } else {
      this.setState({ selectedSchdule: {}, loading: false });
    }
  };
  updateSeenNotification = async (
    jobScheduleId: string,
    driverNotes: NotifiedDriverNotes
  ) => {
    if (jobScheduleId) {
      await services.updateNotificationJobSeen(jobScheduleId, driverNotes);
    }
  };
  render = () => {
    const { refreshInvoices } = this.context as GlobalStateModel;
    const {
      jobSchedules,
      loading,
      selectedSchdule,
      lastDataUpdate,
      error,
      showSiteDocketsDialog,
      showJobScheduleDetail,
      jobToCancel,
      showCancelDialog,
      jobToUncancel,
      showUncancelDialog,
      showNotificationPopOver,
      notificationDropdownEvent,
      scrollToDriverNotes,
    } = this.state;
    const notifiedJobSchedules = _.compact(
      (jobSchedules || []).map((schedule) => {
        return !!schedule.driverNotes &&
          !_.isEmpty(schedule.driverNotes) &&
          schedule.driverNotes.adminSeen === false
          ? schedule
          : null;
      })
    );
    // console.log("constant refresh in main page");
    return (
      <>
        <div className="job-schedule-container">
          <div className="job-schedule-header-container">
            <div className="title-notifcation-container">
              <IonLabel className="ewp-h2">Job Schedule</IonLabel>
              <IonButton
                fill="clear"
                color={
                  notifiedJobSchedules.length
                    ? EWPCOLORS.primary
                    : EWPCOLORS.light
                }
                className="notification-button"
                onClick={(e) => {
                  // faultAndDefect.setNotesDialogOpen(faultAndDefect);
                  // setShowJobAdminNotes(true);
                  // @TODO: add props onShow Notification show the job details modal dialog
                  this.setState({
                    showNotificationPopOver: true,
                    notificationDropdownEvent: e.nativeEvent,
                  });
                }}
              >
                <IonIcon icon={notificationsSharp}></IonIcon>
                <div
                  className={`notification-counter ${
                    notifiedJobSchedules.length && "has-notifications"
                  }`}
                >
                  <IonLabel className="ewp-paragraph small white">
                    {notifiedJobSchedules.length}
                  </IonLabel>
                </div>
              </IonButton>
            </div>
            <IonButton
              mode="ios"
              className="job-schedule-add-button ewp-h5 white ion-text-capitalize ion-no-margin"
              routerLink={routes.CREATE_JOB_SCHEDULE}
            >
              <IonIcon className="job-schedule-add-icon" />
              New Job
            </IonButton>
          </div>

          <div
            className={`job-schedule-calendar-container ${
              isTablet() && "ipad"
            }`}
          >
            <JobScheduleCalendar
              // key={
              //   !!jobSchedules
              //     ? jobSchedules
              //         .map((schedule, index) => {
              //           return `jobScheduleCalendar_${_.truncate(
              //             schedule.docId,
              //             { length: 5 }
              //           )}`;
              //         })
              //         .join()
              //     : "job_calendar_key"
              // }
              jobSchedules={jobSchedules}
              lastDataUpdate={lastDataUpdate}
              onChangeDates={(startDate, endDate) => {
                this.getJobSchedules(startDate, endDate);
                // console.log("CHANGE DATE CALLED -- ", {
                //   startDate,
                //   endDate,
                // });
              }}
              showSelectedScheduleDetail={this.showSelectedScheduleDetail}
            />
          </div>
        </div>
        {!!selectedSchdule && !_.isEmpty(selectedSchdule) && (
          <JobScheduleDetailDialog
            onCancel={(jobToCancel) => {
              this.setState({
                jobToCancel,
                showJobScheduleDetail: false,
                showCancelDialog: true,
              });
            }}
            onUncancel={(jobToUncancel) => {
              this.setState({
                jobToUncancel,
                showJobScheduleDetail: false,
                showUncancelDialog: true,
              });
            }}
            isOpen={showJobScheduleDetail}
            jobSchedule={selectedSchdule}
            showSiteDockets={() => {
              if (
                !!selectedSchdule.siteDockets &&
                !_.isEmpty(selectedSchdule.siteDockets)
              ) {
                this.setState({
                  showSiteDocketsDialog: true,
                  showJobScheduleDetail: false,
                });
              } else {
                this.setState({
                  error: "No site dockets uploaded for this job schedule.",
                });
              }
            }}
            onDidDismiss={() => {
              console.log("WILL TRY TO DISMISS");
              this.setState({
                showJobScheduleDetail: false,
                scrollToDriverNotes: false,
              });
            }}
            onShowAttachmentDialog={(isOpen: boolean) => {
              this.setState({
                attachmentDialogOpen: isOpen,
                showJobScheduleDetail: null,
              });
            }}
            onAttachmentToOpen={(attachmentIndex: number) => {
              this.setState({ attachmentIndexToOpen: attachmentIndex });
            }}
            scrollToDriverNotes={scrollToDriverNotes}
            {...this.props}
          />
        )}
        {
          // !!showSiteDocketsDialog &&
          !!selectedSchdule.siteDockets &&
            !_.isEmpty(selectedSchdule.siteDockets) && (
              <AttachmentDialog
                attachments={selectedSchdule.siteDockets.map((siteDocket) => {
                  return {
                    notApplicable: !!siteDocket.notApplicable,
                    attachmentUrl: siteDocket.attachmentURL,
                    photoName: siteDocket.siteDocketName,
                    title: `Job date: ${moment(
                      // toDateTimeFromSecs(
                      //   (siteDocket.siteDocketDate as firebase.firestore.Timestamp)
                      //     .seconds
                      // )
                      getDateFromTimezoneIgnoredTimestamp(
                        siteDocket.siteDocketDateNoTimeZone as TimezoneIgnoredTimeStamp
                      )
                    ).format("ddd DD MMM YYYY")} / Upload date: ${moment(
                      (
                        siteDocket.uploadDate as firebase.firestore.Timestamp
                      ).toDate()
                    ).format("DD MMM YYYY, hh:mm:A")}`,
                    brokenFile: !!siteDocket.brokenFile,
                  };
                })}
                showModal={showSiteDocketsDialog}
                onModalDidDismiss={() => {
                  refreshInvoices();
                  this.setState({
                    showSiteDocketsDialog: false,
                    showJobScheduleDetail: true,
                  });
                }}
              />
            )
        }
        {!_.isEmpty(selectedSchdule) &&
          !!selectedSchdule &&
          !!selectedSchdule.attachments &&
          selectedSchdule.attachments.length > 0 && (
            <AttachmentDialog
              attachments={selectedSchdule.attachments.map((url) => {
                return { attachmentUrl: url };
              })}
              title="Job Schedule Attachments"
              showModal={this.state.attachmentDialogOpen}
              isTitleHTML={true}
              {...(!_.isNull(this.state.attachmentIndexToOpen) && {
                selectedIndex: this.state.attachmentIndexToOpen,
              })}
              onModalDidDismiss={() => {
                refreshInvoices();
                this.setState({
                  attachmentDialogOpen: false,
                  showJobScheduleDetail: true,
                });
              }}
            />
          )}

        {showCancelDialog && !_.isEmpty(jobToCancel) && (
          <SoftDeleteJobSchedule
            isCancelled={true}
            isOpen={showCancelDialog}
            notify={jobToCancel!.notifyDriver}
            jobSchedule={jobToCancel!}
            onDidDismiss={() => {
              this.setState({
                showCancelDialog: false,
              });
            }}
            onCancel={() => {
              refreshInvoices();
              this.setState({
                showCancelDialog: false,
                showJobScheduleDetail: true,
              });
            }}
            onSuccess={() => {
              this.setState({
                showCancelDialog: false,
              });
            }}
          />
        )}
        {showUncancelDialog && !_.isEmpty(jobToUncancel) && (
          <UncancelJobSchedule
            isCancelled={true}
            isOpen={showUncancelDialog}
            notify={jobToUncancel!.notifyDriver}
            jobSchedule={jobToUncancel!}
            onDidDismiss={() => {
              this.setState({
                showUncancelDialog: false,
              });
            }}
            onCancel={() => {
              refreshInvoices();
              this.setState({
                showUncancelDialog: false,
                showJobScheduleDetail: true,
              });
            }}
            onSuccess={() => {
              this.setState({
                showUncancelDialog: false,
              });
            }}
          />
        )}
        {/* NOTIFICATION POP OVER */}
        <IonPopover
          isOpen={showNotificationPopOver}
          event={notificationDropdownEvent}
          className="ewp-header-notification-popover-container"
          showBackdrop={false}
          onDidDismiss={(e) =>
            this.setState({
              showNotificationPopOver: false,
            })
          }
        >
          <div className="pop-over-content">
            {notifiedJobSchedules.length ? (
              notifiedJobSchedules.map(
                (notifiedJob: JobScheduleView, index) => {
                  const { driverNotes } = notifiedJob;
                  return (
                    <React.Fragment key={`notif-job-${index}`}>
                      {driverNotes && (
                        <>
                          <div
                            className="notifications-container"
                            onClick={() => {
                              this.showSelectedScheduleDetail(
                                notifiedJob.docId as string
                              );
                              this.updateSeenNotification(
                                notifiedJob.docId as string,
                                driverNotes
                              );
                              this.setState({ scrollToDriverNotes: true });
                            }}
                          >
                            <IonLabel className="ewp-paragraph primary bold">{`${notifiedJob.driverDetails.firstName} ${notifiedJob.driverDetails.lastName}`}</IonLabel>
                            <IonLabel className="ewp-paragraph bold">{`${
                              driverNotes.updateDate ? "Updated" : "Sent"
                            } a Note`}</IonLabel>
                            <IonLabel className="note-message ewp-paragraph">
                              {driverNotes.description}
                            </IonLabel>
                            <IonLabel className="ewp-paragraph small">
                              Job: {notifiedJob.docId}
                            </IonLabel>
                          </div>
                          {notifiedJobSchedules.length !== index + 1 && (
                            <EWPDivider />
                          )}
                        </>
                      )}
                    </React.Fragment>
                  );
                }
              )
            ) : (
              <div className="empty-notification">
                <IonLabel className="ewp-paragraph bold">
                  No new notifications
                </IonLabel>
              </div>
            )}
          </div>
        </IonPopover>

        <IonLoading
          spinner="circular"
          cssClass="job-sched-loading"
          translucent={true}
          mode="ios"
          isOpen={loading}
        />
        <IonToast
          isOpen={!_.isEmpty(error)}
          message={error}
          duration={2000}
          onDidDismiss={() => {
            this.setState({ error: "" });
          }}
          color={EWPCOLORS.danger}
        />
      </>
    );
  };
}

JobScheduleMainPage.contextType = GlobalContext;

export default JobScheduleMainPage;
