import React from "react";

import "./EWPPageTitleAndDescription.scss";

export const EWPPageTitleAndDescription = ({
  title,
  description,
  subtitle = "",
  alignment = "ion-text-center",
  className,
  htmlMode,
  clamp,
}: {
  title: string;
  description?: string;
  subtitle?: string;
  alignment?: "ion-text-center" | "ion-text-left" | "ion-text-right";
  className?: string;
  htmlMode?: boolean;
  clamp?: boolean;
}) => (
  <div
    className={`ewp-page-title-and-description-container ${
      className !== undefined ? className : ""
    }`}
  >
    <div className="ewp-title-div ewp-h1">{title}</div>
    {!!subtitle && (
      <div className="ewp-title-div">
        <i>{subtitle}</i>
      </div>
    )}
    {!!description &&
      (htmlMode ? (
        <div
          className={`ewp-description-div ewp-body ${alignment} ${
            alignment === "ion-text-left" && "align-left"
          } ${clamp ? "clamp" : ""}`}
          {...(htmlMode
            ? { dangerouslySetInnerHTML: { __html: description } }
            : {})}
        />
      ) : (
        <div
          className={`ewp-description-div ewp-paragraph ${alignment} ${
            alignment === "ion-text-left" && "align-left"
          } ${clamp ? "clamp" : ""}`}
        >
          {description}
        </div>
      ))}
  </div>
);
