import * as _ from "lodash";
import { caretDown, close } from "ionicons/icons";
import React, { useState, useEffect } from "react";
import {
  IonLabel,
  IonIcon,
  IonPopover,
  IonList,
  IonItem,
  IonSearchbar,
  IonCheckbox,
  IonButton,
  IonGrid,
  IonCol,
  IonRow,
} from "@ionic/react";

import "./EWPWebDropdownSelect.scss";
import { EWPCOLORS } from "../../constants/config";
import moment from "moment";

export interface DropdownItem {
  name: string;
  id: string | number;
  subName?: string;
  metadata?: any;
}
interface EWPWebDropdownSelectProps {
  dropdownItems: DropdownItem[];
  onSelectItem?: (selectedItem: string | number) => void;
  onSelectItems?: (selectedItems: string[]) => void;
  itemsHeader?: string;
  placeholder: string;
  isSelectMultiple?: boolean;
  errorMsg?: string;
  value?: string | string[];
  defaultSelected?: string;
  disabled?: boolean;
  label?: string;
  hasSearch?: boolean;
  itemPictures?: { [id: string]: string };
  widthSize?: string;
  className?: string;
  small?: boolean;
}
export const EWPWebDropdownSelect = (props: EWPWebDropdownSelectProps) => {
  const {
    dropdownItems,
    onSelectItem,
    itemsHeader,
    isSelectMultiple,
    placeholder,
    onSelectItems,
    errorMsg,
    value,
    defaultSelected,
    label,
    disabled,
    hasSearch,
    widthSize,
    className,
    small,
  } = props;
  const [showDropdownList, setShowDropdownList] = useState(false);
  const [dropdownEvent, setDropdownEvent] = useState(undefined) as any;
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedItems, setSelectedItems] = useState([] as string[]);
  const [filteredItems, setFilteredItems] = useState([] as DropdownItem[]);
  const [showItemContainer, setShowItemContainer] = useState(false);
  const [currentDefaultSelected, setCurrentDefaultSelected] = useState("");

  const [dropDownOptionsState, setDropDownOptionsState] = useState("");

  const filterDropdownItems = (query: string) => {
    const filteredList = _.filter(
      dropdownItems,
      (dropdownItem: DropdownItem) => {
        if (!!query)
          return (
            dropdownItem.name.toLowerCase().indexOf(query.toLowerCase()) !==
              -1 ||
            dropdownItem.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
          );
      }
    ) as DropdownItem[];
    setFilteredItems(filteredList);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const newDropdownOptionsState = dropdownItems
      .map((item) => item.id)
      .join("");

    if (
      !_.isEmpty(value) &&
      value !== undefined &&
      newDropdownOptionsState !== dropDownOptionsState
    ) {
      if (isSelectMultiple && value instanceof Array) {
        setSelectedItems(value);
        setShowItemContainer(true);
      } else {
        setSelectedItem(getName(value as string));
      }

      setDropDownOptionsState(newDropdownOptionsState);
    } else if (_.isEmpty(value)) {
      setSelectedItem("");
    }

    if (
      defaultSelected !== undefined &&
      !_.isEmpty(defaultSelected) &&
      isSelectMultiple &&
      currentDefaultSelected !== defaultSelected
    ) {
      setCurrentDefaultSelected(defaultSelected);
      const isCurrentlySelected = !_.isEmpty(
        selectedItems.find((item) => item === defaultSelected)
      );

      if (!isCurrentlySelected) {
        const newSelectedItems = [...selectedItems, defaultSelected];
        setSelectedItems(newSelectedItems);
        setShowItemContainer(true);

        if (onSelectItems !== undefined) {
          onSelectItems(newSelectedItems);
        }
      }
    }
  });

  const getName = (id: string) => {
    const result = _.find(dropdownItems, (item) => item.id.toString() === id);

    return !_.isEmpty(result) && result !== undefined ? result.name : "N/A";
  };

  const getSubName = (id: string) => {
    const result = _.find(dropdownItems, (item) => item.id === id);
    return {
      name: !!result && !_.isEmpty(result.subName) ? result.subName : "N/A",
      hasSubName: !!result && !_.isEmpty(result.subName),
    };
  };

  const isItemCheck = (itemId: string | number) => {
    const isAlreadySelected = _.find(selectedItems, (item) => {
      return item === itemId;
    });
    return !_.isEmpty(isAlreadySelected) ? true : false;
  };

  return (
    <div>
      <IonItem
        lines="none"
        className={`ewp-web-dropdown-select-list-item ${className} ${
          !_.isEmpty(selectedItem) && "has-value"
        } ${!_.isEmpty(errorMsg) && "has-error"} ${small ? "small" : ""}`}
        onClick={(e) => {
          if (!disabled) {
            setShowDropdownList(true);
            setDropdownEvent(e.nativeEvent);
          } else {
            e.preventDefault();
          }
        }}
      >
        {label !== undefined &&
          !_.isEmpty(label) &&
          !_.isEmpty(selectedItem) && (
            <IonLabel
              className={`ewp-web-dropdown-label ewp-paragraph primary ion-no-margin ${
                !_.isEmpty(errorMsg) && "danger"
              }`}
            >
              {label}
            </IonLabel>
          )}
        <IonLabel
          className={`ewp-web-dropdown-selected-label ${
            !!errorMsg && "has-error"
          } ewp-body ${
            !_.isEmpty(label) && !_.isEmpty(selectedItem)
              ? "has-label-and-data"
              : !_.isEmpty(selectedItem) && "dark"
          } ion-no-margin 
            ${disabled && "is-read-only"}`}
        >
          {!_.isEmpty(selectedItem) ? selectedItem : placeholder}
        </IonLabel>
        {!disabled && (
          <IonIcon
            slot="end"
            className="ewp-web-dropdown-select-item-icon"
            icon={caretDown}
          />
        )}
      </IonItem>
      {!_.isEmpty(errorMsg) && (
        <div className="ewp-input-error-container">
          <IonLabel className="ewp-input-error-msg ewp-paragraph ion-no-margin ion-no-padding">
            {errorMsg}
          </IonLabel>
        </div>
      )}

      {isSelectMultiple && !!onSelectItems ? (
        <>
          {showItemContainer && (
            <div className="ewp-web-dropdown-selected-items-container">
              <IonList className="ewp-web-dropdown-selected-items-list">
                <IonGrid className="ion-no-padding">
                  {_.chunk(selectedItems, 2).map((row) => {
                    return (
                      <IonRow key={`row_${row[0]}`}>
                        {row.map((col: string) => {
                          const subNameProps = getSubName(col);
                          return (
                            <IonCol
                              className="ewp-web-dropdown-selected-items-col ion-no-padding"
                              size="6"
                              key={col}
                            >
                              <IonItem
                                key={col}
                                lines="none"
                                className="ewp-web-dropdown-selected-items-item"
                              >
                                {/* {itemPictures === undefined ? (
                                  <IonIcon className="ewp-web-dropdown-item-icon" />
                                ) : Object.keys(itemPictures).indexOf(col) ===
                                  -1 ? (
                                  <div className="ewp-web-dropdown-initials-container">
                                    <IonLabel className="ewp-web-dropdown-initials ewp-h5 white ion-text-uppercase ion-no-margin ion-text-center">
                                      <IonSpinner color={EWPCOLORS.primary} />
                                    </IonLabel>
                                  </div>
                                ) : _.isEmpty(itemPictures[col]) ? (
                                  <div className="ewp-web-dropdown-initials-container">
                                    <IonLabel className="ewp-web-dropdown-initials ewp-h6 small white ion-text-uppercase ion-no-margin ion-text-center">
                                      {getName(col).split(" ")[0][0] +
                                        getName(col).split(" ")[1][0]}
                                    </IonLabel>
                                  </div>
                                ) : (
                                  <IonAvatar className="ewp-web-dropdown-item-picture">
                                    <img
                                      src={itemPictures[col]}
                                      alt={"dropdown-detail"}
                                    />
                                  </IonAvatar>
                                )} */}
                                <div className="ewp-web-dropdown-name-sub-name-container">
                                  <IonLabel className="ewp-web-dropdown-label ewp-h5 bold ion-no-margin ion-no-padding">
                                    {getName(col)}
                                  </IonLabel>
                                  {subNameProps.hasSubName && (
                                    <IonLabel className="ewp-paragraph light ion-no-margin ion-no-padding">
                                      {subNameProps.name}
                                    </IonLabel>
                                  )}
                                </div>
                                <IonButton
                                  fill="clear"
                                  color={EWPCOLORS.light}
                                  className="ewp-web-dropdown-selected-items-close-button ion-no-margin ion-no-padding"
                                  onClick={() => {
                                    const newSelectedItem = _.remove(
                                      selectedItems,
                                      (selectedItem) => {
                                        return selectedItem !== col;
                                      }
                                    );
                                    setSelectedItems(newSelectedItem);
                                    setShowItemContainer(
                                      !_.isEmpty(newSelectedItem)
                                    );
                                    if (onSelectItems !== undefined) {
                                      onSelectItems(newSelectedItem);
                                    }
                                  }}
                                >
                                  <IonIcon
                                    color={EWPCOLORS.light}
                                    icon={close}
                                  />
                                </IonButton>
                              </IonItem>
                            </IonCol>
                          );
                        })}
                      </IonRow>
                    );
                  })}
                </IonGrid>
              </IonList>
            </div>
          )}
          <IonPopover
            isOpen={showDropdownList}
            event={dropdownEvent}
            className={`ewp-web-dropdown-select-popover-container ${
              !!widthSize && widthSize
            }`}
            showBackdrop={false}
            onDidDismiss={() => {
              setShowDropdownList(false);
              setShowItemContainer(!_.isEmpty(selectedItems));
              onSelectItems(selectedItems);
              setFilteredItems([]);
            }}
          >
            <div className="ewp-web-dropdown-searchbar-list-container">
              {hasSearch && (
                <IonSearchbar
                  mode="md"
                  className="ewp-web-dropdown-searchbar ion-no-padding"
                  onIonChange={(event: CustomEvent) => {
                    filterDropdownItems(event.detail.value);
                  }}
                />
              )}
              {showDropdownList && (
                <IonList className="ewp-web-dropdown-select-popover-list">
                  {(!_.isEmpty(filteredItems)
                    ? filteredItems
                    : dropdownItems
                  ).map((item, index, list) => {
                    return (
                      <IonItem
                        {...(index === list.length - 1 && { lines: "none" })}
                        key={item.id}
                        className="ewp-web-dropdown-select-popover-list-item ion-justify-content-center ion-no-margin ion-no-padding"
                      >
                        <IonLabel
                          className={`ewp-web-dropdown-select-popover-list-label ewp-h5 light ion-no-margin ion-no-padding ${
                            hasSearch && "has-padding-top"
                          }`}
                        >
                          {item.name}
                        </IonLabel>
                        <IonCheckbox
                          className="ewp-web-dropdown-popover-checkbox ion-no-padding ion-no-margin"
                          slot="end"
                          color="success"
                          checked={isItemCheck(item.id)}
                          onIonChange={() => {
                            const isChecked = isItemCheck(item.id);
                            if (isChecked) {
                              const newSelectedItem = _.remove(
                                selectedItems,
                                (selectedItem) => {
                                  return selectedItem !== item.id;
                                }
                              );
                              setSelectedItems(newSelectedItem);
                            } else {
                              setSelectedItems([
                                ...selectedItems,
                                item.id as string,
                              ]);
                            }
                          }}
                        />
                      </IonItem>
                    );
                  })}
                </IonList>
              )}
            </div>
          </IonPopover>
        </>
      ) : (
        <IonPopover
          isOpen={showDropdownList}
          event={dropdownEvent}
          className={`ewp-web-dropdown-select-popover-container ${
            !!widthSize && widthSize
          }`}
          showBackdrop={false}
          onDidDismiss={() => {
            setShowDropdownList(false);
            setFilteredItems([]);
          }}
        >
          {showDropdownList && (
            <div className="ewp-web-dropdown-searchbar-list-container">
              {hasSearch && (
                <IonSearchbar
                  mode="md"
                  className="ewp-web-dropdown-searchbar ion-no-padding"
                  onIonChange={(event: CustomEvent) => {
                    filterDropdownItems(event.detail.value);
                  }}
                />
              )}
              <IonList className="ewp-web-dropdown-select-popover-list">
                {!_.isEmpty(itemsHeader) && (
                  <IonItem
                    className="ewp-web-dropdown-select-popover-list-item ion-justify-content-center ion-no-margin ion-no-padding"
                    lines="none"
                  >
                    <IonLabel className="ewp-web-dropdown-select-popover-list-subtitle ewp-h5 bold ion-no-margin ion-no-padding">
                      {itemsHeader}
                    </IonLabel>
                  </IonItem>
                )}
                {!_.isEmpty(dropdownItems) &&
                  (!_.isEmpty(filteredItems)
                    ? filteredItems
                    : dropdownItems
                  ).map((item, index, list) => {
                    return !!item.metadata && !!item.metadata.xeroStatus ? (
                      <div className="ewp-dropdown-xero-section">
                        <IonItem
                          key={item.id}
                          className={`ewp-web-dropdown-select-popover-list-item ${
                            !hasSearch && "no-padding"
                          } ion-justify-content-center ion-no-margin ion-no-padding`}
                          button={true}
                          {...((!hasSearch || index === list.length - 1) && {
                            lines: "none",
                          })}
                          onClick={() => {
                            setSelectedItem(item.name);
                            if (onSelectItem !== undefined) {
                              onSelectItem(item.id);
                            }
                            setShowDropdownList(false);
                          }}
                          lines="none"
                        >
                          <IonLabel
                            className={`ewp-web-dropdown-select-popover-list-label ewp-h5 light ion-no-margin ion-no-padding ${
                              hasSearch && "has-padding-top"
                            }`}
                          >
                            {item.name}
                          </IonLabel>

                          <IonLabel
                            className={`xero-invoice-status-label ${item.metadata.xeroStatus} ion-no-margin ion-no-padding`}
                          >
                            {item.metadata.xeroStatus}
                          </IonLabel>
                          {item.metadata.xeroStatus !== "PAID" &&
                            moment(item.metadata.dueDate).toDate().valueOf() <
                              new Date().valueOf() && (
                              <IonLabel
                                className={`xero-invoice-status-label DUE ion-no-margin ion-no-padding`}
                              >
                                DUE
                              </IonLabel>
                            )}
                        </IonItem>
                        <IonGrid className="xero-details-section ion-no-padding">
                          <IonRow>
                            <IonCol size="6">
                              <IonLabel className="ewp-h5">
                                <b>Due Date: </b>
                                {item.metadata.dueDate}
                              </IonLabel>
                            </IonCol>
                            <IonCol size="6">
                              <IonLabel className="ewp-h5">
                                <b>Invoice Date: </b>
                                {item.metadata.invoiceDate}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol size="6">
                              <IonLabel className="ewp-h5">
                                <b>Amount Due: </b>
                                {item.metadata.amountDue}
                              </IonLabel>
                            </IonCol>
                            <IonCol size="6">
                              <IonLabel className="ewp-h5">
                                <b>Amount Paid: </b>
                                {item.metadata.amountPaid}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                    ) : (
                      <IonItem
                        key={item.id}
                        className={`ewp-web-dropdown-select-popover-list-item ${
                          !hasSearch && "no-padding"
                        } ion-justify-content-center ion-no-margin ion-no-padding`}
                        button={true}
                        {...((!hasSearch || index === list.length - 1) && {
                          lines: "none",
                        })}
                        onClick={() => {
                          setSelectedItem(item.name);
                          if (onSelectItem !== undefined) {
                            onSelectItem(item.id);
                          }
                          setShowDropdownList(false);
                        }}
                      >
                        <IonLabel
                          className={`ewp-web-dropdown-select-popover-list-label ewp-h5 light ion-no-margin ion-no-padding ${
                            hasSearch && "has-padding-top"
                          }`}
                        >
                          {item.name}
                        </IonLabel>
                        {!!item.metadata &&
                          !!item.metadata.selectedTruckVOC && (
                            <IonLabel className="truck-preferred-label ion-no-margin ion-no-padding">
                              VOC
                            </IonLabel>
                          )}
                        {!!item.metadata &&
                          !!item.metadata.isPreferredByClient && (
                            <IonLabel className="truck-preferred-label ion-no-margin ion-no-padding">
                              Preferred
                            </IonLabel>
                          )}
                        {!!item.metadata && !!item.metadata.hasBooking && (
                          <IonLabel className="truck-not-available-label ion-no-margin ion-no-padding">
                            Not Available
                          </IonLabel>
                        )}
                        {!!item.metadata &&
                          !!item.metadata.hasExpiredLicence && (
                            <IonLabel className="truck-not-available-label ion-no-margin ion-no-padding">
                              Expired Expiry
                            </IonLabel>
                          )}

                        {!!item.metadata && !!item.metadata.xeroStatus && (
                          <IonLabel
                            className={`xero-invoice-status-label ${item.metadata.xeroStatus} ion-no-margin ion-no-padding`}
                          >
                            {item.metadata.xeroStatus}
                          </IonLabel>
                        )}
                      </IonItem>
                    );
                  })}
              </IonList>
            </div>
          )}
        </IonPopover>
      )}
    </div>
  );
};
