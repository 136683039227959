import React from "react";
import { IonPage, IonContent, IonLabel, IonButton } from "@ionic/react";

import "./ResetPasswordSuccess.scss";
import * as routes from "../../constants/routes";
import EWPHeader from "../../components/EWPHeader/EWPHeader";
import { EWPProps } from "../../config/global";
import { EWPCOLORS } from "../../constants/config";

export default class ResetPasswordSuccess extends React.Component<EWPProps> {
  render = () => {
    return (
      <IonPage>
        <EWPHeader {...this.props} />
        <IonContent className="reset-password-success-content">
          <div className="reset-password-success-sent-container">
            <IonLabel className="reset-password-success-label ewp-h1 ion-no-margin ion-no-padding">
              Success
            </IonLabel>
            <IonLabel className="reset-password-success-description ewp-paragraph ion-no-margin ion-no-padding">
              Your password has been reset. To sign in with your new password
              click login.
            </IonLabel>
            <IonButton
              color={EWPCOLORS.primary}
              mode="ios"
              className="ewp-web-auth-company-rep-button ewp-h3 ion-no-padding ion-no-margin"
              routerLink={routes.SIGN_IN}
            >
              Log In
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    );
  };
}
