import * as _ from "lodash";
import React, { useState, DragEvent } from "react";
import { close, add } from "ionicons/icons";
import {
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonRippleEffect,
  IonLabel,
  IonIcon,
  IonThumbnail,
  IonModal,
  IonContent,
} from "@ionic/react";

import "./ServiceHistoryDialog.scss";
import * as services from "../../services";
import { auth } from "../../firebase";
import {
  formatString,
  uploadAttachments,
  removedUploadedAttachements,
  getServerTimestamp,
  isTablet,
} from "../../functions/common";
import { EWPProps } from "../../config/global";
import { EWPInput } from "../EWPInput/EWPInput";
import { EWPDatePicker } from "../EWPDatePicker/EWPDatePicker";
import { EWPCalendar } from "../EWPCalendar/EWPCalendar";
import { EWPWebDialog } from "../EWPWebDialog/EWPWebDialog";
import { TruckServiceHistory } from "../../models/drivers";
import { EWPCOLORS, LIMITS } from "../../constants/config";
import { MSGS_COMMON, MSGS_TRUCK_REGISTER } from "../../constants/messages";
import Bugsnag from "@bugsnag/js";

interface ServiceHistoryDialogProps extends EWPProps {
  onDidDismiss: () => void;
  isOpen: boolean;
  uploaderId: string; // use in upload photo path
  editData?: TruckServiceHistory;
}

export const ServiceHistoryDialog = (props: ServiceHistoryDialogProps) => {
  const { isOpen, onDidDismiss, uploaderId, editData } = props;
  const [serviceType, setserviceType] = useState(
    !!editData ? editData.serviceType : ""
  );
  const [selectedServiceDate, setSelectedServiceDate] = useState(
    (!!editData
      ? !_.isEmpty(editData.serviceDate)
        ? editData.serviceDate!.toDate()
        : null
      : null) as null | Date
  );
  const [listOfAttachment, setListOfAttachment] = useState(
    !!editData ? editData.attachments : []
  ) as any[];
  const [removedAttachments, setRemovedAttachments] = useState([] as string[]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [errorMsgserviceType, setErrorMsgserviceType] = useState("");
  const [errorMsgServiceDate, setErrorMsgServiceDate] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [calendarAnchor, setCalendarAnchor] = useState(
    null as Event | undefined | null
  );
  const ref: React.RefObject<HTMLInputElement> = React.createRef();

  const onClickUploadPhoto = () => {
    if (!!listOfAttachment) {
      if (
        listOfAttachment.length < LIMITS.serviceTypeAttachments.count &&
        !!ref
      ) {
        ref.current!.click(); // ref for input field file
      } else {
        setError(MSGS_TRUCK_REGISTER.serviceTypeAttachmentsExceed);
      }
    }
  };

  const submitForm = (event: React.FormEvent) => {
    event.preventDefault();
    const formattedserviceType = formatString(serviceType);
    const user = auth.currentUser;

    let errorMessage = null;
    if (formattedserviceType.length === 0) {
      errorMessage = MSGS_TRUCK_REGISTER.serviceType;
      setErrorMsgserviceType(errorMessage);
    } else if (_.isNull(selectedServiceDate)) {
      errorMessage = MSGS_TRUCK_REGISTER.serviceDate;
      setErrorMsgServiceDate(errorMessage);
    }

    if (_.isEmpty(errorMessage) && !!user) {
      if (!!editData) {
        updateServiceHistory();
      } else {
        addNewService(formattedserviceType, selectedServiceDate as Date);
      }
    }
  };

  const clearFields = () => {
    setserviceType("");
    setSelectedServiceDate(null);
    setListOfAttachment([]);
  };

  const clearErrorMsgs = () => {
    setErrorMsgserviceType("");
    setErrorMsgServiceDate("");
  };

  const updateServiceHistory = async () => {
    if (!!editData) {
      setLoading(true);
      let attachmentUrlList = [] as string[];
      const docId = editData.docId || "";
      const newFileAttach = _.compact(
        listOfAttachment.map((item: any) => {
          return typeof item !== "string" && item;
        })
      ) as any[];
      const prevousFileAttach = _.compact(
        listOfAttachment.map((item: any) => {
          return typeof item === "string" && item;
        })
      );
      try {
        if (newFileAttach.length > 0) {
          const urlList = await uploadAttachments(
            newFileAttach,
            serviceType,
            uploaderId,
            "truckServiceHistories"
          );
          attachmentUrlList = urlList;
        }
        const updatedAttachment = prevousFileAttach.concat(
          attachmentUrlList
        ) as string[];

        await services.updateTruckServiceHistory(
          docId,
          serviceType,
          getServerTimestamp(selectedServiceDate),
          editData.truckId,
          updatedAttachment
        );
        await removedUploadedAttachements(removedAttachments);
        setSuccess(MSGS_COMMON.successUpdate);
        setLoading(false);
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        await removedUploadedAttachements(attachmentUrlList);

        setError(error);
        setLoading(false);
        Bugsnag.notify(new Error(error));
      }
    }
  };

  const addNewService = async (serviceType: string, serviceDate: Date) => {
    setLoading(true);
    let attachmentUrlList = [] as string[];
    try {
      if (listOfAttachment.length > 0) {
        const urlList = await uploadAttachments(
          listOfAttachment,
          serviceType,
          uploaderId,
          "truckServiceHistories"
        );
        attachmentUrlList = urlList;
      }

      await services.addTruckServiceHistory(
        uploaderId,
        serviceType,
        getServerTimestamp(serviceDate),
        attachmentUrlList
      );
      setLoading(false);
      setSuccess(MSGS_COMMON.success);
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      //remove the file in our storage
      await removedUploadedAttachements(attachmentUrlList);
      setLoading(false);
      setError(error);
      Bugsnag.notify(new Error(error));
    }
  };

  const deleteServiceHistory = async () => {
    try {
      setLoading(true);
      if (!!editData && !!editData.docId) {
        await services.deleteServiceHistory(editData.docId);
        if (!_.isEmpty(editData.attachments)) {
          await removedUploadedAttachements(editData.attachments!); //remove the file in our storage
        }
        setSuccess(MSGS_COMMON.successDelete);
      }
      setLoading(false);
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      setError(error);
      setLoading(false);
      Bugsnag.notify(new Error(error));
    }
  };

  const onAttachPhoto = async (file: any) => {
    if (
      listOfAttachment.length + file.target.files.length <=
      LIMITS.serviceTypeAttachments.count
    ) {
      if (!!file && file.target.files.length) {
        const attachments = file.target.files;
        const fileSizes = Object.values(attachments).map((attachment: any) => {
          return attachment.size <= LIMITS.serviceTypeAttachments.size;
        });
        const hasExceededFileSize = _.includes(fileSizes, false);
        if (!hasExceededFileSize) {
          const toBeUploadedAttachments = _.concat(
            Object.values(attachments),
            listOfAttachment
          );
          setListOfAttachment(toBeUploadedAttachments as []);
        } else {
          setError(MSGS_TRUCK_REGISTER.attachmentFileSizeExceed);
        }
      }
    } else {
      setError(MSGS_TRUCK_REGISTER.serviceTypeAttachmentsExceed);
    }
  };

  const onDrop = (event: DragEvent) => {
    event.preventDefault();
    if (listOfAttachment.length <= LIMITS.serviceTypeAttachments.count) {
      if (event.dataTransfer.files) {
        // Use DataTransferItemList interface to access the file(s)
        if (
          event.dataTransfer.files.length <= LIMITS.serviceTypeAttachments.count
        ) {
          const files = Object.values(event.dataTransfer.files);
          const fileSizes = Object.values(files).map((attachment: any) => {
            return attachment.size <= LIMITS.serviceTypeAttachments.size;
          });
          const hasExceededFileSize = _.includes(fileSizes, false);
          if (!hasExceededFileSize) {
            files.forEach((file) => {
              const imageType = /image.*/;
              if (
                file &&
                (file.type.match(imageType) || file.type === "application/pdf")
              ) {
                const attachments = Object.values(event.dataTransfer.files);
                const toBeUploadedAttachments = _.concat(
                  attachments,
                  listOfAttachment
                );
                setListOfAttachment(toBeUploadedAttachments as []);
              } else {
                setError(MSGS_TRUCK_REGISTER.invalidFileType);
              }
            });
          } else {
            setError(MSGS_TRUCK_REGISTER.attachmentFileSizeExceed);
          }
        } else {
          setError(MSGS_TRUCK_REGISTER.serviceTypeAttachmentsExceed);
        }
      }
    } else {
      setError(MSGS_TRUCK_REGISTER.serviceTypeAttachmentsExceed);
    }
  };

  const onRemoveAttachment = (attachment: any) => {
    const updatedListOfAttachment = _.remove(listOfAttachment, (item) => {
      return item !== attachment;
    });

    if (typeof attachment === "string") {
      setRemovedAttachments([...removedAttachments, attachment]);
    }
    setListOfAttachment(updatedListOfAttachment);
  };

  return (
    <>
      <EWPWebDialog
        headerTitle={
          !_.isEmpty(editData) ? "Edit Service History" : "Add Service History"
        }
        isOpen={isOpen}
        onDidDismiss={() => {
          onDidDismiss();
        }}
      >
        <div className="truck-service-history-form-container">
          <form onSubmit={submitForm}>
            <IonGrid className="ion-no-padding">
              <IonRow className="truck-service-history-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="truck-service-history-start-col ion-no-padding"
                >
                  <EWPInput
                    errorMessage={errorMsgserviceType}
                    inputLabel="Service Type"
                    inputValue={serviceType}
                    inputType="text"
                    inputMode="text"
                    name="serviceType"
                    onInputChange={(serviceType: string) => {
                      clearErrorMsgs();
                      setserviceType(serviceType);
                    }}
                  />
                </IonCol>
                <IonCol
                  size="6"
                  className="truck-service-history-end-col ion-no-padding"
                >
                  <EWPDatePicker
                    className="service-date-input-date"
                    datePickerLabel="Service Date"
                    selectedDate={selectedServiceDate}
                    onDateChange={(serviceDate: Date) => {
                      setSelectedServiceDate(serviceDate);
                      setErrorMsgServiceDate("");
                    }}
                    errorMsg={errorMsgServiceDate}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonGrid className="ion-no-padding">
              <IonRow className="truck-service-history-row ion-no-padding ion-no-margin">
                <IonCol size="12" className="ion-no-padding">
                  <div
                    className={`truck-service-history-upload-container ${
                      isTablet() && "is-tablet"
                    }`}
                  >
                    <div className="truck-service-history-title-button-container">
                      <IonLabel className="truck-service-history-upload-title-label ewp-h3 ion-no-padding ion-no-margin">
                        Upload Images
                      </IonLabel>
                      {!_.isEmpty(listOfAttachment) && (
                        <IonButton
                          mode="ios"
                          className="truck-service-history-attachments-add-another-button ewp-h6 ion-no-margin ion-no-padding"
                          color={EWPCOLORS.gray}
                          onClick={onClickUploadPhoto}
                        >
                          <IonIcon
                            className="truck-service-history-small-add-icon"
                            icon={add}
                          />
                          Add Another
                        </IonButton>
                      )}
                    </div>
                    {!_.isEmpty(listOfAttachment) ? (
                      <div className="web-upload-list-attachment-container">
                        <IonGrid className="ion-no-margin ion-no-padding">
                          {_.chunk(listOfAttachment, 8).map(
                            (row: any, index: any) => {
                              return (
                                <IonRow
                                  key={`row_${index}`}
                                  className="ion-no-margin ion-no-padding"
                                >
                                  {row.map((attachment: any, index: any) => {
                                    const fileUrl =
                                      typeof attachment === "string"
                                        ? attachment
                                        : "";
                                    const fileSplit = fileUrl
                                      .split("?")[0]
                                      .split(".");
                                    const fileTypeAttachment =
                                      fileSplit[
                                        fileSplit.length - 1
                                      ].toLowerCase();
                                    return (
                                      <IonCol
                                        key={`col_${index}`}
                                        size="1.5"
                                        className="ion-no-margin ion-no-padding"
                                      >
                                        <div className="web-upload-attachment-container">
                                          <IonButton
                                            className="remove-attachment-button ion-no-margin ion-no-padding"
                                            shape="round"
                                            color="primary"
                                            onClick={() =>
                                              onRemoveAttachment(attachment)
                                            }
                                          >
                                            <IonIcon
                                              className="web-upload-attachment-close-icon ion-no-margin ion-no-padding"
                                              icon={close}
                                            />
                                          </IonButton>
                                          {typeof attachment !== "string" &&
                                          !!attachment.type.match("image/*") ? (
                                            <IonThumbnail
                                              className="web-upload-attachment-avatar ion-no-margin ion-no-padding"
                                              slot="start"
                                            >
                                              <img
                                                id="attachmentPhoto"
                                                src={
                                                  typeof attachment === "string"
                                                    ? attachment
                                                    : URL.createObjectURL(
                                                        attachment
                                                      )
                                                }
                                                alt="attachmentPhoto"
                                              />
                                            </IonThumbnail>
                                          ) : fileTypeAttachment !== "pdf" &&
                                            typeof attachment === "string" ? (
                                            <IonThumbnail
                                              className="web-upload-attachment-avatar ion-no-margin ion-no-padding"
                                              slot="start"
                                            >
                                              <img
                                                id="attachmentPhoto"
                                                src={
                                                  typeof attachment === "string"
                                                    ? attachment
                                                    : URL.createObjectURL(
                                                        attachment
                                                      )
                                                }
                                                alt="attachmentPhoto"
                                              />
                                            </IonThumbnail>
                                          ) : (
                                            <div className="attachment-file-link-container">
                                              <a
                                                target="blank"
                                                href={
                                                  typeof attachment === "string"
                                                    ? attachment
                                                    : URL.createObjectURL(
                                                        attachment
                                                      )
                                                }
                                              >
                                                View File
                                              </a>
                                            </div>
                                          )}
                                        </div>
                                      </IonCol>
                                    );
                                  })}
                                </IonRow>
                              );
                            }
                          )}
                        </IonGrid>
                      </div>
                    ) : (
                      <IonItem
                        lines="none"
                        className="truck-service-history-upload-item ewp-body ion-no-margin"
                        onClick={onClickUploadPhoto}
                        onDragOver={(event) => {
                          event.preventDefault();
                        }}
                        onDrop={onDrop}
                      >
                        <div className="web-upload-icon-label-container">
                          <IonIcon className="truck-service-history-upload-icon" />
                          <IonLabel className="truck-service-history-upload-label ewp-paragraph ion-no-margin ion-no-padding">
                            Drop files here to upload or <u>choose file</u>
                          </IonLabel>
                        </div>
                      </IonItem>
                    )}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonItem
              lines="none"
              className="ewp-item-no-horizontal-padding ewp-button-padding-top"
            >
              <IonButton
                mode="ios"
                slot="start"
                className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
                fill="clear"
                color={EWPCOLORS.medium}
                onClick={() => {
                  if (onDidDismiss) {
                    onDidDismiss();
                    clearFields();
                  }
                }}
              >
                Cancel
              </IonButton>
              {!_.isEmpty(editData) && (
                <IonButton
                  slot="end"
                  className="truck-service-history-delete-button ewp-h5 ion-no-margin ion-text-capitalize"
                  color={EWPCOLORS.gray}
                  onClick={() => {
                    setShowDeleteDialog(true);
                  }}
                >
                  Delete Service
                  <IonRippleEffect />
                </IonButton>
              )}
              <IonButton
                mode="md"
                slot="end"
                className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
                color={EWPCOLORS.primary}
                type="submit"
              >
                {!_.isEmpty(editData) ? "Save" : "Add Service"}
                <IonRippleEffect />
              </IonButton>
            </IonItem>
          </form>
          <input
            hidden
            type="file"
            ref={ref}
            accept="image/*,.pdf"
            onChange={onAttachPhoto}
            multiple
          />
        </div>
      </EWPWebDialog>
      {!_.isNull(calendarAnchor) && !_.isEmpty(calendarAnchor) && (
        <EWPCalendar
          calendarAnchor={calendarAnchor}
          selectedDate={
            _.isNull(selectedServiceDate) ? new Date() : selectedServiceDate
          }
          removeCalendarAnchor={() => {
            setCalendarAnchor(null);
          }}
          onDateChange={(selectedDate: Date) => {
            setSelectedServiceDate(selectedDate);
          }}
          minDate={new Date()}
        />
      )}
      <IonModal
        className="truck-service-history-delete-dialog"
        isOpen={showDeleteDialog}
        onDidDismiss={() => {
          setShowDeleteDialog(false);
        }}
      >
        <IonContent
          scrollY={false}
          className="truck-service-history-delete-dialog-content ion-no-padding"
        >
          <IonButton
            className="truck-service-history-delete-dialog-close-button-dialog ion-no-padding ion-no-margin"
            color={EWPCOLORS.light}
            fill="clear"
            onClick={() => {
              setShowDeleteDialog(false);
            }}
          >
            <IonIcon color={EWPCOLORS.light} icon={close} />
          </IonButton>
          <div className="truck-service-history-delete-dialog-title-container">
            <IonLabel className="ewp-h1 ion-text-center">
              Are you sure you want to delete this Service?
            </IonLabel>
            <IonLabel className="truck-service-history-delete-dialog-subtitle ewp-paragraph ion-text-center">
              Once you delete this Service, you will not be able to restore it.
            </IonLabel>
          </div>

          <IonItem
            lines="none"
            className="truck-service-history-delete-dialog-action-buttons-item ewp-item-no-horizontal-padding"
          >
            <IonButton
              mode="ios"
              slot="start"
              className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
              fill="clear"
              color={EWPCOLORS.medium}
              onClick={() => {
                setShowDeleteDialog(false);
              }}
            >
              Cancel
            </IonButton>
            <IonButton
              mode="ios"
              slot="end"
              className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
              color={EWPCOLORS.danger}
              onClick={() => {
                deleteServiceHistory();
              }}
            >
              Yes, Delete
              <IonRippleEffect />
            </IonButton>
          </IonItem>
        </IonContent>
      </IonModal>
      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
          setShowDeleteDialog(false);
          onDidDismiss();
          clearFields();
        }}
        color={EWPCOLORS.success}
      />
    </>
  );
};
