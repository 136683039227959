import * as _ from "lodash";
import React, { useState } from "react";
import {
  IonLabel,
  IonItem,
  IonInput,
  IonDatetime,
  IonTextarea,
} from "@ionic/react";

import "./EWPInput.scss";
import { isMobile } from "../../functions/common";

export const EWPInput = ({
  errorMessage,
  inputLabel,
  inputValue,
  inputType,
  inputMode,
  name,
  onInputChange,
  disabled = false,
  disableTab = false,
  autocomplete = "on",
  readOnly = false,
  min,
  max,
  mode,
  className,
  hideLabel = false,
}: {
  errorMessage?: string;
  inputLabel?: string;
  inputValue: any;
  inputType: "text" | "number" | "tel" | "date";
  inputMode?: "text" | "none" | "tel";
  mode?: "textArea";
  name: string;
  onInputChange?: (eventValue: any) => void;
  disabled?: boolean;
  autocomplete?: "on" | "off";
  disableTab?: boolean;
  readOnly?: boolean;
  min?: string; // use to date min
  max?: string; // use to date max
  className?: string;
  hideLabel?: boolean;
}) => {
  const [mobileClass, setMobileClass] = useState("mobile");
  const [hasFocus, setHasFocus] = useState(false);
  return (
    <div className={className}>
      <IonItem
        className={`ewp-input-item-container  ${readOnly && "readOnly"} ${
          !_.isEmpty(errorMessage) && "item-has-error-class"
        }`}
        lines="none"
        mode="md"
      >
        {inputLabel && (
          <IonLabel
            mode="md"
            className={`ewp-web-label-input ${isMobile() && mobileClass} 
          `}
            position="floating"
          >
            {inputLabel}
          </IonLabel>
        )}
        {inputType === "date" ? (
          <IonDatetime
            mode="md"
            disabled={disabled}
            className={`ewp-web-input ${
              !_.isEmpty(errorMessage) && "has-error-class"
            }`}
            name={name}
            value={inputValue}
            min={min}
            max={max}
            {...(isMobile() && {
              onIonFocus: () => {
                setMobileClass("mobile has-focus");
              },
              onIonBlur: () => {
                setMobileClass(
                  !_.isEmpty(inputValue) ? "mobile has-value" : "mobile"
                );
              },
            })}
            {...(!!onInputChange
              ? {
                  onIonChange: (event) => {
                    onInputChange(event.detail.value || "");
                  },
                }
              : {})}
            {...(disableTab && {
              onKeyDown: (event: React.KeyboardEvent) => {
                if (event.keyCode === 9) {
                  event.preventDefault();
                }
              },
            })}
          />
        ) : mode === "textArea" ? (
          <IonTextarea
            value={inputValue}
            className={`ewp-web-input text-area ${
              hasFocus && !readOnly && "has-focus"
            } ${!!errorMessage && "has-error-class"} ewp-body`}
            onIonFocus={() => {
              setHasFocus(true);
            }}
            onIonBlur={() => {
              setHasFocus(false);
            }}
            disabled={disabled !== undefined ? disabled : false}
            {...(!!onInputChange && {
              onIonChange: (event) => {
                onInputChange(event.detail.value || "");
              },
            })}
          ></IonTextarea>
        ) : (
          <IonInput
            mode="md"
            autocomplete={autocomplete}
            disabled={disabled}
            readonly={readOnly}
            className={`ewp-web-input ${
              !_.isEmpty(errorMessage) && "has-error-class"
            }`}
            name={name}
            inputmode={inputMode}
            type={inputType}
            value={inputValue}
            {...(isMobile() && {
              onIonFocus: () => {
                setMobileClass("mobile has-focus");
              },
              onIonBlur: () => {
                setMobileClass(
                  !_.isEmpty(inputValue) ? "mobile has-value" : "mobile"
                );
              },
            })}
            {...(!!onInputChange
              ? {
                  onIonChange: (event) => {
                    onInputChange(event.detail.value || "");
                  },
                }
              : {})}
            {...(disableTab && {
              onKeyDown: (event: React.KeyboardEvent) => {
                if (event.keyCode === 9) {
                  event.preventDefault();
                }
              },
            })}
          />
        )}
      </IonItem>
      {!_.isEmpty(errorMessage) && (
        <div className="ewp-input-error-container">
          <IonLabel className="ewp-input-error-msg">{errorMessage}</IonLabel>
        </div>
      )}
    </div>
  );
};
