import "./EWPUserAccountFooter.scss";
import {
  IonFooter,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/react";
import React from "react";
import { construct } from "ionicons/icons";
import { EWPCOLORS } from "../../../constants/config";

type Pages = "driver" | "jobSchedule" | "maintenance" | "logout";
interface EWPUserAccountFooterProps {
  onDriverClick: () => void;
  onJobScheduleClick: () => void;
  onMaintenanceClick: () => void;
  onLogout: () => void;
  activePage: Pages;
}

export const EWPUserAccountFooter: React.FunctionComponent<EWPUserAccountFooterProps> = (
  props
) => (
  <IonFooter>
    <IonToolbar
      mode="ios"
      className="ewp-user-account-toolbar ion-no-padding ion-no-margin"
    >
      <IonButtons className="ion-no-padding ion-no-margin">
        <IonButton
          mode="ios"
          size="large"
          className="ewp-account-footer-button"
          onClick={props.onDriverClick}
        >
          <IonIcon
            className={`ewp-account-footer-icon driver ${
              props.activePage === "driver" ? "active" : ""
            }`}
            slot="icon-only"
            mode="ios"
          />
        </IonButton>
        <IonButton
          size="large"
          className="ewp-account-footer-button"
          onClick={props.onJobScheduleClick}
        >
          <IonIcon
            className={`ewp-account-footer-icon jobSchedule ${
              props.activePage === "jobSchedule" ? "active" : ""
            }`}
            slot="icon-only"
            mode="ios"
          />
        </IonButton>
        <IonButton
          size="large"
          className="ewp-account-footer-button"
          onClick={props.onMaintenanceClick}
        >
          <IonIcon
            className="ewp-account-footer-icon"
            color={
              props.activePage === "maintenance"
                ? EWPCOLORS.primary
                : EWPCOLORS.light
            }
            icon={construct}
            slot="icon-only"
            mode="ios"
          />
        </IonButton>
        <IonButton
          size="large"
          className="ewp-account-footer-button"
          onClick={props.onLogout}
        >
          <IonIcon
            className={`ewp-account-footer-icon logout ${
              props.activePage === "logout" ? "active" : ""
            }`}
            slot="icon-only"
            mode="ios"
          />
        </IonButton>
      </IonButtons>
    </IonToolbar>
  </IonFooter>
);
