import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import React, { useState } from "react";
import { close } from "ionicons/icons";
import {
  IonItem,
  IonLabel,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonRippleEffect,
  IonContent,
  IonIcon,
  IonModal,
} from "@ionic/react";

import "./ClientContactForm.scss";
import * as services from "../../services";
import { formatString } from "../../functions/common";
import { ContactPerson } from "../../models";
import { EWPInput } from "../EWPInput/EWPInput";
import { EWPCOLORS, LIMITS } from "../../constants/config";
import { MSGS_COMMON, MSGS_CONTACT_PERSON } from "../../constants/messages";
import { EWPNumberFieldWithCountryCode } from "../EWPNumberFieldWithCountryCode/EWPNumberFieldWithCountryCode";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import Bugsnag from "@bugsnag/js";

interface ClientContactFormProps {
  clientId: string;
  onModalDidDismiss?: () => void;
  onSuccess?: () => void;
  isEdit: boolean;
  editData?: ContactPerson;
}
export const ClientContactForm = (props: ClientContactFormProps) => {
  const { clientId, isEdit, editData, onSuccess } = props;
  let existingData = {
    docId: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
  } as ContactPerson;

  if (!_.isEmpty(editData) && !!editData) {
    existingData = _.clone(editData);
  }

  const [firstName, setFirstName] = useState(
    !!existingData && !_.isEmpty(existingData) ? existingData.firstName : ""
  );
  const [lastName, setLastName] = useState(
    !!existingData && !_.isEmpty(existingData) ? existingData.lastName : ""
  );
  const [emailAddress, setEmailAddress] = useState(
    !!existingData && !_.isEmpty(existingData) ? existingData.emailAddress : ""
  );
  const [contactNumber, setContactNumber] = useState(
    !!existingData && !_.isEmpty(existingData) ? existingData.phoneNumber : ""
  );

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [errorMsgFirstName, setErrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgEmailAddress, setErrorMsgEmailAddress] = useState("");
  const [errorMsgContactNumber, setErrorContactNumber] = useState("");

  const submitForm = async (event: React.FormEvent) => {
    event.preventDefault();
    const formattedFirstName = formatString(firstName || "");
    const formattedEmailAddress = formatString(emailAddress || "");
    const formattedLastName = formatString(lastName || "");
    const formattedPhoneNumber = formatString((contactNumber as string) || "");
    //! FIX VALIDATION FOR MOBILE NUMBER to use ausie number only?

    let errorMessage = null;

    if (formattedFirstName.length === 0) {
      errorMessage = MSGS_CONTACT_PERSON.firstName.required;
      setErrorMsgFirstName(errorMessage);
    }
    // if (formattedLastName.length === 0) {
    //   errorMessage = MSGS_CONTACT_PERSON.lastName.required;
    //   setErrorMsgLastName(errorMessage);
    // }
    if (formattedFirstName.length > LIMITS.name.single) {
      errorMessage = MSGS_CONTACT_PERSON.firstName.max;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedLastName.length > LIMITS.name.single) {
      errorMessage = MSGS_CONTACT_PERSON.lastName.max;
      setErrorMsgLastName(errorMessage);
    }
    // if (_.isEmpty(formattedPhoneNumber)) {
    //   errorMessage = MSGS_CONTACT_PERSON.mobileNumber;
    //   setErrorContactNumber(errorMessage);
    // }
    if (
      !_.isEmpty(formattedPhoneNumber) &&
      !validatePhoneNumber(formattedPhoneNumber)
    ) {
      errorMessage = MSGS_CONTACT_PERSON.invalidMobileNumber;
      setErrorContactNumber(errorMessage);
    }

    if (
      !_.isEmpty(formattedEmailAddress) &&
      !EmailValidator.validate(formattedEmailAddress)
    ) {
      errorMessage = MSGS_CONTACT_PERSON.email.invalid;
      setErrorMsgEmailAddress(errorMessage);
    }

    if (_.isEmpty(errorMessage)) {
      setSaving(true);
      try {
        if (!isEdit && _.isEmpty(editData)) {
          await services.createContactPerson(
            clientId,
            formattedFirstName,
            formattedLastName,
            (formattedPhoneNumber || "") as string,
            formattedEmailAddress
          );
          setSaving(false);
          setSuccess("New Contact Person Created");
        } else {
          await services.updateContactPerson(
            editData!.docId || "",
            formattedFirstName,
            formattedLastName,
            (formattedPhoneNumber || "") as string,
            formattedEmailAddress
          );
          setSaving(false);
          setSuccess("Contact Person Updated");
        }
      } catch (eUnknown) {
        const e = eUnknown as any;
        setError(e);
        setSaving(false);
      }
    }
  };

  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setContactNumber("");
  };

  const deleteContactPerson = async () => {
    try {
      setSaving(true);
      if (!!editData && !!editData.docId) {
        await services.deleteContactPerson(editData.docId, clientId);
        setSuccess(MSGS_COMMON.successDelete);
      }
      setSaving(false);
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      setError(error);
      setSaving(false);
      Bugsnag.notify(new Error(error));
    }
  };

  return (
    <>
      <form onSubmit={submitForm}>
        {!isEdit && (
          <>
            <IonLabel className="client-form-personal-details-title ewp-h3 ion-no-padding">
              Contact Person
            </IonLabel>
            <IonGrid className="client-form-grid ion-no-padding ion-no-margin">
              {_.chunk(
                [
                  <EWPInput
                    errorMessage={errorMsgFirstName}
                    inputLabel="First Name"
                    inputValue={firstName}
                    inputType="text"
                    name="firstName"
                    onInputChange={(firstName: string) => {
                      setFirstName(firstName);
                      if (!!firstName) setErrorMsgFirstName("");
                    }}
                  />,
                  <EWPInput
                    errorMessage={errorMsgLastName}
                    inputLabel="Last Name"
                    inputValue={lastName}
                    inputType="text"
                    name="lastName"
                    onInputChange={(lastName: string) => {
                      setLastName(lastName);
                      if (!!lastName) setErrorMsgLastName("");
                    }}
                  />,

                  <EWPNumberFieldWithCountryCode
                    errorMessage={errorMsgContactNumber}
                    phoneNumber={contactNumber}
                    onChange={(contactNumber: string) => {
                      if (
                        contactNumber.indexOf("+61") === 0 &&
                        contactNumber.length === 3
                      ) {
                        setContactNumber("");
                      } else {
                        setContactNumber(contactNumber);
                      }
                      setErrorContactNumber("");
                    }}
                  />,
                  <EWPInput
                    errorMessage={errorMsgEmailAddress}
                    inputLabel="Email"
                    inputValue={emailAddress}
                    inputType="text"
                    name="emailAddress"
                    onInputChange={(emailAddress: string) => {
                      setEmailAddress(emailAddress);
                      if (!!emailAddress) setErrorMsgEmailAddress("");
                    }}
                  />,
                ],
                2
              ).map((components) => {
                return (
                  <IonRow className="client-form-contact-person-details-row ion-no-padding ion-no-margin">
                    {components.map((component, index) => {
                      return (
                        <IonCol
                          size="6"
                          className={`${
                            index === 0
                              ? "ewp-client-form-start-col"
                              : "ewp-client-form-end-col"
                          } ion-no-padding`}
                        >
                          {component}
                        </IonCol>
                      );
                    })}
                  </IonRow>
                );
              })}
            </IonGrid>
          </>
        )}
        <IonItem
          lines="none"
          className="ewp-item-no-horizontal-padding ewp-button-padding-top"
        >
          <IonButton
            mode="ios"
            slot="start"
            className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
            fill="clear"
            color={EWPCOLORS.medium}
            onClick={() => {
              if (props.onModalDidDismiss) {
                props.onModalDidDismiss();
                clearFields();
              }
            }}
          >
            Cancel
          </IonButton>
          {!_.isEmpty(editData) && (
            <IonButton
              slot="end"
              className="voc-dialog-delete-button ewp-h5 ion-no-margin ion-text-capitalize"
              color={EWPCOLORS.gray}
              onClick={() => {
                setShowDeleteDialog(true);
              }}
            >
              Delete Contact Person
              <IonRippleEffect />
            </IonButton>
          )}
          <IonButton
            mode="md"
            slot="end"
            className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
            color={EWPCOLORS.primary}
            type="submit"
          >
            {!!editData && !_.isNull(editData) ? "Save" : "Add Contact Person"}
            <IonRippleEffect />
          </IonButton>
        </IonItem>
      </form>
      <IonModal
        className="voc-dialog-delete-dialog"
        isOpen={showDeleteDialog}
        onDidDismiss={() => {
          setShowDeleteDialog(false);
        }}
      >
        <IonContent
          scrollY={false}
          className="voc-dialog-delete-dialog-content ion-no-padding"
        >
          <IonButton
            className="voc-dialog-delete-dialog-close-button-dialog ion-no-padding ion-no-margin"
            color={EWPCOLORS.light}
            fill="clear"
            onClick={() => {
              setShowDeleteDialog(false);
            }}
          >
            <IonIcon color={EWPCOLORS.light} icon={close} />
          </IonButton>
          <div className="voc-dialog-delete-dialog-title-container">
            <IonLabel className="ewp-h1 ion-text-center">
              Are you sure you want to delete this Contact Person?
            </IonLabel>
            <IonLabel className="voc-dialog-delete-dialog-subtitle ewp-paragraph ion-text-center">
              Once you delete this Contact Person, you will not be able to
              restore it.
            </IonLabel>
          </div>

          <IonItem
            lines="none"
            className="voc-dialog-delete-dialog-action-buttons-item ewp-item-no-horizontal-padding"
          >
            <IonButton
              mode="ios"
              slot="start"
              className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
              fill="clear"
              color={EWPCOLORS.medium}
              onClick={() => {
                setShowDeleteDialog(false);
              }}
            >
              Cancel
            </IonButton>
            <IonButton
              mode="ios"
              slot="end"
              className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
              color={EWPCOLORS.danger}
              onClick={() => {
                deleteContactPerson();
              }}
            >
              Yes, Delete
              <IonRippleEffect />
            </IonButton>
          </IonItem>
        </IonContent>
      </IonModal>
      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={saving}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
          if (!!onSuccess) {
            onSuccess();
          }
          if (!!props.onModalDidDismiss) {
            props.onModalDidDismiss();
          }
          clearFields();
        }}
        color={EWPCOLORS.success}
      />
    </>
  );
};
