import _ from "lodash";
import { close } from "ionicons/icons";
import React, { useState } from "react";
import {
  IonModal,
  IonContent,
  IonLabel,
  IonButton,
  IonIcon,
  IonCard,
  IonCardContent,
  IonItem,
  IonRippleEffect,
  IonLoading,
  IonToast,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";

import "./ArchiveOrRestoreUserDialog.scss";
import * as services from "../../services";
import { AdminRow } from "../../pages/Home/UserList/UserList";
import { EWPNumberEmail } from "../EWPNumberEmail/EWPNumberEmail";
import { EWPCOLORS } from "../../constants/config";
import { ADMIN_ACCESS, ADMIN_ACCESS_KEYS } from "../../config/global";
import { MSGS_ARCHIVE_ADMIN, MSGS_COMMON } from "../../constants/messages";
import Bugsnag from "@bugsnag/js";

interface ArchiveOrRestoreUserDialogProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  onSuccess: () => void;
  admin: AdminRow;
  restore?: boolean;
}

export const ArchiveOrRestoreUserDialog = (
  props: ArchiveOrRestoreUserDialogProps
) => {
  const { isOpen, onDidDismiss, onSuccess, admin, restore } = props;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const archiveOrRestoreCompanyRep = async () => {
    const { admin, restore } = props;

    if (!!admin) {
      setLoading(true);
      try {
        if (!!restore) {
          await services.restoreAdmin(admin.id || "");
          setSuccess(MSGS_ARCHIVE_ADMIN.restored);
        } else {
          await services.archiveAdmin(admin.id || "");
          setSuccess(MSGS_ARCHIVE_ADMIN.archived);
        }
        setLoading(false);
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        setError(error);
        setLoading(false);
        Bugsnag.notify(new Error(error));
      }
    }
  };

  return (
    <IonModal
      className="archive-company-rep-dialog"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <IonContent
        scrollY={false}
        className="archive-company-rep-content ion-no-padding"
      >
        <IonButton
          className="archive-company-rep-close-button-dialog ion-no-padding ion-no-margin"
          color={EWPCOLORS.light}
          fill="clear"
          onClick={onDidDismiss}
        >
          <IonIcon color={EWPCOLORS.light} icon={close} />
        </IonButton>
        <div className="archive-company-rep-title-container">
          <IonLabel className="ewp-h1 ion-text-center">
            Are you sure you want to {!!restore ? "restore" : "archive"} this
            user?
          </IonLabel>
          <IonLabel className="archive-company-rep-subtitle ewp-paragraph ion-text-center">
            {restore
              ? "If you restore this user, their account will be restored and they will be able to login again."
              : "If you archive this user, they will not longer be able to login or use their account."}
          </IonLabel>
        </div>

        <IonCard
          className="archive-company-rep-dialog-container ion-no-padding ion-no-margin"
          color={EWPCOLORS.tertiary}
        >
          <IonCardContent className="archive-company-rep-details-car-dialog ion-no-padding">
            <IonItem
              lines="none"
              className="archive-company-rep-details-item-dialog"
            >
              <IonIcon className="archive-company-rep-profile-icon ion-no-padding" />
              <IonGrid className="ion-no-padding">
                <IonRow>
                  <IonCol
                    size="4"
                    className="archive-company-rep-item-details-dialog ion-no-padding"
                  >
                    <IonLabel className="ewp-h2 ion-text-capitalize">
                      {`${admin.firstName} ${admin.lastName}`}
                    </IonLabel>
                    <IonLabel className="ewp-paragraph ion-text-capitalize">
                      {ADMIN_ACCESS[ADMIN_ACCESS_KEYS[admin.access]].access}
                    </IonLabel>
                  </IonCol>

                  <IonCol size="8" className="ion-no-padding ">
                    <IonItem
                      className="archive-company-rep-action-item-dialog-container"
                      lines="none"
                    >
                      <div
                        slot="start"
                        className="archive-company-rep-contact-info-dialog"
                      >
                        {/* <IonLabel className="ewp-paragraph ion-text-capitalize">
                          <IonIcon className="archive-company-rep-details-phone-icon" />
                          {admin.phoneNumber}
                        </IonLabel>
                        <IonLabel className="archive-company-rep-details-email ewp-paragraph">
                          <IonIcon className="archive-company-rep-details-message-icon" />
                          {admin.emailAddress}
                        </IonLabel> */}
                        <EWPNumberEmail
                          phoneNumber={admin.phoneNumber as string}
                          email={admin.emailAddress}
                        />
                      </div>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          </IonCardContent>
        </IonCard>
        <IonItem
          lines="none"
          className="archive-company-rep-action-buttons-item ewp-item-no-horizontal-padding"
        >
          <IonButton
            mode="ios"
            slot="start"
            className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
            fill="clear"
            color={EWPCOLORS.medium}
            onClick={onDidDismiss}
          >
            Cancel
          </IonButton>

          <IonButton
            mode="ios"
            slot="end"
            className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
            color={!!restore ? EWPCOLORS.success : EWPCOLORS.danger}
            onClick={archiveOrRestoreCompanyRep}
          >
            Yes, {!!restore ? "Restore" : "Archive"}
            <IonRippleEffect />
          </IonButton>
        </IonItem>
      </IonContent>

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => {
          setError("");
        }}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={1000}
        onDidDismiss={() => {
          setSuccess("");
          onSuccess();
        }}
        color={EWPCOLORS.success}
      />
    </IonModal>
  );
};
