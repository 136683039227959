export const ENVIRONMENTS = {
  development: "development",
  production: "production",
  local: "local",
  localProd: "localProd",
};

export const LOCAL_STORAGE = {
  accessToken: "access_token",
  fbIdToken: "id_token",
  userId: "user_id",
  coupon: "coupon",
  recentMobileVerification: "recent_mobile_verification",
  hasAcceptedNotifPermission: "hasAcceptedNotifPermission",
  hasAcceptedLocationPermission: "hasAcceptedLocationPermission",
  loggedInDriver: "loggedInDriver",
  XERO: {
    access_token: "xero_access_token",
    refresh_token: "xero_refresh_token",
    refresh_token_expiry: "xero_refresh_token_expiry",
    expires_at: "expires_at",
    id_token: "xero_id_token",
    scope: "scope",
    session_state: "session_state",
    token_type: "token_type",
  },
};
