import * as _ from "lodash";
import React, { useState } from "react";
import {
  close,
  chevronForwardOutline,
  chevronBackOutline,
} from "ionicons/icons";
import { IonIcon, IonButton, IonModal, IonImg, IonLabel } from "@ionic/react";

import "./MobileAttachmentDialog.scss";
import { EWPCOLORS } from "../../../constants/config";

interface MobileAttachmentDialogProps {
  showModal: boolean;
  onDidDismiss: () => void;
  attachments?: any; //attachement with details
  onDelete?: (siteDocketIndex: number) => void;
  onEdit?: () => void;
  selectedIndex?: number;
}

export const MobileAttachmentDialog = (props: MobileAttachmentDialogProps) => {
  const {
    showModal,
    onDidDismiss,
    attachments,
    onEdit,
    onDelete,
    selectedIndex,
  } = props;
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState(
    !!selectedIndex ? selectedIndex : 0
  );
  console.log("this is attachments -- ", attachments);
  return (
    <IonModal
      className="mobile-attachment-dialog"
      isOpen={showModal}
      onDidDismiss={onDidDismiss}
      showBackdrop={false}
    >
      <div className="mobile-attachment-dialog-header">
        <IonButton
          className="mobile-attachment-dialog-header-close ion-no-padding ion-no-margin"
          color={EWPCOLORS.light}
          fill="clear"
          slot="end"
          onClick={onDidDismiss}
        >
          <IonIcon color={EWPCOLORS.secondary} icon={close} />
        </IonButton>
      </div>
      <div className="mobile-attachment-dialog-content ion-no-padding ion-no-margin">
        <IonButton
          fill="clear"
          color={EWPCOLORS.light}
          className="attachment-prev-button ion-no-padding"
          disabled={selectedAttachmentIndex <= 0}
          onClick={() => {
            const prevIndex = selectedAttachmentIndex - 1;
            if (prevIndex >= 0) {
              setSelectedAttachmentIndex(prevIndex);
            }
          }}
        >
          <IonIcon
            className="attachment-next-prev-icon"
            mode="ios"
            color={EWPCOLORS.tertiary}
            icon={chevronBackOutline}
          />
        </IonButton>
        <IonButton
          fill="clear"
          color={EWPCOLORS.light}
          className="attachment-next-button ion-no-padding"
          disabled={
            _.isEmpty(props.attachments.attachments) ||
            (!_.isEmpty(props.attachments.attachments) &&
              selectedAttachmentIndex + 1 >=
                props.attachments.attachments.length)
          }
          onClick={() => {
            const nextIndex = selectedAttachmentIndex + 1;
            if (!_.isEmpty(props.attachments.attachments)) {
              if (nextIndex <= props.attachments.attachments.length) {
                setSelectedAttachmentIndex(nextIndex);
              }
            }
          }}
        >
          <IonIcon
            className="attachment-next-prev-icon"
            mode="ios"
            color={EWPCOLORS.tertiary}
            icon={chevronForwardOutline}
          />
        </IonButton>

        {!_.isEmpty(attachments.attachments) ? (
          <>
            <IonImg
              className="attachment-image"
              src={
                attachments.attachments[selectedAttachmentIndex] &&
                !!attachments.attachments[selectedAttachmentIndex].attachmentUrl
                  ? attachments.attachments[selectedAttachmentIndex]
                      .attachmentUrl
                  : attachments.attachments[selectedAttachmentIndex]
              }
            />
            {!!attachments.attachments[selectedAttachmentIndex].photoName && (
              <IonLabel className="image-label ewp-h4 bold white">
                {attachments.attachments[selectedAttachmentIndex].photoName}
              </IonLabel>
            )}
          </>
        ) : (
          // <IonIcon className="worker-licenses-icon-img" />
          <IonLabel className="attachment-empty-placeholder ewp-h2 bold white">
            No Image Available
          </IonLabel>
        )}
        {!!onDelete && (
          <IonButton
            fill="clear"
            mode="ios"
            onClick={() => {
              if (!!onDelete) {
                onDelete(selectedAttachmentIndex);
              }
            }}
            color={EWPCOLORS.secondary}
            className="attachment-delete-button ewp-h6 ion-no-padding ion-no-margin"
          >
            <IonIcon className="mobile-delete-icon" />
          </IonButton>
        )}
        {!!onEdit && (
          <IonButton
            fill="clear"
            color={EWPCOLORS.secondary}
            className="attachment-edit-button ewp-h3 small white"
            onClick={() => {
              if (!!onEdit) {
                onEdit();
              }
            }}
          >
            Edit
          </IonButton>
        )}
      </div>
    </IonModal>
  );
};
