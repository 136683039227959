import React from "react";
import { close } from "ionicons/icons";
import { IonItem, IonIcon, IonButton, IonModal, IonLabel } from "@ionic/react";

import "./EWPWebDialog.scss";
import { EWPCOLORS } from "../../constants/config";

interface EWPWebDialogProps {
  headerTitle: string;
  isOpen: boolean;
  onDidDismiss: () => void;
  children?: any;
  small?: boolean;
  contentClassName?: string;
}

export const EWPWebDialog = (props: EWPWebDialogProps) => {
  const {
    isOpen,
    onDidDismiss,
    headerTitle,
    children,
    small,
    contentClassName,
  } = props;
  return (
    <IonModal
      className={`ewp-web-dialog ${!!small && "small"}`}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <div className="modal-content">
        <IonItem className="ewp-dialog-header-item ion-no-margin ion-no-padding">
          <IonLabel className="ewp-web-dialog-header-title ewp-h4 ion-no-padding ion-no-margin">
            {headerTitle}
          </IonLabel>
          <IonButton
            className="ewp-web-dialog-header-close ion-no-padding ion-no-margin"
            color={EWPCOLORS.light}
            fill="clear"
            slot="end"
            onClick={onDidDismiss}
          >
            <IonIcon color={EWPCOLORS.light} icon={close} />
          </IonButton>
        </IonItem>
        <div
          className={`ewp-web-dialog-children-container ${
            contentClassName !== undefined ? contentClassName : ""
          }`}
        >
          {children}
        </div>
      </div>
    </IonModal>
  );
};
