import * as _ from "lodash";
import moment from "moment";
import Bugsnag from "@bugsnag/js";

import { firestore, auth, fb, storage } from "../firebase";
import {
  getDateFromTimezoneIgnoredTimestamp,
  getServerTimestamp,
  timezoneIgnoredTimestamp,
} from "../functions/common";
import { Driver, Truck } from "../models/drivers";
import {
  Admin,
  BookedTrucksView,
  JobAdminNotes,
  JobAdminNotesView,
  JobSchedule,
  JobScheduleView,
  NotificationStatusId,
  NOTIFICATION_STATUS,
  NotifiedDriverNotes,
  SiteDocket,
} from "../models";
import {
  ADMINS,
  CLIENTS,
  CONTACT_PEOPLE,
  DEPOTS,
  DRIVERS,
  JOB_ADMIN_NOTES,
  JOB_SCHEDULES,
  TRUCKS,
} from "../constants/dbCollections";
import { sendSMS } from "../api";
import { getDriver } from "./driver";
import { driverNewJobSchedule } from "../constants/messages";
import firebase from "firebase";

export const createJobSchedule = async (
  // isRemote: boolean,
  startDate: Date,
  endDate: Date,
  onsiteTime: Date,
  finishTime: Date,
  clientId: string,
  contactPersonId: string,
  bookingContactName = "",
  bookingContactNumber = "",
  truckId: string,
  depotId: string,
  jobType: number,
  truckSize: string,
  address: string,
  notes = "",
  notifyDriver: boolean,
  notificationStatusId: NotificationStatusId,
  travelJobTime?: string,
  distanceJob?: string,
  travelBackTime?: string,
  stopPoints?: string[],
  driverId?: string,
  pencilledIn?: boolean,
  attachments?: string[]
) => {
  const createdBy = auth.currentUser;
  if (!_.isNull(createdBy)) {
    const startDateNoTimeZone = timezoneIgnoredTimestamp(startDate);
    const endDateNoTimeZone = timezoneIgnoredTimestamp(endDate);

    const startNOTZDate = startDateNoTimeZone.date;
    const endNOTZDate = endDateNoTimeZone.date;

    const startInitialMonth = startDateNoTimeZone.month;
    const endInitialMonth = endDateNoTimeZone.month;
    let startMonth = startInitialMonth;
    let endMonth = endInitialMonth;

    if (!(startMonth === 0 && endMonth === 11)) {
      startMonth =
        startNOTZDate < 8
          ? startInitialMonth !== 0
            ? startInitialMonth - 1
            : 11
          : startInitialMonth;
      endMonth =
        endNOTZDate > 21
          ? endInitialMonth !== 11
            ? endInitialMonth + 1
            : 0
          : endInitialMonth;
    }

    const spillOver = startMonth > endMonth;
    const initRange = _.uniq(
      _.flatten([
        ..._.range(startMonth, (spillOver ? 11 : endMonth) + 1),
        ...(spillOver ? _.range(0, endMonth + 1) : []),
      ])
    ).map((value) => value.toString());
    const monthRange = initRange.reduce(
      (previousArray, nextMonth, index, array) => {
        const newArray = _.clone(previousArray);
        if (index > 0) {
          newArray.push(`${previousArray[index - 1]}${nextMonth}`);
        } else {
          newArray.push(
            `${
              previousArray[0] !== "0"
                ? (parseInt(previousArray[0]) - 1).toString()
                : "11"
            }${previousArray[0]}`
          );
        }
        if (index + 1 === array.length) {
          newArray.push(
            `${nextMonth}${
              nextMonth !== "11" ? (parseInt(nextMonth) + 1).toString() : "0"
            }`
          );
        }
        return newArray;
      },
      _.cloneDeep(initRange)
    );

    await firestore.collection(JOB_SCHEDULES).add({
      // isRemote,
      monthRange,
      startDateNoTimeZone,
      startDate,
      endDateNoTimeZone,
      endDate,
      onsiteTimeNoTimeZone: timezoneIgnoredTimestamp(onsiteTime),
      onsiteTime,
      finishTime: timezoneIgnoredTimestamp(finishTime),
      clientId,
      contactPersonId,
      bookingContactName,
      bookingContactNumber,
      truckId,
      depotId,
      jobType,
      truckSize,
      travelJobTime,
      distanceJob,
      travelBackTime,
      stopPoints,
      driverId,
      address,
      notes,
      notifyDriver,
      attachments,
      notificationStatusId,
      pencilledIn,
      createdBy: createdBy.uid,
      createdDt: getServerTimestamp(),
      updatedDt: getServerTimestamp(),
    });
  } else {
    throw new Error("No user logged in");
  }
};

export const softDeleteJobSchedule = async (docId: string) => {
  const updatedBy = auth.currentUser;
  if (!_.isNull(updatedBy)) {
    await firestore.collection(JOB_SCHEDULES).doc(docId).update({
      softDeleted: true,
      updatedBy: updatedBy.uid,
      updatedDt: getServerTimestamp(),
    });
  } else {
    throw new Error("No user logged in");
  }
};

export const cancelJobSchedule = async (docId: string) => {
  const updatedBy = auth.currentUser;
  if (!_.isNull(updatedBy)) {
    await firestore.collection(JOB_SCHEDULES).doc(docId).update({
      cancelled: true,
      cancelledBy: updatedBy.uid,
      updatedBy: updatedBy.uid,
      updatedDt: getServerTimestamp(),
    });
  } else {
    throw new Error("No user logged in");
  }
};

export const uncancelJobSchedule = async (docId: string) => {
  const updatedBy = auth.currentUser;
  if (!_.isNull(updatedBy)) {
    await firestore.collection(JOB_SCHEDULES).doc(docId).update({
      cancelled: fb.firestore.FieldValue.delete(),
      cancelledBy: fb.firestore.FieldValue.delete(),
      updatedBy: updatedBy.uid,
      updatedDt: getServerTimestamp(),
    });
  } else {
    throw new Error("No user logged in");
  }
};

export const updateJobSchedule = async (
  docId: string,
  // isRemote: boolean,
  startDate: Date,
  endDate: Date,
  onsiteTime: Date,
  finishTime: Date,
  clientId: string,
  contactPersonId: string,
  bookingContactName = "",
  bookingContactNumber = "",
  truckId: string,
  depotId: string,
  jobType: number,
  truckSize: string,
  address: string,
  notes = "",
  notifyDriver: boolean,
  notificationStatusId: NotificationStatusId,
  travelJobTime?: string,
  distanceJob?: string,
  travelBackTime?: string,
  stopPoints?: string[],
  driverId?: string,
  pencilledIn?: boolean,
  attachments?: string[]
) => {
  const updatedBy = auth.currentUser;
  if (!_.isNull(updatedBy)) {
    const startDateNoTimeZone = timezoneIgnoredTimestamp(startDate);
    const endDateNoTimeZone = timezoneIgnoredTimestamp(endDate);

    const startNOTZDate = startDateNoTimeZone.date;
    const endNOTZDate = endDateNoTimeZone.date;

    const startInitialMonth = startDateNoTimeZone.month;
    const endInitialMonth = endDateNoTimeZone.month;
    let startMonth = startInitialMonth;
    let endMonth = endInitialMonth;

    if (!(startMonth === 0 && endMonth === 11)) {
      startMonth =
        startNOTZDate < 8
          ? startInitialMonth !== 0
            ? startInitialMonth - 1
            : 11
          : startInitialMonth;
      endMonth =
        endNOTZDate > 21
          ? endInitialMonth !== 11
            ? endInitialMonth + 1
            : 0
          : endInitialMonth;
    }

    const spillOver = startMonth > endMonth;
    const initRange = _.uniq(
      _.flatten([
        ..._.range(startMonth, (spillOver ? 11 : endMonth) + 1),
        ...(spillOver ? _.range(0, endMonth + 1) : []),
      ])
    ).map((value) => value.toString());
    const monthRange = initRange.reduce(
      (previousArray, nextMonth, index, array) => {
        const newArray = _.clone(previousArray);
        if (index > 0) {
          newArray.push(`${previousArray[index - 1]}${nextMonth}`);
        } else {
          newArray.push(
            `${
              previousArray[0] !== "0"
                ? (parseInt(previousArray[0]) - 1).toString()
                : "11"
            }${previousArray[0]}`
          );
        }
        if (index + 1 === array.length) {
          newArray.push(
            `${nextMonth}${
              nextMonth !== "11" ? (parseInt(nextMonth) + 1).toString() : "0"
            }`
          );
        }
        return newArray;
      },
      _.cloneDeep(initRange)
    );

    await firestore
      .collection(JOB_SCHEDULES)
      .doc(docId)
      .update({
        // isRemote,
        monthRange,
        startDateNoTimeZone,
        startDate,
        endDateNoTimeZone,
        endDate,
        onsiteTimeNoTimeZone: timezoneIgnoredTimestamp(onsiteTime),
        onsiteTime,
        finishTime: timezoneIgnoredTimestamp(finishTime),
        clientId,
        contactPersonId,
        bookingContactName,
        bookingContactNumber,
        truckId,
        depotId,
        jobType,
        truckSize,
        travelJobTime,
        distanceJob,
        travelBackTime,
        stopPoints,
        driverId,
        address,
        notes,
        attachments,
        notifyDriver,
        pencilledIn,
        notificationStatusId,
        updatedBy: updatedBy.uid,
        updatedDt: getServerTimestamp(),
      });
  } else {
    throw new Error("No user logged in");
  }
};

export const updateJobScheduleGeneric = async (
  docId: string,
  updateData: Partial<JobSchedule>,
  userId = null as null | string
) => {
  const updatedBy = !_.isNull(userId) ? { uid: userId } : auth.currentUser;
  if (!_.isNull(updatedBy)) {
    // console.log(
    //   "WILL UPDATE --- nowww",
    //   {
    //     ...updateData,
    //     updatedBy: !_.isNull(userId) ? userId : updatedBy.uid,
    //     updatedDt: getServerTimestamp(new Date()),
    //   },
    //   docId
    // );
    return await firestore
      .collection(JOB_SCHEDULES)
      .doc(docId)
      .update({
        ...updateData,
        ...(updateData.invoiceChecked && {
          checkedBy: !_.isNull(userId) ? userId : updatedBy.uid,
        }),
        ...((updateData.invoiceChecked ||
          !_.isEmpty(updateData.invoiceNumber)) && {
          checkedDt: getServerTimestamp(new Date()),
        }),
        updatedBy: !_.isNull(userId) ? userId : updatedBy.uid,
        updatedDt: getServerTimestamp(new Date()),
      });
  } else {
    throw new Error("No user logged in");
  }
};

export const notifyJobScheduleDriver = async (
  docId: string,
  startDate: Date,
  driverId: string,
  clientName: string
) => {
  const updatedBy = auth.currentUser;
  if (!_.isNull(updatedBy)) {
    await firestore.collection(JOB_SCHEDULES).doc(docId).update({
      notifyDriver: true,
      notificationStatusId: NOTIFICATION_STATUS.pending.id,
      updatedBy: updatedBy.uid,
      updatedDt: getServerTimestamp(),
    });

    try {
      const driver = await getDriver(driverId);
      if (!_.isEmpty(driver)) {
        await sendSMS(
          driver.phoneNumber as string,
          driverNewJobSchedule(startDate, clientName)
        );
      }
    } catch (eUnknown) {
      const e = eUnknown as any;
      console.log("ERROR SENDING NOTIF", e);
    }
  } else {
    throw new Error("No user logged in");
  }
};

export const updateJobSiteDocket = async (
  siteDocket: SiteDocket,
  jobScheduleId: string,
  driverId: string
) => {
  await firestore
    .collection(JOB_SCHEDULES)
    .doc(jobScheduleId)
    .update({
      siteDockets: fb.firestore.FieldValue.arrayUnion({ ...siteDocket }),
      updatedBy: driverId,
      updatedDt: getServerTimestamp(),
    });
};
export const deleteAttachment = async (attachmentRef: string) => {
  await new Promise<void>(async (resolve) => {
    try {
      const refFromUrl = storage.refFromURL(attachmentRef);

      try {
        await refFromUrl.delete();
        resolve();
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        resolve();
        Bugsnag.notify(new Error(error));
      }
    } catch (errorUnknownMain) {
      const errorMain = errorUnknownMain as any;
      resolve();
      Bugsnag.notify(new Error(errorMain));
    }
  });
};

export const removeJobSiteDocket = async (
  siteDocket: SiteDocket,
  jobScheduleId: string,
  driverId: string
) => {
  await firestore
    .collection(JOB_SCHEDULES)
    .doc(jobScheduleId)
    .update({
      siteDockets: fb.firestore.FieldValue.arrayRemove({ ...siteDocket }),
      updatedBy: driverId,
      updatedDt: getServerTimestamp(),
    });
};

export const updateNotificationReadJobSchedule = async (docId: string) => {
  await firestore
    .collection(JOB_SCHEDULES)
    .doc(docId)
    .update({
      notificationReadAtNoTimeZone: timezoneIgnoredTimestamp(new Date()),
      updatedDt: getServerTimestamp(),
    });
};

export const driverAcceptJob = async (docId: string) => {
  await firestore.collection(JOB_SCHEDULES).doc(docId).update({
    notificationStatusId: NOTIFICATION_STATUS.accepted.id,
    updatedDt: getServerTimestamp(),
  });
};

export const closeJobSchedule = async (docId: string) => {
  await firestore
    .collection(JOB_SCHEDULES)
    .doc(docId)
    .update({
      jobCompletedAtNoTimeZone: timezoneIgnoredTimestamp(new Date()),
      updatedDt: getServerTimestamp(),
    });
};

export const submitDriverNote = async (
  docId: string,
  driverNotes: NotifiedDriverNotes
) => {
  try {
    if (driverNotes) {
      await firestore.collection(JOB_SCHEDULES).doc(docId).update({
        driverNotes,
      });
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("error -- submitSendNote -- ", error);
    Bugsnag.notify(new Error(error));
  }
};

export const updateNotificationJobSeen = async (
  docId: string,
  driverNotes: NotifiedDriverNotes
) => {
  try {
    if (driverNotes) {
      const mutatedDriverNotes = {
        ...driverNotes,
        seenDate: getServerTimestamp(),
        adminSeen: true,
      };
      await firestore.collection(JOB_SCHEDULES).doc(docId).update({
        driverNotes: mutatedDriverNotes,
      });
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("error -- submitSendNote -- ", error);
    Bugsnag.notify(new Error(error));
  }
};

export const deleteDriverNote = async (docId: string) => {
  try {
    await firestore.collection(JOB_SCHEDULES).doc(docId).update({
      driverNotes: fb.firestore.FieldValue.delete(),
    });
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("error -- deleteDriverNote -- ", error);
    Bugsnag.notify(new Error(error));
  }
};

export const getAllJobSchedule = async () => {
  const result = await firestore.collection(JOB_SCHEDULES).get();

  if (!result.empty) {
    return result.docs.map((job) => {
      return {
        ...job.data(),
        docId: job.id,
      } as JobSchedule;
    });
  } else {
    return [];
  }
};

export const migrateJobNotes = async () => {
  // update job schedule to keep counter of notes
  // on add notes, increment counter
  // get all admin notes
  // Group by job schedule id
  // update job schedule one by one
};

export const migrateJobSchedules = async () => {
  const jobScheds = await firestore.collection(JOB_SCHEDULES).get();
  // const jobSchedData = jobScheds.docs.map(
  //   (data) => ({ ...data.data(), docId: data.id } as JobSchedule)
  // );

  await Promise.all(
    jobScheds.docs.map((jobData) => {
      const job = jobData.data() as JobSchedule;
      const startNOTZDate = job.startDateNoTimeZone.date;
      const endNOTZDate = job.endDateNoTimeZone.date;

      const startInitialMonth = job.startDateNoTimeZone.month;
      const endInitialMonth = job.endDateNoTimeZone.month;
      let startMonth = startInitialMonth;
      let endMonth = endInitialMonth;

      if (!(startMonth === 0 && endMonth === 11)) {
        startMonth =
          startNOTZDate < 8
            ? startInitialMonth !== 0
              ? startInitialMonth - 1
              : 11
            : startInitialMonth;
        endMonth =
          endNOTZDate > 21
            ? endInitialMonth !== 11
              ? endInitialMonth + 1
              : 0
            : endInitialMonth;
      }

      const spillOver = startMonth > endMonth;
      const initRange = _.uniq(
        _.flatten([
          ..._.range(startMonth, (spillOver ? 11 : endMonth) + 1),
          ...(spillOver ? _.range(0, endMonth + 1) : []),
        ])
      ).map((value) => value.toString());
      const monthRange = initRange.reduce(
        (previousArray, nextMonth, index, array) => {
          const newArray = _.clone(previousArray);
          if (index > 0) {
            newArray.push(`${previousArray[index - 1]}${nextMonth}`);
          } else {
            newArray.push(
              `${
                previousArray[0] !== "0"
                  ? (parseInt(previousArray[0]) - 1).toString()
                  : "11"
              }${previousArray[0]}`
            );
          }
          if (index + 1 === array.length) {
            newArray.push(
              `${nextMonth}${
                nextMonth !== "11" ? (parseInt(nextMonth) + 1).toString() : "0"
              }`
            );
          }
          return newArray;
        },
        _.cloneDeep(initRange)
      );

      return jobData.ref.update({
        monthRange,
      });
    })
  );
  // _.map(jobSchedData, (job) => {
  //   //!PLEASE TAKE INTO CONSIDERATION SKIPPING TO NEXT YEAR IN RANGE ----
  //   if (job.startDateNoTimeZone.month !== job.endDateNoTimeZone.month) {
  //     const spillOver =
  //       job.startDateNoTimeZone.month > job.endDateNoTimeZone.month;
  //     const initRange = _.uniq(
  //       _.flatten([
  //         ..._.range(
  //           job.startDateNoTimeZone.month,
  //           (spillOver ? 11 : job.endDateNoTimeZone.month) + 1
  //         ),
  //         ...(spillOver ? _.range(0, job.endDateNoTimeZone.month + 1) : []),
  //       ])
  //     ).map((value) => value.toString());

  //     console.log(
  //       "CHECKING RANGE",
  //       JSON.stringify({
  //         // start: job.startDateNoTimeZone.month,
  //         // end: job.endDateNoTimeZone.month,
  //         result: initRange,
  //         result2: initRange.reduce(
  //           (previousArray, nextMonth, index, array) => {
  //             const newArray = _.clone(previousArray);
  //             if (index > 0) {
  //               newArray.push(`${previousArray[index - 1]}${nextMonth}`);

  //               console.log({
  //                 previousMonth: previousArray,
  //                 nextMonth,
  //                 array,
  //                 index,
  //                 newArray,
  //               });
  //             } else {
  //               newArray.push(
  //                 `${
  //                   previousArray[0] !== "0"
  //                     ? (parseInt(previousArray[0]) - 1).toString()
  //                     : "11"
  //                 }${previousArray[0]}`
  //               );
  //             }
  //             if (index + 1 === array.length) {
  //               newArray.push(
  //                 `${nextMonth}${
  //                   nextMonth !== "11"
  //                     ? (parseInt(nextMonth) + 1).toString()
  //                     : "0"
  //                 }`
  //               );
  //             }
  //             return newArray;
  //           },
  //           _.cloneDeep(initRange)
  //         ),
  //       })
  //     );
  //   }
  // });
};

export const getJobSchedulesRangeRealTimeView = async (
  callback: (
    jobSchedules: JobScheduleView[],

    error?: string
  ) => void,
  startDateMonth: number,
  endDateMonth: number
) => {
  try {
    let query:
      | firebase.firestore.CollectionReference
      | firebase.firestore.Query = firestore
      .collection(JOB_SCHEDULES)
      .where(
        "monthRange",
        "array-contains",
        startDateMonth !== endDateMonth
          ? `${startDateMonth.toString()}${endDateMonth.toString()}`
          : startDateMonth.toString()
      );

    const unsubscribe = query.onSnapshot(async (jobSchedules) => {
      if (!jobSchedules.empty) {
        const clientMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
        } = {};
        const contactPersonMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const adminCancelledMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const checkedByMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const depotMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const truckMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const driverMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
        } = {};

        const notesCountMap: {
          [id: string]: number;
        } = {};

        const jobScheduleDataList = jobSchedules.docs.map(
          (jobSchedule) =>
            ({
              ...jobSchedule.data(),
              docId: jobSchedule.id,
            } as JobSchedule)
        );

        await Promise.all([
          ..._.uniq(jobScheduleDataList.map((sched) => sched.clientId)).map(
            (clientId) =>
              new Promise<void>(async (resolve) => {
                clientMap[clientId] = await firestore
                  .collection(CLIENTS)
                  .doc(clientId)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.contactPersonId))
          ).map(
            (contactPersonId) =>
              new Promise<void>(async (resolve) => {
                contactPersonMap[contactPersonId] = await firestore
                  .collection(CONTACT_PEOPLE)
                  .doc(contactPersonId)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.cancelledBy))
          ).map(
            (cancelledBy) =>
              new Promise<void>(async (resolve) => {
                adminCancelledMap[cancelledBy] = await firestore
                  .collection(ADMINS)
                  .doc(cancelledBy)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.checkedBy))
          ).map(
            (checkedBy) =>
              new Promise<void>(async (resolve) => {
                checkedByMap[checkedBy] = await firestore
                  .collection(ADMINS)
                  .doc(checkedBy)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.depotId))
          ).map(
            (depotId) =>
              new Promise<void>(async (resolve) => {
                depotMap[depotId] = await firestore
                  .collection(DEPOTS)
                  .doc(depotId)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.truckId))
          ).map(
            (truckId) =>
              new Promise<void>(async (resolve) => {
                truckMap[truckId] = await firestore
                  .collection(TRUCKS)
                  .doc(truckId)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.driverId))
          ).map(
            (driverId) =>
              new Promise<void>(async (resolve) => {
                driverMap[driverId] = await firestore
                  .collection(DRIVERS)
                  .doc(driverId)
                  .get();
                resolve();
              })
          ),
          ...jobScheduleDataList.map(
            (job) =>
              new Promise<void>(async (resolve) => {
                notesCountMap[job.docId || ""] = (
                  await firestore
                    .collection(JOB_ADMIN_NOTES)
                    .where("jobScheduleId", "==", job.docId || "")
                    .get()
                ).size;
                resolve();
              })
          ),
        ]);

        const jobSchedulesFormattedView = (await Promise.all(
          jobScheduleDataList.map((jobScheduleData) => {
            return new Promise(async (resolve) => {
              const clientQuery = clientMap[jobScheduleData.clientId];

              const contactPersonQuery = !_.isEmpty(
                jobScheduleData.contactPersonId
              )
                ? contactPersonMap[jobScheduleData.contactPersonId] !==
                  undefined
                  ? contactPersonMap[jobScheduleData.contactPersonId]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.contactPersonId)) {
                contactPersonMap[jobScheduleData.contactPersonId] =
                  contactPersonQuery;
              }

              const adminCancelledQuery = !_.isEmpty(
                jobScheduleData.cancelledBy
              )
                ? adminCancelledMap[jobScheduleData.cancelledBy!] !== undefined
                  ? adminCancelledMap[jobScheduleData.cancelledBy!]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.cancelledBy)) {
                adminCancelledMap[jobScheduleData.cancelledBy!] =
                  adminCancelledQuery;
              }

              const checkedByQuery = !_.isEmpty(jobScheduleData.checkedBy)
                ? checkedByMap[jobScheduleData.checkedBy!] !== undefined
                  ? checkedByMap[jobScheduleData.checkedBy!]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.checkedBy)) {
                checkedByMap[jobScheduleData.checkedBy!] = checkedByQuery;
              }

              const depotQuery = !_.isEmpty(jobScheduleData.depotId)
                ? depotMap[jobScheduleData.depotId] !== undefined
                  ? depotMap[jobScheduleData.depotId]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.depotId)) {
                depotMap[jobScheduleData.depotId] = depotQuery;
              }

              const truckQuery = !_.isEmpty(jobScheduleData.truckId)
                ? truckMap[jobScheduleData.truckId] !== undefined
                  ? truckMap[jobScheduleData.truckId]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.truckId)) {
                truckMap[jobScheduleData.truckId] = truckQuery;
              }

              let driverDetails = {} as Driver;
              if (!_.isEmpty(jobScheduleData.driverId)) {
                const driverQuery = driverMap[jobScheduleData.driverId!];

                if (driverQuery.exists) {
                  driverDetails = {
                    ...driverQuery.data(),
                    docId: driverQuery.id,
                  } as Driver;
                }
              }

              const notesCounter = notesCountMap[jobScheduleData.docId || ""];

              if (clientQuery.exists && !jobScheduleData.softDeleted) {
                resolve({
                  ...jobScheduleData,
                  clientDetails: clientQuery.data(),
                  contactPersonDetails:
                    !_.isNull(contactPersonQuery) && contactPersonQuery.exists
                      ? contactPersonQuery!.data()
                      : {},
                  depotDetails:
                    !_.isNull(depotQuery) && depotQuery.exists
                      ? depotQuery!.data()
                      : {},
                  truckDetails:
                    !_.isNull(truckQuery) && truckQuery.exists
                      ? { ...truckQuery!.data(), docId: truckQuery!.id }
                      : {},
                  driverDetails,
                  ...(!!adminCancelledQuery &&
                    !_.isNull(adminCancelledQuery) &&
                    adminCancelledQuery.exists && {
                      cancelledByName: `${
                        (adminCancelledQuery.data() as Admin).firstName
                      } ${(adminCancelledQuery.data() as Admin).lastName}`,
                    }),
                  ...(!!checkedByQuery &&
                    !_.isNull(checkedByQuery) &&
                    checkedByQuery.exists && {
                      checkedByName: `${
                        (checkedByQuery.data() as Admin).firstName
                      } ${(checkedByQuery.data() as Admin).lastName}`,
                    }),
                  notesCounter,
                });
              } else {
                resolve(null);
              }
            });
          })
        )) as (JobScheduleView | null)[];
        // console.log("MAPPED DATA -- ", {
        //   clientMap,
        //   contactPersonMap,
        //   adminCancelledMap,
        //   checkedByMap,
        //   depotMap,
        //   truckMap,
        //   driverMap,
        // });
        callback(_.compact(jobSchedulesFormattedView));
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERRR IN getJobSchedulesRealTime: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const getJobSchedulesForDriversRealTimeView = async (
  driverId: string,
  callback: (jobSchedules: JobScheduleView[], error?: string) => void
) => {
  try {
    let query:
      | firebase.firestore.CollectionReference
      | firebase.firestore.Query = firestore
      .collection(JOB_SCHEDULES)
      .where("driverId", "==", driverId);

    const unsubscribe = query.onSnapshot(async (jobSchedules) => {
      if (!jobSchedules.empty) {
        const clientMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
        } = {};
        const contactPersonMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const adminCancelledMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const checkedByMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const depotMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const truckMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const driverMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
        } = {};

        const jobScheduleDataList = jobSchedules.docs.map(
          (jobSchedule) =>
            ({
              ...jobSchedule.data(),
              docId: jobSchedule.id,
            } as JobSchedule)
        );

        await Promise.all([
          ..._.uniq(jobScheduleDataList.map((sched) => sched.clientId)).map(
            (clientId) =>
              new Promise<void>(async (resolve) => {
                clientMap[clientId] = await firestore
                  .collection(CLIENTS)
                  .doc(clientId)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.contactPersonId))
          ).map(
            (contactPersonId) =>
              new Promise<void>(async (resolve) => {
                contactPersonMap[contactPersonId] = await firestore
                  .collection(CONTACT_PEOPLE)
                  .doc(contactPersonId)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.cancelledBy))
          ).map(
            (cancelledBy) =>
              new Promise<void>(async (resolve) => {
                adminCancelledMap[cancelledBy] = await firestore
                  .collection(ADMINS)
                  .doc(cancelledBy)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.checkedBy))
          ).map(
            (checkedBy) =>
              new Promise<void>(async (resolve) => {
                checkedByMap[checkedBy] = await firestore
                  .collection(ADMINS)
                  .doc(checkedBy)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.depotId))
          ).map(
            (depotId) =>
              new Promise<void>(async (resolve) => {
                depotMap[depotId] = await firestore
                  .collection(DEPOTS)
                  .doc(depotId)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.truckId))
          ).map(
            (truckId) =>
              new Promise<void>(async (resolve) => {
                truckMap[truckId] = await firestore
                  .collection(TRUCKS)
                  .doc(truckId)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.driverId))
          ).map(
            (driverId) =>
              new Promise<void>(async (resolve) => {
                driverMap[driverId] = await firestore
                  .collection(DRIVERS)
                  .doc(driverId)
                  .get();
                resolve();
              })
          ),
        ]);

        const jobSchedulesFormattedView = (await Promise.all(
          jobScheduleDataList.map((jobScheduleData) => {
            return new Promise(async (resolve) => {
              const clientQuery = clientMap[jobScheduleData.clientId];

              const contactPersonQuery = !_.isEmpty(
                jobScheduleData.contactPersonId
              )
                ? contactPersonMap[jobScheduleData.contactPersonId] !==
                  undefined
                  ? contactPersonMap[jobScheduleData.contactPersonId]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.contactPersonId)) {
                contactPersonMap[jobScheduleData.contactPersonId] =
                  contactPersonQuery;
              }

              const adminCancelledQuery = !_.isEmpty(
                jobScheduleData.cancelledBy
              )
                ? adminCancelledMap[jobScheduleData.cancelledBy!] !== undefined
                  ? adminCancelledMap[jobScheduleData.cancelledBy!]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.cancelledBy)) {
                adminCancelledMap[jobScheduleData.cancelledBy!] =
                  adminCancelledQuery;
              }

              const checkedByQuery = !_.isEmpty(jobScheduleData.checkedBy)
                ? checkedByMap[jobScheduleData.checkedBy!] !== undefined
                  ? checkedByMap[jobScheduleData.checkedBy!]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.checkedBy)) {
                checkedByMap[jobScheduleData.checkedBy!] = checkedByQuery;
              }

              const depotQuery = !_.isEmpty(jobScheduleData.depotId)
                ? depotMap[jobScheduleData.depotId] !== undefined
                  ? depotMap[jobScheduleData.depotId]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.depotId)) {
                depotMap[jobScheduleData.depotId] = depotQuery;
              }

              const truckQuery = !_.isEmpty(jobScheduleData.truckId)
                ? truckMap[jobScheduleData.truckId] !== undefined
                  ? truckMap[jobScheduleData.truckId]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.truckId)) {
                truckMap[jobScheduleData.truckId] = truckQuery;
              }

              let driverDetails = {} as Driver;
              if (!_.isEmpty(jobScheduleData.driverId)) {
                const driverQuery = driverMap[jobScheduleData.driverId!];

                if (driverQuery.exists) {
                  driverDetails = {
                    ...driverQuery.data(),
                    docId: driverQuery.id,
                  } as Driver;
                }
              }

              if (clientQuery.exists && !jobScheduleData.softDeleted) {
                resolve({
                  ...jobScheduleData,
                  clientDetails: clientQuery.data(),
                  contactPersonDetails:
                    !_.isNull(contactPersonQuery) && contactPersonQuery.exists
                      ? contactPersonQuery!.data()
                      : {},
                  depotDetails:
                    !_.isNull(depotQuery) && depotQuery.exists
                      ? depotQuery!.data()
                      : {},
                  truckDetails:
                    !_.isNull(truckQuery) && truckQuery.exists
                      ? { ...truckQuery!.data(), docId: truckQuery!.id }
                      : {},
                  driverDetails,
                  ...(!!adminCancelledQuery &&
                    !_.isNull(adminCancelledQuery) &&
                    adminCancelledQuery.exists && {
                      cancelledByName: `${
                        (adminCancelledQuery.data() as Admin).firstName
                      } ${(adminCancelledQuery.data() as Admin).lastName}`,
                    }),
                  ...(!!checkedByQuery &&
                    !_.isNull(checkedByQuery) &&
                    checkedByQuery.exists && {
                      checkedByName: `${
                        (checkedByQuery.data() as Admin).firstName
                      } ${(checkedByQuery.data() as Admin).lastName}`,
                    }),
                });
              } else {
                resolve(null);
              }
            });
          })
        )) as (JobScheduleView | null)[];
        // console.log("MAPPED DATA -- ", {
        //   clientMap,
        //   contactPersonMap,
        //   adminCancelledMap,
        //   checkedByMap,
        //   depotMap,
        //   truckMap,
        //   driverMap,
        // });
        callback(_.compact(jobSchedulesFormattedView));
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERRR IN getJobSchedulesRealTime: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const getJobSchedulesForClientsRealTimeView = async (
  clientId: string,
  callback: (jobSchedules: JobScheduleView[], error?: string) => void
) => {
  try {
    let query:
      | firebase.firestore.CollectionReference
      | firebase.firestore.Query = firestore
      .collection(JOB_SCHEDULES)
      .where("clientId", "==", clientId);

    const unsubscribe = query.onSnapshot(async (jobSchedules) => {
      if (!jobSchedules.empty) {
        const clientMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
        } = {};
        const contactPersonMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const adminCancelledMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const checkedByMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const depotMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const truckMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const driverMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
        } = {};

        const jobScheduleDataList = jobSchedules.docs.map(
          (jobSchedule) =>
            ({
              ...jobSchedule.data(),
              docId: jobSchedule.id,
            } as JobSchedule)
        );

        await Promise.all([
          ..._.uniq(jobScheduleDataList.map((sched) => sched.clientId)).map(
            (clientId) =>
              new Promise<void>(async (resolve) => {
                clientMap[clientId] = await firestore
                  .collection(CLIENTS)
                  .doc(clientId)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.contactPersonId))
          ).map(
            (contactPersonId) =>
              new Promise<void>(async (resolve) => {
                contactPersonMap[contactPersonId] = await firestore
                  .collection(CONTACT_PEOPLE)
                  .doc(contactPersonId)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.cancelledBy))
          ).map(
            (cancelledBy) =>
              new Promise<void>(async (resolve) => {
                adminCancelledMap[cancelledBy] = await firestore
                  .collection(ADMINS)
                  .doc(cancelledBy)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.checkedBy))
          ).map(
            (checkedBy) =>
              new Promise<void>(async (resolve) => {
                checkedByMap[checkedBy] = await firestore
                  .collection(ADMINS)
                  .doc(checkedBy)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.depotId))
          ).map(
            (depotId) =>
              new Promise<void>(async (resolve) => {
                depotMap[depotId] = await firestore
                  .collection(DEPOTS)
                  .doc(depotId)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.truckId))
          ).map(
            (truckId) =>
              new Promise<void>(async (resolve) => {
                truckMap[truckId] = await firestore
                  .collection(TRUCKS)
                  .doc(truckId)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.driverId))
          ).map(
            (driverId) =>
              new Promise<void>(async (resolve) => {
                driverMap[driverId] = await firestore
                  .collection(DRIVERS)
                  .doc(driverId)
                  .get();
                resolve();
              })
          ),
        ]);

        const jobSchedulesFormattedView = (await Promise.all(
          jobScheduleDataList.map((jobScheduleData) => {
            return new Promise(async (resolve) => {
              const clientQuery = clientMap[jobScheduleData.clientId];

              const contactPersonQuery = !_.isEmpty(
                jobScheduleData.contactPersonId
              )
                ? contactPersonMap[jobScheduleData.contactPersonId] !==
                  undefined
                  ? contactPersonMap[jobScheduleData.contactPersonId]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.contactPersonId)) {
                contactPersonMap[jobScheduleData.contactPersonId] =
                  contactPersonQuery;
              }

              const adminCancelledQuery = !_.isEmpty(
                jobScheduleData.cancelledBy
              )
                ? adminCancelledMap[jobScheduleData.cancelledBy!] !== undefined
                  ? adminCancelledMap[jobScheduleData.cancelledBy!]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.cancelledBy)) {
                adminCancelledMap[jobScheduleData.cancelledBy!] =
                  adminCancelledQuery;
              }

              const checkedByQuery = !_.isEmpty(jobScheduleData.checkedBy)
                ? checkedByMap[jobScheduleData.checkedBy!] !== undefined
                  ? checkedByMap[jobScheduleData.checkedBy!]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.checkedBy)) {
                checkedByMap[jobScheduleData.checkedBy!] = checkedByQuery;
              }

              const depotQuery = !_.isEmpty(jobScheduleData.depotId)
                ? depotMap[jobScheduleData.depotId] !== undefined
                  ? depotMap[jobScheduleData.depotId]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.depotId)) {
                depotMap[jobScheduleData.depotId] = depotQuery;
              }

              const truckQuery = !_.isEmpty(jobScheduleData.truckId)
                ? truckMap[jobScheduleData.truckId] !== undefined
                  ? truckMap[jobScheduleData.truckId]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.truckId)) {
                truckMap[jobScheduleData.truckId] = truckQuery;
              }

              let driverDetails = {} as Driver;
              if (!_.isEmpty(jobScheduleData.driverId)) {
                const driverQuery = driverMap[jobScheduleData.driverId!];

                if (driverQuery.exists) {
                  driverDetails = {
                    ...driverQuery.data(),
                    docId: driverQuery.id,
                  } as Driver;
                }
              }

              if (clientQuery.exists && !jobScheduleData.softDeleted) {
                resolve({
                  ...jobScheduleData,
                  clientDetails: clientQuery.data(),
                  contactPersonDetails:
                    !_.isNull(contactPersonQuery) && contactPersonQuery.exists
                      ? contactPersonQuery!.data()
                      : {},
                  depotDetails:
                    !_.isNull(depotQuery) && depotQuery.exists
                      ? depotQuery!.data()
                      : {},
                  truckDetails:
                    !_.isNull(truckQuery) && truckQuery.exists
                      ? { ...truckQuery!.data(), docId: truckQuery!.id }
                      : {},
                  driverDetails,
                  ...(!!adminCancelledQuery &&
                    !_.isNull(adminCancelledQuery) &&
                    adminCancelledQuery.exists && {
                      cancelledByName: `${
                        (adminCancelledQuery.data() as Admin).firstName
                      } ${(adminCancelledQuery.data() as Admin).lastName}`,
                    }),
                  ...(!!checkedByQuery &&
                    !_.isNull(checkedByQuery) &&
                    checkedByQuery.exists && {
                      checkedByName: `${
                        (checkedByQuery.data() as Admin).firstName
                      } ${(checkedByQuery.data() as Admin).lastName}`,
                    }),
                });
              } else {
                resolve(null);
              }
            });
          })
        )) as (JobScheduleView | null)[];
        // console.log("MAPPED DATA -- ", {
        //   clientMap,
        //   contactPersonMap,
        //   adminCancelledMap,
        //   checkedByMap,
        //   depotMap,
        //   truckMap,
        //   driverMap,
        // });
        callback(_.compact(jobSchedulesFormattedView));
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERRR IN getJobSchedulesRealTime: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const getJobSchedulesRealTimeView = async (
  callback: (jobSchedules: JobScheduleView[], error?: string) => void
) => {
  try {
    let query:
      | firebase.firestore.CollectionReference
      | firebase.firestore.Query = firestore.collection(JOB_SCHEDULES);

    const unsubscribe = query.onSnapshot(async (jobSchedules) => {
      if (!jobSchedules.empty) {
        const clientMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
        } = {};
        const contactPersonMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const adminCancelledMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const checkedByMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const depotMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const truckMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null;
        } = {};
        const driverMap: {
          [
            id: string
          ]: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
        } = {};

        const jobScheduleDataList = jobSchedules.docs.map(
          (jobSchedule) =>
            ({
              ...jobSchedule.data(),
              docId: jobSchedule.id,
            } as JobSchedule)
        );

        await Promise.all([
          ..._.uniq(jobScheduleDataList.map((sched) => sched.clientId)).map(
            (clientId) =>
              new Promise<void>(async (resolve) => {
                clientMap[clientId] = await firestore
                  .collection(CLIENTS)
                  .doc(clientId)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.contactPersonId))
          ).map(
            (contactPersonId) =>
              new Promise<void>(async (resolve) => {
                contactPersonMap[contactPersonId] = await firestore
                  .collection(CONTACT_PEOPLE)
                  .doc(contactPersonId)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.cancelledBy))
          ).map(
            (cancelledBy) =>
              new Promise<void>(async (resolve) => {
                adminCancelledMap[cancelledBy] = await firestore
                  .collection(ADMINS)
                  .doc(cancelledBy)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.checkedBy))
          ).map(
            (checkedBy) =>
              new Promise<void>(async (resolve) => {
                checkedByMap[checkedBy] = await firestore
                  .collection(ADMINS)
                  .doc(checkedBy)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.depotId))
          ).map(
            (depotId) =>
              new Promise<void>(async (resolve) => {
                depotMap[depotId] = await firestore
                  .collection(DEPOTS)
                  .doc(depotId)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.truckId))
          ).map(
            (truckId) =>
              new Promise<void>(async (resolve) => {
                truckMap[truckId] = await firestore
                  .collection(TRUCKS)
                  .doc(truckId)
                  .get();
                resolve();
              })
          ),
          ..._.uniq(
            _.compact(jobScheduleDataList.map((sched) => sched.driverId))
          ).map(
            (driverId) =>
              new Promise<void>(async (resolve) => {
                driverMap[driverId] = await firestore
                  .collection(DRIVERS)
                  .doc(driverId)
                  .get();
                resolve();
              })
          ),
        ]);

        const jobSchedulesFormattedView = (await Promise.all(
          jobScheduleDataList.map((jobScheduleData) => {
            return new Promise(async (resolve) => {
              const clientQuery = clientMap[jobScheduleData.clientId];

              const contactPersonQuery = !_.isEmpty(
                jobScheduleData.contactPersonId
              )
                ? contactPersonMap[jobScheduleData.contactPersonId] !==
                  undefined
                  ? contactPersonMap[jobScheduleData.contactPersonId]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.contactPersonId)) {
                contactPersonMap[jobScheduleData.contactPersonId] =
                  contactPersonQuery;
              }

              const adminCancelledQuery = !_.isEmpty(
                jobScheduleData.cancelledBy
              )
                ? adminCancelledMap[jobScheduleData.cancelledBy!] !== undefined
                  ? adminCancelledMap[jobScheduleData.cancelledBy!]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.cancelledBy)) {
                adminCancelledMap[jobScheduleData.cancelledBy!] =
                  adminCancelledQuery;
              }

              const checkedByQuery = !_.isEmpty(jobScheduleData.checkedBy)
                ? checkedByMap[jobScheduleData.checkedBy!] !== undefined
                  ? checkedByMap[jobScheduleData.checkedBy!]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.checkedBy)) {
                checkedByMap[jobScheduleData.checkedBy!] = checkedByQuery;
              }

              const depotQuery = !_.isEmpty(jobScheduleData.depotId)
                ? depotMap[jobScheduleData.depotId] !== undefined
                  ? depotMap[jobScheduleData.depotId]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.depotId)) {
                depotMap[jobScheduleData.depotId] = depotQuery;
              }

              const truckQuery = !_.isEmpty(jobScheduleData.truckId)
                ? truckMap[jobScheduleData.truckId] !== undefined
                  ? truckMap[jobScheduleData.truckId]
                  : null
                : null;

              if (!_.isEmpty(jobScheduleData.truckId)) {
                truckMap[jobScheduleData.truckId] = truckQuery;
              }

              let driverDetails = {} as Driver;
              if (!_.isEmpty(jobScheduleData.driverId)) {
                const driverQuery = driverMap[jobScheduleData.driverId!];

                if (driverQuery.exists) {
                  driverDetails = {
                    ...driverQuery.data(),
                    docId: driverQuery.id,
                  } as Driver;
                }
              }

              if (clientQuery.exists && !jobScheduleData.softDeleted) {
                resolve({
                  ...jobScheduleData,
                  clientDetails: clientQuery.data(),
                  contactPersonDetails:
                    !_.isNull(contactPersonQuery) && contactPersonQuery.exists
                      ? contactPersonQuery!.data()
                      : {},
                  depotDetails:
                    !_.isNull(depotQuery) && depotQuery.exists
                      ? depotQuery!.data()
                      : {},
                  truckDetails:
                    !_.isNull(truckQuery) && truckQuery.exists
                      ? { ...truckQuery!.data(), docId: truckQuery!.id }
                      : {},
                  driverDetails,
                  ...(!!adminCancelledQuery &&
                    !_.isNull(adminCancelledQuery) &&
                    adminCancelledQuery.exists && {
                      cancelledByName: `${
                        (adminCancelledQuery.data() as Admin).firstName
                      } ${(adminCancelledQuery.data() as Admin).lastName}`,
                    }),
                  ...(!!checkedByQuery &&
                    !_.isNull(checkedByQuery) &&
                    checkedByQuery.exists && {
                      checkedByName: `${
                        (checkedByQuery.data() as Admin).firstName
                      } ${(checkedByQuery.data() as Admin).lastName}`,
                    }),
                });
              } else {
                resolve(null);
              }
            });
          })
        )) as (JobScheduleView | null)[];
        // console.log("MAPPED DATA -- ", {
        //   clientMap,
        //   contactPersonMap,
        //   adminCancelledMap,
        //   checkedByMap,
        //   depotMap,
        //   truckMap,
        //   driverMap,
        // });
        callback(_.compact(jobSchedulesFormattedView));
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERRR IN getJobSchedulesRealTime: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const getDriverJobSchdules = async (
  driverId: string,
  callback: (jobSchedules: JobScheduleView[], error?: string) => void
) => {
  const dateMin = _.clone(new Date());
  dateMin.setHours(0);
  dateMin.setMinutes(0);
  dateMin.setSeconds(0);
  dateMin.setMilliseconds(0);

  const dateMax = _.clone(new Date());
  dateMax.setHours(23);
  dateMax.setMinutes(59);
  dateMax.setSeconds(59);
  dateMax.setMilliseconds(59);

  try {
    const unsubscribe = firestore
      .collection(JOB_SCHEDULES)
      .where("driverId", "==", driverId)
      .onSnapshot(
        async (jobSchedules) => {
          if (!jobSchedules.empty) {
            const jobSchedulesFormattedView = (await Promise.all(
              jobSchedules.docs.map((jobSchedule) => {
                return new Promise(async (resolve) => {
                  const jobScheduleData = {
                    ...jobSchedule.data(),
                    docId: jobSchedule.id,
                  } as JobSchedule;

                  const clientQuery = await firestore
                    .collection(CLIENTS)
                    .doc(jobScheduleData.clientId)
                    .get();

                  // const contactPersonQuery = await firestore
                  //   .collection(CONTACT_PEOPLE)
                  //   .doc(jobScheduleData.contactPersonId)
                  //   .get();

                  // const depotQuery = await firestore
                  //   .collection(DEPOTS)
                  //   .doc(jobScheduleData.depotId)
                  //   .get();

                  // const truckQuery = await firestore
                  //   .collection(TRUCKS)
                  //   .doc(jobScheduleData.truckId)
                  //   .get();

                  const contactPersonQuery = !_.isEmpty(
                    jobScheduleData.contactPersonId
                  )
                    ? await firestore
                        .collection(CONTACT_PEOPLE)
                        .doc(jobScheduleData.contactPersonId)
                        .get()
                    : null;

                  const depotQuery = !_.isEmpty(jobScheduleData.depotId)
                    ? await firestore
                        .collection(DEPOTS)
                        .doc(jobScheduleData.depotId)
                        .get()
                    : null;

                  const truckQuery = !_.isEmpty(jobScheduleData.truckId)
                    ? await firestore
                        .collection(TRUCKS)
                        .doc(jobScheduleData.truckId)
                        .get()
                    : null;

                  let driverDetails = {} as Driver;
                  if (!_.isEmpty(jobScheduleData.driverId)) {
                    const driverQuery = await firestore
                      .collection(DRIVERS)
                      .doc(jobScheduleData.driverId)
                      .get();
                    if (driverQuery.exists) {
                      driverDetails = {
                        ...driverQuery.data(),
                        docId: driverQuery.id,
                      } as Driver;
                    }
                  }

                  if (
                    clientQuery.exists &&
                    // contactPersonQuery.exists &&
                    // depotQuery.exists &&
                    // truckQuery.exists &&
                    !jobScheduleData.softDeleted
                  ) {
                    resolve({
                      ...jobScheduleData,
                      clientDetails: clientQuery.data(),
                      contactPersonDetails:
                        !_.isNull(contactPersonQuery) &&
                        contactPersonQuery.exists
                          ? contactPersonQuery!.data()
                          : {},
                      depotDetails:
                        !_.isNull(depotQuery) && depotQuery.exists
                          ? depotQuery!.data()
                          : {},
                      truckDetails:
                        !_.isNull(truckQuery) && truckQuery.exists
                          ? truckQuery!.data()
                          : {},
                      driverDetails,
                    });
                  } else {
                    resolve(null);
                  }
                });
              })
            )) as (JobScheduleView | null)[];
            // const driverBookingsToday = _.filter(
            //   _.compact(jobSchedulesFormattedView),
            //   (driverBooking) => {
            //     return _.isEmpty(driverBooking.jobCompletedAtNoTimeZone);
            //   }
            // );
            // console.log("WILL RETURN NO NUL PLEASEEE", {
            //   driverBookingsToday,
            //   compacted: _.compact(jobSchedulesFormattedView),
            // });
            // const driverBookingsToday = _.filter(
            //   _.compact(jobSchedulesFormattedView),
            //   (driverBooking) => {
            //     return (
            //       _.isEmpty(driverBooking.jobCompletedAt) &&
            //       moment(dateMin).isSameOrBefore(
            //         toDateTimeFromSecs(driverBooking.endDate.seconds)
            //       ) &&
            //       moment(dateMax).isSameOrAfter(
            //         toDateTimeFromSecs(driverBooking.startDate.seconds)
            //       )
            //     );
            //   }
            // );

            callback(_.compact(jobSchedulesFormattedView) || []);
          } else {
            callback([]);
          }
        },
        (error) => {
          callback([], error.message);
        }
      );
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERRR IN getJobSchedulesRealTime: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const getBookedTrucks = async (startDate: Date, endDate: Date) => {
  const dateMin = _.clone(startDate);
  dateMin.setHours(0);
  dateMin.setMinutes(0);
  dateMin.setSeconds(0);
  dateMin.setMilliseconds(0);

  const dateMax = _.clone(endDate);
  dateMax.setHours(23);
  dateMax.setMinutes(59);
  dateMax.setSeconds(59);
  dateMax.setMilliseconds(59);

  try {
    const jobSchedules = await firestore.collection(JOB_SCHEDULES).get();

    if (!jobSchedules.empty) {
      const bookedTrucks = (await Promise.all(
        jobSchedules.docs.map((truckJobSchedule) => {
          return new Promise(async (resolve) => {
            const truckJobScheduleData = {
              ...truckJobSchedule.data(),
              docId: truckJobSchedule.id,
            } as JobSchedule;

            const truckQuery = !_.isEmpty(truckJobScheduleData.truckId)
              ? await firestore
                  .collection(TRUCKS)
                  .doc(truckJobScheduleData.truckId)
                  .get()
              : null;

            if (!_.isNull(truckQuery) && truckQuery.exists) {
              resolve({
                ...truckJobScheduleData,
                truckDetails: { ...truckQuery.data(), docId: truckQuery.id },
              } as BookedTrucksView);
            } else {
              resolve(null);
            }
          });
        })
      )) as (BookedTrucksView | null)[];
      const trucksWithBooking = _.filter(
        _.compact(bookedTrucks),
        (bookedTruck) => {
          return (
            !bookedTruck.softDeleted &&
            _.isEmpty(bookedTruck.jobCompletedAtNoTimeZone) &&
            moment(startDate).isSameOrBefore(
              getDateFromTimezoneIgnoredTimestamp(bookedTruck.endDateNoTimeZone)
            ) &&
            moment(endDate).isSameOrAfter(
              getDateFromTimezoneIgnoredTimestamp(
                bookedTruck.startDateNoTimeZone
              )
            )
          );
        }
      ).map((truck) => truck.truckDetails);
      return trucksWithBooking;
    } else {
      return [] as Truck[];
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("error - getBookedTrucks -- ", error);
    Bugsnag.notify(new Error(error));
    return [] as Truck[];
  }
};

export const getFiltertedJobSchedules = async (
  startDate: Date,
  endDate: Date
) => {
  const dateMin = _.clone(startDate);
  dateMin.setHours(0);
  dateMin.setMinutes(0);
  dateMin.setSeconds(0);
  dateMin.setMilliseconds(0);

  const dateMax = _.clone(endDate);
  dateMax.setHours(23);
  dateMax.setMinutes(59);
  dateMax.setSeconds(59);
  dateMax.setMilliseconds(59);

  try {
    const jobSchedules = await firestore.collection(JOB_SCHEDULES).get();

    if (!jobSchedules.empty) {
      const filteredJobSchedules = _.filter(
        jobSchedules.docs.map((jobSchedule) => {
          return { ...jobSchedule.data(), docId: jobSchedule.id };
        }) as JobSchedule[],
        (schedules) => {
          return (
            _.isEmpty(schedules.jobCompletedAt) &&
            moment(startDate).isSameOrBefore(
              getDateFromTimezoneIgnoredTimestamp(schedules.endDateNoTimeZone)
            ) &&
            moment(endDate).isSameOrAfter(
              getDateFromTimezoneIgnoredTimestamp(schedules.startDateNoTimeZone)
            )
          );
        }
      );
      return filteredJobSchedules;
    } else {
      return [] as JobSchedule[];
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("error - getBookedTrucks -- ", error);
    Bugsnag.notify(new Error(error));
    return [] as JobSchedule[];
  }
};

export const createJobAdminNotes = async (data: JobAdminNotes) => {
  try {
    const createdBy = auth.currentUser;
    if (!_.isNull(createdBy)) {
      await firestore.collection(JOB_ADMIN_NOTES).add({
        ...data,
        createdBy: createdBy.uid,
        createdDate: getServerTimestamp(),
      });
    } else {
      throw new Error("No user logged in");
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("Error -- createJobAdminNotes -- ", error);
    Bugsnag.notify(new Error(error));
  }
};

export const getJobAdminNotes = async (
  jobScheduleId: string,
  callback: (jobAdminNotes: JobAdminNotesView[], error?: string) => void
) => {
  if (!!jobScheduleId) {
    try {
      const unsubscribe = firestore
        .collection(JOB_ADMIN_NOTES)
        .where("jobScheduleId", "==", jobScheduleId)
        .onSnapshot(
          async (jobAdminNotes) => {
            if (!jobAdminNotes.empty) {
              const jobAdminNotesFormattedView = (await Promise.all(
                jobAdminNotes.docs.map((jobAdminNote) => {
                  return new Promise(async (resolve) => {
                    const jobAdminNoteData = {
                      ...jobAdminNote.data(),
                      docId: jobAdminNote.id,
                    } as JobAdminNotes;

                    const adminQuery = await firestore
                      .collection(ADMINS)
                      .doc(jobAdminNoteData.createdBy)
                      .get();

                    if (adminQuery.exists) {
                      resolve({
                        ...jobAdminNoteData,
                        adminDetails: adminQuery.data(),
                      });
                    } else {
                      resolve(null);
                    }
                  });
                })
              )) as (JobAdminNotesView | null)[];

              callback(_.compact(jobAdminNotesFormattedView) || []);
            } else {
              callback([]);
            }
          },
          (error) => {
            callback([], error.message);
          }
        );
      return unsubscribe;
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      Bugsnag.notify(new Error(error));
      callback([], error);
    }
  } else {
    callback([], "Error: no id/s provided");
  }
};

export const updateJobAdminNotes = async (
  docId: string,
  updatedNoteEntry: JobAdminNotes
) => {
  try {
    const updatedBy = auth.currentUser;
    if (!_.isNull(updatedBy)) {
      await firestore
        .collection(JOB_ADMIN_NOTES)
        .doc(docId)
        .update({
          ...updatedNoteEntry,
          updatedDate: getServerTimestamp(),
        });
    } else {
      throw new Error("No user logged in");
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("Error - updateJobAdminNotes-- ", error);
    Bugsnag.notify(new Error(error));
  }
};

export const deleteJobAdminNote = async (docId: string) => {
  try {
    if (!docId) return;
    const result = await firestore
      .collection(JOB_ADMIN_NOTES)
      .doc(docId)
      .delete();
    return result;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN deleteJobAdminNote: ", e);
    Bugsnag.notify(new Error(e));
  }
};
