import * as _ from "lodash";
import Bugsnag from "@bugsnag/js";
import { add, close, closeOutline } from "ionicons/icons";
import React, { useEffect, useState, DragEvent } from "react";
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonRippleEffect,
  IonRow,
  IonThumbnail,
  IonToast,
} from "@ionic/react";

import "./TruckDashboard.scss";
import * as routes from "../../../constants/routes";
import * as services from "../../../services";
import { storage } from "../../../firebase";
import { AttachmentDialog } from "../AttachmentDialog/AttachmentDialog";
import { ArchivedBanner } from "../../ArchivedBanner/ArchivedBanner";
import { ArchiveOrRestoreTruckDialog } from "../../ArchiveOrRestoreTruckDialog/ArchiveOrRestoreTruckDialog";
import { EWPDetailCard } from "../../EWPDetailCard/EWPDetailCard";
import { EWPTitleDetailPage } from "../../EWPTitleDetailPage/EWPTitleDetailPage";
import { EWPNoteWithAttachment } from "../../EWPNoteWithAttachment/EWPNoteWithAttachment";
import { ADMIN_ACCESS, EWPProps, TextEditorEdit } from "../../../config/global";
import { ExpiriesCard } from "../../ExpiriesCard/ExpiriesCard";
import { EWPDatePicker } from "../../EWPDatePicker/EWPDatePicker";
import { EWPInput } from "../../EWPInput/EWPInput";
import { EWPWebDialog } from "../../EWPWebDialog/EWPWebDialog";
import { JobScheduleFor } from "../../../pages/Home/Home";
import { ServiceHistoryCard } from "../../ServiceHistoryCard/ServiceHistoryCard";
import { Admin, Notes } from "../../../models";
import { VOCCard } from "../../VOCCard/VOCCard";
import {
  daysSinceDate,
  getServerTimestamp,
  removedUploadedAttachements,
  uploadAttachments,
} from "../../../functions/common";
import {
  DriverVOCView,
  ExpiryReference,
  ExpiryWithDate,
  TruckChangeLogDetails,
  TruckNotes,
  TruckServiceHistory,
  TruckView,
} from "../../../models/drivers";
import { EWPNoteCard } from "../EWPNoteCard/EWPNoteCard";
import { EWPCOLORS, LIMITS } from "../../../constants/config";
import {
  MSGS_COMMON,
  MSGS_DRIVER,
  MSGS_NOTE_ENTRY,
} from "../../../constants/messages";
import { ChangeLogsCard } from "../../ChangeLogsCard/ChangeLogsCard";
import { EWPCommonTooltip } from "../EWPCommonTooltip/EWPCommonTooltip";

interface TruckDashboardProps extends EWPProps {
  truckView: TruckView | null;
}
export const TruckDashboard = (props: TruckDashboardProps) => {
  const { truckView } = props;
  const [truckServiceHistories, setTruckServiceHistories] = useState(
    null as null | TruckServiceHistory[]
  );
  const [driverVOCs, setDriverVOCs] = useState(null as null | DriverVOCView[]);
  const [showAddNote, setShowAddNote] = useState(false);
  const [listOfAttachment, setListOfAttachment] = useState([] as any[]);
  const [editTruckNoteData, setEditTruckNoteData] = useState({} as TruckNotes);
  const [truckNotes, setTruckNotes] = useState(null as null | TruckNotes[]);
  const [noteTitle, setNoteTitle] = useState("");
  const [noteDescription, setNoteDescription] = useState("");
  const [removedUrl, setRemovedUrl] = useState([] as any[]);
  const [willEditNoteEntry, setwillEditNoteEntry] = useState(false);
  const [selectedEditIndex, setSelectedEditIndex] = useState(
    null as null | number
  );

  const [changeLogs, setChangeLogs] = useState(
    null as null | TruckChangeLogDetails[]
  );

  const [showArchivedRestoreDialog, setShowArchivedRestore] = useState(false);
  const [truckExpiryWithDate, setTruckExpiryWithDate] = useState(
    null as null | ExpiryWithDate[]
  );
  const [removedAttachments, setRemovedAttachments] = useState([] as string[]);

  const [expiryId, setExpiryId] = useState("");
  const [expiryName, setExpiryName] = useState("");
  const [expiryDate, setExpiryDate] = useState(null as Date | null);
  const [showUpdateExpiryDialog, setShowUpdateExpiryDialog] = useState(false);

  const [hasExpiredLicence, setHasExpiredLicence] = useState(false);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const [lastUpdated, setLastUpdated] = useState(null as null | number);
  const [selectedExpries, setSelectedExpries] = useState(
    null as null | ExpiryReference
  );
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);

  const [tooltipCoords, setTooltipCoords] = useState({
    left: 0,
    top: 0,
  });

  const [tooltipMessage, setTooltipMessage] = useState("");

  const isMechanic =
    (props.authUser as unknown as Admin).access === ADMIN_ACCESS.mechanic.id;

  const ref: React.RefObject<HTMLInputElement> = React.createRef();
  const onClickUploadPhoto = () => {
    console.log("CLICK UPLOAD -- ", listOfAttachment);
    if (!!listOfAttachment) {
      if (
        listOfAttachment.length < LIMITS.serviceTypeAttachments.count &&
        !!ref
      ) {
        ref.current!.click(); // ref for input field file
      } else {
        setError(MSGS_DRIVER.vocAttachmentsExceed);
      }
    }
  };

  const onAttachExpiryPhoto = async (file: any) => {
    if (
      listOfAttachment.length + file.target.files.length <=
      LIMITS.serviceTypeAttachments.count
    ) {
      if (!!file && file.target.files.length) {
        const attachments = file.target.files;
        const fileSizes = Object.values(attachments).map((attachment: any) => {
          return attachment.size <= LIMITS.serviceTypeAttachments.size;
        });
        const hasExceededFileSize = _.includes(fileSizes, false);
        if (!hasExceededFileSize) {
          const toBeUploadedAttachments = _.concat(
            Object.values(attachments),
            listOfAttachment
          );

          setListOfAttachment(toBeUploadedAttachments as []);
        } else {
          setError(MSGS_DRIVER.attachmentFileSizeExceed);
        }
      }
    } else {
      setError(MSGS_DRIVER.vocAttachmentsExceed);
    }
  };

  const onDrop = (event: DragEvent) => {
    event.preventDefault();
    if (listOfAttachment.length <= LIMITS.serviceTypeAttachments.count) {
      if (event.dataTransfer.files) {
        // Use DataTransferItemList interface to access the file(s)
        if (
          event.dataTransfer.files.length <= LIMITS.serviceTypeAttachments.count
        ) {
          const files = Object.values(event.dataTransfer.files);
          const fileSizes = Object.values(files).map((attachment: any) => {
            return attachment.size <= LIMITS.serviceTypeAttachments.size;
          });
          const hasExceededFileSize = _.includes(fileSizes, false);
          if (!hasExceededFileSize) {
            files.forEach((file) => {
              const imageType = /image.*/;
              if (
                file &&
                (file.type.match(imageType) || file.type === "application/pdf")
              ) {
                const attachments = Object.values(event.dataTransfer.files);
                const toBeUploadedAttachments = _.concat(
                  attachments,
                  listOfAttachment
                );
                setListOfAttachment(toBeUploadedAttachments as []);
              } else {
                setError(MSGS_DRIVER.invalidFileType);
              }
            });
          } else {
            setError(MSGS_DRIVER.attachmentFileSizeExceed);
          }
        } else {
          setError(MSGS_DRIVER.vocAttachmentsExceed);
        }
      }
    } else {
      setError(MSGS_DRIVER.vocAttachmentsExceed);
    }
  };
  const onRemoveExpiryAttachment = (attachment: any) => {
    const updatedListOfAttachment = _.remove(listOfAttachment, (item) => {
      return item !== attachment;
    });

    if (typeof attachment === "string") {
      setRemovedAttachments([...removedAttachments, attachment]);
    }
    setListOfAttachment(updatedListOfAttachment);
  };

  const getChangeLogs = (truckId: string) => {
    services.getTruckChangeLogs(truckId, (changeLogs, error) => {
      setChangeLogs(changeLogs);
      setError(error || "");
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      !_.isEmpty(truckView) &&
      !_.isNull(truckView) &&
      (_.isNull(truckExpiryWithDate) ||
        (!_.isNull(truckExpiryWithDate) &&
          lastUpdated !==
            (!!truckView!.updatedDt
              ? truckView!.updatedDt!.toDate().valueOf()
              : null)))
    ) {
      getChangeLogs(truckView!.docId || "");
      getTruckExpiryList(truckView);
      console.log("GOT EXPIRIERS -- ", truckView);

      setLastUpdated(
        !!truckView!.updatedDt ? truckView!.updatedDt!.toDate().valueOf() : null
      );
    }

    if (!_.isNull(truckView) && _.isNull(truckServiceHistories)) {
      getTruckServiceHistories();
    }
    if (!_.isNull(truckView) && _.isNull(driverVOCs)) {
      getDriverVOCs();
    }
    if (_.isNull(truckNotes)) {
      getTruckNotes();
    }
    // eslint-disable-next-line
  });

  const getTruckNotes = () => {
    if (_.isNull(truckNotes) && !!truckView && !!truckView.docId) {
      services.getTruckNotes(truckView.docId as string, (truckNote, error) => {
        setTruckNotes(truckNote);
        setError(error || "");
      });
    }
  };

  const onSaveTruckNotes = async () => {
    setLoading(true);
    const { docId, lastName } = props.authUser;
    const noteEntry = {
      title: noteTitle,
      description: noteDescription,
    } as Notes;
    let errorMsg = "";

    if (!_.isEmpty(editTruckNoteData)) {
      let updatedEditData = _.clone(editTruckNoteData);
      const updatedTile = !_.isEmpty(noteTitle)
        ? noteTitle
        : updatedEditData!.notes!.title;
      const updatedDescription = !_.isEmpty(noteDescription)
        ? noteDescription
        : updatedEditData!.notes!.description;
      const updatedNotes = {
        title: updatedTile,
        description: updatedDescription,
      };
      try {
        let attachmentUrlList = [] as string[];
        if (listOfAttachment.length > 0) {
          const urlList = await uploadAttachments(
            listOfAttachment,
            lastName,
            docId,
            "truckNotes"
          );
          attachmentUrlList = urlList;
        }
        const updateAttachments = _.concat(
          updatedEditData.attachments,
          attachmentUrlList
        ) as string[];
        updatedEditData = {
          ...updatedEditData,
          notes: updatedNotes,
          updatedDate: getServerTimestamp(),
          attachments: updateAttachments,
          updatedBy: docId,
        };
        await services.updateTruckNoteEntry(
          updatedEditData.docId!,
          updatedEditData
        );
        //remove the file in our storage
        await Promise.resolve(
          removedUrl.forEach((url) => {
            new Promise(async (resolve) => {
              try {
                const refFromUrl = storage.refFromURL(url);
                const deletedUrl = await refFromUrl.delete();
                resolve(deletedUrl);
              } catch (errorUnknown) {
                const error = errorUnknown as any;
                resolve(null);
                Bugsnag.notify(new Error(error));
              }
            });
          })
        );
        setSuccess(MSGS_COMMON.success);
        setListOfAttachment([]);
        setEditTruckNoteData({} as TruckNotes);
        setwillEditNoteEntry(false);
        setSelectedEditIndex(null);
        setRemovedUrl([]);
        // this.setState({
        //   success: MSGS_COMMON.success,
        //   willEditDailyEntry: false,
        //   listOfAttachment: [],
        //   editDiaryEntryData: {} as ClientNotes,
        //   selectedEditIndex: null,
        //   removedUrl: [],
        // });
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        setError(error);
        Bugsnag.notify(new Error(error));
      }
    } else {
      if (!noteEntry.title) {
        errorMsg = MSGS_NOTE_ENTRY.required;
      } else if (!noteEntry.description) {
        errorMsg = MSGS_NOTE_ENTRY.required;
      }
      if (errorMsg) {
        setError(errorMsg);
      } else {
        try {
          let attachmentUrlList = [] as string[];
          if (listOfAttachment.length > 0) {
            const urlList = await uploadAttachments(
              listOfAttachment,
              lastName,
              docId,
              "truckNotes"
            );
            attachmentUrlList = urlList;
          }
          const truckNoteEntry = {
            truckId: truckView!.docId as string,
            createdBy: docId,
            attachments: attachmentUrlList,
            notes: noteEntry,
          } as TruckNotes;
          await services.createTruckNotes(truckNoteEntry);
          setSuccess(MSGS_COMMON.success);
          setListOfAttachment([]);
          setRemovedUrl([]);
          setShowAddNote(false);
        } catch (errorUnknown) {
          const error = errorUnknown as any;
          setError(error);
          Bugsnag.notify(new Error(error));
        }
      }
    }
  };

  const onEditNoteEntry = (
    noteEntry: TruckNotes,
    selectedEditIndex: number
  ) => {
    const editNoteEntryData = _.cloneDeep(noteEntry);
    setwillEditNoteEntry(true);
    setEditTruckNoteData(editNoteEntryData);
    setSelectedEditIndex(selectedEditIndex);
  };

  const getTruckServiceHistories = () => {
    if (!!truckView && !_.isEmpty(truckView)) {
      setTruckServiceHistories(null);
      services.getTruckServiceHistoriesRealTime(
        truckView.docId as string,
        (serviceHistories) => {
          console.log("this is serviceHistories --- ", serviceHistories);
          setTruckServiceHistories(serviceHistories);
        }
      );
    }
  };
  const onAttachPhoto = async (file: any) => {
    if (!!file && file.target.files.length > 0) {
      const attachments = file.target.files;
      const toBeUploadedAttachments = _.concat(
        Object.values(attachments),
        listOfAttachment
      );
      setListOfAttachment(toBeUploadedAttachments);
    }
  };

  const onRemoveAttachment = (attachment: any) => {
    let updatedEditData = _.clone(editTruckNoteData);

    const updatedListOfEditDataAttachment = _.remove(
      updatedEditData.attachments as string[],
      (item) => {
        return item !== attachment;
      }
    );
    updatedEditData = {
      ...updatedEditData,
      attachments: updatedListOfEditDataAttachment,
    };

    const updatedListOfAttachment = _.remove(listOfAttachment, (item) => {
      return item !== attachment;
    });

    setListOfAttachment(updatedListOfAttachment);
    setEditTruckNoteData(updatedEditData);
    setRemovedUrl([...removedUrl, attachment]);
  };

  const getTruckExpiryList = (truckView: TruckView) => {
    services.getTruckExpiryReminders((truckExpiryList, error) => {
      // setDriverExpiryList(driverExpiryList);
      const truckExpiryDates = truckExpiryList.map((expiry) => {
        const expiryReference = _.find(
          truckView!.truckExpiries || [],
          (truckExpiry) => {
            return truckExpiry.expiryId === expiry.docId;
          }
        );

        return {
          expiryId: expiry.docId || "",
          name: expiry.name,
          expiryReminder: expiry.expiryReminder,
          expiryDate: !_.isEmpty(expiryReference)
            ? expiryReference!.expirationDate
            : null,
        };
      });
      setTruckExpiryWithDate(truckExpiryDates);
      setError(error || "");

      const expiredLicence = _.find(
        truckView!.truckExpiries || [],
        (expiryData) => {
          return (
            !!expiryData.expirationDate &&
            daysSinceDate(new Date(), expiryData.expirationDate.toDate()) <=
              0 &&
            !_.isEmpty(
              _.find(
                truckExpiryDates,
                (expiry) => expiry.expiryId === expiryData.expiryId
              )
            )
          );
        }
      );
      setHasExpiredLicence(!_.isEmpty(expiredLicence));
    });
  };

  const updateDriverExpiriesReference = async () => {
    if (!_.isEmpty(truckView)) {
      let attachmentUrlList = [] as string[];
      const newFileAttach = _.compact(
        listOfAttachment.map((item: any) => {
          return typeof item !== "string" && item;
        })
      ) as any[];
      const prevousFileAttach = _.compact(
        listOfAttachment.map((item: any) => {
          return typeof item === "string" && item;
        })
      );
      if (newFileAttach.length > 0) {
        const urlList = await uploadAttachments(
          newFileAttach,
          truckView!.name,
          expiryId,
          "truckExpiries"
        );
        attachmentUrlList = urlList;
      }
      const updatedAttachment = prevousFileAttach.concat(
        attachmentUrlList
      ) as string[];
      const updatedExpiries = _.clone(
        truckView!.truckExpiries || []
      ) as ExpiryReference[];
      _.remove(updatedExpiries, (expiry) => expiry.expiryId === expiryId);

      // if (!_.isNull(expiryDate)) {
      updatedExpiries.push({
        expiryId,
        expirationDate: !_.isNull(expiryDate)
          ? getServerTimestamp(expiryDate)
          : null,
        attachments: updatedAttachment,
        // attachmentFileType:
      });
      await services.addTruckChangeLogs(
        truckView!.docId || "",
        `Expiry updated - "${expiryName}"`,
        props.authUser.docId
      );
      // }

      try {
        setLoading(true);

        await services.updateTruckExpiries(
          truckView!.docId || "",
          updatedExpiries
        );
        await removedUploadedAttachements(removedAttachments);
        setLoading(false);
        setSuccess("Update Success");
        setTimeout(() => {
          setShowUpdateExpiryDialog(false);
        }, 1000);
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        setLoading(false);
        setError(error);
        Bugsnag.notify(new Error(error));
      }
    }
  };
  const getDriverVOCs = () => {
    if (!!truckView && !_.isEmpty(truckView)) {
      setDriverVOCs(null);
      services.getDriverVOCsViewRealtime(
        (driverVOCs) => {
          setDriverVOCs(driverVOCs);
        },
        null,
        truckView.docId as string
      );
    }
  };

  const editData =
    !_.isEmpty(editTruckNoteData) && willEditNoteEntry
      ? {
          title: editTruckNoteData!.notes!.title,
          description: editTruckNoteData!.notes!.description,
          listOfAttachment: editTruckNoteData.attachments,
        }
      : {};

  // console.log("PRINTING -- ", {
  //   truckView,
  //   isMechanic,
  //   hasExpiredLicence,
  //   result:
  //     _.isEmpty(truckView) || (truckView as TruckView).archived || isMechanic,
  // });

  return (
    <>
      <div className="truck-dashboard-container">
        <EWPTitleDetailPage
          pageTitle="Truck"
          createButtonLabel="Create Job For Truck"
          disableCreate={
            _.isEmpty(truckView) ||
            (truckView as TruckView).archived ||
            isMechanic
          }
          disableSecondaryAction={hasExpiredLicence || isMechanic}
          removeCreateUpdateButtons={isMechanic}
          newButtonLabel="Edit Truck"
          onClickCreateButton={() => {
            if (!_.isEmpty(truckView) && !isMechanic) {
              props.history.push(routes.CREATE_JOB_SCHEDULE, {
                truckId: truckView!.docId || "",
              } as JobScheduleFor);
            }
          }}
          backURL={routes.HOME_TRUCK_REGISTER}
          onClickEditButton={() => {
            if (!isMechanic) {
              props.history.push(
                routes.HOME_EDIT_TRUCK.replace(
                  ":truckId",
                  !!truckView ? (truckView.docId as string) : ""
                )
              );
            }
          }}
          {...props}
        />
        <div className="truck-dashboard-detail-card-container">
          {!!truckView && truckView.archived && (
            <div className="archived-banner-container">
              <ArchivedBanner {...props} mode="truck" data={truckView} />
            </div>
          )}
          <EWPDetailCard
            {...props}
            mode="truck"
            cardDetailTitle="Truck Details"
            cardData={truckView as TruckView}
            onArchived={() => {
              if (!isMechanic) {
                setShowArchivedRestore(true);
              }
            }}
            {...(!isMechanic && {
              cardButtonLabel: `${
                !!(truckView as TruckView) && (truckView as TruckView).archived
                  ? "Restore"
                  : "Archive"
              } Truck`,
            })}
          />
        </div>
        <div className="truck-dashboard-card-container">
          <IonGrid
            className={`truck-dashboard-grid ${
              !!truckView && truckView.archived ? "disabled-component" : ""
            } ion-no-padding ion-no-margin`}
          >
            <IonRow className="truck-dashboard-row ion-no-padding ion-no-margin">
              <IonCol
                className="truck-dashboard-expires-col ion-no-padding ion-no-margin"
                size="6"
              >
                <ExpiriesCard
                  {...props}
                  onClickViewAttachment={(truckViewExpiryId) => {
                    const truckExpiry = _.find(
                      truckView!.truckExpiries,
                      (expiryWithDate) =>
                        expiryWithDate.expiryId === truckViewExpiryId
                    );
                    console.log("truckExpiry --- ", truckExpiry);
                    if (!!truckExpiry) {
                      setSelectedExpries(truckExpiry);
                    }
                    setShowAttachmentDialog(true);
                  }}
                  expiriesData={truckExpiryWithDate}
                  hasAttachment={(truckExpiryId) => {
                    const truckExpiry = _.find(
                      truckView!.truckExpiries,
                      (expiryWithDate) =>
                        expiryWithDate.expiryId === truckExpiryId
                    );
                    return !!truckExpiry && !_.isEmpty(truckExpiry.attachments);
                  }}
                  {...(!isMechanic && {
                    onClickEdit: (expiryId) => {
                      //show edit dialog here
                      const expiry = _.find(
                        truckExpiryWithDate,
                        (expiryWithDate) => expiryWithDate.expiryId === expiryId
                      );
                      const driverExpiry = _.find(
                        truckView!.truckExpiries,
                        (expiryWithDate) => expiryWithDate.expiryId === expiryId
                      );
                      if (!!expiry && !_.isEmpty(expiry)) {
                        setExpiryId(expiryId);
                        setExpiryName(expiry!.name);
                        setExpiryDate(
                          !_.isNull(expiry!.expiryDate)
                            ? expiry!.expiryDate.toDate()
                            : null
                        );
                        setListOfAttachment(
                          !!driverExpiry && !!driverExpiry.attachments
                            ? driverExpiry.attachments
                            : ([] as any[])
                        );
                      }
                      setShowUpdateExpiryDialog(true);
                    },
                  })}
                />
              </IonCol>
              <IonCol
                className="truck-dashboard-voc-col ion-no-padding ion-no-margin"
                size="6"
              >
                <VOCCard vocData={driverVOCs} {...props} mode="truck" />
              </IonCol>
            </IonRow>
            <IonRow className="truck-dashboard-row ion-no-padding ion-no-margin">
              <IonCol
                className="truck-dashboard-service-hsitory-col ion-no-padding ion-no-margin"
                size="6"
              >
                {!!truckServiceHistories && !!truckView && (
                  <ServiceHistoryCard
                    truckId={truckView.docId as string}
                    serviceHistories={truckServiceHistories}
                    disableAddEdit={isMechanic}
                    // onClickEdit={() => {
                    //   setShowServiceHistory(true);
                    // }}
                    // onAddServiceDialog={() => {
                    //   setShowServiceHistory(true);
                    // }}
                    {...props}
                  />
                )}
              </IonCol>
              <IonCol
                className="truck-dashboard-change-logs-col ion-no-padding ion-no-margin"
                size="6"
              >
                {!!truckView && (
                  <ChangeLogsCard changeLogs={changeLogs} mode="truck" />
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
          {/* <EWPJobHistoryCard {...props} jobHistories={dummyJobHistories} /> */}
        </div>
        {!!truckNotes && (
          <div>
            {showAddNote ? (
              <IonCard
                className="truck-dashboard-note-card ion-no-padding ion-no-margin"
                color={EWPCOLORS.tertiary}
              >
                <IonCardHeader className="truck-dashboard-note-card-header ion-no-padding ion-no-margin">
                  <IonLabel className="truck-dashboard-note-card-headerlabel ewp-h4 bold ion-no-padding ion-no-margin">
                    Notes
                  </IonLabel>
                </IonCardHeader>
                <div className="truck-dashboard-note-container">
                  <EWPNoteWithAttachment
                    onChangeTitle={(titleData) => {
                      setNoteTitle(titleData);
                    }}
                    onChangeBody={(bodyData) => {
                      setNoteDescription(bodyData);
                    }}
                    listOfAttachment={listOfAttachment} //callback here to display attachment
                    onAttachPhoto={onAttachPhoto}
                    onRemoveAttachment={onRemoveAttachment}
                  />

                  <IonItem
                    lines="none"
                    className="truck-dashboard-note-action-buttons-item ewp-item-no-horizontal-padding"
                    color={EWPCOLORS.tertiary}
                  >
                    <IonButton
                      mode="ios"
                      slot="start"
                      className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
                      fill="clear"
                      color={EWPCOLORS.medium}
                      onClick={() => {
                        setShowAddNote(false);
                      }}
                    >
                      Cancel
                    </IonButton>
                    <IonButton
                      slot="end"
                      mode="ios"
                      className="truck-dashboard-note-save-button ewp-h6 ion-no-margin ion-no-padding"
                      color={EWPCOLORS.primary}
                      onClick={() => {
                        onSaveTruckNotes();
                      }}
                    >
                      Save
                    </IonButton>
                  </IonItem>
                </div>
              </IonCard>
            ) : (
              _.isEmpty(truckNotes) &&
              !isMechanic && (
                <div className="truck-add-note-button-container">
                  <IonButton
                    mode="ios"
                    className="truck-add-note-button ewp-h3 ion-no-margin ion-no-padding"
                    color={EWPCOLORS.primary}
                    disabled={(truckView as TruckView).archived}
                    onClick={() => {
                      setShowAddNote(true);
                    }}
                  >
                    Add New Truck Note
                  </IonButton>
                </div>
              )
            )}
          </div>
        )}

        {!!truckNotes &&
          !_.isEmpty(truckNotes) &&
          truckNotes.map((truckNote, index) => {
            return index === selectedEditIndex ? (
              <IonCard
                className="truck-dashboard-note-card ion-no-padding ion-no-margin"
                color={EWPCOLORS.tertiary}
                key={`truck_notes_${index}`}
              >
                <IonCardHeader className="truck-dashboard-note-card-header ion-no-padding ion-no-margin">
                  <IonLabel className="truck-dashboard-note-card-headerlabel ewp-h4 bold ion-no-padding ion-no-margin">
                    Edit Note
                  </IonLabel>
                </IonCardHeader>
                <div className="truck-dashboard-note-container">
                  <EWPNoteWithAttachment
                    onChangeTitle={(titleData) => {
                      setNoteTitle(titleData);
                    }}
                    onChangeBody={(bodyData) => {
                      setNoteDescription(bodyData);
                    }}
                    listOfAttachment={listOfAttachment} //callback here to display attachment
                    onAttachPhoto={onAttachPhoto}
                    onRemoveAttachment={onRemoveAttachment}
                    editData={editData as TextEditorEdit}
                  />

                  <IonItem
                    lines="none"
                    className="truck-dashboard-note-action-buttons-item ewp-item-no-horizontal-padding"
                    color={EWPCOLORS.tertiary}
                  >
                    <IonButton
                      mode="ios"
                      slot="start"
                      className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
                      fill="clear"
                      color={EWPCOLORS.medium}
                      onClick={() => {
                        setSelectedEditIndex(null);
                        setEditTruckNoteData({} as TruckNotes);
                      }}
                    >
                      Cancel
                    </IonButton>
                    <IonButton
                      slot="end"
                      mode="ios"
                      className="truck-dashboard-note-save-button ewp-h6 ion-no-margin ion-no-padding"
                      color={EWPCOLORS.primary}
                      onClick={() => {
                        if (!isMechanic) {
                          onSaveTruckNotes();
                        }
                      }}
                    >
                      Save
                    </IonButton>
                  </IonItem>
                </div>
              </IonCard>
            ) : (
              !loading && (
                <div className="note-card-container">
                  <EWPNoteCard
                    onEdit={() => {
                      onEditNoteEntry(truckNote, index);
                    }}
                    noteEntry={truckNote as TruckNotes}
                    index={index}
                    isArchivedSite={isMechanic} //!will use for now as disable edit
                  />
                  {index + 1 === truckNotes.length && !isMechanic && (
                    <IonButton
                      className="note-entry-action-button ewp-web-gray-button ewp-h6 dark ion-text-capitalize ion-no-margin"
                      onClick={() => {
                        setShowAddNote(true);
                      }}
                    >
                      <IonIcon className="note-entry-add-icon" />
                      Add New Entry
                    </IonButton>
                  )}
                </div>
              )
            );
          })}
      </div>

      {!isMechanic && (
        <EWPWebDialog
          headerTitle="Edit Expiries"
          isOpen={showUpdateExpiryDialog}
          onDidDismiss={() => {
            setShowUpdateExpiryDialog(false);
            setExpiryId("");
            setExpiryName("");
            setExpiryDate(null);
          }}
        >
          <div className="expiries-history-form-container">
            <div className="expiries-history-form-input-container">
              <div className="expiries-form-inputs">
                <EWPInput
                  className="expiries-history-expiries-type-input"
                  inputLabel="Expiry Name"
                  inputValue={expiryName}
                  inputType="text"
                  name="expiriesName"
                  readOnly={true}
                />
                <EWPDatePicker
                  className="expiries-date-input-date"
                  datePickerLabel="Expiry Date"
                  selectedDate={expiryDate}
                  format="DD MMM, YYYY"
                  onDateChange={(expiriesDate: Date) => {
                    setExpiryDate(expiriesDate);
                  }}
                />
                {!_.isNull(expiryDate) && (
                  <IonButton
                    className="remove-expiry"
                    fill="clear"
                    color={EWPCOLORS.light}
                    onClick={() => {
                      setExpiryDate(null);
                    }}
                  >
                    <IonIcon icon={closeOutline} color={EWPCOLORS.light} />
                  </IonButton>
                )}
              </div>
              <div className="driver-exp-dialog-upload-container">
                <div className="driver-exp-dialog-title-button-container">
                  <IonLabel className="driver-exp-dialog-upload-title-label ewp-h3 ion-no-padding ion-no-margin">
                    Upload Images
                  </IonLabel>
                  {!_.isEmpty(listOfAttachment) && (
                    <IonButton
                      mode="ios"
                      className="driver-exp-dialog-attachments-add-another-button ewp-h6 ion-no-margin ion-no-padding"
                      color={EWPCOLORS.gray}
                      onClick={onClickUploadPhoto}
                    >
                      <IonIcon
                        className="driver-exp-dialog-small-add-icon"
                        icon={add}
                      />
                      Add Another
                    </IonButton>
                  )}
                </div>
                {!_.isEmpty(listOfAttachment) ? (
                  <div className="web-upload-list-attachment-container">
                    <IonGrid className="ion-no-margin ion-no-padding">
                      {_.chunk(listOfAttachment, 8).map(
                        (row: any, rowIndex: any) => {
                          // console.log("DISPLAYING ROWWW --- ", row);
                          return (
                            <IonRow
                              key={`row_list_${rowIndex}`}
                              className="ion-no-margin ion-no-padding"
                            >
                              {row.map((attachment: any, colIndex: any) => {
                                const fileUrl =
                                  typeof attachment === "string"
                                    ? attachment
                                    : "";
                                const fileSplit = fileUrl
                                  .split("?")[0]
                                  .split(".");
                                const fileTypeAttachment =
                                  fileSplit[fileSplit.length - 1].toLowerCase();
                                return (
                                  <IonCol
                                    key={`col_${colIndex}_row${rowIndex}`}
                                    size="1.5"
                                    className="ion-no-margin ion-no-padding"
                                  >
                                    <div className="web-upload-attachment-container">
                                      <IonButton
                                        className="remove-attachment-button ion-no-margin ion-no-padding"
                                        shape="round"
                                        color="primary"
                                        onClick={() =>
                                          onRemoveExpiryAttachment(attachment)
                                        }
                                      >
                                        <IonIcon
                                          className="web-upload-attachment-close-icon ion-no-margin ion-no-padding"
                                          icon={close}
                                        />
                                      </IonButton>
                                      {typeof attachment !== "string" &&
                                      !!attachment.type.match("image/*") ? (
                                        <IonThumbnail
                                          className="web-upload-attachment-avatar ion-no-margin ion-no-padding"
                                          slot="start"
                                        >
                                          <img
                                            id="attachmentPhoto"
                                            src={
                                              typeof attachment === "string"
                                                ? attachment
                                                : URL.createObjectURL(
                                                    attachment
                                                  )
                                            }
                                            alt="attachmentPhoto"
                                          />
                                        </IonThumbnail>
                                      ) : fileTypeAttachment !== "pdf" &&
                                        typeof attachment === "string" ? (
                                        <IonThumbnail
                                          className="web-upload-attachment-avatar ion-no-margin ion-no-padding"
                                          slot="start"
                                          onClick={() => {
                                            window.open(
                                              typeof attachment === "string"
                                                ? attachment
                                                : URL.createObjectURL(
                                                    attachment
                                                  )
                                            );
                                          }}
                                        >
                                          <img
                                            id="attachmentPhoto"
                                            src={
                                              typeof attachment === "string"
                                                ? attachment
                                                : URL.createObjectURL(
                                                    attachment
                                                  )
                                            }
                                            alt="attachmentPhoto"
                                          />
                                        </IonThumbnail>
                                      ) : (
                                        <div
                                          className="attachment-file-link-container"
                                          onMouseOver={(
                                            e: React.MouseEvent<
                                              HTMLDivElement,
                                              MouseEvent
                                            >
                                          ) => {
                                            // handleMouseOver(e, attachment.attachmentUrl || "");

                                            const rect =
                                              e.currentTarget.getBoundingClientRect();
                                            setTooltipCoords({
                                              left: rect.left,
                                              top: rect.top,
                                            });
                                            if (
                                              typeof attachment === "string"
                                            ) {
                                              const fileName = attachment;

                                              const decodedURL =
                                                decodeURIComponent(fileName);
                                              let urlObj = new URL(decodedURL);
                                              let path = urlObj.pathname;

                                              let parts = path.split("/");
                                              let encodedFileName =
                                                parts[parts.length - 1];

                                              // Decode the file name
                                              let fileNameList =
                                                decodeURIComponent(
                                                  encodedFileName
                                                ).split("_");
                                              fileNameList.splice(0, 2);

                                              setTooltipMessage(
                                                fileNameList.join("_") || ""
                                              );
                                            } else {
                                              setTooltipMessage(
                                                attachment.name
                                              );
                                            }
                                          }}
                                          onMouseOut={() => {
                                            setTooltipMessage("");
                                          }}
                                        >
                                          <a
                                            target="blank"
                                            href={
                                              typeof attachment === "string"
                                                ? attachment
                                                : URL.createObjectURL(
                                                    attachment
                                                  )
                                            }
                                          >
                                            View File
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </IonCol>
                                );
                              })}
                            </IonRow>
                          );
                        }
                      )}
                    </IonGrid>
                  </div>
                ) : (
                  <IonItem
                    lines="none"
                    className="driver-exp-dialog-upload-item ewp-body ion-no-margin"
                    onClick={onClickUploadPhoto}
                    onDragOver={(event) => {
                      event.preventDefault();
                    }}
                    onDrop={onDrop}
                  >
                    <div className="web-upload-icon-label-container">
                      <IonIcon className="driver-exp-dialog-upload-icon" />
                      <IonLabel className="driver-exp-dialog-upload-label ewp-paragraph ion-no-margin ion-no-padding">
                        Drop files here to upload or <u>choose file</u>
                      </IonLabel>
                    </div>
                  </IonItem>
                )}
                <input
                  hidden
                  type="file"
                  ref={ref}
                  accept="image/*,.pdf"
                  onChange={onAttachExpiryPhoto}
                  multiple
                />
              </div>
            </div>

            <IonItem
              lines="none"
              className="ewp-item-no-horizontal-padding ewp-button-padding-top"
            >
              <IonButton
                mode="ios"
                slot="start"
                className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
                fill="clear"
                color={EWPCOLORS.medium}
                onClick={() => {
                  setShowUpdateExpiryDialog(false);
                }}
              >
                Cancel
              </IonButton>
              <IonButton
                mode="md"
                slot="end"
                className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
                color={EWPCOLORS.primary}
                type="submit"
                onClick={() => {
                  updateDriverExpiriesReference();
                }}
              >
                Save
                <IonRippleEffect />
              </IonButton>
            </IonItem>
          </div>
        </EWPWebDialog>
      )}
      {
        // !!showAttachmentDialog &&
        !!selectedExpries &&
          !!selectedExpries.attachments &&
          !_.isEmpty(selectedExpries.attachments) && (
            <AttachmentDialog
              title="Expiries"
              attachments={selectedExpries.attachments.map((attachmentUrl) => {
                return {
                  attachmentUrl,
                };
              })}
              showModal={showAttachmentDialog}
              onModalDidDismiss={() => {
                setShowAttachmentDialog(false);
                // setSelectedExpries(null);
              }}
            />
          )
      }
      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={
          _.isEmpty(truckView) ||
          _.isNull(truckView) ||
          _.isNull(truckServiceHistories)
        }
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />
      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={2000}
        onDidDismiss={() => {
          setSuccess("");
          setLoading(false);
        }}
        color={EWPCOLORS.success}
      />

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.saving}
      />

      <EWPCommonTooltip
        left={tooltipCoords.left}
        top={tooltipCoords.top}
        content={tooltipMessage}
      />

      {truckView !== undefined && !_.isEmpty(truckView) && (
        <ArchiveOrRestoreTruckDialog
          mode="truck"
          restore={(truckView as TruckView).archived}
          isOpen={showArchivedRestoreDialog}
          data={truckView as TruckView}
          onDidDismiss={() => {
            setShowArchivedRestore(false);
          }}
          onSuccess={() => {
            setShowArchivedRestore(false);
          }}
        />
      )}
    </>
  );
};
