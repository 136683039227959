import _ from "lodash";
import { XeroInvoice } from "../models/xero";
import { GlobalStateModel } from "./GlobalState";

interface GlobalAction {
  type:
    | "UPDATE_XERO_INVOICES"
    | "UPDATE_XERO_AUTHENTICATION"
    | "UPDATE_XERO_ERROR"
    | "CLEAR_XERO_SESSION";

  payload: XeroInvoice[] | boolean | null | string;
}

const reducer = (state: GlobalStateModel, action: GlobalAction) => {
  switch (action.type) {
    case "UPDATE_XERO_INVOICES":
      return {
        ...state,
        xeroInvoices:
          typeof action.payload !== "boolean"
            ? action.payload
            : state.xeroInvoices || null,
      } as GlobalStateModel;
    case "UPDATE_XERO_ERROR":
      return {
        ...state,
        xeroError: !_.isNull(action.payload)
          ? "Xero error: " + action.payload
          : action.payload,
      } as GlobalStateModel;
    case "UPDATE_XERO_AUTHENTICATION":
      return {
        ...state,
        isXeroAuthenticated: action.payload,
      } as GlobalStateModel;

    case "CLEAR_XERO_SESSION":
      return {
        xeroInvoices: null,
        isXeroAuthenticated: false,
        xeroError: null,
      } as GlobalStateModel;

    default:
      return state;
  }
};

export default reducer;
