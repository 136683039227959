import * as _ from "lodash";
import React, { useState } from "react";
import {
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonRippleEffect,
} from "@ionic/react";

import "./EWPDepotForm.scss";
import * as services from "../../services";
// import { formatPhoneNumber } from "../../functions/phoneNumber";
import { formatString } from "../../functions/common";
import { EWPDepotFormProps } from "./EWPDepotForm.interface";
import { MSGS_COMMON, MSGS_DEPOT } from "../../constants/messages";
import { EWPCOLORS, LIMITS } from "../../constants/config";
import { EWPInput } from "../EWPInput/EWPInput";
import { Depot } from "../../models";

export const EWPDepotForm = (props: EWPDepotFormProps) => {
  const { isEdit, editData } = props;
  let existingData = {
    name: "",
    address: "",
    contactPerson: "",
    contactNumber: "",
  } as Depot;

  if (!_.isEmpty(editData) && !!editData) {
    existingData = _.clone(editData);
  }

  const [name, setName] = useState(existingData.name);
  const [address, setAddress] = useState(existingData.address);
  const [contactPerson, setContactPerson] = useState(
    existingData.contactPerson
  );
  const [contactNumber, setContactNumber] = useState(
    existingData.contactNumber
  );

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [errorMsgName, setErrorMsgName] = useState("");
  const [errorMsgAddress, setErrorMsgAddress] = useState("");
  const [errorMsgContactPerson, setErrorMsgContactPerson] = useState("");
  const [errorMsgContactNumber, setErrorMsgContactNumber] = useState("");

  const submitForm = async (event: React.FormEvent) => {
    event.preventDefault();
    const formattedName = formatString(name || "");
    const formattedAddress = formatString(address || "");
    const formattedContactPerson = formatString(contactPerson || "");
    const formattedContactNumber = formatString(contactNumber);
    //! handle validation asap, first check what numbers are usually given here, tel? mobile?
    //  formatPhoneNumber(
    //   (contactNumber as string) || ""
    // );

    let errorMessage = null;
    if (formattedName.length === 0) {
      errorMessage = MSGS_DEPOT.depotName.required;
      setErrorMsgName(errorMessage);
    }
    if (formattedName.length > LIMITS.name.single) {
      errorMessage = MSGS_DEPOT.depotName.max;
      setErrorMsgName(errorMessage);
    }
    if (formattedContactPerson.length === 0) {
      errorMessage = MSGS_DEPOT.contactPerson.required;
      setErrorMsgContactPerson(errorMessage);
    }
    if (formattedContactPerson.length > LIMITS.name.single) {
      errorMessage = MSGS_DEPOT.contactPerson.max;
      setErrorMsgContactPerson(errorMessage);
    }

    if (formattedAddress.length === 0) {
      errorMessage = MSGS_DEPOT.address.required;
      setErrorMsgAddress(errorMessage);
    }
    if (_.isNull(formattedContactNumber)) {
      errorMessage = MSGS_DEPOT.contactNumber.invalid;
      setErrorMsgContactNumber(errorMessage);
    } else if (_.isEmpty(formattedContactNumber)) {
      errorMessage = MSGS_DEPOT.contactNumber.required;
      setErrorMsgContactNumber(errorMessage);
    }

    if (_.isEmpty(errorMessage)) {
      setSaving(true);
      try {
        if (_.isEmpty(editData)) {
          await services.createDepot(
            formattedName,
            formattedAddress,
            formattedContactPerson,
            formattedContactNumber as string
          );
          setSaving(false);
          setSuccess("New Depot Created");
        } else {
          await services.updateDepot(
            editData!.docId || "",
            formattedName,
            formattedAddress,
            formattedContactPerson,
            formattedContactNumber as string
          );
          setSaving(false);
          setSuccess("Depot Updated");
        }
      } catch (eUnknown) {
        const e = eUnknown as any;
        setError(e);
        setSaving(false);
      }
    }
  };

  const clearFields = () => {
    setName("");
    setAddress("");
    setContactPerson("");
    setContactNumber("");
  };

  return (
    <>
      <form onSubmit={submitForm}>
        <IonGrid className="ion-no-padding ion-no-margin">
          <IonRow className="ewp-depot-row ion-no-padding ion-no-margin">
            <IonCol size="6" className="ewp-depot-start-col ion-no-padding">
              <EWPInput
                errorMessage={errorMsgName}
                inputLabel="Depot Name"
                inputValue={name}
                inputType="text"
                name="name"
                onInputChange={(name: string) => {
                  setName(name);
                }}
              />
            </IonCol>
            <IonCol size="6" className="ewp-depot-end-col ion-no-padding">
              <EWPInput
                errorMessage={errorMsgAddress}
                inputLabel="Address"
                inputValue={address}
                inputType="text"
                name="address"
                onInputChange={(address: string) => {
                  setAddress(address);
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow className="ewp-depot-row ion-no-padding ion-no-margin">
            <IonCol className="ewp-depot-start-col ion-no-padding">
              <EWPInput
                errorMessage={errorMsgContactPerson}
                inputLabel="Contact Person"
                inputValue={contactPerson}
                inputType="text"
                name="contactPerson"
                onInputChange={(contactPerson: string) => {
                  setContactPerson(contactPerson);
                }}
              />
            </IonCol>

            <IonCol className="ewp-depot-end-col ion-no-padding">
              <EWPInput
                errorMessage={errorMsgContactNumber}
                inputLabel="Contact Number"
                inputValue={contactNumber}
                inputType="text"
                name="contactNumber"
                onInputChange={(contactNumber: string) => {
                  setContactNumber(contactNumber);
                }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonItem
          lines="none"
          className="ewp-item-no-horizontal-padding ewp-button-padding-top"
        >
          <IonButton
            mode="ios"
            slot="start"
            className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
            fill="clear"
            color={EWPCOLORS.medium}
            onClick={() => {
              if (props.onModalDidDismiss) {
                props.onModalDidDismiss();
                clearFields();
              }
            }}
          >
            Cancel
          </IonButton>
          <IonButton
            mode="md"
            slot="end"
            className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
            color={EWPCOLORS.primary}
            type="submit"
          >
            {isEdit ? "Save" : "Add Depot"}
            <IonRippleEffect />
          </IonButton>
        </IonItem>
      </form>
      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={saving}
        message={MSGS_COMMON.saving}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
          props.onModalDidDismiss();
          clearFields();
        }}
        color={EWPCOLORS.success}
      />
    </>
  );
};
