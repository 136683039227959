import React from "react";
import { IonContent, IonGrid, IonRow, IonCol, IonPage } from "@ionic/react";

import "./AccountSettings.scss";
import { isTablet } from "../../functions/common";
import EWPHeader from "../../components/EWPHeader/EWPHeader";
import ProfileSettings from "./ProfileSettings/ProfileSettings";
import { EWPSideMenu } from "../../components/EWPSideMenu/EWPSideMenu";
import { EWPProps } from "../../config/global";
import { EWPWebSideNavigator } from "../../components/EWPWebSideNavigator/EWPWebSideNavigator";
import { ACCOUNT_SETTINGS_PAGES } from "../../constants/navPages";

export interface SubPageParam {
  subPage: "profile";
}

class AccountSettings extends React.Component<EWPProps> {
  state = {
    loading: false,
    error: "",
    initialized: false,
    userPhotoUrl: "",
  };

  renderPage = (subPage: string) => {
    switch (subPage) {
      case ACCOUNT_SETTINGS_PAGES.profile:
        return (
          <ProfileSettings {...this.props} setPhotoURL={this.setPhotoURL} />
        );
      default:
        return <div>404</div>;
    }
  };

  setPhotoURL = (photoUrl: string) => {
    this.setState({ userPhotoUrl: photoUrl });
  };

  render = () => {
    const { subPage } = this.props.match.params as SubPageParam;
    // const { loading, error } = this.state;
    return (
      <>
        {isTablet() && (
          <EWPSideMenu
            activeSubPage={subPage}
            {...this.props}
            mode="accountSettings"
          />
        )}
        <IonPage id="accountSettings">
          <EWPHeader {...this.props} />
          <IonContent>
            <IonGrid className="nav-content-grid-container ion-no-padding">
              <IonRow className="nav-content-row-container">
                {!isTablet() && (
                  <IonCol className="nav-content-col-container" size="2">
                    <EWPWebSideNavigator
                      activeSubPage={subPage}
                      {...this.props}
                      mode="accountSettings"
                    />
                  </IonCol>
                )}

                <IonCol className="account-settings-container ion-no-padding">
                  {this.renderPage(subPage)}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonPage>
      </>
    );
  };
}

export default AccountSettings;
