import _ from "lodash";
import Bugsnag from "@bugsnag/js";
import React from "react";
import {
  withIonLifeCycle,
  IonLoading,
  IonToast,
  IonTitle,
  IonButton,
  IonItem,
  IonRippleEffect,
} from "@ionic/react";

import "./WebChangePassword.scss";
import * as services from "../../../services";
import { validatePassword } from "../../../functions/common";
import { EWPProps } from "../../../config/global";
import { EWPPasswordInput } from "../../../components/EWPPasswordInput/EWPPasswordInput";
import { EWPCOLORS } from "../../../constants/config";
import { MSGS_CHANGE_PASSWORD, MSGS_COMMON } from "../../../constants/messages";

interface WebChangePasswordProps extends EWPProps {
  gotoProfileSettings: () => void;
}

class WebChangePassword extends React.Component<WebChangePasswordProps> {
  state = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    oldPasswordVisibility: false,
    newPasswordVisibility: false,
    confirmPasswordVisibility: false,
    saving: false,
    error: "",
    success: "",
    errorCurrentPassword: "",
    errorNewPassword: "",
    errorConfirmPassword: "",
  };

  submitNewPassword = async (event: React.FormEvent) => {
    event.preventDefault();

    this.setState({
      error: "",
      saving: true,
    });

    const { oldPassword, newPassword, confirmPassword } = this.state;

    let errorMessage = "";
    if (oldPassword.length === 0) {
      errorMessage = MSGS_CHANGE_PASSWORD.currentRequired;
      this.setState({
        errorCurrentPassword: errorMessage,
      });
    } else if (newPassword.length === 0) {
      errorMessage = MSGS_CHANGE_PASSWORD.newRequired;
      this.setState({
        errorNewPassword: errorMessage,
      });
    } else if (confirmPassword.length === 0) {
      errorMessage = MSGS_CHANGE_PASSWORD.confirmRequired;
      this.setState({
        errorConfirmPassword: errorMessage,
      });
    } else if (newPassword !== confirmPassword) {
      errorMessage = MSGS_CHANGE_PASSWORD.mismatch;
      this.setState({
        errorNewPassword: errorMessage,
        errorConfirmPassword: errorMessage,
      });
    } else if (!validatePassword(newPassword)) {
      errorMessage = MSGS_CHANGE_PASSWORD.invalid;
      this.setState({
        errorNewPassword: errorMessage,
      });
    }

    if (errorMessage) {
      this.setState({
        saving: false,
      });
      return;
    }
    try {
      await services.updatePassword(oldPassword, newPassword);
      this.setState({
        saving: false,
        success: MSGS_CHANGE_PASSWORD.success,
      });
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      this.setState({
        error: error.message,
        saving: false,
      });
      Bugsnag.notify(new Error(error.message));
      return;
    }
  };

  clearFields = () => {
    this.setState({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };
  clearErrorMsgs = () => {
    this.setState({
      errorCurrentPassword: "",
      errorConfirmPassword: "",
      errorNewPassword: "",
    });
  };

  render = () => {
    const {
      saving,
      error,
      success,
      oldPassword,
      newPassword,
      confirmPassword,
      errorCurrentPassword,
      errorNewPassword,
      errorConfirmPassword,
    } = this.state;
    const { gotoProfileSettings } = this.props;
    return (
      <>
        <div>
          <IonTitle className="web-change-password-title ion-text-start ion-no-padding">
            Change Password
          </IonTitle>
        </div>
        <div className="web-change-password-container">
          <form onSubmit={this.submitNewPassword}>
            <div className="web-change-password-input-container">
              <EWPPasswordInput
                passwordLabel="Current Password"
                passwordValue={oldPassword}
                onChange={(oldPassword: string) => {
                  this.setState({
                    oldPassword: oldPassword,
                  });
                  this.clearErrorMsgs();
                }}
                errorMessage={errorCurrentPassword}
              />
            </div>
            <div className="web-change-password-input-container">
              <EWPPasswordInput
                passwordLabel="New Password"
                passwordValue={newPassword}
                onChange={(newPassword: string) => {
                  this.setState({
                    newPassword: newPassword,
                  });
                  this.clearErrorMsgs();
                }}
                errorMessage={errorNewPassword}
              />
            </div>
            <div className="web-change-password-input-container">
              <EWPPasswordInput
                passwordLabel="Retype New Password"
                passwordValue={confirmPassword}
                onChange={(confirmPassword: string) => {
                  this.setState({
                    confirmPassword: confirmPassword,
                  });
                  this.clearErrorMsgs();
                }}
                errorMessage={errorConfirmPassword}
              />
            </div>

            <IonItem
              tabIndex={-1}
              lines="none"
              className="ewp-item-no-horizontal-padding ewp-button-padding-top"
            >
              <IonButton
                mode="ios"
                slot="start"
                className="ewp-cancel-button"
                fill="clear"
                onClick={() => {
                  this.clearFields();
                  this.clearErrorMsgs();
                  gotoProfileSettings();
                }}
              >
                Cancel
              </IonButton>
              <IonButton
                mode="md"
                slot="end"
                className="ewp-save-form-button ion-text-capitalize"
                color="primary"
                type="submit"
              >
                Save
                <IonRippleEffect />
              </IonButton>
            </IonItem>
          </form>
        </div>

        <IonLoading
          spinner="circular"
          translucent={true}
          mode="ios"
          isOpen={saving}
          message={MSGS_COMMON.saving}
        />
        <IonToast
          isOpen={!_.isEmpty(error)}
          message={error}
          duration={2000}
          onDidDismiss={() => this.setState({ error: "" })}
          color={EWPCOLORS.danger}
        />

        <IonToast
          isOpen={!_.isEmpty(success)}
          message={success}
          duration={500}
          onDidDismiss={() => {
            this.setState({
              success: "",
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
            gotoProfileSettings();
          }}
          color={EWPCOLORS.success}
        />
      </>
    );
  };
}

export default withIonLifeCycle(WebChangePassword);
