import * as _ from "lodash";
import React from "react";
import {
  IonLabel,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCardHeader,
  IonButton,
  IonIcon,
  IonSpinner,
} from "@ionic/react";

import "./ClientContactsCard.scss";
import { isTablet } from "../../functions/common";
import { EWPProps } from "../../config/global";
import { EWPCOLORS } from "../../constants/config";
import { ContactPerson } from "../../models";

const setColumns = [
  {
    name: "Name",
    key: "name",
    colSize: isTablet() ? "3" : "5",
  },
  {
    name: "PHONE",
    key: "phoneNumber",
    colSize: isTablet() ? "3.5" : "2.5",
  },
  {
    name: "Email",
    key: "emailAddress",
    colSize: isTablet() ? "5" : "4",
  },
];

interface ClientContactsCardProps extends EWPProps {
  contactData: ContactPerson[] | null; //todo: update interface once model is okay
  onClickEdit: (induction: ContactPerson) => void;
  onAdd: () => void;
}
export const ClientContactsCard = (props: ClientContactsCardProps) => {
  const { contactData, onAdd, onClickEdit } = props;

  return (
    <>
      <IonCard
        className={`contacts-card ${
          isTablet() ? "tablet" : ""
        } ion-no-padding ion-no-margin`}
        color={EWPCOLORS.tertiary}
      >
        <IonCardContent className="contacts-card-content ion-no-padding ion-no-margin">
          <IonCardHeader className="contacts-card-header ion-no-padding ion-no-margin">
            <IonLabel className="contacts-card-header-label ewp-h4 bold ion-no-padding ion-no-margin">
              Contacts
            </IonLabel>
            <IonButton
              mode="ios"
              fill="clear"
              color={EWPCOLORS.primary}
              onClick={onAdd}
              className="contacts-card-header-add-contacts-button ewp-h5 ion-no-padding ion-no-margin"
            >
              <u>Add New Contact</u>
            </IonButton>
          </IonCardHeader>
          <IonGrid className="contacts-card-grid ion-no-padding ion-no-margin">
            <IonRow className="contacts-card-row-content-header ion-no-padding ion-no-margin">
              {setColumns.map((contactColumn) => {
                return (
                  <IonCol
                    className="ion-no-padding ion-text-start"
                    size={contactColumn.colSize}
                  >
                    <IonLabel className="ewp-h6 medium bold ion-text-uppercase ion-text-start">
                      {contactColumn.name}
                    </IonLabel>
                  </IonCol>
                );
              })}
              <IonCol className="ion-no-padding ion-text-start" size="1">
                {/* for button container */}
              </IonCol>
            </IonRow>

            {!!contactData && !_.isNull(contactData) ? (
              contactData.map((contactPerson) => {
                return (
                  <IonRow className="contacts-card-row-content-data ion-no-padding ion-no-margin">
                    {setColumns.map((contactColumn, index) => {
                      return (
                        <IonCol
                          className="ion-no-padding ion-text-start"
                          size={contactColumn.colSize}
                        >
                          <IonLabel className={`ewp-h6 medium ion-text-start`}>
                            {contactColumn.key === "name"
                              ? `${contactPerson.firstName} ${contactPerson.lastName}`
                              : contactPerson[
                                  contactColumn.key as
                                    | "phoneNumber"
                                    | "emailAddress"
                                ]}
                          </IonLabel>
                        </IonCol>
                      );
                    })}
                    <IonCol
                      className="ion-no-padding ion-text-start"
                      size="0.5"
                    >
                      <div className="contacts-card-buttons-container">
                        <IonButton
                          fill="clear"
                          mode="ios"
                          className="contacts-edit-button ewp-web-gray-button ion-no-padding ion-no-margin"
                          onClick={() => {
                            onClickEdit(contactPerson);
                          }}
                        >
                          <IonIcon className="contacts-edit-icon" />
                        </IonButton>
                      </div>
                    </IonCol>
                  </IonRow>
                );
              })
            ) : (
              <div className="ewp-table-spinner-container">
                <IonSpinner className="ewp-spinner" color={EWPCOLORS.primary} />
              </div>
            )}
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  );
};
