import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import React, { useState } from "react";
import {
  IonItem,
  IonLabel,
  IonButton,
  IonToast,
  IonAlert,
  IonLoading,
  IonRippleEffect,
} from "@ionic/react";

import "./EWPUserAccountForm.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import { auth, fb } from "../../firebase";
import {
  formatString,
  validatePassword,
  isMobile,
} from "../../functions/common";
import { User } from "../../models";
import {
  EWPUserAccountProps,
  AddUserAccount,
  EditUserAccount,
} from "./EWPUserAccountForm.interface";
import { EWPInput } from "../EWPInput/EWPInput";
import { EWPPasswordInput } from "../EWPPasswordInput/EWPPasswordInput";
import { EWPButton } from "../EWPButton/EWPButton";
import { MSGS_SIGNUP } from "../../constants/messages";
import { LIMITS, EWPCOLORS } from "../../constants/config";
import Bugsnag from "@bugsnag/js";

interface AuthDialogform {
  emailAddress: string;
  password: string;
}

const AuthDialogFields = {
  emailAddress: "emailAddress",
  password: "password",
};

export const EWPUserAccountForm = (props: EWPUserAccountProps) => {
  const { isEdit, editData } = props;
  let existingData = {
    firstName: "",
    lastName: "",
    phoneNumber: "" as string,
  } as User;
  // let userId = ""; //todo: enable this once model for user is okay
  let existingEmailAddress = "";
  let existingIsEmailVerified = false;

  if (!_.isEmpty(editData) && !!editData) {
    existingData = _.clone(editData!.data);
    // userId = editData.userId;
    existingEmailAddress = editData.emailAddress;
    existingIsEmailVerified = editData.isEmailVerified;
  }

  const formatPhoneNumber = (phoneNumber: string | undefined) => {
    if (!!phoneNumber) {
      const formattedNumber = parsePhoneNumberFromString(phoneNumber);
      return formattedNumber!.formatInternational();
    }
  };

  const [firstName, setFirstName] = useState(existingData.firstName);
  const [lastName, setLastName] = useState(existingData.lastName);

  const [emailAddress, setEmailAddress] = useState(existingEmailAddress);
  const [emailVerified, setEmailVerified] = useState(existingIsEmailVerified);
  const [phoneNumber, setPhoneNumber] = useState(
    formatPhoneNumber(existingData.phoneNumber as string)
  );

  const [password, setPassword] = useState("");
  const [showAuthAlert, setShowAuthAlert] = useState(false);

  const [saving, setSaving] = useState(props.isPhotoStillUploading || false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [errorMsgFirstName, setErrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgEmaillAddress, setErrorMsgEmaillAddress] = useState("");
  const [errorMsgPassword, setErrorMsgPassword] = useState("");
  const [errorMsgPhoneNumber, setErrorMsgPhoneNumber] = useState("");

  const [pendingUpdateData, setPendingUpdateData] = useState(
    {} as EditUserAccount
  );

  const submitForm = (event: React.FormEvent) => {
    event.preventDefault();

    const formattedFirstName = formatString(firstName);
    const formattedLastName = formatString(lastName);
    const formattedEmailAddress = formatString(emailAddress || "");
    const formattedPassword = password || "";
    const formattedPhoneNumber = formatString(phoneNumber || "")
      .replace(/\s/g, "")
      .replace("0", "+61"); //set into AUS formatted number
    let errorMessage = null;
    if (formattedFirstName.length === 0) {
      errorMessage = MSGS_SIGNUP.firstName.required;
      setErrorMsgFirstName(errorMessage);
    } else if (formattedFirstName.length > LIMITS.name.single) {
      errorMessage = MSGS_SIGNUP.firstName.max;
      setErrorMsgFirstName(errorMessage);
    } else if (formattedLastName.length === 0) {
      errorMessage = MSGS_SIGNUP.lastName.required;
      setErrorMsgLastName(errorMessage);
    } else if (formattedLastName.length > LIMITS.name.single) {
      errorMessage = MSGS_SIGNUP.lastName.max;
      setErrorMsgLastName(errorMessage);
    } else if (formattedEmailAddress.length === 0) {
      errorMessage = MSGS_SIGNUP.email.required;
      setErrorMsgEmaillAddress(errorMessage);
    } else if (!EmailValidator.validate(formattedEmailAddress)) {
      errorMessage = MSGS_SIGNUP.email.invalid;
      setErrorMsgEmaillAddress(errorMessage);
    } else if (_.isEmpty(formattedPhoneNumber)) {
      errorMessage = MSGS_SIGNUP.mobileNumber;
      setErrorMsgPhoneNumber(errorMessage);
    } else if (!validatePhoneNumber(formattedPhoneNumber)) {
      errorMessage = MSGS_SIGNUP.invalidMobileNumber;
      setErrorMsgPhoneNumber(errorMessage);
    }

    if (!isEdit) {
      if (password.length === 0) {
        errorMessage = MSGS_SIGNUP.password.required;
      } else if (!validatePassword(formattedPassword)) {
        errorMessage = MSGS_SIGNUP.password.invalid;
      }
    }

    if (_.isEmpty(errorMessage)) {
      if (!isEdit) {
        addUser({
          firstName: formattedFirstName,
          lastName: formattedLastName,
          emailAddress: formattedEmailAddress,
          password: formattedPassword,
        });
      } else {
        if (!!props.editData!.onUpdateCallback) {
          props.editData!.onUpdateCallback();
        }

        updateUser({
          firstName: formattedFirstName,
          lastName: formattedLastName,
          emailAddress: formattedEmailAddress,
          phoneNumber: formattedPhoneNumber,
        });
      }
    }
  };

  const addUser = async (user: AddUserAccount) => {
    // TODO: add user services here once model is ready
    console.log("user -- add -- ", user);
  };

  const updateUser = async (user: EditUserAccount) => {
    let { firstName, lastName, emailAddress } = user;
    if (existingEmailAddress !== emailAddress) {
      setPendingUpdateData({
        firstName,
        lastName,
        emailAddress,
        phoneNumber: phoneNumber as string,
      });
      setShowAuthAlert(true);
    } else {
      proceedToUpdateUser(user);
    }
  };

  const proceedToUpdateUser = async (user: EditUserAccount) => {
    const { editData } = props;
    if (!!editData && !_.isEmpty(editData)) {
      setSaving(true);
      try {
        await services.updateAccountDetails(editData.userId, "admins", user);
        setSaving(false);
        setSuccess("Update account success");
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        setSaving(false);
        setError(error);
        Bugsnag.notify(new Error(error));
      }
    }
  };

  const updateEmail = async (emailAddress: string) => {
    setSaving(true);
    const user = auth.currentUser;
    try {
      if (!!user) {
        await services.updateEmail(emailAddress);

        setEmailAddress(emailAddress);
        proceedToUpdateUser(pendingUpdateData);
      }
      setSaving(false);
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      setSaving(false);
      setError(error);
      Bugsnag.notify(new Error(error));
    }
    setSaving(false);
  };

  const handleAuthDialog = async (emailAddress: string, password: string) => {
    setSaving(true);
    let errorMessage = "";
    if (password.length === 0) {
      errorMessage = MSGS_SIGNUP.password.required;
    }

    if (!_.isEmpty(errorMessage)) {
      setEmailAddress(existingEmailAddress);
      setError(errorMessage);
      setSaving(false);

      return false;
    } else {
      const credential = fb.auth.EmailAuthProvider.credential(
        emailAddress,
        password
      );
      const user = auth.currentUser;
      if (!!user) {
        try {
          await user.reauthenticateWithCredential(credential);

          updateEmail(pendingUpdateData.emailAddress);
          setSaving(false);

          return true;
        } catch (errorUnknown) {
          const error = errorUnknown as any;
          setEmailAddress(existingEmailAddress);
          setSaving(false);
          setError(error);
          Bugsnag.notify(new Error(error));
          return false;
        }
      } else {
        setSaving(false);
        setError("No User Found");

        return false;
      }
    }
  };

  const clearErrorMsgs = () => {
    setErrorMsgFirstName("");
    setErrorMsgLastName("");
    setErrorMsgEmaillAddress("");
    setErrorMsgPhoneNumber("");
  };

  return (
    <>
      <form
        id="sign-up-form"
        onSubmit={submitForm}
        className="user-account-form"
      >
        <div className="user-details-input-container">
          <EWPInput
            errorMessage={errorMsgFirstName}
            inputLabel="First Name"
            inputValue={firstName}
            inputType="text"
            inputMode="text"
            name="firstName"
            onInputChange={(firstName: string) => {
              clearErrorMsgs();
              setFirstName(firstName);
            }}
          />
        </div>

        <div className="user-details-input-container">
          <EWPInput
            errorMessage={errorMsgLastName}
            inputLabel="Last Name"
            inputValue={lastName}
            inputType="text"
            inputMode="text"
            name="lastName"
            onInputChange={(lastName: string) => {
              clearErrorMsgs();
              setLastName(lastName);
            }}
          />
        </div>
        <div className="user-details-input-container">
          <EWPInput
            errorMessage={errorMsgEmaillAddress}
            inputLabel="Email"
            inputValue={emailAddress}
            inputType="text"
            name="emailAddress"
            onInputChange={(emailAddress: string) => {
              clearErrorMsgs();
              setEmailAddress(emailAddress);
            }}
          />
        </div>
        {!emailVerified && isEdit && (
          <div className="user-detail-email-container">
            <IonButton
              fill="clear"
              slot="end"
              mode="ios"
              className="user-detail-resend-email-verification"
              onClick={() => {
                //WILL HIDE BUTTON IF VERIFICATION IS RE-SENT,
                //UPON COMING BACK TO PAGE THIS BUTTON WILL RE-APPEAR IF EMAIL IS STILL NOT VERIFIED=
                services.doSendVerificationEmail();
                setSuccess("Email verification sent!");
                setEmailVerified(true);
              }}
            >
              Resend Email Verification
            </IonButton>
          </div>
        )}
        <div className="user-details-input-container">
          <EWPInput
            errorMessage={errorMsgPhoneNumber}
            inputLabel="Phone Number"
            inputValue={phoneNumber}
            inputType="text"
            inputMode="text"
            name="phoneNumber"
            onInputChange={(phoneNumber: string) => {
              clearErrorMsgs();
              setPhoneNumber(phoneNumber);
            }}
          />
        </div>

        {isEdit ? (
          <>
            <IonItem
              type="button"
              className={
                !isMobile()
                  ? "web-ewp-user-change-password ion-no-padding"
                  : "ewp-user-change-password ion-no-padding"
              }
              detail={true}
              lines="none"
              {...(!props.onChangePassword
                ? { routerLink: routes.CHANGE_PASSWORD }
                : { onClick: props.onChangePassword })}
            >
              <IonLabel
                className="ewp-label-change-password"
                color={EWPCOLORS.primary}
              >
                Change Password
              </IonLabel>
            </IonItem>
            {/* //TODO: enable this if final design arrive and has a change number */}
            {/* <div className="ewp-phone-number">
              <IonItem className="mobile-item" lines="none">
                <IonTitle
                  slot="start"
                  className="mobile-label ion-no-padding ion-padding-bottom ion-text-start"
                >
                  Mobile Number
                </IonTitle>
                <IonButton
                  slot="end"
                  fill="clear"
                  mode="ios"
                  color="primary"
                  className="ewp-edit-phone-number ion-no-padding"
                  {...(!props.onChangePhoneNumber
                    ? { routerLink: "" }
                    : { onClick: props.onChangePhoneNumber })}
                >
                  Change Number
                </IonButton>
              </IonItem>
              <IonItem className="ewp-mobile-number-item" lines="none">
                <IonInput
                  className={
                    !isMobile() ? "ewp-mobile-number-input" : "ewp-input"
                  }
                  name="phoneNumber"
                  inputmode="text"
                  type="text"
                  value={formatPhoneNumber(existingData.phoneNumber)}
                  disabled
                />
              </IonItem>
            </div> */}
          </>
        ) : (
          <>
            <EWPPasswordInput
              passwordLabel="Password"
              passwordValue={password}
              onChange={(password: string) => {
                setPassword(password);
                setErrorMsgPassword("");
              }}
              errorMessage={errorMsgPassword}
            />
          </>
        )}
        <div className="end-form-spacing ion-no-padding ion-no-margin">
          {isMobile() ? (
            <div className="user-form-submit-button-container">
              <EWPButton
                title={!isEdit ? "Continue" : "Save"}
                routerLink="#"
                type="submit"
              />
            </div>
          ) : (
            <IonButton
              mode="md"
              className="ewp-large-web-button ion-text-capitalize"
              color="primary"
              type="submit"
            >
              Save Changes
              <IonRippleEffect />
            </IonButton>
          )}
        </div>
      </form>

      <IonAlert
        isOpen={showAuthAlert}
        backdropDismiss={false}
        translucent={true}
        mode="ios"
        onDidDismiss={() => setShowAuthAlert(false)}
        header={"Authentication Required"}
        inputs={[
          {
            name: AuthDialogFields.emailAddress,
            type: "email",
            value: existingEmailAddress,
            disabled: true,
          },
          {
            name: AuthDialogFields.password,
            type: "password",
            id: "password",
            placeholder: "Enter Password",
          },
        ]}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Confirm",
            handler: async (data) => {
              const { emailAddress, password } = data as AuthDialogform;

              return handleAuthDialog(emailAddress, password);
            },
          },
        ]}
      />

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={saving}
        message={"Saving ..."}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
        }}
        color={EWPCOLORS.success}
      />
    </>
  );
};
