export const ADMINS = "admins";
export const COMPANIES = "companies";
export const CONTACT_PEOPLE = "contactPeople";
export const CLIENTS = "clients";
export const CLIENT_CONTACT_PERSON = "clientContactPerson";
export const CLIENT_NOTES = "clientNotes";
export const DEPOTS = "depots";
export const DRIVERS = "drivers";
export const DRIVER_EXPIRY = "driverExpiry";
export const DRIVER_NOTES = "driverNotes";
export const DRIVERS_VOC = "driversVOC";
export const JOB_SCHEDULES = "jobSchedules";
export const TRUCKS = "trucks";
export const TRUCK_SERVICE_HISTORIES = "truckServiceHistories";
export const TRUCK_EXPIRY = "truckExpiry";
export const INDUCTIONS = "inductions";
export const TRUCK_NOTES = "truckNotes";
export const USER_ROLE = "userRole";
export const DRIVER_CHANGE_LOGS = "driverChangeLogs";
export const TRUCK_CHANGE_LOGS = "truckChangeLogs";
export const FAULT_AND_DEFECTS = "faultAndDefects";
export const FAULT_AND_DEFECTS_NOTES = "faultAndDefectsNotes";
export const JOB_ADMIN_NOTES = "jobAdminNotes";
