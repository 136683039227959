import _ from "lodash";
import Bugsnag from "@bugsnag/js";

import { getServerTimestamp } from "../functions/common";
import { firestore, auth } from "../firebase";
import { Depot } from "../models";
import { DEPOTS } from "../constants/dbCollections";

export const createDepot = async (
  name: string,
  address: string,
  contactPerson: string,
  contactNumber: string
) => {
  const createdBy = auth.currentUser;
  if (!_.isNull(createdBy)) {
    const result = await firestore.collection(DEPOTS).add({
      name,
      address,
      contactPerson,
      contactNumber,
      archived: false,
      isArchivedBy: "",
      createdBy: createdBy.uid,
      createdDt: getServerTimestamp(),
      updatedDt: getServerTimestamp(),
    });
    return result.id;
  } else {
    throw new Error("No user logged in");
  }
};

export const updateDepot = async (
  docId: string,
  name: string,
  address: string,
  contactPerson: string,
  contactNumber: string
) => {
  const updatedBy = auth.currentUser;
  if (!_.isNull(updatedBy)) {
    await firestore.collection(DEPOTS).doc(docId).update({
      name,
      address,
      contactPerson,
      contactNumber,
      updatedBy: updatedBy.uid,
      updatedDt: getServerTimestamp(),
    });
  } else {
    throw new Error("No user logged in");
  }
};

export const setDepotArchived = async (docId: string, archived: boolean) => {
  const updatedBy = auth.currentUser;
  if (!_.isNull(updatedBy)) {
    await firestore.collection(DEPOTS).doc(docId).update({
      archived,
      isArchivedBy: updatedBy.uid,
      updatedBy: updatedBy.uid,
      updatedDt: getServerTimestamp(),
    });
  } else {
    throw new Error("No user logged in");
  }
};

export const getDepotsRealTime = (
  callback: (depots: Depot[], error?: string) => void,
  archived = false
) => {
  try {
    const unsubscribe = firestore
      .collection(DEPOTS)
      .where("archived", "==", archived)
      .onSnapshot((query) => {
        if (!query.empty) {
          callback(
            query.docs.map((depot) => {
              return {
                docId: depot.id,
                ...depot.data(),
              } as Depot;
            })
          );
        } else {
          callback([]);
        }
      });
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const getDepots = async (archived = false): Promise<Depot[]> => {
  const query = await firestore
    .collection(DEPOTS)
    .where("archived", "==", archived)
    .get();

  if (!query.empty) {
    return query.docs.map((depot) => {
      return {
        docId: depot.id,
        ...depot.data(),
      } as Depot;
    });
  } else {
    return [] as Depot[];
  }
};

export const getDepot = async (docId: string): Promise<Depot> => {
  const query = await firestore.collection(DEPOTS).doc(docId).get();

  if (query.exists) {
    return {
      docId: query.id,
      ...query.data(),
    } as Depot;
  } else {
    return {} as Depot;
  }
};

export const deleteDepot = async (docId: string) => {
  try {
    if (!docId) return;
    const result = await firestore.collection(DEPOTS).doc(docId).delete();
    return result;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN deleteDepot: ", e);
    Bugsnag.notify(new Error(e));
  }
};
