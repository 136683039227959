import React from "react";
import { chevronBackOutline } from "ionicons/icons";
import { IonLabel, IonIcon, IonButton } from "@ionic/react";

import "./EWPTitleDetailPage.scss";
// import * as routes from "../../constants/routes";
import { EWPProps } from "../../config/global";

interface EWPTitleDetailPageProps extends EWPProps {
  pageTitle: string;
  createButtonLabel: string;
  newButtonLabel: string;
  onClickCreateButton: () => void;
  onClickEditButton: () => void;
  backURL: string;
  disableCreate?: boolean;
  disableSecondaryAction?: boolean;
  removeCreateUpdateButtons?: boolean;
}

export const EWPTitleDetailPage = (props: EWPTitleDetailPageProps) => {
  // const [isToggleButtonChecked, setIsToggleButtonChecked] = useState(false);

  const {
    pageTitle,
    createButtonLabel,
    newButtonLabel,
    onClickCreateButton,
    onClickEditButton,
    backURL,
    disableCreate,
    disableSecondaryAction,
    removeCreateUpdateButtons,
  } = props;

  return (
    <div className="ewp-title-detail-page-container ion-no-margin ion-no-padding">
      <div className="ewp-title-detail-page-back-title-container">
        <IonButton
          fill="solid"
          className="ewp-title-detail-page-container-back-button"
          routerLink={backURL}
        >
          <IonIcon
            icon={chevronBackOutline}
            mode="ios"
            className="ewp-title-detail-page-container-back-icon"
          />
        </IonButton>
        <IonLabel className="ewp-h2 ion-no-padding ion-padding-end">
          {pageTitle}
        </IonLabel>
      </div>

      {!removeCreateUpdateButtons && (
        <div className="ewp-title-detail-page-action-buttons-container">
          <IonButton
            mode="ios"
            fill="clear"
            className="ewp-title-detail-page-create-button ewp-h5 primary ion-no-padding ion-no-margin"
            onClick={onClickCreateButton}
            disabled={!!disableSecondaryAction ? disableSecondaryAction : false}
          >
            {createButtonLabel}
          </IonButton>
          <IonButton
            mode="ios"
            className="ewp-title-detail-page-new-button ewp-h5  white ion-no-padding ion-no-margin"
            onClick={onClickEditButton}
            disabled={!!disableCreate ? disableCreate : false}
          >
            <IonIcon className="edit-icon" />
            <IonLabel className="ewp-title-detail-page-new-button-label ewp-h5 white">
              {newButtonLabel}
            </IonLabel>
          </IonButton>
        </div>
      )}
    </div>
  );
};
