import * as _ from "lodash";
import * as serviceWorker from "./serviceWorker";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { getEnv } from "./env";
import { setupIonicReact } from "@ionic/react";
import { ENVIRONMENTS } from "./config";

var env = getEnv();

const getDOMRender = () => {
  setupIonicReact({ spinner: "circular" });
  if (
    process.env.REACT_APP_APPENV !== ENVIRONMENTS.local &&
    !_.isEmpty(env.bugSnag)
  ) {
    Bugsnag.start({
      apiKey: env.bugSnag.apiKey,
      releaseStage: env.bugSnag.releaseStage,
      plugins: [new BugsnagPluginReact()],
    });

    const ErrorBoundary =
      Bugsnag.getPlugin("react")!.createErrorBoundary(React);

    return !_.isNull(ErrorBoundary) && !!ErrorBoundary ? (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    ) : (
      <App />
    );
  } else {
    return <App />;
  }
};

ReactDOM.render(getDOMRender(), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
