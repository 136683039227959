import { ENVIRONMENTS } from "../config/index";

export const getEnv = () => {
  var env;
  if (process.env.REACT_APP_APPENV === ENVIRONMENTS.production) {
    env = require("./env.production.json");
  } else if (process.env.REACT_APP_APPENV === ENVIRONMENTS.development) {
    env = require("./env.development.json");
  } else if (process.env.REACT_APP_APPENV === ENVIRONMENTS.local) {
    env = require("./env.local.json");
  } else if (process.env.REACT_APP_APPENV === ENVIRONMENTS.localProd) {
    env = require("./env.local.production.json");
  } else {
    env = require("./env.development.json");
  }
  return env;
};
