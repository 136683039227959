import * as _ from "lodash";
import React from "react";
import {
  IonLabel,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonCardHeader,
  IonButton,
} from "@ionic/react";

import "./EWPDetailCard.scss";
import { isTablet } from "../../functions/common";
import { EWPLabel } from "../EWPLabel/EWPLabel";
import { EWPProps } from "../../config/global";
import { Truck, TruckView } from "../../models/drivers";
import { EWPCOLORS } from "../../constants/config";
import { ClientContactPersonDetailedView, JOBS_WITH_KEYS } from "../../models";

interface EWPDetailCardProps extends EWPProps {
  cardData: ClientContactPersonDetailedView | Truck | null; //Todo: update interface based on schema
  cardDetailTitle: string;
  cardButtonLabel?: string;
  onArchived: () => void;
  mode: "client" | "truck";
}
export const EWPDetailCard = (props: EWPDetailCardProps) => {
  const {
    cardData,
    onArchived,
    cardDetailTitle,
    cardButtonLabel,
    mode,
  } = props;
  // console.log("CLIENT --- DETAIL --- ", cardData);
  const client = cardData as ClientContactPersonDetailedView;
  const truck = cardData as TruckView;

  return (
    <IonCard
      className={`ewp-detail-card ${
        isTablet() ? "tablet" : ""
      } ion-no-padding ion-no-margin`}
      color={EWPCOLORS.tertiary}
    >
      <IonCardContent className="ewp-detail-card-content ion-no-padding ion-no-margin">
        <IonCardHeader className="ewp-detail-card-header ion-no-padding ion-no-margin">
          <IonLabel className="ewp-detail-card-header-label ewp-h4 bold ion-no-padding ion-no-margin">
            {cardDetailTitle}
          </IonLabel>
          {!!cardButtonLabel && (
            <IonButton
              mode="ios"
              className="ewp-detail-card-header-button ewp-h6 dark ion-no-padding ion-no-margin"
              onClick={onArchived}
            >
              {cardButtonLabel}
            </IonButton>
          )}
        </IonCardHeader>
        <IonGrid className="ewp-detail-card-grid ion-no-padding ion-no-margin">
          <IonRow className="ewp-detail-card-row ion-no-padding ion-no-margin">
            <IonCol
              size={isTablet() ? "2.5" : "2"}
              className="ewp-detail-card-icon-col ion-no-padding ion-no-margin"
            >
              <div className="ewp-detail-card-col-container">
                <IonIcon className={`ewp-detail-card-icon ${mode}`} />
              </div>
            </IonCol>
            <IonCol
              size="4.5"
              className="ewp-detail-card-detail-col ion-no-padding ion-no-margin"
            >
              <div className="ewp-detail-card-col-container">
                <div className="ewp-detail-card-detail-title-container">
                  <EWPLabel
                    loading={_.isNull(cardData) || _.isEmpty(cardData)}
                    text={
                      !_.isEmpty(cardData)
                        ? mode === "client"
                          ? client.name
                          : truck.name
                        : "Loading..."
                    }
                    loadingWidth={80}
                    className="ewp-detail-card-detail-client-name ewp-h1 ion-no-padding ion-no-margin"
                  />
                  <EWPLabel
                    loading={_.isNull(cardData) || _.isEmpty(cardData)}
                    text={
                      !_.isEmpty(cardData)
                        ? mode === "client"
                          ? `ABN ${client.abn}`
                          : `${JOBS_WITH_KEYS[truck.type].name}`
                        : "Loading..."
                    }
                    loadingWidth={60}
                    className="ewp-detail-card-detail-client-abn ewp-h3 primary ion-no-padding ion-no-margin"
                  />
                </div>
                {mode === "client" ? (
                  <>
                    <div className="ewp-detail-card-detail-sub-details-container">
                      <EWPLabel
                        loading={_.isNull(cardData)}
                        text={
                          !_.isEmpty(cardData)
                            ? `Preferred Trucks: ${
                                !_.isEmpty(client.preferredTrucks)
                                  ? client.preferredTrucks!.map(
                                      (truck, index) => {
                                        return `${truck.name}${
                                          (index === 0 ||
                                            index !==
                                              client.preferredTrucks!.length -
                                                1) &&
                                          client.preferredTrucks!.length > 0
                                            ? ", "
                                            : ""
                                        }  `;
                                      }
                                    )
                                  : "N/A"
                              }`
                            : "Loading..."
                        }
                        className="ewp-paragraph ion-no-padding ion-no-margin"
                      />

                      <EWPLabel
                        loading={_.isNull(cardData)}
                        text={
                          !_.isEmpty(cardData)
                            ? `Preferred Drivers: ${
                                !_.isEmpty(client.preferredDrivers)
                                  ? client.preferredDrivers!.map(
                                      (driver, index) => {
                                        return `${driver!.firstName} ${
                                          driver!.lastName
                                        }${
                                          (index === 0 ||
                                            index !==
                                              client.preferredDrivers!.length -
                                                1) &&
                                          client.preferredDrivers!.length > 0
                                            ? ", "
                                            : ""
                                        }  `;
                                      }
                                    )
                                  : "N/A"
                              }`
                            : "Loading..."
                        }
                        className="ewp-paragraph ion-no-padding ion-no-margin"
                      />
                    </div>
                  </>
                ) : (
                  <IonGrid className="ewp-detail-card-truck-grid ion-no-padding ion-no-margin">
                    <IonRow className="ewp-detail-card-truck-row ion-no-padding ion-no-margin">
                      <IonCol
                        size={isTablet() ? "5" : "6"}
                        className="ewp-detail-card-truck-col ion-no-padding ion-no-margin"
                      >
                        <div className="ewp-detail-truck-label-container">
                          <IonLabel className="ewp-detail-card-truck-detail-title-label ewp-paragraph ion-no-padding ion-no-margin">
                            <b>Make:</b>
                          </IonLabel>
                          <EWPLabel
                            loading={_.isEmpty(cardData)}
                            text={
                              !_.isEmpty(cardData)
                                ? ` ${
                                    !_.isEmpty(truck.make) ? truck.make : "N/A"
                                  }`
                                : "Loading..."
                            }
                            className="ewp-detail-card-truck-detail-label ewp-paragraph ion-no-padding ion-no-margin"
                          />
                        </div>
                        <div className="ewp-detail-truck-label-container">
                          <IonLabel className="ewp-detail-card-truck-detail-title-label ewp-paragraph ion-no-padding ion-no-margin">
                            <b>Model:</b>
                          </IonLabel>
                          <EWPLabel
                            loading={_.isEmpty(cardData)}
                            text={
                              !_.isEmpty(cardData)
                                ? ` ${
                                    !_.isEmpty(truck.model)
                                      ? truck.model
                                      : "N/A"
                                  }`
                                : "Loading..."
                            }
                            className="ewp-detail-card-truck-detail-label ewp-paragraph ion-no-padding ion-no-margin"
                          />
                        </div>

                        <div className="ewp-detail-truck-label-container">
                          <IonLabel className="ewp-detail-card-truck-detail-title-label ewp-paragraph ion-no-padding ion-no-margin">
                            <b>Year:</b>
                          </IonLabel>
                          <EWPLabel
                            loading={_.isEmpty(cardData)}
                            text={
                              !_.isEmpty(cardData)
                                ? ` ${
                                    !_.isEmpty(truck.year) ? truck.year : "N/A"
                                  }`
                                : "Loading..."
                            }
                            className="ewp-detail-card-truck-detail-label ewp-paragraph ion-no-padding ion-no-margin"
                          />
                        </div>
                      </IonCol>
                      <IonCol
                        size="6"
                        className="ewp-detail-card-truck-col ion-no-padding ion-no-margin"
                      >
                        <div className="ewp-detail-truck-label-container">
                          <IonLabel className="ewp-detail-card-truck-detail-title-label ewp-paragraph ion-no-padding ion-no-margin">
                            <b>Rego:</b>
                          </IonLabel>
                          <EWPLabel
                            loading={_.isEmpty(cardData)}
                            text={
                              !_.isEmpty(cardData)
                                ? ` ${
                                    !_.isEmpty(truck.regoNumber)
                                      ? truck.regoNumber
                                      : "N/A"
                                  }`
                                : "Loading..."
                            }
                            className="ewp-detail-card-truck-detail-label ewp-paragraph ion-no-padding ion-no-margin"
                          />
                        </div>
                        <div className="ewp-detail-truck-label-container">
                          <IonLabel className="ewp-detail-card-truck-detail-title-label ewp-paragraph ion-no-padding ion-no-margin">
                            <b>VIN:</b>
                          </IonLabel>
                          <EWPLabel
                            loading={_.isEmpty(cardData)}
                            text={
                              !_.isEmpty(cardData)
                                ? ` ${
                                    !_.isEmpty(truck.vinNumber)
                                      ? truck.vinNumber
                                      : "N/A"
                                  }`
                                : "Loading..."
                            }
                            className="ewp-detail-card-truck-detail-label ewp-paragraph ion-no-padding ion-no-margin"
                          />
                        </div>
                        <div className="ewp-detail-truck-label-container">
                          <IonLabel className="ewp-detail-card-truck-detail-title-label text-no-wrap ewp-paragraph ion-no-padding ion-no-margin">
                            <b>RMS ID:</b>
                          </IonLabel>
                          <EWPLabel
                            loading={_.isEmpty(cardData)}
                            text={
                              !_.isEmpty(cardData)
                                ? ` ${
                                    !_.isEmpty(truck.rmsTransportNumber)
                                      ? truck.rmsTransportNumber
                                      : "N/A"
                                  }`
                                : "Loading..."
                            }
                            className="ewp-detail-card-truck-detail-label ewp-paragraph ion-no-padding ion-no-margin"
                          />
                        </div>
                        <div className="ewp-detail-truck-label-container">
                          <IonLabel className="ewp-detail-card-truck-detail-title-label ewp-paragraph ion-no-padding ion-no-margin">
                            <b>Depot:</b>
                          </IonLabel>
                          <EWPLabel
                            loading={_.isEmpty(cardData)}
                            text={
                              !_.isEmpty(cardData)
                                ? ` ${
                                    !!truck.depotName &&
                                    !_.isEmpty(truck.depotName)
                                      ? truck.depotName
                                      : "N/A"
                                  }`
                                : "Loading..."
                            }
                            className="ewp-detail-card-truck-detail-label ewp-paragraph ion-no-padding ion-no-margin"
                          />
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                )}
              </div>
            </IonCol>
            {mode === "truck" && (
              <IonCol
                size="5"
                className="ewp-detail-card-sub-detail-col ion-no-padding ion-no-margin"
              >
                <div className="ewp-detail-card-col-container">
                  <div className="ewp-detail-contents-container">
                    <>
                      <IonLabel className="ewp-detail-card-truck-title ewp-h3 ion-no-padding ion-no-margin">
                        Truck Description
                      </IonLabel>
                      <EWPLabel
                        loading={_.isEmpty(cardData)}
                        text={
                          !_.isEmpty(cardData)
                            ? `${
                                !_.isEmpty(truck.description)
                                  ? truck.description
                                  : "N/A"
                              }`
                            : "Loading..."
                        }
                        className={`ewp-detail-card-truck-description ${
                          isTablet() ? "tablet" : ""
                        } ewp-paragraph ion-no-padding ion-no-margin`}
                      />
                    </>
                    {/* {mode === "client" ? (
                    <>
                      <IonLabel className="ewp-detail-card-contact-person-label ewp-h3 ion-no-padding ion-no-margin">
                        Contact Person
                      </IonLabel>
                      <div className="ewp-icon-label-container">
                        <IonIcon className="contact-person-icon person" />
                        <EWPLabel
                          loading={_.isNull(cardData)}
                          loadingWidth={60}
                          text={
                            !_.isEmpty(cardData)
                              ? !_.isEmpty(client.contactPeople)
                                ? `${client.contactPeople[0].firstName} ${client.contactPeople[0].lastName}`
                                : "N/A"
                              : "Loading..."
                          }
                          className="ewp-paragraph ion-no-padding ion-no-margin"
                        />
                      </div>

                      <div className="ewp-icon-label-container">
                        <IonIcon className="contact-person-icon number" />
                        <EWPLabel
                          loading={_.isNull(cardData)}
                          loadingWidth={70}
                          text={
                            !_.isEmpty(cardData)
                              ? !_.isEmpty(client.contactPeople)
                                ? client.contactPeople[0].phoneNumber
                                : "N/A"
                              : "Loading..."
                          }
                          className="ewp-paragraph ion-no-padding ion-no-margin"
                        />
                      </div>
                      <div className="ewp-icon-label-container">
                        <IonIcon className="contact-person-icon email" />
                        <EWPLabel
                          loading={_.isNull(cardData)}
                          loadingWidth={65}
                          text={
                            !_.isEmpty(cardData)
                              ? !_.isEmpty(client.contactPeople)
                                ? client.contactPeople[0].emailAddress
                                : "N/A"
                              : "Loading..."
                          }
                          className="ewp-paragraph ion-no-padding ion-no-margin"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <IonLabel className="ewp-detail-card-truck-title ewp-h3 ion-no-padding ion-no-margin">
                        Truck Description
                      </IonLabel>
                      <EWPLabel
                        loading={_.isEmpty(cardData)}
                        text={
                          !_.isEmpty(cardData)
                            ? `${
                                !_.isEmpty(truck.description)
                                  ? truck.description
                                  : "N/A"
                              }`
                            : "Loading..."
                        }
                        className={`ewp-detail-card-truck-description ${
                          isTablet() ? "tablet" : ""
                        } ewp-paragraph ion-no-padding ion-no-margin`}
                      />
                    </>
                  )} */}
                  </div>
                </div>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};
