import * as _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import {
  IonLabel,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCardHeader,
  IonButton,
  IonIcon,
  IonSpinner,
  IonToast,
} from "@ionic/react";

import "./VOCCard.scss";
import { isTablet } from "../../functions/common";
import { AttachmentDialog } from "../Web/AttachmentDialog/AttachmentDialog";
import { DriverVOCView } from "../../models/drivers";
import { EWPProps } from "../../config/global";
import { VOCDialog } from "../VOCDialog/VOCDialog";
import { EWPCOLORS } from "../../constants/config";

interface VOCCardProps extends EWPProps {
  vocData: DriverVOCView[] | null;
  mode: "driver" | "truck";
  driverId?: string;
  truckId?: string;
}
export const VOCCard = (props: VOCCardProps) => {
  const { vocData, mode, driverId } = props;
  const [showVOCDialog, setShowVOCDialog] = useState(false);
  const [selectedVOC, setSelectedVOC] = useState(null as null | DriverVOCView);
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);
  const [error, setError] = useState("");
  return (
    <>
      <IonCard
        className={`voc-card ${
          isTablet() ? "tablet" : ""
        } ion-no-padding ion-no-margin`}
        color={EWPCOLORS.tertiary}
      >
        <IonCardContent className="voc-card-content ion-no-padding ion-no-margin">
          <IonCardHeader className="voc-card-header ion-no-padding ion-no-margin">
            <IonLabel className="voc-card-header-label ewp-h4 bold ion-no-padding ion-no-margin">
              VOC
            </IonLabel>
            {mode === "driver" && (
              <IonButton
                mode="ios"
                fill="clear"
                color={EWPCOLORS.primary}
                className="voc-card-header-add-voc-button ewp-h5 ion-no-padding ion-no-margin"
                onClick={() => {
                  setShowVOCDialog(true);
                }}
              >
                <u>Add VOC</u>
              </IonButton>
            )}
          </IonCardHeader>
          <IonGrid className="voc-card-grid ion-no-padding ion-no-margin">
            <IonRow
              className={`voc-card-row-content-header ${
                _.isEmpty(vocData) && "no-border-bottom"
              } ion-no-padding ion-no-margin`}
            >
              <IonCol
                className="ion-no-padding ion-text-start"
                size={isTablet() ? "8" : "9"}
              >
                <IonLabel className="ewp-h6 medium bold ion-text-uppercase ion-text-start">
                  {mode === "driver" ? "TRUCK NAME" : "DRIVER NAME"}
                </IonLabel>
              </IonCol>
              <IonCol
                className="ion-no-padding ion-text-start"
                size={isTablet() ? "3" : "2"}
              >
                <IonLabel className="ewp-h6 medium bold ion-text-uppercase ion-text-start">
                  DATE ISSUED
                </IonLabel>
              </IonCol>
              <IonCol className="ion-no-padding ion-text-start" size="1">
                {/* for button container */}
              </IonCol>
            </IonRow>

            {!!vocData ? (
              _.isEmpty(vocData) ? (
                <IonLabel className="no-data-label ewp-h4 light bold">
                  No Data
                </IonLabel>
              ) : (
                vocData.map((voc, vocIndex) => {
                  return (
                    <IonRow
                      key={`voc-index-row-${vocIndex}`}
                      className="voc-card-row-content-data ion-no-padding ion-no-margin"
                    >
                      <IonCol
                        className="ion-no-padding ion-text-start"
                        size={isTablet() ? "8" : "9"}
                      >
                        <IonLabel className="ewp-h6 medium ion-text-start">
                          {mode === "driver"
                            ? voc.truckDetails.name
                            : `${voc.driverDetails.firstName} ${voc.driverDetails.lastName} `}
                        </IonLabel>
                      </IonCol>
                      <IonCol
                        className="ion-no-padding ion-text-start"
                        size={isTablet() ? "3" : "2"}
                      >
                        <IonLabel className="ewp-h6 medium ion-text-start">
                          {moment(voc.dateIssued.toDate()).format(
                            "DD MMM YYYY"
                          )}
                        </IonLabel>
                      </IonCol>
                      <IonCol
                        className="ion-no-padding ion-text-start"
                        size="1"
                      >
                        <div className="voc-card-buttons-container">
                          {!_.isEmpty(voc.attachments) && (
                            <IonButton
                              fill="clear"
                              mode="ios"
                              className="voc-attachment-button ewp-web-gray-button ion-no-padding ion-no-margin"
                              onClick={() => {
                                setShowAttachmentDialog(true);
                                setSelectedVOC(voc);
                                if (_.isEmpty(voc.attachments)) {
                                  setError("No attachements");
                                }
                              }}
                            >
                              <IonIcon className="voc-attachment-icon" />
                            </IonButton>
                          )}
                          {mode !== "truck" && (
                            <IonButton
                              fill="clear"
                              mode="ios"
                              className="voc-edit-button ewp-web-gray-button ion-no-padding ion-no-margin"
                              onClick={() => {
                                setShowVOCDialog(true);
                                setSelectedVOC(voc);
                              }}
                            >
                              <IonIcon className="voc-edit-icon" />
                            </IonButton>
                          )}
                        </div>
                      </IonCol>
                    </IonRow>
                  );
                })
              )
            ) : (
              <div className="ewp-table-spinner-container">
                <IonSpinner className="ewp-spinner" color={EWPCOLORS.primary} />
              </div>
            )}
          </IonGrid>
        </IonCardContent>
      </IonCard>

      {!!selectedVOC &&
        !!selectedVOC.attachments &&
        !_.isEmpty(selectedVOC.attachments) && (
          <AttachmentDialog
            title="VOC"
            attachments={selectedVOC.attachments.map((attachmentUrl) => {
              return {
                attachmentUrl,
              };
            })}
            showModal={showAttachmentDialog}
            onModalDidDismiss={() => {
              setShowAttachmentDialog(false);
              // setSelectedVOC(null);
            }}
          />
        )}
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => {
          setError("");
        }}
        color={EWPCOLORS.danger}
      />

      {!!driverId && driverId !== undefined && !_.isEmpty(driverId) && (
        <VOCDialog
          {...props}
          isOpen={showVOCDialog}
          driverId={driverId}
          onDidDismiss={() => {
            setShowVOCDialog(false);
          }}
          editData={selectedVOC as DriverVOCView}
        />
      )}
    </>
  );
};
