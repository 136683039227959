import React from "react";
import { chevronBackOutline } from "ionicons/icons";
import { IonLabel, IonIcon, IonButton } from "@ionic/react";

import "./EWPFormPage.scss";
// import * as routes from "../../constants/routes";
import { EWPProps } from "../../config/global";

interface EWPFormPageProps extends EWPProps {
  pageTitle: string;
  children?: any;
  backURL: string;
}

export const EWPFormPage = (props: EWPFormPageProps) => {
  // const [isToggleButtonChecked, setIsToggleButtonChecked] = useState(false);

  const { pageTitle, backURL, children } = props;

  return (
    <div className="ewp-form-page-container ion-no-margin ion-no-padding">
      <div className="ewp-form-page-back-title-container">
        <IonButton
          fill="solid"
          className="ewp-form-page-back-button"
          routerLink={backURL}
        >
          <IonIcon
            icon={chevronBackOutline}
            mode="ios"
            className="ewp-form-page-container-back-icon"
          />
        </IonButton>
        <IonLabel className="ewp-h2 ion-no-padding ion-padding-end">
          {pageTitle}
        </IonLabel>
      </div>
      <div className="ewp-form-page-children-container">{children}</div>
    </div>
  );
};
