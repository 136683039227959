import * as _ from "lodash";
import Bugsnag from "@bugsnag/js";
import { E164Number } from "libphonenumber-js";

import * as capacitorStorage from "../functions/localStorageCapacitor";
import { firestore, auth } from "../firebase";
import { getServerTimestamp } from "../functions/common";
import { Depot, JobType, User, JOBS_WITH_KEYS } from "../models";
import {
  Driver,
  DriverExpiry,
  ExpiryReference,
  TruckExpiry,
  Truck,
  TruckServiceHistory,
  TruckWithDepot,
  DriverVOC,
  DriverVOCView,
  Inductions,
  DriverNotes,
  TruckNotes,
  DriverChangeLogDetails,
  DriverChangeLog,
  TruckChangeLogDetails,
  TruckChangeLog,
  DriverExpiryWithDateView,
  TruckExpiryWithDateView,
  DriverInductionView,
  TruckInfo,
  DriverInfo,
} from "../models/drivers";
import { LOCAL_STORAGE } from "../config";
import {
  NON_REMOVEABLE_DRIVER_EXPIRIES,
  NON_REMOVEABLE_TRUCK_EXPIRIES,
} from "../constants/config";
import {
  DEPOTS,
  DRIVERS,
  DRIVER_EXPIRY,
  TRUCK_EXPIRY,
  TRUCKS,
  TRUCK_SERVICE_HISTORIES,
  DRIVERS_VOC,
  INDUCTIONS,
  DRIVER_NOTES,
  TRUCK_NOTES,
  DRIVER_CHANGE_LOGS,
  TRUCK_CHANGE_LOGS,
  ADMINS,
} from "../constants/dbCollections";
import moment from "moment";

interface LoginResponse {
  result: boolean;
  error: string;
}

export const getDriverExpiriesForSearch = (
  callback: (list: DriverExpiryWithDateView[], error?: string) => void
) => {
  try {
    firestore.collection(DRIVERS).onSnapshot(
      async (query) => {
        if (!query.empty) {
          const expiryReminder = await firestore
            .collection(DRIVER_EXPIRY)
            .get();

          if (!expiryReminder.empty) {
            const expiryReminderList = expiryReminder.docs.map((expiry) => {
              return {
                docId: expiry.id,
                ...expiry.data(),
              } as DriverExpiry;
            });
            const drivers = _.filter(
              query.docs.map((driver) => {
                return {
                  docId: driver.id,
                  ...driver.data(),
                } as Driver;
              }),
              (driver) => !driver.archived
            );

            const result = [] as DriverExpiryWithDateView[];

            drivers.forEach((driver) => {
              expiryReminderList.forEach((reminder) => {
                const driverExpiryWithDate = _.find(
                  driver.driverExpiries || [],
                  (driverExpiry) => driverExpiry.expiryId === reminder.docId
                );

                result.push({
                  driverName: `${driver.firstName} ${driver.lastName}`,
                  driverId: driver.docId as string,
                  expiryId: reminder.docId || "",
                  name: reminder.name,
                  expiryReminder: reminder.expiryReminder, // weeks
                  expiryDate: !_.isEmpty(driverExpiryWithDate)
                    ? driverExpiryWithDate!.expirationDate
                    : null,
                  driverExpiryDate:
                    !_.isEmpty(driverExpiryWithDate) &&
                    !_.isNull(driverExpiryWithDate!.expirationDate)
                      ? moment(
                          driverExpiryWithDate!.expirationDate.toDate()
                        ).format("DD MMM YYYY")
                      : "N/A",
                  attachments:
                    !!driverExpiryWithDate &&
                    !_.isEmpty(driverExpiryWithDate.attachments)
                      ? (driverExpiryWithDate.attachments as string[])
                      : [],
                });
              });
            });

            callback(result);
          } else {
            callback([] as DriverExpiryWithDateView[]);
          }
        } else {
          callback([] as DriverExpiryWithDateView[]);
        }
      },
      (error) => {
        callback([] as DriverExpiryWithDateView[], error.message);
        Bugsnag.notify(new Error(error.message));
      }
    );
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN getDriverExpiriesForSearch: ", e);
    callback([] as DriverExpiryWithDateView[], e);
    Bugsnag.notify(new Error(e));
  }
};

export const getDriverInductionsForSearch = (
  callback: (list: DriverInductionView[], error?: string) => void
) => {
  try {
    const unsubscribe = firestore.collection(INDUCTIONS).onSnapshot(
      async (snapshot) => {
        if (!snapshot.empty) {
          const inductionsFormattedView = (await Promise.all(
            snapshot.docs.map((driverInduction) => {
              return new Promise(async (resolve) => {
                const driverInductionData = {
                  docId: driverInduction.id,
                  ...driverInduction.data(),
                } as Inductions;

                const driverDetailsQuery = await firestore
                  .collection(DRIVERS)
                  .doc(driverInductionData.driverId)
                  .get();

                if (driverDetailsQuery.exists) {
                  resolve({
                    ...driverInductionData,
                    driverName: `${
                      (driverDetailsQuery.data() as Driver).firstName
                    } ${(driverDetailsQuery.data() as Driver).lastName}`,
                    inductionDateIssued: !!driverInductionData.dateIssued
                      ? moment(driverInductionData.dateIssued.toDate()).format(
                          "DD MMM YYYY"
                        )
                      : "N/A",
                    inductionExpiryDate: !!driverInductionData.expiryDate
                      ? moment(driverInductionData.expiryDate.toDate()).format(
                          "DD MMM YYYY"
                        )
                      : "N/A",
                  } as DriverInductionView);
                } else {
                  resolve(null);
                }
              });
            })
          )) as DriverInductionView[];

          callback(_.compact(inductionsFormattedView));
        } else {
          callback([]);
        }
      },
      (error) => {
        callback([], error.message);
        Bugsnag.notify(new Error(error.message));
      }
    );
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    // console.log("ERRR IN getDriverInductionsForSearch: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const getTruckExpiriesForSearch = (
  callback: (list: TruckExpiryWithDateView[], error?: string) => void
) => {
  try {
    firestore.collection(TRUCKS).onSnapshot(
      async (query) => {
        if (!query.empty) {
          const expiryReminder = await firestore.collection(TRUCK_EXPIRY).get();

          if (!expiryReminder.empty) {
            const expiryReminderList = expiryReminder.docs.map((expiry) => {
              return {
                docId: expiry.id,
                ...expiry.data(),
              } as TruckExpiry;
            });
            const trucks = _.filter(
              query.docs.map((truck) => {
                return {
                  docId: truck.id,
                  ...truck.data(),
                } as Truck;
              }),
              (driver) => !driver.archived
            );

            const result = [] as TruckExpiryWithDateView[];

            trucks.forEach((truck) => {
              expiryReminderList.forEach((reminder) => {
                const truckExpiryWithDate = _.find(
                  truck.truckExpiries || [],
                  (truckExpiry) => truckExpiry.expiryId === reminder.docId
                );

                result.push({
                  truckName: truck.name,
                  truckId: truck.docId as string,
                  expiryId: reminder.docId || "",
                  name: reminder.name,
                  expiryReminder: reminder.expiryReminder, // weeks
                  expiryDate: !_.isEmpty(truckExpiryWithDate)
                    ? truckExpiryWithDate!.expirationDate
                    : null,
                  truckExpiryDate:
                    !_.isEmpty(truckExpiryWithDate) &&
                    !_.isNull(truckExpiryWithDate!.expirationDate)
                      ? moment(
                          truckExpiryWithDate!.expirationDate.toDate()
                        ).format("DD MMM YYYY")
                      : "N/A",
                  attachments:
                    !!truckExpiryWithDate &&
                    !_.isEmpty(truckExpiryWithDate.attachments)
                      ? (truckExpiryWithDate.attachments as string[])
                      : [],
                });
              });
            });

            callback(result);
          } else {
            callback([] as TruckExpiryWithDateView[]);
          }
        } else {
          callback([] as TruckExpiryWithDateView[]);
        }
      },
      (error) => {
        callback([] as TruckExpiryWithDateView[], error.message);
        Bugsnag.notify(new Error(error.message));
      }
    );
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN getTruckExpiriesForSearch: ", e);
    callback([] as TruckExpiryWithDateView[], e);
    Bugsnag.notify(new Error(e));
  }
};

export const getTruckInfoForSearch = (
  callback: (list: TruckInfo[], error?: string) => void
) => {
  try {
    firestore.collection(TRUCKS).onSnapshot(
      async (query) => {
        if (!query.empty) {
          const truckFormattedView = (await Promise.all(
            query.docs.map((truck) => {
              return new Promise(async (resolve) => {
                const trucksData = {
                  ...truck.data(),
                  docId: truck.id,
                } as Truck;

                const depotQuery = await firestore
                  .collection(DEPOTS)
                  .doc(trucksData.depotId)
                  .get();

                if (depotQuery.exists) {
                  resolve({
                    ...trucksData,
                    depotName: (depotQuery.data() as Depot).name,
                    typeName: JOBS_WITH_KEYS[trucksData.type].name,
                  } as TruckInfo);
                } else {
                  resolve({
                    ...trucksData,
                    depotName: "N/A",
                    typeName: JOBS_WITH_KEYS[trucksData.type].name,
                  } as TruckInfo);
                }
              });
            })
          )) as TruckInfo[];

          callback(_.compact(truckFormattedView));
        } else {
          callback([]);
        }
      },
      (error) => {
        callback([] as TruckInfo[], error.message);
        Bugsnag.notify(new Error(error.message));
      }
    );
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN getTruckExpiriesForSearch: ", e);
    callback([] as TruckInfo[], e);
    Bugsnag.notify(new Error(e));
  }
};

export const getDriverInfoForSearch = (
  callback: (list: DriverInfo[], error?: string) => void
) => {
  try {
    firestore.collection(DRIVERS).onSnapshot(
      async (query) => {
        if (!query.empty) {
          const driverFormattedView = (await Promise.all(
            query.docs.map((driver) => {
              return new Promise(async (resolve) => {
                const driverData = {
                  ...driver.data(),
                  docId: driver.id,
                } as Driver;

                const depotQuery = await firestore
                  .collection(DEPOTS)
                  .doc(driverData.depotId)
                  .get();
                let truckName = "N/A" as string;
                if (
                  !!driverData.truckPreferenceId &&
                  !_.isEmpty(driverData.truckPreferenceId)
                ) {
                  const truckQuery = await firestore
                    .collection(TRUCKS)
                    .doc(driverData.truckPreferenceId)
                    .get();
                  if (truckQuery.exists) {
                    truckName = (truckQuery.data() as Truck).name;
                  }
                }
                if (depotQuery.exists) {
                  resolve({
                    ...driverData,
                    depotName: (depotQuery.data() as Depot).name,
                    truckPreferenceName: truckName,
                    name: `${driverData.firstName} ${driverData.lastName}`,
                  } as DriverInfo);
                } else {
                  resolve({
                    ...driverData,
                    depotName: "N/A",
                    truckPreferenceName: truckName,
                    name: `${driverData.firstName} ${driverData.lastName}`,
                  } as DriverInfo);
                }
              });
            })
          )) as DriverInfo[];

          callback(_.compact(driverFormattedView));
        } else {
          callback([]);
        }
      },
      (error) => {
        callback([] as DriverInfo[], error.message);
        Bugsnag.notify(new Error(error.message));
      }
    );
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN getDriverInfoForSearch: ", e);
    callback([] as DriverInfo[], e);
    Bugsnag.notify(new Error(e));
  }
};

export const addDriverChangeLogs = async (
  driverId: string,
  description: string,
  updatedBy: string
) => {
  await firestore.collection(DRIVER_CHANGE_LOGS).add({
    driverId,
    description,
    updatedBy,
    updatedDt: getServerTimestamp(),
  });
};

export const addTruckChangeLogs = async (
  truckId: string,
  description: string,
  updatedBy: string
) => {
  await firestore.collection(TRUCK_CHANGE_LOGS).add({
    truckId,
    description,
    updatedBy,
    updatedDt: getServerTimestamp(),
  });
};

export const getDriverChangeLogs = async (
  driverId: string,
  callback: (changeLogs: DriverChangeLogDetails[], error?: string) => void
) => {
  try {
    const unsubscribe = firestore
      .collection(DRIVER_CHANGE_LOGS)
      .where("driverId", "==", driverId)
      .onSnapshot(
        async (changeLogs) => {
          if (!changeLogs.empty) {
            const result = await Promise.all(
              changeLogs.docs.map((changeLog) => {
                return new Promise(async (resolve) => {
                  const changeLogData = changeLog.data() as DriverChangeLog;
                  const person = await firestore
                    .collection(ADMINS)
                    .doc(changeLogData.updatedBy)
                    .get();
                  if (person.exists) {
                    const personData = person.data() as User;

                    resolve({
                      ...changeLogData,
                      person: `${personData.firstName} ${personData.lastName}`,
                    } as DriverChangeLogDetails);
                  } else {
                    resolve(null);
                  }
                }) as Promise<null | DriverChangeLogDetails>;
              })
            );
            callback(
              _.orderBy(
                _.compact(result),
                (log, index) =>
                  !_.isNull(log.updatedDt) ? log.updatedDt.toDate() : index,
                "desc"
              )
            );
          } else {
            callback([]);
          }
        },
        (error) => {
          callback([], error.message);
          Bugsnag.notify(new Error(error.message));
        }
      );

    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const getTruckChangeLogs = async (
  truckId: string,
  callback: (changeLogs: TruckChangeLogDetails[], error?: string) => void
) => {
  try {
    const unsubscribe = firestore
      .collection(TRUCK_CHANGE_LOGS)
      .where("truckId", "==", truckId)
      .onSnapshot(
        async (changeLogs) => {
          if (!changeLogs.empty) {
            const result = await Promise.all(
              changeLogs.docs.map((changeLog) => {
                return new Promise(async (resolve) => {
                  const changeLogData = changeLog.data() as TruckChangeLog;
                  const person = await firestore
                    .collection(ADMINS)
                    .doc(changeLogData.updatedBy)
                    .get();
                  if (person.exists) {
                    const personData = person.data() as User;

                    resolve({
                      ...changeLogData,
                      person: `${personData.firstName} ${personData.lastName}`,
                    } as TruckChangeLogDetails);
                  } else {
                    resolve(null);
                  }
                }) as Promise<null | TruckChangeLogDetails>;
              })
            );
            callback(
              _.orderBy(
                _.compact(result),
                (log, index) =>
                  !_.isNull(log.updatedDt) ? log.updatedDt.toDate() : index,
                "desc"
              )
            );
          } else {
            callback([]);
          }
        },
        (error) => {
          callback([], error.message);
          Bugsnag.notify(new Error(error.message));
        }
      );

    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const addInduction = async (
  driverId: string,
  name: string,
  dateIssued: Date,
  expiryDate: null | Date,
  notes = "",
  attachments?: string[]
) => {
  try {
    const createdBy = auth.currentUser;
    if (!_.isNull(createdBy)) {
      await firestore.collection(INDUCTIONS).add({
        driverId,
        name,
        dateIssued,
        expiryDate,
        notes,
        attachments,
        createdBy: createdBy.uid,
        createdDt: getServerTimestamp(),
      });
      await addDriverChangeLogs(
        driverId,
        `New induction added - "${name}"`,
        createdBy.uid
      );
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN createTruck: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const updateInduction = async (
  docId: string,
  name: string,
  dateIssued: Date,
  expiryDate: null | Date,
  notes = "",
  driverId: string,
  attachments?: string[]
) => {
  try {
    const updatedBy = auth.currentUser;
    if (!_.isNull(updatedBy)) {
      await firestore.collection(INDUCTIONS).doc(docId).update({
        name,
        dateIssued,
        expiryDate,
        notes,
        attachments,
        updatedBy: updatedBy.uid,
        updatedDt: getServerTimestamp(),
      });

      await addDriverChangeLogs(
        driverId,
        `Induction updated - "${name}"`,
        updatedBy.uid
      );
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN updateTruck: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const getInductionsRealTime = (
  driverId: string,
  callback: (inductions: Inductions[], error?: string) => void
) => {
  try {
    const unsubscribe = firestore
      .collection(INDUCTIONS)
      .where("driverId", "==", driverId)
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          callback(
            snapshot.docs.map((driverQuery) => {
              const induction = {
                docId: driverQuery.id,
                ...driverQuery.data(),
              } as Inductions;

              return induction;
            })
          );
        } else {
          callback([]);
        }
      });
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERRR IN getInductionsRealTime: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const createNonRemovableExpiry = async () => {
  try {
    const createdBy = auth.currentUser;
    if (!_.isNull(createdBy)) {
      const existingNonRemovable = await firestore
        .collection(DRIVER_EXPIRY)
        .doc(NON_REMOVEABLE_DRIVER_EXPIRIES[0].docId)
        .get();

      if (!existingNonRemovable.exists) {
        await Promise.all(
          NON_REMOVEABLE_DRIVER_EXPIRIES.map((licence) => {
            return firestore.collection(DRIVER_EXPIRY).doc(licence.docId).set({
              name: licence.name,
              expiryReminder: licence.expiryReminder,
              createdBy: createdBy.uid,
              createdDt: getServerTimestamp(),
            });
          })
        );
      }
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN createNonRemovableExpiry: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const createNonRemovableTruckExpiry = async () => {
  try {
    const createdBy = auth.currentUser;
    if (!_.isNull(createdBy)) {
      const existingNonRemovable = await firestore
        .collection(TRUCK_EXPIRY)
        .doc(NON_REMOVEABLE_TRUCK_EXPIRIES[0].docId)
        .get();

      if (!existingNonRemovable.exists) {
        await Promise.all(
          NON_REMOVEABLE_TRUCK_EXPIRIES.map((licence) => {
            return firestore.collection(TRUCK_EXPIRY).doc(licence.docId).set({
              name: licence.name,
              expiryReminder: licence.expiryReminder,
              createdBy: createdBy.uid,
              createdDt: getServerTimestamp(),
            });
          })
        );
      }
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN createNonRemovableTruckExpiry: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const createDriverExpiry = async (
  name: string,
  expiryReminder: number
) => {
  try {
    const createdBy = auth.currentUser;
    if (!_.isNull(createdBy)) {
      await firestore.collection(DRIVER_EXPIRY).add({
        name,
        expiryReminder,
        createdBy: createdBy.uid,
        createdDt: getServerTimestamp(),
      });

      const drivers = await getDrivers();

      await Promise.all(
        drivers.map((driver) => {
          return addDriverChangeLogs(
            driver.docId || "",
            `New expiry added - "${name}"`,
            createdBy.uid
          );
        })
      );
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN createDriver: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const createTruckExpiry = async (
  name: string,
  expiryReminder: number
) => {
  try {
    const createdBy = auth.currentUser;
    if (!_.isNull(createdBy)) {
      await firestore.collection(TRUCK_EXPIRY).add({
        name,
        expiryReminder,
        createdBy: createdBy.uid,
        createdDt: getServerTimestamp(),
      });

      const trucks = await getTrucks();

      await Promise.all(
        trucks.map((truck) => {
          return addTruckChangeLogs(
            truck.docId || "",
            `New expiry added - "${name}"`,
            createdBy.uid
          );
        })
      );
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN createDriver: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const updateDriverExpiry = async (
  docId: string,
  name: string,
  expiryReminder: number
) => {
  try {
    const updatedBy = auth.currentUser;
    if (!_.isNull(updatedBy)) {
      await firestore.collection(DRIVER_EXPIRY).doc(docId).update({
        name,
        expiryReminder,
        updatedBy: updatedBy.uid,
        updatedDt: getServerTimestamp(),
      });

      const drivers = await getDrivers();

      await Promise.all(
        drivers.map((driver) => {
          return addDriverChangeLogs(
            driver.docId || "",
            `Expiry updated - "${name}"`,
            updatedBy.uid
          );
        })
      );
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN createDriver: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const updateTruckExpiry = async (
  docId: string,
  name: string,
  expiryReminder: number
) => {
  try {
    const updatedBy = auth.currentUser;
    if (!_.isNull(updatedBy)) {
      await firestore.collection(TRUCK_EXPIRY).doc(docId).update({
        name,
        expiryReminder,
        updatedBy: updatedBy.uid,
        updatedDt: getServerTimestamp(),
      });

      const trucks = await getTrucks();

      await Promise.all(
        trucks.map((truck) => {
          return addTruckChangeLogs(
            truck.docId || "",
            `Expiry Updated - "${name}"`,
            updatedBy.uid
          );
        })
      );
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN createDriver: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const deleteDriverExpiry = async (docId: string, name: string) => {
  const updatedBy = auth.currentUser;
  await firestore.collection(DRIVER_EXPIRY).doc(docId).delete();

  const drivers = await getDrivers();

  await Promise.all(
    drivers.map((driver) => {
      return addDriverChangeLogs(
        driver.docId || "",
        `Expiry deleted - "${name}"`,
        !_.isEmpty(updatedBy) ? updatedBy!.uid : ""
      );
    })
  );
};

export const deleteTruckExpiry = async (docId: string, name: string) => {
  const updatedBy = auth.currentUser;
  await firestore.collection(TRUCK_EXPIRY).doc(docId).delete();

  const trucks = await getTrucks();

  await Promise.all(
    trucks.map((truck) => {
      return addTruckChangeLogs(
        truck.docId || "",
        `Expiry deleted - "${name}"`,
        !_.isEmpty(updatedBy) ? updatedBy!.uid : ""
      );
    })
  );
};

export const getDriverExpiryReminders = (
  callback: (driversExpiryReminder: DriverExpiry[], error?: string) => void
) => {
  try {
    const unsubscribe = firestore.collection(DRIVER_EXPIRY).onSnapshot(
      (snapshot) => {
        if (!snapshot.empty) {
          callback(
            snapshot.docs.map((expiryQuery) => {
              const driverExpiry = {
                docId: expiryQuery.id,
                ...expiryQuery.data(),
              } as DriverExpiry;

              return driverExpiry;
            })
          );
        } else {
          callback([]);
        }
      },
      (error) => {
        callback([], error.message);
        Bugsnag.notify(new Error(error.message));
      }
    );
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERRR IN getDriverExpiryReminders: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const getTruckExpiryReminders = (
  callback: (trucksExpiryReminder: TruckExpiry[], error?: string) => void
) => {
  try {
    const unsubscribe = firestore.collection(TRUCK_EXPIRY).onSnapshot(
      (snapshot) => {
        if (!snapshot.empty) {
          callback(
            snapshot.docs.map((expiryQuery) => {
              const truckExpiry = {
                docId: expiryQuery.id,
                ...expiryQuery.data(),
              } as TruckExpiry;

              return truckExpiry;
            })
          );
        } else {
          callback([]);
        }
      },
      (error) => {
        callback([], error.message);
        Bugsnag.notify(new Error(error.message));
      }
    );
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERRR IN getTruckExpiryReminders: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const driverLogin = async (pin: string): Promise<LoginResponse> => {
  try {
    const driver = await firestore
      .collection(DRIVERS)
      .where("password", "==", pin)
      .get();

    if (!driver.empty) {
      if (driver.docs.length === 1) {
        await capacitorStorage.setItem(
          LOCAL_STORAGE.loggedInDriver,
          driver.docs[0].id
        );

        return {
          result: true,
          error: "",
        };
      } else {
        return {
          result: false,
          error: "Duplicate driver found! please coordinate with the admin.",
        };
      }
    } else {
      return {
        result: false,
        error: "No driver found.",
      };
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    return {
      result: false,
      error,
    };
  }
};

export const driverLogout = async () => {
  await capacitorStorage.removeItem(LOCAL_STORAGE.loggedInDriver);
};

export const createDriver = async (
  firstName: string,
  lastName: string,
  emailAddress: string,
  phoneNumber: E164Number | null | string,
  // truckPreferenceId: string,
  depotId: string,
  licenceNumber: string,
  password: string,
  hasFirstAid: boolean,
  hasDefibrillator: boolean
) => {
  try {
    const createdBy = auth.currentUser;
    if (!_.isNull(createdBy)) {
      await firestore.collection(DRIVERS).add({
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
        // truckPreferenceId,
        licenceNumber,
        depotId,
        password,
        hasFirstAid,
        hasDefibrillator,
        createdBy: createdBy.uid,
        createdDt: getServerTimestamp(),
      });
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN createDriver: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const isPinExisting = async (pin: string) => {
  const existingPin = await firestore
    .collection(DRIVERS)
    .where("password", "==", pin)
    .get();
  return !existingPin.empty;
};

export const updateDriver = async (
  docId: string,
  firstName: string,
  lastName: string,
  emailAddress: string,
  phoneNumber: E164Number | null | string,
  // truckPreferenceId: string,
  depotId: string,
  licenceNumber: string,
  password: string,
  hasFirstAid: boolean,
  hasDefibrillator: boolean
) => {
  const updatedBy = auth.currentUser;
  if (!_.isNull(updatedBy)) {
    await firestore.collection(DRIVERS).doc(docId).update({
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      // truckPreferenceId,
      licenceNumber,
      depotId,
      password,
      hasFirstAid,
      hasDefibrillator,
      updatedBy: updatedBy.uid,
      updatedDt: getServerTimestamp(),
    });
  } else {
    throw new Error("No user logged in");
  }
};

export const updateDriverExpiries = async (
  docId: string,
  driverExpiries: ExpiryReference[]
) => {
  const updatedBy = auth.currentUser;
  if (!_.isNull(updatedBy)) {
    await firestore.collection(DRIVERS).doc(docId).update({
      driverExpiries,
      updatedBy: updatedBy.uid,
      updatedDt: getServerTimestamp(),
    });
  } else {
    throw new Error("No user logged in");
  }
};

export const updateTruckExpiries = async (
  docId: string,
  truckExpiries: ExpiryReference[]
) => {
  const updatedBy = auth.currentUser;
  if (!_.isNull(updatedBy)) {
    await firestore.collection(TRUCKS).doc(docId).update({
      truckExpiries,
      updatedBy: updatedBy.uid,
      updatedDt: getServerTimestamp(),
    });
  } else {
    throw new Error("No user logged in");
  }
};

export const getDrivers = async (): Promise<Driver[]> => {
  try {
    const query = await firestore.collection(DRIVERS).get();

    if (!query.empty) {
      return _.filter(
        query.docs.map((driver) => {
          return {
            docId: driver.id,
            ...driver.data(),
          } as Driver;
        }),
        (driver) => !driver.archived
      );
    } else {
      return [] as Driver[];
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN getDrivers: ", e);
    Bugsnag.notify(new Error(e));
    return [] as Driver[];
  }
};

export const getDriver = async (docId: string): Promise<Driver> => {
  try {
    const query = await firestore.collection(DRIVERS).doc(docId).get();

    if (query.exists) {
      return {
        docId: query.id,
        ...query.data(),
      } as Driver;
    } else {
      return {} as Driver;
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN getDriver: ", e);
    Bugsnag.notify(new Error(e));
    return {} as Driver;
  }
};

export const getDriversRealTime = (
  callback: (drivers: Driver[], error?: string) => void
) => {
  try {
    const unsubscribe = firestore.collection(DRIVERS).onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        callback(
          snapshot.docs.map((driverQuery) => {
            const driver = {
              docId: driverQuery.id,
              ...driverQuery.data(),
            } as Driver;

            return driver;
          })
        );
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERRR IN getDriversRealTime: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const createTruck = async (
  name: string,
  size: string,
  type: JobType,
  make: string,
  model: string,
  year: string,
  regoNumber: string,
  vinNumber: string,
  rmsTransportNumber: string,
  depotId: string,
  description: string
) => {
  try {
    const createdBy = auth.currentUser;
    if (!_.isNull(createdBy)) {
      const result = await firestore.collection(TRUCKS).add({
        name,
        size,
        type,
        make,
        model,
        year,
        regoNumber,
        vinNumber,
        rmsTransportNumber,
        depotId,
        description,
        createdBy: createdBy.uid,
        createdDt: getServerTimestamp(),
      });
      return result.id;
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN createTruck: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const updateTruck = async (
  docId: string,
  name: string,
  size: string,
  type: JobType,
  make: string,
  model: string,
  year: string,
  regoNumber: string,
  vinNumber: string,
  rmsTransportNumber: string,
  depotId: string,
  description: string
) => {
  try {
    const updatedBy = auth.currentUser;
    if (!_.isNull(updatedBy)) {
      await firestore.collection(TRUCKS).doc(docId).update({
        name,
        size,
        type,
        make,
        model,
        year,
        regoNumber,
        vinNumber,
        rmsTransportNumber,
        depotId,
        description,
        updatedBy: updatedBy.uid,
        updatedDt: getServerTimestamp(),
      });
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN updateTruck: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const setTruckArchived = async (docId: string, archived: boolean) => {
  const updatedBy = auth.currentUser;
  try {
    if (!_.isNull(updatedBy)) {
      await firestore.collection(TRUCKS).doc(docId).update({
        archived,
        isArchivedBy: updatedBy.uid,
        updatedBy: updatedBy.uid,
        updatedDt: getServerTimestamp(),
      });
    } else {
      throw new Error("No user logged in");
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("error - setTruckArchived -- ", error);
    Bugsnag.notify(new Error(error));
  }
};

export const setDriverArchived = async (docId: string, archived: boolean) => {
  const updatedBy = auth.currentUser;
  try {
    if (!_.isNull(updatedBy)) {
      await firestore.collection(DRIVERS).doc(docId).update({
        archived,
        isArchivedBy: updatedBy.uid,
        updatedBy: updatedBy.uid,
        updatedDt: getServerTimestamp(),
      });
    } else {
      throw new Error("No user logged in");
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("error - setDriverArchived -- ", error);
    Bugsnag.notify(new Error(error));
  }
};

export const getTrucks = async (): Promise<Truck[]> => {
  try {
    const query = await firestore.collection(TRUCKS).get();

    if (!query.empty) {
      return query.docs.map((truck) => {
        return {
          docId: truck.id,
          ...truck.data(),
        } as Truck;
      });
    } else {
      return [] as Truck[];
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN getTrucks: ", e);
    Bugsnag.notify(new Error(e));
    return [] as Truck[];
  }
};

export const getTruck = async (docId: string): Promise<Truck> => {
  try {
    const query = await firestore.collection(TRUCKS).doc(docId).get();

    if (query.exists) {
      return {
        docId: query.id,
        ...query.data(),
      } as Truck;
    } else {
      return {} as Truck;
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN getTruck: ", e);
    Bugsnag.notify(new Error(e));
    return {} as Truck;
  }
};

export const getTrucksRealTime = (
  callback: (trucks: Truck[], error?: string) => void
) => {
  try {
    const unsubscribe = firestore.collection(TRUCKS).onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        callback(
          snapshot.docs.map((truckQuery) => {
            const truck = {
              docId: truckQuery.id,
              ...truckQuery.data(),
            } as Truck;

            return truck;
          })
        );
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERRR IN getTrucksRealTime: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const getTrucksWithDepotRealTime = (
  callback: (trucksWithDepot: TruckWithDepot[], error?: string) => void
) => {
  try {
    const unsubscribe = firestore
      .collection(TRUCKS)
      .onSnapshot(async (snapshot) => {
        if (!snapshot.empty) {
          const trucksData = snapshot.docs.map((truck) => {
            return {
              docId: truck.id,
              ...truck.data(),
            } as Truck;
          });

          const depotMap = {} as { [depotId: string]: Depot };

          const uniqueDepotIds = _.uniq(
            trucksData.map((truck) => truck.depotId)
          );

          //create depot map
          await Promise.all(
            uniqueDepotIds.map((depotId) => {
              return new Promise<void>(async (resolve) => {
                const depot = await firestore
                  .collection(DEPOTS)
                  .doc(depotId)
                  .get();
                if (depot.exists) {
                  depotMap[depotId] = {
                    docId: depotId,
                    ...depot.data(),
                  } as Depot;
                } else {
                  depotMap[depotId] = {} as Depot;
                }

                resolve();
              });
            })
          );
          const fitlerTruckWithDepot = _.filter(
            trucksData.map((truckData) => {
              return {
                ...truckData,
                depot: depotMap[truckData.depotId],
              } as TruckWithDepot;
            }),
            (truck) => !_.isEmpty(truck.depot)
          );

          callback(fitlerTruckWithDepot);
        } else {
          callback([]);
        }
      });
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERRR IN getTrucksRealTime: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const addTruckServiceHistory = async (
  truckId: string,
  serviceType: string,
  serviceDate: firebase.firestore.Timestamp,
  attachments?: string[]
) => {
  try {
    const createdBy = auth.currentUser;
    if (!_.isNull(createdBy)) {
      await firestore.collection(TRUCK_SERVICE_HISTORIES).add({
        truckId,
        serviceType,
        serviceDate,
        attachments,
        createdBy: createdBy.uid,
        createdDt: getServerTimestamp(),
      });

      await addTruckChangeLogs(
        truckId,
        `New service history added - "${serviceType}"`,
        createdBy.uid
      );
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN createTruck: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const getTruckServiceHistoriesRealTime = (
  truckId: string,
  callback: (serviceHistories: TruckServiceHistory[], error?: string) => void
) => {
  try {
    const unsubscribe = firestore
      .collection(TRUCK_SERVICE_HISTORIES)
      .where("truckId", "==", truckId)
      .onSnapshot(
        (snapshot) => {
          if (!snapshot.empty) {
            callback(
              snapshot.docs.map((serviceHistoryQuery) => {
                const serviceHistory = {
                  docId: serviceHistoryQuery.id,
                  ...serviceHistoryQuery.data(),
                } as TruckServiceHistory;

                return serviceHistory;
              })
            );
          } else {
            callback([]);
          }
        },
        (error) => {
          callback([], error.message);
          Bugsnag.notify(new Error(error.message));
        }
      );
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERRR IN getTruckServiceHistoriesRealTime: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const updateTruckServiceHistory = async (
  docId: string,
  serviceType: string,
  serviceDate: firebase.firestore.Timestamp,
  truckId: string,
  attachments?: string[]
) => {
  try {
    const updatedBy = auth.currentUser;
    if (!_.isNull(updatedBy)) {
      await firestore.collection(TRUCK_SERVICE_HISTORIES).doc(docId).update({
        serviceType,
        serviceDate,
        attachments,
        updatedBy: updatedBy.uid,
        updatedDt: getServerTimestamp(),
      });
      await addTruckChangeLogs(
        truckId,
        `Service history updated - "${serviceType}"`,
        updatedBy.uid
      );
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN updateTruck: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const deleteServiceHistory = async (docId: string) => {
  try {
    if (!docId) return;
    const result = await firestore
      .collection(TRUCK_SERVICE_HISTORIES)
      .doc(docId)
      .delete();
    return result;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN updateTruck: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const addDriverVOC = async (
  driverId: string,
  truckId: string,
  dateIssued: firebase.firestore.Timestamp,
  name: string,
  attachments?: string[]
) => {
  try {
    const createdBy = auth.currentUser;
    if (!_.isNull(createdBy)) {
      await firestore.collection(DRIVERS_VOC).add({
        driverId,
        truckId,
        dateIssued,
        attachments,
        createdBy: createdBy.uid,
        createdDt: getServerTimestamp(),
      });

      await addDriverChangeLogs(
        driverId,
        `New VOC Added - "${name}"`,
        createdBy.uid
      );
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN createTruck: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const getDriverVOCsViewRealtime = (
  callback: (driverVOCs: DriverVOCView[], error?: string) => void,
  driverId: string | null,
  truckId: string | null
) => {
  try {
    const isDriver = !!driverId;
    const unsubscribe = firestore
      .collection(DRIVERS_VOC)
      .where(
        isDriver ? "driverId" : "truckId",
        "==",
        isDriver ? driverId : truckId
      )
      .onSnapshot(
        async (snapshot) => {
          if (!snapshot.empty) {
            const vocsFormattedView = (await Promise.all(
              snapshot.docs.map((driverVOC) => {
                return new Promise(async (resolve) => {
                  const driverVOCData = {
                    docId: driverVOC.id,
                    ...driverVOC.data(),
                  } as DriverVOC;
                  const truckDetailsQuery = await firestore
                    .collection(TRUCKS)
                    .doc(driverVOCData.truckId)
                    .get();

                  const driverDetailsQuery = await firestore
                    .collection(DRIVERS)
                    .doc(driverVOCData.driverId)
                    .get();

                  if (truckDetailsQuery.exists && driverDetailsQuery.exists) {
                    resolve({
                      ...driverVOCData,
                      truckDetails: truckDetailsQuery.data(),
                      driverDetails: driverDetailsQuery.data(),
                    } as DriverVOCView);
                  } else {
                    resolve(null);
                  }
                });
              })
            )) as DriverVOCView[];

            callback(
              _.filter(_.compact(vocsFormattedView), (truckOrDriver) =>
                isDriver
                  ? !truckOrDriver.truckDetails.archived
                  : !truckOrDriver.driverDetails.archived
              )
            );
          } else {
            callback([]);
          }
        },
        (error) => {
          callback([], error.message);
          Bugsnag.notify(new Error(error.message));
        }
      );
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERRR IN getDriverVOCsViewRealtime: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const getAllDriverVOCsViewRealtime = (
  callback: (driverVOCs: DriverVOCView[], error?: string) => void
) => {
  try {
    const unsubscribe = firestore.collection(DRIVERS_VOC).onSnapshot(
      async (snapshot) => {
        if (!snapshot.empty) {
          const vocsFormattedView = (await Promise.all(
            snapshot.docs.map((driverVOC) => {
              return new Promise(async (resolve) => {
                const driverVOCData = {
                  docId: driverVOC.id,
                  ...driverVOC.data(),
                } as DriverVOC;
                const truckDetailsQuery = await firestore
                  .collection(TRUCKS)
                  .doc(driverVOCData.truckId)
                  .get();

                const driverDetailsQuery = await firestore
                  .collection(DRIVERS)
                  .doc(driverVOCData.driverId)
                  .get();

                if (truckDetailsQuery.exists && driverDetailsQuery.exists) {
                  resolve({
                    ...driverVOCData,
                    truckDetails: truckDetailsQuery.data(),
                    driverDetails: driverDetailsQuery.data(),
                  } as DriverVOCView);
                } else {
                  resolve(null);
                }
              });
            })
          )) as DriverVOCView[];

          callback(_.compact(vocsFormattedView));
        } else {
          callback([]);
        }
      },
      (error) => {
        callback([], error.message);
        Bugsnag.notify(new Error(error.message));
      }
    );
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERRR IN getDriverVOCsViewRealtime: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const getDriverVOCs = async (): Promise<DriverVOC[]> => {
  const query = await firestore.collection(DRIVERS_VOC).get();

  if (!query.empty) {
    return query.docs.map((driverVoc) => {
      return {
        docId: driverVoc.id,
        ...driverVoc.data(),
      } as DriverVOC;
    });
  } else {
    return [] as DriverVOC[];
  }
};

export const deleteVOC = async (
  docId: string,
  driverId: string,
  name: string
) => {
  try {
    if (!docId) return;
    const deletedBy = auth.currentUser;
    const result = await firestore.collection(DRIVERS_VOC).doc(docId).delete();
    await addDriverChangeLogs(
      driverId,
      `VOC updated - "${name}"`,
      deletedBy!.uid
    );
    return result;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN updateTruck: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const updateVOC = async (
  docId: string,
  driverId: string,
  truckId: string,
  dateIssued: firebase.firestore.Timestamp,
  name: string,
  attachments?: string[]
) => {
  try {
    const updatedBy = auth.currentUser;
    if (!_.isNull(updatedBy)) {
      await firestore.collection(DRIVERS_VOC).doc(docId).update({
        truckId,
        dateIssued,
        attachments,
        updatedBy: updatedBy.uid,
        updatedDt: getServerTimestamp(),
      });

      await addDriverChangeLogs(
        driverId,
        `VOC updated - "${name}"`,
        updatedBy.uid
      );
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN updateTruck: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const createDriverNotes = async (data: DriverNotes) => {
  try {
    const createdBy = auth.currentUser;
    if (!_.isNull(createdBy)) {
      await firestore.collection(DRIVER_NOTES).add({
        ...data,
        createdBy: createdBy.uid,
        createdDate: getServerTimestamp(),
      });
    } else {
      throw new Error("No user logged in");
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("Error -- createDriverNotes -- ", error);
    Bugsnag.notify(new Error(error));
  }
};

export const getDriverNotes = async (
  driverId: string,
  callback: (driverNotes: DriverNotes[], error?: string) => void
) => {
  if (!!driverId) {
    try {
      const unsubscribe = firestore
        .collection(DRIVER_NOTES)
        .where("driverId", "==", driverId)
        .onSnapshot(
          (snapshot) => {
            if (snapshot.docs.length > 0) {
              callback(
                snapshot.docs.map((driverNote) => {
                  return {
                    ...driverNote.data(),
                    docId: driverNote.id,
                  } as DriverNotes;
                })
              );
            } else {
              callback([]);
            }
          },
          (error) => {
            callback([], error.message);
          }
        );
      return unsubscribe;
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      Bugsnag.notify(new Error(error));
      callback([], error);
    }
  } else {
    callback([], "Error: no id/s provided");
  }
};

export const updateDriverNoteEntry = async (
  docId: string,
  updatedNoteEntry: DriverNotes
) => {
  try {
    const updatedBy = auth.currentUser;
    if (!_.isNull(updatedBy)) {
      await firestore
        .collection(DRIVER_NOTES)
        .doc(docId)
        .update({
          ...updatedNoteEntry,
          updatedBy: updatedBy.uid,
          updatedDate: getServerTimestamp(),
        });
    } else {
      throw new Error("No user logged in");
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("Error - updateDriverNotes -- ", error);
    Bugsnag.notify(new Error(error));
  }
};

export const createTruckNotes = async (data: TruckNotes) => {
  try {
    const createdBy = auth.currentUser;
    if (!_.isNull(createdBy)) {
      await firestore.collection(TRUCK_NOTES).add({
        ...data,
        createdBy: createdBy.uid,
        createdDate: getServerTimestamp(),
      });
    } else {
      throw new Error("No user logged in");
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("Error -- createTruckNotes -- ", error);
    Bugsnag.notify(new Error(error));
  }
};

export const getTruckNotes = async (
  truckId: string,
  callback: (truckNotes: TruckNotes[], error?: string) => void
) => {
  if (!!truckId) {
    try {
      const unsubscribe = firestore
        .collection(TRUCK_NOTES)
        .where("truckId", "==", truckId)
        .onSnapshot(
          (snapshot) => {
            if (snapshot.docs.length > 0) {
              callback(
                snapshot.docs.map((truckNote) => {
                  return {
                    ...truckNote.data(),
                    docId: truckNote.id,
                  } as TruckNotes;
                })
              );
            } else {
              callback([]);
            }
          },
          (error) => {
            callback([], error.message);
          }
        );
      return unsubscribe;
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      callback([], error);
      Bugsnag.notify(new Error(error));
    }
  } else {
    callback([], "Error: no id/s provided");
  }
};

export const updateTruckNoteEntry = async (
  docId: string,
  updatedNoteEntry: TruckNotes
) => {
  try {
    const updatedBy = auth.currentUser;
    if (!_.isNull(updatedBy)) {
      await firestore
        .collection(TRUCK_NOTES)
        .doc(docId)
        .update({
          ...updatedNoteEntry,
          updatedBy: updatedBy.uid,
          updatedDate: getServerTimestamp(),
        });
    } else {
      throw new Error("No user logged in");
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("Error - updateTruckNotes-- ", error);
    Bugsnag.notify(new Error(error));
  }
};
