import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import { getEnv } from "../env";

var env = getEnv();

const config = {
  apiKey: env.firebase.api_key,
  authDomain: env.firebase.auth_domain,
  databaseURL: env.firebase.database_url,
  projectId: env.firebase.project_id,
  storageBucket: env.firebase.storage_bucket,
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const fb = firebase;
