import React, { Component } from "react";
import { IonPage, IonContent, withIonLifeCycle, IonIcon } from "@ionic/react";

import "./Landing.scss";
import * as routes from "../../../constants/routes";
import { EWPTermOfUseAndPrivacy } from "../../../components/Mobile/EWPTermOfUseAndPrivacy/EWPTermOfUseAndPrivacy";
import { EWPButton } from "../../../components/EWPButton/EWPButton";
import { EWPFooterLink } from "../../../components/common/EWPFooterLink";

class Landing extends Component<{}> {
  state = {
    safeToView: false,
  };

  ionViewDidEnter = () => {
    setTimeout(() => {
      this.setState({ safeToView: true });
    });
  };

  render() {
    const { safeToView } = this.state;

    return (
      <>
        <IonPage
          className={`ewp-mobile-landing-page ${
            safeToView ? "safe-to-view" : ""
          }`}
        >
          <IonContent
            className="ion-no-padding ewp-mobile-landing-container"
            scrollY={false}
          >
            <div className="ewp-mobile-landing-wrapper-pattern" />
            <div className="ewp-mobile-landing-wrapper" />

            <div className="ewp-mobile-landing-login-container">
              <div className="ewp-landing-logo-button-container">
                <IonIcon className="ewp-mobile-landing-login-logo" />
                <div className="ewp-mobile-landing-login-action-container">
                  <EWPButton title="Login" routerLink={routes.DRIVER_SIGN_IN} />

                  <EWPFooterLink
                    title="Sign up"
                    routerLink={routes.DRIVER_SIGNUP}
                  />
                  {/* <IonLabel className="ewp-h5 white ion-text-center ion-padding-top">                    
                    - OR SIGN UP -{" "}
                  </IonLabel>
                  <EWPInput
                    inputLabel="Email"
                    inputValue={email}
                    errorMessage={""}
                    name="emailAddress"
                    inputType="text"
                    onInputChange={(emailAddress) => {
                      this.setState({
                        email: emailAddress,
                      });
                    }}
                  />
                  <EWPButton
                    className="ion-padding-top"
                    title="Submit"
                    onClick={() => {
                      this.setState({
                        signedUp: true,
                      });
                    }}
                  /> */}
                </div>
              </div>

              <EWPTermOfUseAndPrivacy className="ewp-mobile-landing-footer" />
            </div>
          </IonContent>
        </IonPage>
        {/* <IonToast
          isOpen={signedUp}
          message={"Sign up success! We will contact you shortly"}
          duration={2000}
          onDidDismiss={() => this.setState({ signedUp: false, email: "" })}
          color={EWPCOLORS.success}
        /> */}
      </>
    );
  }
}

export default withIonLifeCycle(Landing);
