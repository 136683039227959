import * as _ from "lodash";
import moment from "moment";
import Bugsnag from "@bugsnag/js";
import { chatbubbleSharp, checkmarkCircle, closeCircle } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  IonLoading,
  IonToast,
  IonThumbnail,
} from "@ionic/react";

import "./DriverJobScheduleDetail.scss";
import * as api from "../../../api";
import * as services from "../../../services";
import {
  JobScheduleView,
  NOTIFICATION_STATUS,
  SiteDocket,
} from "../../../models/jobSchedule";
import { setTimeout } from "timers";
import { JobSchduleSiteDocket } from "../DriverJobScheduleSiteDocket/DriverJobScheduleSiteDocket";
import { MobileAttachmentDialog } from "../MobileAttachmentDialog/MobileAttachmentDialog";
import {
  getDateFromTimezoneIgnoredTimestamp,
  isDayShift,
  TimezoneIgnoredTimeStamp,
} from "../../../functions/common";
import { EWPCOLORS } from "../../../constants/config";
import { MSGS_COMMON } from "../../../constants/messages";

interface DriverJobScheduleDetailProps {
  jobSchedule: JobScheduleView | null;
  onSuccessRefresh: (scheduleId: string) => void;
  uploadSiteDockets: () => void;
  onSuccessJobCompleted: () => void;
  sendDriverNotes: () => void;
}

export const DriverJobScheduleDetail = (
  props: DriverJobScheduleDetailProps
) => {
  const {
    jobSchedule,
    onSuccessRefresh,
    uploadSiteDockets,
    onSuccessJobCompleted,
    sendDriverNotes,
  } = props;
  const mapEle: React.RefObject<HTMLDivElement> = React.createRef();
  const [jobSiteDockets, setJobSiteDockets] = useState(
    null as null | JobSchduleSiteDocket[]
  );
  const [selectedJobSiteDockets, setSelectedJobSiteDockets] = useState(
    {} as JobSchduleSiteDocket
  );
  const [showSiteDocketDialog, setShowSiteDocketDialog] = useState(false);
  const [jobSiteDocketsComplete, setJobSiteDocketsComplete] = useState(false);

  const [showJobAttachmentDialog, setShowJobAttachmentDialog] = useState(false);
  const [selectedJobAttachmentIndex, setSelectedJobAttachmentIndex] =
    useState(0);

  const [googleMapInfo, setGoogleMapInfo] = useState({} as any);
  const googleMapInfoRef: { current: undefined | any } = useRef();
  googleMapInfoRef.current = googleMapInfo;

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      !_.isNull(mapEle.current) &&
      _.isEmpty(googleMapInfo) &&
      (_.isEmpty(googleMapInfoRef.current) ||
        googleMapInfoRef.current === undefined)
    ) {
      setTimeout(() => {
        getLatLng();
      }, 300);
    }
    if (_.isNull(jobSiteDockets)) {
      getJobScheduleSiteDockets();
    }
  });

  const getJobScheduleSiteDockets = () => {
    if (!!jobSchedule) {
      const dateMin = _.clone(
        // toDateTimeFromSecs(jobSchedule.startDate.seconds)
        getDateFromTimezoneIgnoredTimestamp(jobSchedule.startDateNoTimeZone)
      );
      dateMin.setHours(0);
      dateMin.setMinutes(0);
      dateMin.setSeconds(0);
      dateMin.setMilliseconds(0);
      const jobFinish = moment(
        getDateFromTimezoneIgnoredTimestamp(jobSchedule.endDateNoTimeZone)
      );
      const numOfDaysOfJob = jobFinish.diff(moment(dateMin), "days");
      // console.log("numOfDaysOfJob -- ", numOfDaysOfJob);

      if (numOfDaysOfJob > 0) {
        let setJobSchedules = [dateMin, jobFinish.toDate()] as Date[];
        for (let dayCounter = 1; dayCounter < numOfDaysOfJob; dayCounter++) {
          setJobSchedules.push(
            _.cloneDeep(moment(dateMin)).add(dayCounter, "days").toDate()
          );
        }
        const formattedJobSchedules = _.orderBy(setJobSchedules);
        const jobScheduleSiteDockets = formattedJobSchedules.map((date) => {
          return {
            jobScheduleId: jobSchedule.docId as string,
            jobScheduleDate: date,
            siteDockets: _.filter(
              jobSchedule.siteDockets || [],
              (siteDocket) => {
                return moment(
                  // toDateTimeFromSecs(
                  //   (siteDocket.siteDocketDate as firebase.firestore.Timestamp)
                  //     .seconds
                  // )
                  getDateFromTimezoneIgnoredTimestamp(
                    siteDocket.siteDocketDateNoTimeZone as TimezoneIgnoredTimeStamp
                  )
                ).isSame(moment(date), "days");
              }
            ),
          } as JobSchduleSiteDocket;
        });
        const hasSiteDocket = !_.isEmpty(
          _.compact(
            jobScheduleSiteDockets.map(
              (jobScheduleSiteDocket) =>
                !_.isEmpty(jobScheduleSiteDocket.siteDockets)
            )
          )
        );

        if (!!hasSiteDocket) {
          setJobSiteDockets(jobScheduleSiteDockets);
        } else {
          setJobSiteDockets([]);
        }

        const siteDocketsComplete = !_.includes(
          jobScheduleSiteDockets.map(
            (jobScheduleSiteDocket) =>
              !_.isEmpty(jobScheduleSiteDocket.siteDockets)
          ),
          false
        );
        setJobSiteDocketsComplete(siteDocketsComplete);
      } else {
        //single day job
        setJobSiteDockets([
          {
            jobScheduleId: jobSchedule.docId as string,
            jobScheduleDate: jobFinish.toDate(),
            siteDockets: _.filter(
              jobSchedule.siteDockets || [],
              (siteDocket) => {
                return moment(
                  // toDateTimeFromSecs(
                  //   (siteDocket.siteDocketDate as firebase.firestore.Timestamp)
                  //     .seconds
                  // )
                  getDateFromTimezoneIgnoredTimestamp(
                    siteDocket.siteDocketDateNoTimeZone as TimezoneIgnoredTimeStamp
                  )
                ).isSame(moment(jobFinish.toDate()), "days");
              }
            ),
          },
        ]);
        setJobSiteDocketsComplete(!_.isEmpty(jobSchedule.siteDockets));
      }
    }
  };

  const closeJobSchedule = async () => {
    if (!!jobSchedule) {
      setLoading(true);
      try {
        //todo: create update job complete
        await services.closeJobSchedule(jobSchedule.docId as string);
        await api.sendEmailForCompletedJob(jobSchedule.docId as string);
        setSuccess("Job Completed!");
        setLoading(false);
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        console.log("error -- closeJobSchedule -- ", error);
        setError(error);
        setLoading(false);
        Bugsnag.notify(new Error(error));
      }
    }
  };

  const createGoogleMap = (coordinates: any) => {
    if (!!mapEle && !!mapEle.current) {
      return new window.google.maps.Map(mapEle.current, {
        zoom: 16,
        center: {
          lat: coordinates.lat(),
          lng: coordinates.lng(),
        },
        disableDefaultUI: true,
      });
    }
  };

  let mapTries = 0;
  const getLatLng = () => {
    let lat, lng, placeId;
    if (
      !!jobSchedule &&
      !_.isNull(mapEle.current) &&
      _.isEmpty(googleMapInfo) &&
      (googleMapInfoRef.current === undefined ||
        _.isEmpty(googleMapInfoRef.current))
    ) {
      new window.google.maps.Geocoder().geocode(
        { address: `${jobSchedule.address}` },
        function (results, status) {
          console.log("googleMap ---- status -- ", {
            address: jobSchedule.address,
            status,
          });
          if (status === window.google.maps.GeocoderStatus.OK) {
            placeId = results[0].place_id;
            const googleMap = createGoogleMap(results[0].geometry.location);
            lat = results[0].geometry.location.lat();
            lng = results[0].geometry.location.lng();
            console.log(lat, lng);
            console.log("googleMap ---- this is googleMap -- ", googleMap);
            if (!!googleMap) {
              new window.google.maps.Marker({
                position: { lat, lng },
                map: googleMap as google.maps.Map,
                animation: window.google.maps.Animation.DROP,
                title: `${jobSchedule.address}`,
              });

              setGoogleMapInfo({
                ...googleMapInfo,
                lat,
                lng,
                placeId,
                isLoading: false,
                googleMap,
              });
              mapTries = 0;
              setLoading(false);
            } else {
              console.log(
                "WOOPS SOMETHING SHITTY -- ",
                JSON.stringify(googleMap, null, 1)
              );
              if (mapTries <= 3) {
                mapTries += 1;
                setTimeout(() => {
                  getLatLng();
                }, 500);
              } else {
                setLoading(false);
                setError(`Failed to load address: ${jobSchedule.address}`);
              }
            }
          } else {
            if (mapTries <= 3) {
              mapTries += 1;
              setTimeout(() => {
                getLatLng();
              }, 500);
            } else {
              setLoading(false);
              setError(`Failed to load address: ${jobSchedule.address}`);
            }
          }
        }
      );
    } else {
      if (mapTries <= 3) {
        mapTries += 1;
        setTimeout(() => {
          getLatLng();
        }, 500);
      } else {
        setLoading(false);
        if (
          _.isEmpty(googleMapInfoRef) ||
          googleMapInfoRef.current === undefined
        ) {
          setError(`Failed to load map, will try again.`);
        }
      }
    }
  };

  const removeJobSiteDocket = async (
    siteDocket: SiteDocket,
    jobScheduleId: string,
    driverId: string
  ) => {
    setLoading(true);
    try {
      await services.deleteAttachment(siteDocket.attachmentURL);
      await services.removeJobSiteDocket(siteDocket, jobScheduleId, driverId);
      setSuccess("Site Docket Removed!");
      setShowSiteDocketDialog(false);
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("error - removeJobSiteDocket -- ", error);
      setError(error);
      setLoading(false);
      Bugsnag.notify(new Error(error));
    }
  };

  const setDriverAcceptedJob = async (jobScheduleId: string) => {
    setLoading(true);
    try {
      await services.driverAcceptJob(jobScheduleId);
      setSuccess("Job Successfully Accepted!");
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      setLoading(false);
      setError(error);
      console.log("error -- setDriverAcceptedJob -- ", error);
      Bugsnag.notify(new Error(error));
    }
  };

  return (
    <div className="driver-job-detail">
      {!!jobSchedule && (
        <>
          <div className="driver-job-detail-header-container">
            <IonGrid className="driver-job-detail-grid ion-no-padding ion-no-margin">
              <IonRow className="driver-job-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="2.5"
                  className="driver-job-detail-col shift ion-no-margin ion-no-padding"
                >
                  <div
                    className={`driver-job-detail-shift ${
                      isDayShift(
                        getDateFromTimezoneIgnoredTimestamp(
                          jobSchedule.onsiteTimeNoTimeZone
                        )
                        // toDateTimeFromSecs(jobSchedule.onsiteTime.seconds)
                      )
                        ? "day"
                        : "night"
                    }`}
                  />
                </IonCol>
                <IonCol
                  size="7"
                  className="driver-job-detail-col ion-no-margin ion-no-padding"
                >
                  <div className="driver-job-detail-header-label-container">
                    <IonLabel className="ewp-h4">
                      {jobSchedule.depotDetails.name}
                    </IonLabel>
                    <IonLabel
                      className={`driver-job-detail-header-label ${
                        isDayShift(
                          getDateFromTimezoneIgnoredTimestamp(
                            jobSchedule.onsiteTimeNoTimeZone
                          )
                        )
                          ? "day"
                          : "night"
                      } ewp-h6 bold`}
                    >
                      {`${moment(
                        getDateFromTimezoneIgnoredTimestamp(
                          jobSchedule.startDateNoTimeZone
                        )
                      ).format("ddd DD MMM YYYY")} - ${moment(
                        getDateFromTimezoneIgnoredTimestamp(
                          jobSchedule.endDateNoTimeZone
                        )
                      ).format("ddd DD MMM YYYY")}`}
                    </IonLabel>
                    <IonLabel className="job-id-label ewp-h6">
                      <b>Job Id:</b> {jobSchedule.docId}
                    </IonLabel>
                  </div>
                </IonCol>
                <IonCol
                  size="1"
                  className="driver-job-detail-col shift ion-no-margin ion-no-padding"
                >
                  <IonButton
                    mode="ios"
                    className="driver-job-detail-button ion-no-margin ion-no-padding"
                    color={EWPCOLORS.primary}
                    onClick={() => {
                      sendDriverNotes();
                    }}
                    fill="clear"
                  >
                    <IonIcon icon={chatbubbleSharp} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <div className="driver-job-detail-details-container">
            <div className="driver-job-detail-details-container details">
              <div className="detail-icon-label-container">
                <IonIcon className="detail-icon client" />
                <IonLabel className="detail-label ewp-h5 normal ion-no-margin ion-no-padding">
                  {!_.isEmpty(jobSchedule.clientDetails)
                    ? jobSchedule.clientDetails.name
                    : "N/A"}
                </IonLabel>
              </div>
              <div className="detail-icon-label-container">
                <IonIcon className="detail-icon contact-person" />
                <IonLabel className="detail-label ewp-h5 normal ion-no-margin ion-no-padding">
                  {!_.isEmpty(jobSchedule.contactPersonDetails)
                    ? `${jobSchedule.contactPersonDetails.firstName} ${jobSchedule.contactPersonDetails.lastName}`
                    : "N/A"}
                </IonLabel>
              </div>
              <div className="detail-icon-label-container">
                <IonIcon className="detail-icon phone-number" />
                {!_.isEmpty(jobSchedule.contactPersonDetails) ? (
                  <IonLabel
                    className="detail-label ewp-h5 normal ion-no-margin ion-no-padding"
                    onClick={() => {
                      window.open(
                        `tel:${jobSchedule.contactPersonDetails.phoneNumber}`,
                        "_system"
                      );
                    }}
                  >
                    <u>{`${jobSchedule.contactPersonDetails.phoneNumber}`}</u>
                  </IonLabel>
                ) : (
                  <IonLabel className="detail-label ewp-h5 normal ion-no-margin ion-no-padding">
                    N/A
                  </IonLabel>
                )}
              </div>
              <div className="detail-icon-label-container">
                <IonIcon className="detail-icon truck" />
                <IonLabel className="detail-label ewp-h5 normal ion-no-margin ion-no-padding">
                  {!_.isEmpty(jobSchedule.truckDetails)
                    ? `${jobSchedule.truckDetails.name}`
                    : "N/A"}
                </IonLabel>
              </div>
              <div className="detail-icon-label-container">
                <IonIcon className="detail-icon address" />
                <IonLabel className="detail-label ewp-h5 normal ion-no-margin ion-no-padding">{`${jobSchedule.address}`}</IonLabel>
              </div>

              <div
                ref={mapEle}
                className="driver-job-detail-map-container"
                id="driver-job-detail-map-container"
              />
              <div className="detail-icon-label-container">
                <IonIcon className="detail-icon notes" />
                <IonLabel className="detail-label ewp-h5 normal ion-no-margin ion-no-padding">{`${jobSchedule.notes}`}</IonLabel>
              </div>
              {!!jobSchedule.attachments &&
                !_.isEmpty(jobSchedule.attachments) && (
                  <div className="job-attachments-container">
                    <IonLabel className="job-attachments-title ewp-h6 bold light ion-no-padding ion-no-margin">
                      JOB ATTACHMENTS
                    </IonLabel>

                    <div className="job-attachment-container">
                      {jobSchedule.attachments.map((jobAttachment, index) => {
                        return (
                          <IonThumbnail
                            className="job-attachment-avatar ion-no-margin ion-no-padding"
                            slot="start"
                            onClick={() => {
                              setSelectedJobAttachmentIndex(index);
                              setShowJobAttachmentDialog(true);
                            }}
                          >
                            <img
                              id="attachmentPhoto"
                              src={jobAttachment}
                              alt="attachmentPhoto"
                            />
                          </IonThumbnail>
                        );
                      })}
                    </div>
                  </div>
                )}
            </div>
            {/* {!!jobSchedule.isRemote && (
              
            )} */}

            <div className="driver-job-detail-remote-container">
              <IonLabel className="remote-detail-title-header ewp-h5 bold light ion-no-padding ion-no-margin">
                Departure & Travel Details
              </IonLabel>
              <div className="remote-details-container">
                <IonLabel className="remote-details-title ewp-paragraph ion-no-padding ion-no-margin">
                  Depot Departure Time
                </IonLabel>
                <IonLabel className="remote-details-value ewp-paragraph ion-no-padding ion-no-margin">
                  {jobSchedule.travelJobTime || "N/A"}
                </IonLabel>
              </div>
              <div className="remote-details-container">
                <IonLabel className="remote-details-title ewp-paragraph ion-no-padding ion-no-margin">
                  Distance and ETA to Job
                </IonLabel>
                <IonLabel className="remote-details-value ewp-paragraph ion-no-padding ion-no-margin">
                  {jobSchedule.distanceJob || "N/A"}
                </IonLabel>
              </div>
              <div className="remote-details-container">
                <IonLabel className="remote-details-title ewp-paragraph ion-no-padding ion-no-margin">
                  Travel Back Time
                </IonLabel>
                <IonLabel className="remote-details-value ewp-paragraph ion-no-padding ion-no-margin">
                  {jobSchedule.travelBackTime || "N/A"}
                </IonLabel>
              </div>

              {!!jobSchedule.stopPoints && !_.isEmpty(jobSchedule.stopPoints) && (
                <div className="remote-details-container stop-points">
                  <IonLabel className="remote-details-title stop-points ewp-h5 bold light ion-no-padding ion-no-margin">
                    RECOMMENDED STOP POINTS
                  </IonLabel>

                  {jobSchedule.stopPoints.map((stopPoint, index) => {
                    return (
                      <IonLabel className="remote-details-value stop-points ewp-paragraph ion-no-padding ion-no-margin">
                        {`${index + 1}. ${stopPoint}`}
                      </IonLabel>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          {jobSchedule.notificationStatusId ===
            NOTIFICATION_STATUS.accepted.id &&
          !_.isNull(jobSiteDockets) &&
          !_.isEmpty(jobSiteDockets) ? (
            <>
              <div className="driver-job-detail-site-dockets-container">
                <IonLabel className="driver-site-dockets-title ewp-h6 bold light ion-no-padding ion-no-margin">
                  SITE DOCKETS
                </IonLabel>
                {jobSiteDockets.map((jobSiteDocket) => {
                  return (
                    <div className="site-dockets-container">
                      <div className="icon-label-container">
                        <IonIcon className="site-docket-img-icon" />
                        <IonLabel
                          className="site-docket-date-label ewp-paragraph light ion-no-margin ion-no-padding"
                          onClick={() => {
                            if (!_.isEmpty(jobSiteDocket.siteDockets)) {
                              setShowSiteDocketDialog(true);
                              setSelectedJobSiteDockets(jobSiteDocket);
                            } else {
                              setError(
                                "No Site Docket uploaded for this schdule."
                              );
                            }
                          }}
                        >
                          <u>
                            {moment(jobSiteDocket.jobScheduleDate).format(
                              "ddd DD MMM YYYY"
                            )}
                          </u>
                        </IonLabel>
                      </div>
                      <IonIcon
                        color={
                          !_.isEmpty(jobSiteDocket.siteDockets)
                            ? EWPCOLORS.success
                            : EWPCOLORS.danger
                        }
                        icon={
                          !_.isEmpty(jobSiteDocket.siteDockets)
                            ? checkmarkCircle
                            : closeCircle
                        }
                      />
                    </div>
                  );
                })}
                <div className="edit-docket-button-container">
                  <IonButton
                    mode="ios"
                    className="edit-docket-button ewp-h5 ion-no-padding ion-no-margin"
                    onClick={() => {
                      uploadSiteDockets();
                    }}
                  >
                    <IonIcon className="edit-docket-button-icon" />
                    Attach Photo of Docket
                  </IonButton>
                </div>
              </div>
              <div className="driver-job-detail-button-container">
                <IonButton
                  mode="ios"
                  className="driver-job-detail-button ion-no-margin ion-no-padding"
                  color={
                    jobSiteDocketsComplete
                      ? EWPCOLORS.primary
                      : EWPCOLORS.danger
                  }
                  onClick={() => {
                    if (jobSiteDocketsComplete) {
                      closeJobSchedule();
                    } else {
                      uploadSiteDockets();
                    }
                  }}
                >
                  {jobSiteDocketsComplete
                    ? "Close Job"
                    : "Submit Dockets & Close Job"}
                </IonButton>
              </div>
            </>
          ) : (
            <div className="driver-job-detail-button-container">
              <IonButton
                mode="ios"
                className="driver-job-detail-button ion-no-margin ion-no-padding"
                color={
                  jobSchedule.notificationStatusId !==
                  NOTIFICATION_STATUS.accepted.id
                    ? EWPCOLORS.primary
                    : EWPCOLORS.danger
                }
                onClick={() => {
                  if (
                    jobSchedule.notificationStatusId !==
                    NOTIFICATION_STATUS.accepted.id
                  ) {
                    setDriverAcceptedJob(jobSchedule.docId as string);
                  } else {
                    uploadSiteDockets();
                  }
                }}
              >
                {jobSchedule.notificationStatusId !==
                NOTIFICATION_STATUS.accepted.id
                  ? "Accept Job"
                  : "Upload Site Docket"}
              </IonButton>
            </div>
          )}
        </>
      )}

      {/* {!!showJobAttachmentDialog && ( */}
      <MobileAttachmentDialog
        showModal={showJobAttachmentDialog}
        onDidDismiss={() => {
          setShowJobAttachmentDialog(false);
        }}
        attachments={{
          attachments:
            !!jobSchedule && !_.isEmpty(jobSchedule.attachments)
              ? jobSchedule.attachments
              : [],
        }}
        selectedIndex={selectedJobAttachmentIndex}
      />
      {/* )} */}

      {/* {!!showSiteDocketDialog && ( */}
      <MobileAttachmentDialog
        showModal={showSiteDocketDialog}
        onDidDismiss={() => {
          setShowSiteDocketDialog(false);
        }}
        attachments={{
          attachments: !_.isEmpty(selectedJobSiteDockets)
            ? selectedJobSiteDockets.siteDockets.map((siteDocketUrl) => {
                return {
                  attachmentUrl: siteDocketUrl.attachmentURL,
                  photoName: siteDocketUrl.siteDocketName,
                };
              })
            : [],
        }}
        onEdit={() => {
          if (!!selectedJobSiteDockets) {
            uploadSiteDockets();
            setShowSiteDocketDialog(false);
          }
        }}
        onDelete={(selectedSiteDocketIndex: number) => {
          if (!!jobSchedule) {
            removeJobSiteDocket(
              selectedJobSiteDockets.siteDockets[selectedSiteDocketIndex],
              selectedJobSiteDockets.jobScheduleId,
              jobSchedule.driverId as string
            );
          }
        }}
      />
      {/* )} */}
      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => {
          setError("");
        }}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={1000}
        onDidDismiss={() => {
          setSuccess("");
          if (jobSiteDocketsComplete) {
            onSuccessJobCompleted();
          } else if (!!jobSchedule) {
            onSuccessRefresh(jobSchedule.docId as string);
          }
          setLoading(false);
        }}
        color={EWPCOLORS.success}
      />
    </div>
  );
};
