import * as _ from "lodash";
import moment from "moment";
import "moment/locale/en-gb";
import React, { useEffect, useState } from "react";
import {
  checkmarkCircle,
  ellipseOutline,
  alertCircle,
  closeCircle,
} from "ionicons/icons";

import { IonLabel, IonGrid, IonRow, IonCol, IonIcon } from "@ionic/react";

import "./DriverJobScheduleList.scss";
import {
  getDateFromTimezoneIgnoredTimestamp,
  getJobStatus,
  isDayShift,
} from "../../../functions/common";
import {
  JobScheduleView,
  JobType,
  NotificationStatusId,
  NOTIFICATION_STATUS,
  NOTIFICATION_STATUS_WITH_KEYS,
} from "../../../models/jobSchedule";

interface DriverJobScheduleListProps {
  jobSchedules: JobScheduleView[] | null;
  onViewSchedule: (scheduleId: string) => void;
}

interface EWPRbCalendarEventInterface {
  allDay?: boolean;
  title?: string;
  start: Date;
  end: Date;
  resource: {
    jobSchduleId: string;
    truckName: string;
    notificationStatus: NotificationStatusId;
    jobStatus: "completed" | "incomplete";
    onSiteTime: Date;
    finishTime?: Date;
    shift: string;
    clientName: string;
    depotName: string;
    driverName: string;
    jobType: JobType;
    isRemoteJob: boolean;
  };
}

export const DriverJobScheduleList = (props: DriverJobScheduleListProps) => {
  const [calendarJobSchedules, setCalendarSchedules] = useState(
    null as EWPRbCalendarEventInterface[] | null
  );

  useEffect(() => {
    if (_.isNull(calendarJobSchedules)) {
      getCalendarSchedules();
    }
  });

  const getCalendarSchedules = () => {
    const { jobSchedules } = props;
    if (!!jobSchedules) {
      const formattedCalendarSchdules = jobSchedules.map((jobSchedule) => {
        // const startDate = jobSchedule.startDate.toDate();
        const startDate = getDateFromTimezoneIgnoredTimestamp(
          jobSchedule.startDateNoTimeZone
        );

        // const endDate = jobSchedule.endDate.toDate();
        const endDate = getDateFromTimezoneIgnoredTimestamp(
          jobSchedule.endDateNoTimeZone
        );
        const dateMin = _.clone(new Date());
        dateMin.setHours(0);
        dateMin.setMinutes(0);
        dateMin.setSeconds(0);
        dateMin.setMilliseconds(0);

        const dateMax = _.clone(endDate);
        dateMax.setHours(23);
        dateMax.setMinutes(59);
        dateMax.setSeconds(59);
        dateMax.setMilliseconds(59);

        const truckName = !_.isEmpty(jobSchedule.truckDetails)
          ? jobSchedule.truckDetails.name
          : "N/A";
        // const onSiteTime = jobSchedule.onsiteTime.toDate();
        const onSiteTime = getDateFromTimezoneIgnoredTimestamp(
          jobSchedule.onsiteTimeNoTimeZone
        );
        const finishTime = !_.isEmpty(jobSchedule.finishTime)
          ? getDateFromTimezoneIgnoredTimestamp(jobSchedule.finishTime!)
          : undefined;

        console.log(
          "jobSchedule.finishTime --- ",
          jobSchedule.finishTime,
          finishTime
        );
        const shift = isDayShift(onSiteTime) ? "day" : "night";
        const clientName = jobSchedule.clientDetails.name;
        const driverName = !_.isEmpty(jobSchedule.driverDetails)
          ? `${jobSchedule.driverDetails.firstName} ${jobSchedule.driverDetails.lastName}`
          : "N/A";
        const depotName = !_.isEmpty(jobSchedule.depotDetails)
          ? jobSchedule.depotDetails.name
          : "N/A";
        const jobStatus =
          moment(dateMin).isAfter(moment(dateMax)) ||
          !!jobSchedule.jobCompletedAtNoTimeZone
            ? getJobStatus(
                !_.isEmpty(jobSchedule.jobCompletedAtNoTimeZone),
                jobSchedule.siteDockets || []
              )
            : "";
        // const isRemoteJob = jobSchedule.isRemote;
        const notificationStatus = jobSchedule.notificationStatusId;
        return {
          start: startDate,
          end: endDate,
          resource: {
            jobSchduleId: jobSchedule.docId as string,
            truckName,
            notificationStatus,
            onSiteTime: onSiteTime,
            finishTime,
            shift,
            jobStatus,
            clientName,
            depotName,
            driverName,
            jobType: jobSchedule.jobType,
            // isRemoteJob,
          },
        };
      }) as EWPRbCalendarEventInterface[];
      setCalendarSchedules(formattedCalendarSchdules);
    }
  };

  return (
    <div className="job-schedule-custom-calendar-day-view-card ion-no-padding ion-no-margin">
      {_.orderBy(
        calendarJobSchedules,
        ["start", "resource.onSiteTime"],
        "desc"
      ).map((event) => {
        // console.log("EVENT --- ", event);
        return (
          <div
            className={`job-schedule-calendar-event-day-view-container ${event.resource.shift} ${event.resource.jobStatus}`}
            onClick={() => {
              props.onViewSchedule(event.resource.jobSchduleId);
            }}
          >
            <IonGrid className="job-schedule-calendar-day-view-grid ion-no-padding ion-no-margin">
              <IonRow className="job-schedule-calendar-day-view-row ion-no-padding ion-no-margin">
                <IonCol
                  size="10.5"
                  className="job-schedule-calendar-day-view-col ion-no-padding ion-no-margin"
                >
                  <div className="driver-job-schedule-detail-container">
                    <IonLabel
                      className={`driver-job-schedule-label ${event.resource.shift} ${event.resource.jobStatus} ewp-h6`}
                    >
                      {`${moment(event.start).format(
                        "ddd DD MMM YYYY"
                      )} - ${moment(event.end).format("ddd DD MMM YYYY")}`}
                    </IonLabel>
                    <IonLabel className="driver-job-schedule-label truckName ewp-h4 bold">
                      {event.resource.depotName}
                    </IonLabel>
                    <IonLabel className="driver-job-schedule-label ewp-paragraph">
                      <b>Onsite: </b>
                      {`${moment(event.resource.onSiteTime).format("HH:mm")}`}
                    </IonLabel>
                    <IonLabel className="driver-job-schedule-label ewp-paragraph">
                      <b>Finish: </b>
                      {event.resource.finishTime !== undefined
                        ? `${moment(event.resource.finishTime).format("HH:mm")}`
                        : "N/A"}
                    </IonLabel>
                    <IonLabel className="driver-job-schedule-label ewp-paragraph">
                      <b>Truck: </b>
                      {event.resource.truckName}
                    </IonLabel>
                  </div>
                </IonCol>
                <IonCol
                  size="1.5"
                  className="job-schedule-calendar-day-view-col ion-no-padding ion-no-margin"
                >
                  <div className="job-schedule-calendar-status-container">
                    {/* {event.resource.isRemoteJob && (
                      <IonIcon
                        className={`ewp-remote-icon ${event.resource.shift}`}
                        icon={location}
                      />
                    )} */}
                    <IonIcon
                      className={`ewp-notif-status-icon ${
                        event.resource.shift
                      } ${
                        NOTIFICATION_STATUS_WITH_KEYS[
                          event.resource.notificationStatus
                        ].key
                      }`}
                      icon={
                        event.resource.notificationStatus ===
                        NOTIFICATION_STATUS.accepted.id
                          ? checkmarkCircle
                          : event.resource.notificationStatus ===
                            NOTIFICATION_STATUS.notSent.id
                          ? ellipseOutline
                          : event.resource.notificationStatus ===
                            NOTIFICATION_STATUS.pending.id
                          ? alertCircle
                          : closeCircle
                      }
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        );
      })}
    </div>
  );
};
