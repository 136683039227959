import * as _ from "lodash";
import * as React from "react";
import * as routes from "../constants/routes";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";

import { ADMIN_ACCESS, AuthUser } from "../config/global";
import { isMobile } from "../functions/common";
import { Admin } from "../models";

interface UnauthenticatedRouteProps {
  component: any;
  path: string;
  exact: boolean;
  authUser: AuthUser;
}
export const UnauthenticatedRoute = (props: UnauthenticatedRouteProps) => {
  const { exact, path, authUser, component } = props;
  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) => {
        return getRender(authUser, path, props, component);
      }}
    />
  );
};

const getRender = (authUser: AuthUser, path: string, props: any, C: any) => {
  if (!_.isEmpty(authUser)) {
    if (!isMobile()) {
      const admin = (authUser as unknown) as Admin;
      console.log("PRINTING ADMIN", admin);
      if (admin.access === ADMIN_ACCESS.mechanic.id) {
        return <Redirect to={routes.HOME_FAULTS_AND_DEFECTS} />;
      } else {
        return <Redirect to={routes.HOME_JOB_SCHEDULE} />;
      }
    } else {
      return <Redirect to={routes.DRIVER_JOB_SCHEDULE} />;
    }
  }

  return <C {...props} {...{ authUser: authUser }} />;
};
