import * as _ from "lodash";
import React, { useState, DragEvent, useEffect } from "react";
import { close, add } from "ionicons/icons";
import {
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonRippleEffect,
  IonLabel,
  IonIcon,
  IonThumbnail,
  IonModal,
  IonContent,
} from "@ionic/react";

import "./VOCDialog.scss";
import * as services from "../../services";
import { auth } from "../../firebase";
import {
  formatString,
  uploadAttachments,
  removedUploadedAttachements,
  getServerTimestamp,
  isTablet,
} from "../../functions/common";
import { DriverVOCView } from "../../models/drivers";
import { EWPProps } from "../../config/global";
import { EWPDatePicker } from "../EWPDatePicker/EWPDatePicker";
import { EWPCalendar } from "../EWPCalendar/EWPCalendar";
import { EWPWebDialog } from "../EWPWebDialog/EWPWebDialog";
import {
  DropdownItem,
  EWPWebDropdownSelect,
} from "../EWPWebDropdownSelect/EWPWebDropdownSelect";
import { JOBS_WITH_KEYS } from "../../models";
import { EWPCOLORS, LIMITS } from "../../constants/config";
import { MSGS_COMMON, MSGS_DRIVER } from "../../constants/messages";
import Bugsnag from "@bugsnag/js";
import { EWPCommonTooltip } from "../Web/EWPCommonTooltip/EWPCommonTooltip";

interface VOCDialogProps extends EWPProps {
  onDidDismiss: () => void;
  isOpen: boolean;
  driverId: string; // use in upload photo path
  editData?: DriverVOCView;
}

export const VOCDialog = (props: VOCDialogProps) => {
  const { isOpen, onDidDismiss, driverId, editData } = props;
  const [selectedTruck, setSelectedTruck] = useState(
    !!editData ? editData.truckId : ""
  );
  const [selectedDateIssued, setSelectedDateIssued] = useState(
    (!!editData
      ? !_.isEmpty(editData.dateIssued)
        ? editData.dateIssued!.toDate()
        : null
      : null) as null | Date
  );
  const [listOfAttachment, setListOfAttachment] = useState(
    !!editData ? editData.attachments : []
  ) as any[];

  useEffect(() => {
    if (!!editData) {
      setSelectedTruck(editData.truckId);
      setSelectedDateIssued(
        !_.isEmpty(editData.dateIssued) ? editData.dateIssued!.toDate() : null
      );
      setListOfAttachment(editData.attachments);
    }
  }, [editData, setListOfAttachment]);

  const [removedAttachments, setRemovedAttachments] = useState([] as string[]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [trucksWithDepotOptions, setTrucksWithDepotOptions] = useState(
    null as DropdownItem[] | null
  );

  const [errorMsgSelectedTruck, setErrorMsgSelectedTruck] = useState("");
  const [errorMsgDateIssued, setErrorMsgDateIssued] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [calendarAnchor, setCalendarAnchor] = useState(
    null as Event | undefined | null
  );
  const ref: React.RefObject<HTMLInputElement> = React.createRef();

  const [tooltipCoords, setTooltipCoords] = useState({
    left: 0,
    top: 0,
  });

  const [tooltipMessage, setTooltipMessage] = useState("");

  const onClickUploadPhoto = () => {
    if (!!listOfAttachment) {
      if (
        listOfAttachment.length < LIMITS.serviceTypeAttachments.count &&
        !!ref
      ) {
        ref.current!.click(); // ref for input field file
      } else {
        setError(MSGS_DRIVER.vocAttachmentsExceed);
      }
    }
  };

  useEffect(() => {
    if (_.isNull(trucksWithDepotOptions)) {
      getTrucksOptions();
    }
  });
  const submitForm = (event: React.FormEvent) => {
    event.preventDefault();
    const formattedSelectedTruck = formatString(selectedTruck);
    const user = auth.currentUser;

    let errorMessage = null;
    if (formattedSelectedTruck.length === 0) {
      errorMessage = MSGS_DRIVER.vocTruck;
      setErrorMsgSelectedTruck(errorMessage);
    } else if (_.isNull(selectedDateIssued)) {
      errorMessage = MSGS_DRIVER.vocDateIssued;
      setErrorMsgDateIssued(errorMessage);
    }

    if (_.isEmpty(errorMessage) && !!user) {
      const selectedTruckData = _.find(
        trucksWithDepotOptions,
        (truck) => truck.id === selectedTruck
      );
      if (!!editData) {
        updateDriverVOC(
          !_.isEmpty(selectedTruckData) ? selectedTruckData!.metadata.name : ""
        );
      } else {
        addNewVOC(
          formattedSelectedTruck,
          selectedDateIssued as Date,
          !_.isEmpty(selectedTruckData) ? selectedTruckData!.metadata.name : ""
        );
      }
    }
  };

  const getTrucksOptions = () => {
    setTrucksWithDepotOptions(null);
    try {
      services.getTrucksWithDepotRealTime((trucksWithDepots, error) => {
        setTrucksWithDepotOptions(
          _.filter(trucksWithDepots, (truck) => !truck.archived).map(
            (truckWithDepot) => {
              return {
                id: truckWithDepot.docId || "",
                name: `${JOBS_WITH_KEYS[truckWithDepot.type].name} - ${
                  truckWithDepot.name
                } - ${truckWithDepot.depot.name}`,
                metadata: {
                  ...truckWithDepot,
                },
              };
            }
          )
        );

        setError(error || "");
      });
      // unsubscribe();
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("error -- getTruckOptions -- ", error);
      setError(error);
      Bugsnag.notify(new Error(error));
    }
  };

  const clearFields = () => {
    setSelectedTruck("");
    setSelectedDateIssued(null);
    setListOfAttachment([]);
  };

  const updateDriverVOC = async (name: string) => {
    if (!!editData) {
      setLoading(true);
      let attachmentUrlList = [] as string[];
      const docId = editData.docId || "";
      const newFileAttach = _.compact(
        listOfAttachment.map((item: any) => {
          return typeof item !== "string" && item;
        })
      ) as any[];
      const prevousFileAttach = _.compact(
        listOfAttachment.map((item: any) => {
          return typeof item === "string" && item;
        })
      );
      try {
        if (newFileAttach.length > 0) {
          const urlList = await uploadAttachments(
            newFileAttach,
            selectedTruck,
            driverId,
            "driverVocs"
          );
          attachmentUrlList = urlList;
        }
        const updatedAttachment = prevousFileAttach.concat(
          attachmentUrlList
        ) as string[];

        await services.updateVOC(
          docId,
          editData.driverId,
          selectedTruck,
          getServerTimestamp(selectedDateIssued),
          name,
          updatedAttachment
        );
        await removedUploadedAttachements(removedAttachments);
        setSuccess(MSGS_COMMON.successUpdate);
        setLoading(false);
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        await removedUploadedAttachements(attachmentUrlList);

        setError(error);
        setLoading(false);
        Bugsnag.notify(new Error(error));
      }
    }
  };

  const addNewVOC = async (
    selectedTruckId: string,
    dateIssued: Date,
    name: string
  ) => {
    setLoading(true);
    let attachmentUrlList = [] as string[];
    try {
      if (listOfAttachment.length > 0) {
        const urlList = await uploadAttachments(
          listOfAttachment,
          selectedTruckId,
          driverId,
          "driverVocs"
        );
        attachmentUrlList = urlList;
      }

      await services.addDriverVOC(
        driverId,
        selectedTruckId,
        getServerTimestamp(dateIssued),
        name,
        attachmentUrlList
      );
      setLoading(false);
      setSuccess(MSGS_COMMON.success);
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      //remove the file in our storage
      await removedUploadedAttachements(attachmentUrlList);
      setLoading(false);
      setError(error);
      Bugsnag.notify(new Error(error));
    }
  };

  const deleteVOC = async () => {
    try {
      setLoading(true);
      if (!!editData && !!editData.docId) {
        const selectedTruckData = _.find(
          trucksWithDepotOptions,
          (truck) => truck.id === editData.truckId
        );
        await services.deleteVOC(
          editData.docId,
          editData.driverId,
          !_.isEmpty(selectedTruckData) ? selectedTruckData!.metadata.name : ""
        );
        if (!_.isEmpty(editData.attachments)) {
          await removedUploadedAttachements(editData.attachments!); //remove the file in our storage
        }
        setSuccess(MSGS_COMMON.successDelete);
      }
      setLoading(false);
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      setError(error);
      setLoading(false);
      Bugsnag.notify(new Error(error));
    }
  };

  const onAttachPhoto = async (file: any) => {
    if (
      listOfAttachment.length + file.target.files.length <=
      LIMITS.serviceTypeAttachments.count
    ) {
      if (!!file && file.target.files.length) {
        const attachments = file.target.files;
        const fileSizes = Object.values(attachments).map((attachment: any) => {
          return attachment.size <= LIMITS.serviceTypeAttachments.size;
        });
        const hasExceededFileSize = _.includes(fileSizes, false);
        if (!hasExceededFileSize) {
          const toBeUploadedAttachments = _.concat(
            Object.values(attachments),
            listOfAttachment
          );
          setListOfAttachment(toBeUploadedAttachments as []);
        } else {
          setError(MSGS_DRIVER.attachmentFileSizeExceed);
        }
      }
    } else {
      setError(MSGS_DRIVER.vocAttachmentsExceed);
    }
  };

  const onDrop = (event: DragEvent) => {
    event.preventDefault();
    if (listOfAttachment.length <= LIMITS.serviceTypeAttachments.count) {
      if (event.dataTransfer.files) {
        // Use DataTransferItemList interface to access the file(s)
        if (
          event.dataTransfer.files.length <= LIMITS.serviceTypeAttachments.count
        ) {
          const files = Object.values(event.dataTransfer.files);
          const fileSizes = Object.values(files).map((attachment: any) => {
            return attachment.size <= LIMITS.serviceTypeAttachments.size;
          });
          const hasExceededFileSize = _.includes(fileSizes, false);
          if (!hasExceededFileSize) {
            files.forEach((file) => {
              const imageType = /image.*/;
              if (
                file &&
                (file.type.match(imageType) || file.type === "application/pdf")
              ) {
                const attachments = Object.values(event.dataTransfer.files);
                const toBeUploadedAttachments = _.concat(
                  attachments,
                  listOfAttachment
                );
                setListOfAttachment(toBeUploadedAttachments as []);
              } else {
                setError(MSGS_DRIVER.invalidFileType);
              }
            });
          } else {
            setError(MSGS_DRIVER.attachmentFileSizeExceed);
          }
        } else {
          setError(MSGS_DRIVER.vocAttachmentsExceed);
        }
      }
    } else {
      setError(MSGS_DRIVER.vocAttachmentsExceed);
    }
  };

  const onRemoveAttachment = (attachment: any) => {
    const updatedListOfAttachment = _.remove(listOfAttachment, (item) => {
      return item !== attachment;
    });

    if (typeof attachment === "string") {
      setRemovedAttachments([...removedAttachments, attachment]);
    }
    setListOfAttachment(updatedListOfAttachment);
  };

  return (
    <>
      <EWPWebDialog
        headerTitle={!_.isEmpty(editData) ? "Edit VOC" : "Add VOC"}
        isOpen={isOpen}
        onDidDismiss={() => {
          onDidDismiss();
        }}
      >
        <div className="voc-dialog-form-container">
          <form onSubmit={submitForm}>
            <IonGrid className="ion-no-padding">
              <IonRow className="voc-dialog-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="voc-dialog-start-col ion-no-padding"
                >
                  <EWPWebDropdownSelect
                    disabled={_.isNull(trucksWithDepotOptions)}
                    dropdownItems={trucksWithDepotOptions || []}
                    onSelectItem={(id) => {
                      setSelectedTruck(id as string);
                      setErrorMsgSelectedTruck("");
                    }}
                    placeholder="Choose Truck"
                    value={selectedTruck}
                    errorMsg={errorMsgSelectedTruck}
                    hasSearch={true}
                  />
                </IonCol>
                <IonCol size="6" className="voc-dialog-end-col ion-no-padding">
                  <EWPDatePicker
                    className="service-date-input-date"
                    datePickerLabel="Date Issued"
                    selectedDate={selectedDateIssued}
                    onDateChange={(serviceDate: Date) => {
                      setSelectedDateIssued(serviceDate);
                      setErrorMsgDateIssued("");
                    }}
                    errorMsg={errorMsgDateIssued}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonGrid className="ion-no-padding">
              <IonRow className="voc-dialog-row ion-no-padding ion-no-margin">
                <IonCol size="12" className="ion-no-padding">
                  <div
                    className={`voc-dialog-upload-container ${
                      isTablet() && "is-tablet"
                    }`}
                  >
                    <div className="voc-dialog-title-button-container">
                      <IonLabel className="voc-dialog-upload-title-label ewp-h3 ion-no-padding ion-no-margin">
                        Upload Images
                      </IonLabel>
                      {!_.isEmpty(listOfAttachment) && (
                        <IonButton
                          mode="ios"
                          className="voc-dialog-attachments-add-another-button ewp-h6 ion-no-margin ion-no-padding"
                          color={EWPCOLORS.gray}
                          onClick={onClickUploadPhoto}
                        >
                          <IonIcon
                            className="voc-dialog-small-add-icon"
                            icon={add}
                          />
                          Add Another
                        </IonButton>
                      )}
                    </div>
                    {!_.isEmpty(listOfAttachment) ? (
                      <div className="web-upload-list-attachment-container">
                        <IonGrid className="ion-no-margin ion-no-padding">
                          {_.chunk(listOfAttachment, 8).map(
                            (row: any, index: any) => {
                              return (
                                <IonRow
                                  key={`row_${index}`}
                                  className="ion-no-margin ion-no-padding"
                                >
                                  {row.map((attachment: any, index: any) => {
                                    const fileUrl =
                                      typeof attachment === "string"
                                        ? attachment
                                        : "";
                                    const fileSplit = fileUrl
                                      .split("?")[0]
                                      .split(".");
                                    const fileTypeAttachment =
                                      fileSplit[
                                        fileSplit.length - 1
                                      ].toLowerCase();
                                    return (
                                      <IonCol
                                        key={`col_${index}`}
                                        size="1.5"
                                        className="ion-no-margin ion-no-padding"
                                      >
                                        <div className="web-upload-attachment-container">
                                          <IonButton
                                            className="remove-attachment-button ion-no-margin ion-no-padding"
                                            shape="round"
                                            color="primary"
                                            onClick={() =>
                                              onRemoveAttachment(attachment)
                                            }
                                          >
                                            <IonIcon
                                              className="web-upload-attachment-close-icon ion-no-margin ion-no-padding"
                                              icon={close}
                                            />
                                          </IonButton>
                                          {typeof attachment !== "string" &&
                                          !!attachment.type.match("image/*") ? (
                                            <IonThumbnail
                                              className="web-upload-attachment-avatar ion-no-margin ion-no-padding"
                                              slot="start"
                                            >
                                              <img
                                                id="attachmentPhoto"
                                                src={
                                                  typeof attachment === "string"
                                                    ? attachment
                                                    : URL.createObjectURL(
                                                        attachment
                                                      )
                                                }
                                                alt="attachmentPhoto"
                                              />
                                            </IonThumbnail>
                                          ) : fileTypeAttachment !== "pdf" &&
                                            typeof attachment === "string" ? (
                                            <IonThumbnail
                                              className="web-upload-attachment-avatar ion-no-margin ion-no-padding"
                                              slot="start"
                                            >
                                              <img
                                                id="attachmentPhoto"
                                                src={
                                                  typeof attachment === "string"
                                                    ? attachment
                                                    : URL.createObjectURL(
                                                        attachment
                                                      )
                                                }
                                                alt="attachmentPhoto"
                                              />
                                            </IonThumbnail>
                                          ) : (
                                            <div
                                              className="attachment-file-link-container"
                                              onMouseOver={(
                                                e: React.MouseEvent<
                                                  HTMLDivElement,
                                                  MouseEvent
                                                >
                                              ) => {
                                                // handleMouseOver(e, attachment.attachmentUrl || "");

                                                const rect =
                                                  e.currentTarget.getBoundingClientRect();
                                                setTooltipCoords({
                                                  left: rect.left,
                                                  top: rect.top,
                                                });
                                                if (
                                                  typeof attachment === "string"
                                                ) {
                                                  const fileName = attachment;

                                                  const decodedURL =
                                                    decodeURIComponent(
                                                      fileName
                                                    );
                                                  let urlObj = new URL(
                                                    decodedURL
                                                  );
                                                  let path = urlObj.pathname;

                                                  let parts = path.split("/");
                                                  let encodedFileName =
                                                    parts[parts.length - 1];

                                                  // Decode the file name
                                                  let fileNameList =
                                                    decodeURIComponent(
                                                      encodedFileName
                                                    ).split("_");
                                                  fileNameList.splice(0, 2);

                                                  setTooltipMessage(
                                                    fileNameList.join("_") || ""
                                                  );
                                                } else {
                                                  setTooltipMessage(
                                                    attachment.name
                                                  );
                                                }
                                              }}
                                              onMouseOut={() => {
                                                setTooltipMessage("");
                                              }}
                                            >
                                              <a
                                                target="blank"
                                                href={
                                                  typeof attachment === "string"
                                                    ? attachment
                                                    : URL.createObjectURL(
                                                        attachment
                                                      )
                                                }
                                              >
                                                View File
                                              </a>
                                            </div>
                                          )}
                                        </div>
                                      </IonCol>
                                    );
                                  })}
                                </IonRow>
                              );
                            }
                          )}
                        </IonGrid>
                      </div>
                    ) : (
                      <IonItem
                        lines="none"
                        className="voc-dialog-upload-item ewp-body ion-no-margin"
                        onClick={onClickUploadPhoto}
                        onDragOver={(event) => {
                          event.preventDefault();
                        }}
                        onDrop={onDrop}
                      >
                        <div className="web-upload-icon-label-container">
                          <IonIcon className="voc-dialog-upload-icon" />
                          <IonLabel className="voc-dialog-upload-label ewp-paragraph ion-no-margin ion-no-padding">
                            Drop files here to upload or <u>choose file</u>
                          </IonLabel>
                        </div>
                      </IonItem>
                    )}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonItem
              lines="none"
              className="ewp-item-no-horizontal-padding ewp-button-padding-top"
            >
              <IonButton
                mode="ios"
                slot="start"
                className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
                fill="clear"
                color={EWPCOLORS.medium}
                onClick={() => {
                  if (onDidDismiss) {
                    onDidDismiss();
                    clearFields();
                  }
                }}
              >
                Cancel
              </IonButton>
              {!_.isEmpty(editData) && (
                <IonButton
                  slot="end"
                  className="voc-dialog-delete-button ewp-h5 ion-no-margin ion-text-capitalize"
                  color={EWPCOLORS.gray}
                  onClick={() => {
                    setShowDeleteDialog(true);
                  }}
                >
                  Delete VOC
                  <IonRippleEffect />
                </IonButton>
              )}
              <IonButton
                mode="md"
                slot="end"
                className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
                color={EWPCOLORS.primary}
                type="submit"
              >
                {!_.isEmpty(editData) ? "Save" : "Add VOC"}
                <IonRippleEffect />
              </IonButton>
            </IonItem>
          </form>
          <input
            hidden
            type="file"
            ref={ref}
            accept="image/*,.pdf"
            onChange={onAttachPhoto}
            multiple
          />
        </div>
      </EWPWebDialog>
      {!_.isNull(calendarAnchor) && !_.isEmpty(calendarAnchor) && (
        <EWPCalendar
          calendarAnchor={calendarAnchor}
          selectedDate={
            _.isNull(selectedDateIssued) ? new Date() : selectedDateIssued
          }
          removeCalendarAnchor={() => {
            setCalendarAnchor(null);
          }}
          onDateChange={(selectedDate: Date) => {
            setSelectedDateIssued(selectedDate);
          }}
          minDate={new Date()}
        />
      )}
      <IonModal
        className="voc-dialog-delete-dialog"
        isOpen={showDeleteDialog}
        onDidDismiss={() => {
          setShowDeleteDialog(false);
        }}
      >
        <IonContent
          scrollY={false}
          className="voc-dialog-delete-dialog-content ion-no-padding"
        >
          <IonButton
            className="voc-dialog-delete-dialog-close-button-dialog ion-no-padding ion-no-margin"
            color={EWPCOLORS.light}
            fill="clear"
            onClick={() => {
              setShowDeleteDialog(false);
            }}
          >
            <IonIcon color={EWPCOLORS.light} icon={close} />
          </IonButton>
          <div className="voc-dialog-delete-dialog-title-container">
            <IonLabel className="ewp-h1 ion-text-center">
              Are you sure you want to delete this Service?
            </IonLabel>
            <IonLabel className="voc-dialog-delete-dialog-subtitle ewp-paragraph ion-text-center">
              Once you delete this Service, you will not be able to restore it.
            </IonLabel>
          </div>

          <IonItem
            lines="none"
            className="voc-dialog-delete-dialog-action-buttons-item ewp-item-no-horizontal-padding"
          >
            <IonButton
              mode="ios"
              slot="start"
              className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
              fill="clear"
              color={EWPCOLORS.medium}
              onClick={() => {
                setShowDeleteDialog(false);
              }}
            >
              Cancel
            </IonButton>
            <IonButton
              mode="ios"
              slot="end"
              className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
              color={EWPCOLORS.danger}
              onClick={() => {
                deleteVOC();
              }}
            >
              Yes, Delete
              <IonRippleEffect />
            </IonButton>
          </IonItem>
        </IonContent>
      </IonModal>
      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <EWPCommonTooltip
        left={tooltipCoords.left}
        top={tooltipCoords.top}
        content={tooltipMessage}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
          setShowDeleteDialog(false);
          onDidDismiss();
          clearFields();
        }}
        color={EWPCOLORS.success}
      />
    </>
  );
};
