import _ from "lodash";
import React, { useState } from "react";
import { chevronBack, chevronForward } from "ionicons/icons";
import { NavigateAction, View } from "react-big-calendar";
import { IonLabel, IonButton, IonIcon } from "@ionic/react";

import "./JobScheduleCalendarToolBar.scss";
import { EWPDivider } from "../../EWPDivider/EWPDivider";
import { EWPCOLORS } from "../../../constants/config";

export type JobSubFilter =
  | "dayJob"
  | "nightJob"
  | "completeJob"
  | "incompleteJob"
  | "cancelledJob"
  | "pencilledJob";
interface JobScheduleCalendarToolBarProps {
  view: string;
  jobType: number;
  label: string;
  onNavigate: (navigate: NavigateAction) => void;
  onView: (view: View) => void;
  onClickJobType: (jobType: number) => void;
  onChangeSubFilters: (filters: JobSubFilter[]) => void;
  onClickExport: () => void;
}

export const JobScheduleCalendarToolBar = (
  props: JobScheduleCalendarToolBarProps
) => {
  const {
    view,
    label,
    onNavigate,
    onView,
    onChangeSubFilters,
    onClickExport,
    // onClickJobType,
    // jobType,
  } = props;
  const [activeCalendarView, setActiveCalendarView] = useState(view as View);
  const [selectedSubOptions, setSelectedSubOptions] = useState(
    [] as JobSubFilter[]
  );

  return (
    <>
      <div className="ewp-rbcalendar-custom-toolbar-container">
        <IonButton
          fill="clear"
          className="ewp-rbcalendar-today-button ewp-paragraph"
          mode="ios"
          onClick={() => {
            onNavigate("TODAY");
          }}
        >
          Today
        </IonButton>
        <div className="ewp-rbcalendar-label-container">
          <IonButton
            mode="ios"
            fill="clear"
            color={EWPCOLORS.dark}
            className="ewp-rbcalendar-nav"
            onClick={() => {
              onNavigate("PREV");
            }}
          >
            <IonIcon icon={chevronBack} className="ion-no-padding" />
          </IonButton>
          <IonLabel className="ewp-rbcalendar-label ewp-paragraph bold dark">
            {label}
          </IonLabel>
          <IonButton
            mode="ios"
            fill="clear"
            color={EWPCOLORS.dark}
            className="ewp-rbcalendar-nav"
            onClick={() => {
              onNavigate("NEXT");
            }}
          >
            <IonIcon icon={chevronForward} className="ion-no-padding" />
          </IonButton>
        </div>
        <div className="ewp-rbcalender-onview-button-container">
          <IonButton
            fill={activeCalendarView === "day" ? "solid" : "clear"}
            className={`ewp-rbcalendar-view-button ewp-paragraph ${
              activeCalendarView === "day" ? "white" : "light"
            }`}
            mode="ios"
            onClick={() => {
              onView("day");
              setActiveCalendarView("day");
            }}
          >
            Day
          </IonButton>
          <IonButton
            fill={activeCalendarView === "week" ? "solid" : "clear"}
            className={`ewp-rbcalendar-view-button ewp-paragraph ${
              activeCalendarView === "week" ? "white" : "light"
            }`}
            mode="ios"
            onClick={() => {
              onView("week");
              setActiveCalendarView("week");
            }}
          >
            Week
          </IonButton>
          <IonButton
            fill={activeCalendarView === "work_week" ? "solid" : "clear"}
            className={`ewp-rbcalendar-view-button work_week ewp-paragraph ${
              activeCalendarView === "work_week" ? "white" : "light"
            }`}
            mode="ios"
            onClick={() => {
              onView("work_week");
              setActiveCalendarView("work_week");
            }}
          >
            Work Week
          </IonButton>
          <IonButton
            fill={activeCalendarView === "month" ? "solid" : "clear"}
            className={`ewp-rbcalendar-view-button ewp-paragraph ${
              activeCalendarView === "month" ? "white" : "light"
            }`}
            mode="ios"
            onClick={() => {
              onView("month");
              setActiveCalendarView("month");
            }}
          >
            Month
          </IonButton>
        </div>
      </div>
      <EWPDivider />
      <div className="ewp-rbcalendar-sub-toolbar-container">
        <div className="ewp-job-toolbar-header-container">
          {/* <div className="ewp-rbcalendar-button-container">
            <IonButton
              mode="ios"
              fill="clear"
              className={`ewp-rbcalendar-sub-tool-button ${
                jobType === 1 ? "active ewp-h5 white" : "ewp-h5 light"
              }`}
              onClick={() => {
                onClickJobType(1);
              }}
            >
              EWP
            </IonButton>
            <IonButton
              mode="ios"
              fill="clear"
              className={`ewp-rbcalendar-sub-tool-button ${
                jobType === 2 ? "active ewp-h5 white" : "ewp-h5 light"
              }`}
              onClick={() => {
                onClickJobType(2);
              }}
            >
              UBM
            </IonButton>
            <IonButton
              mode="ios"
              fill="clear"
              className={`ewp-rbcalendar-sub-tool-button ${
                jobType === 0 ? "active ewp-h5 white" : "ewp-h5 light"
              }`}
              onClick={() => {
                onClickJobType(0);
              }}
            >
              All
            </IonButton>
          </div> */}

          <div className="ewp-rbcalendar-button-container">
            <IonButton
              mode="ios"
              fill="clear"
              className={`ewp-rbcalendar-sub-tool-options-button ${
                selectedSubOptions.indexOf("dayJob") !== -1
                  ? "active ewp-h5 white"
                  : "ewp-h5 light"
              }`}
              shape="round"
              onClick={() => {
                let newSelectedSubOptions = _.cloneDeep(selectedSubOptions);
                if (newSelectedSubOptions.indexOf("dayJob") !== -1) {
                  _.remove(newSelectedSubOptions, (data) => data === "dayJob");
                } else {
                  newSelectedSubOptions.push("dayJob");
                }
                setSelectedSubOptions(newSelectedSubOptions);
                onChangeSubFilters(newSelectedSubOptions);
              }}
            >
              Day job
            </IonButton>
            <IonButton
              mode="ios"
              fill="clear"
              className={`ewp-rbcalendar-sub-tool-options-button ${
                selectedSubOptions.indexOf("nightJob") !== -1
                  ? "active ewp-h5 white"
                  : "ewp-h5 light"
              }`}
              shape="round"
              onClick={() => {
                let newSelectedSubOptions = _.cloneDeep(selectedSubOptions);
                if (newSelectedSubOptions.indexOf("nightJob") !== -1) {
                  _.remove(
                    newSelectedSubOptions,
                    (data) => data === "nightJob"
                  );
                } else {
                  newSelectedSubOptions.push("nightJob");
                }
                setSelectedSubOptions(newSelectedSubOptions);
                onChangeSubFilters(newSelectedSubOptions);
              }}
            >
              Night job
            </IonButton>
            <IonButton
              mode="ios"
              fill="clear"
              className={`ewp-rbcalendar-sub-tool-options-button ${
                selectedSubOptions.indexOf("completeJob") !== -1
                  ? "active ewp-h5 white"
                  : "ewp-h5 light"
              }`}
              shape="round"
              onClick={() => {
                let newSelectedSubOptions = _.cloneDeep(selectedSubOptions);
                if (newSelectedSubOptions.indexOf("completeJob") !== -1) {
                  _.remove(
                    newSelectedSubOptions,
                    (data) => data === "completeJob"
                  );
                } else {
                  newSelectedSubOptions.push("completeJob");
                }
                setSelectedSubOptions(newSelectedSubOptions);
                onChangeSubFilters(newSelectedSubOptions);
              }}
            >
              Complete job
            </IonButton>

            <IonButton
              mode="ios"
              fill="clear"
              className={`ewp-rbcalendar-sub-tool-options-button ${
                selectedSubOptions.indexOf("incompleteJob") !== -1
                  ? "active ewp-h5 white"
                  : "ewp-h5 light"
              }`}
              shape="round"
              onClick={() => {
                let newSelectedSubOptions = _.cloneDeep(selectedSubOptions);
                if (newSelectedSubOptions.indexOf("incompleteJob") !== -1) {
                  _.remove(
                    newSelectedSubOptions,
                    (data) => data === "incompleteJob"
                  );
                } else {
                  newSelectedSubOptions.push("incompleteJob");
                }
                setSelectedSubOptions(newSelectedSubOptions);
                onChangeSubFilters(newSelectedSubOptions);
              }}
            >
              Incomplete job
            </IonButton>
            <IonButton
              mode="ios"
              fill="clear"
              className={`ewp-rbcalendar-sub-tool-options-button ${
                selectedSubOptions.indexOf("cancelledJob") !== -1
                  ? "active ewp-h5 white"
                  : "ewp-h5 light"
              }`}
              shape="round"
              onClick={() => {
                let newSelectedSubOptions = _.cloneDeep(selectedSubOptions);
                if (newSelectedSubOptions.indexOf("cancelledJob") !== -1) {
                  _.remove(
                    newSelectedSubOptions,
                    (data) => data === "cancelledJob"
                  );
                } else {
                  newSelectedSubOptions.push("cancelledJob");
                }
                setSelectedSubOptions(newSelectedSubOptions);
                onChangeSubFilters(newSelectedSubOptions);
              }}
            >
              Cancelled job
            </IonButton>
            <IonButton
              mode="ios"
              fill="clear"
              className={`ewp-rbcalendar-sub-tool-options-button ${
                selectedSubOptions.indexOf("pencilledJob") !== -1
                  ? "active ewp-h5 white"
                  : "ewp-h5 light"
              }`}
              shape="round"
              onClick={() => {
                let newSelectedSubOptions = _.cloneDeep(selectedSubOptions);
                if (newSelectedSubOptions.indexOf("pencilledJob") !== -1) {
                  _.remove(
                    newSelectedSubOptions,
                    (data) => data === "pencilledJob"
                  );
                } else {
                  newSelectedSubOptions.push("pencilledJob");
                }
                setSelectedSubOptions(newSelectedSubOptions);
                onChangeSubFilters(newSelectedSubOptions);
              }}
            >
              Pencilled job
            </IonButton>
          </div>
        </div>

        <div className="ewp-job-toolbar-subheader-container">
          <div className="ewp-job-legends-container">
            <div className="ewp-job-legend-item-container">
              <div className="ewp-job-legend-icon day"></div>
              <IonLabel className="ewp-job-legend-label day ewp-6">
                - Day job
              </IonLabel>
            </div>
            <div className="ewp-job-legend-item-container">
              <div className="ewp-job-legend-icon night"></div>
              <IonLabel className="ewp-job-legend-label night ewp-6">
                - Night job
              </IonLabel>
            </div>
            <div className="ewp-job-legend-item-container">
              <div className="ewp-job-legend-icon complete"></div>
              <IonLabel className="ewp-job-legend-label complete ewp-6">
                - Complete job
              </IonLabel>
            </div>
            <div className="ewp-job-legend-item-container">
              <div className="ewp-job-legend-icon incomplete"></div>
              <IonLabel className="ewp-job-legend-label incomplete ewp-6">
                - Incomplete job
              </IonLabel>
            </div>
            <div className="ewp-job-legend-item-container">
              <div className="ewp-job-legend-icon cancelled"></div>
              <IonLabel className="ewp-job-legend-label cancelled ewp-6">
                - Cancelled job
              </IonLabel>
            </div>
            <div className="ewp-job-legend-item-container">
              <div className="ewp-job-legend-icon pencilled"></div>
              <IonLabel className="ewp-job-legend-label pencilled ewp-6">
                - Pencilled job
              </IonLabel>
            </div>
          </div>

          <IonButton
            mode="ios"
            fill="clear"
            className="ewp-rbcalendar-export-button ion-no-padding ion-no-margin"
            onClick={() => {
              onClickExport();
            }}
          >
            <IonIcon className="export-icon" />
          </IonButton>
        </div>
      </div>
    </>
  );
};
