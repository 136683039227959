import moment from "moment";
import Bugsnag from "@bugsnag/js";
import * as _ from "lodash";
import {
  IonCheckbox,
  IonContent,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonSlide,
  IonSlides,
  withIonLifeCycle,
} from "@ionic/react";
import React from "react";

import "./DriverJobSchedule.scss";
import * as routes from "../../../constants/routes";
import * as services from "../../../services";
import { getDateFromTimezoneIgnoredTimestamp } from "../../../functions/common";
import { DriverJobScheduleList } from "../../../components/Mobile/DriverJobScheduleList/DriverJobScheduleList";
import { DriverJobScheduleDetail } from "../../../components/Mobile/DriverJobScheduleDetail/DriverJobScheduleDetail";
import { DriverJobScheduleSiteDocket } from "../../../components/Mobile/DriverJobScheduleSiteDocket/DriverJobScheduleSiteDocket";
import { EWPHeader } from "../../../components/Mobile/EWPHeader/EWPHeader";
import { EWPUserAccountFooter } from "../../../components/Mobile/EWPUserAccountFooter/EWPUserAccountFooter";
import { EWPProps } from "../../../config/global";
import { JobScheduleView } from "../../../models";
import { EWPCOLORS } from "../../../constants/config";
import { MSGS_COMMON } from "../../../constants/messages";
import { DriverSendNotes } from "../../../components/Mobile/DriverSendNotes/DriverSendNotes";

const dateMin = _.clone(new Date());
dateMin.setHours(0);
dateMin.setMinutes(0);
dateMin.setSeconds(0);
dateMin.setMilliseconds(0);

const dateMax = _.clone(new Date());
dateMax.setHours(23);
dateMax.setMinutes(59);
dateMax.setSeconds(59);
dateMax.setMilliseconds(59);
class DriverJobSchedule extends React.Component<EWPProps> {
  sliderRef: React.RefObject<HTMLIonSlidesElement>;
  contentRef: React.RefObject<HTMLIonContentElement>;

  state = {
    pageIndex: 0,
    loading: true,
    safeToView: false,
    driverJobSchedules: null as JobScheduleView[] | null,
    selectedJobSchedule: null as JobScheduleView | null,
    filteredDriverJobSchedule: null as JobScheduleView[] | null,
    showHistoricalNotComplete: false,
    isToggleButtonChecked: false,
    error: "",
  };

  pages = {
    jobScheduleList: 0,
    viewSchedule: 1,
    docketsPage: 2,
    sendDriverNotesPage: 3,
  };

  constructor(props: EWPProps) {
    super(props);
    this.sliderRef = React.createRef();
    this.contentRef = React.createRef();
  }

  ionViewDidEnter = () => {
    setTimeout(() => {
      this.setState({ safeToView: true });
    });
    this.getJobSchedulesRealTimeToday();
  };

  goToPage = (page: number) => {
    if (!!this.sliderRef && !!this.sliderRef.current) {
      this.sliderRef.current.lockSwipes(false);
      this.sliderRef.current.slideTo(page);
      this.sliderRef.current.lockSwipes(true);
    }
  };

  getJobSchedulesRealTimeToday = async () => {
    // as this is a mother component, put to ref and unsubscribe once logout
    await services.getDriverJobSchdules(
      this.props.authUser.docId,
      (jobSchedule, error) => {
        // console.log("GOT JOB DRIVER SCHED", jobSchedule);
        this.setState({
          driverJobSchedules: _.filter(
            jobSchedule,
            (sched) => sched.notifyDriver
          ),
          error,
          loading: false,
        });
      }
    );
  };
  setSelectedJobSchedule = (scheduleId: string) => {
    const { driverJobSchedules } = this.state;
    const selectedJobSchedule = _.find(driverJobSchedules, (schedule) => {
      return schedule.docId === scheduleId;
    });
    if (
      !!selectedJobSchedule &&
      _.isEmpty(selectedJobSchedule.notificationReadAtNoTimeZone)
    ) {
      this.setJobScheduleAlreadyRead(scheduleId);
    }
    this.goToPage(this.pages.viewSchedule);
    this.setState({ selectedJobSchedule, pageIndex: this.pages.viewSchedule });
  };

  setJobScheduleAlreadyRead = async (jobScheduleId: string) => {
    try {
      await services.updateNotificationReadJobSchedule(jobScheduleId);
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("error -- setJobScheduleAlreadyRead -- ", error);
      Bugsnag.notify(new Error(error));
    }
  };

  onSuccessRefresh = (scheduleId: string) => {
    this.setState({ selectedJobSchedule: null });
    this.setSelectedJobSchedule(scheduleId);
  };

  onSuccessJobCompleted = () => {
    this.goToPage(this.pages.jobScheduleList);
    this.setState({
      pageIndex: this.pages.viewSchedule,
      filteredDriverJobSchedule: null,
      isToggleButtonChecked: false,
    });
  };

  toUploadSiteDocketsPage = () => {
    this.goToPage(this.pages.docketsPage);
    this.setState({ pageIndex: this.pages.docketsPage });
  };
  toSendDriverNotesPage = () => {
    this.goToPage(this.pages.sendDriverNotesPage);
    this.setState({ pageIndex: this.pages.sendDriverNotesPage });
  };

  toggleShowHistoricalNotComplete = (showHistoricalNotComplete: boolean) => {
    const { driverJobSchedules } = this.state;
    if (!!showHistoricalNotComplete) {
      this.setState({
        filteredDriverJobSchedule: _.orderBy(
          _.filter(driverJobSchedules, (driverBooking) => {
            const dateMin = _.clone(new Date());
            dateMin.setHours(0);
            dateMin.setMinutes(0);
            dateMin.setSeconds(0);
            dateMin.setMilliseconds(0);

            const dateMax = _.clone(
              getDateFromTimezoneIgnoredTimestamp(
                driverBooking.endDateNoTimeZone
              )
            );
            dateMax.setHours(23);
            dateMax.setMinutes(59);
            dateMax.setSeconds(59);
            dateMax.setMilliseconds(59);
            return (
              !_.isEmpty(driverBooking.jobCompletedAtNoTimeZone) ||
              moment(dateMin).isAfter(moment(dateMax))
            );
          })
        ),
      });
    } else {
      this.setState({
        filteredDriverJobSchedule: null,
      });
    }
  };
  render = () => {
    const {
      safeToView,
      pageIndex,
      driverJobSchedules,
      filteredDriverJobSchedule,
      selectedJobSchedule,
      isToggleButtonChecked,
    } = this.state;
    console.log("pageIndex --- ", pageIndex);

    console.log(filteredDriverJobSchedule);

    const driverScheduleSorted = _.orderBy(
      _.filter(driverJobSchedules || [], (driverBooking) => {
        return (
          _.isEmpty(driverBooking.jobCompletedAtNoTimeZone) &&
          moment(dateMin).isSameOrBefore(
            getDateFromTimezoneIgnoredTimestamp(driverBooking.endDateNoTimeZone)
          )
        );
      }),
      (job) => getDateFromTimezoneIgnoredTimestamp(job.startDateNoTimeZone),
      "desc"
    );

    return (
      <IonPage
        className={`driver-sched-page ${safeToView ? "safe-to-view" : ""}`}
      >
        <EWPHeader
          title="Job Schedule"
          hideBackButton={pageIndex === 0}
          onBackRoute={() => {
            if (pageIndex === this.pages.viewSchedule) {
              this.goToPage(this.pages.jobScheduleList);
              this.setState({ pageIndex: this.pages.jobScheduleList });
            } else if (
              pageIndex === this.pages.docketsPage ||
              pageIndex === this.pages.sendDriverNotesPage
            ) {
              if (!!selectedJobSchedule) {
                this.onSuccessRefresh(selectedJobSchedule.docId as string);
                this.goToPage(this.pages.viewSchedule);
                this.setState({ pageIndex: this.pages.viewSchedule });
              }
            }
          }}
          color={EWPCOLORS.primary}
        />
        <IonContent ref={this.contentRef}>
          <IonSlides
            ref={this.sliderRef}
            className="driver-sched-slide-container"
            options={{ autoHeight: true }}
            onIonSlidesDidLoad={async () => {
              if (!_.isNull(this.sliderRef.current)) {
                this.sliderRef.current!.lockSwipes(true);
                const activeIndex =
                  await this.sliderRef.current!.getActiveIndex();
                this.setState({ pageIndex: activeIndex });
              }
            }}
            onIonSlideDidChange={async () => {
              if (!!this.contentRef && !!this.contentRef.current) {
                this.contentRef.current.scrollToTop();
              }

              if (!_.isNull(this.sliderRef.current)) {
                const activeIndex =
                  await this.sliderRef.current!.getActiveIndex();
                this.setState({ pageIndex: activeIndex });
              }
            }}
          >
            <IonSlide
              className="driver-sched-slide-job-schedule"
              tabIndex={this.pages.jobScheduleList}
            >
              {pageIndex === this.pages.jobScheduleList &&
              !_.isEmpty(driverJobSchedules) ? (
                <div className="job-list-container">
                  <div className="driver-job-schedule-button-toggle-container">
                    <IonItem
                      lines="none"
                      className="driver-job-schedule-button-toggle-box-item ion-no-padding"
                    >
                      <IonCheckbox
                        className="driver-job-schedule-button-toggle-checkbox"
                        slot="end"
                        color="success"
                        checked={isToggleButtonChecked}
                        onIonChange={() => {
                          this.toggleShowHistoricalNotComplete(
                            !isToggleButtonChecked
                          );

                          this.setState({
                            isToggleButtonChecked: !isToggleButtonChecked,
                          });
                        }}
                      />
                      <IonLabel
                        className="driver-job-schedule-button-toggle-checkbox-label ewp-h6"
                        slot="end"
                      >
                        Show Historical and Not Completed Jobs
                      </IonLabel>
                    </IonItem>
                  </div>
                  {!_.isNull(filteredDriverJobSchedule) &&
                    !!driverJobSchedules && (
                      <DriverJobScheduleList
                        key={driverJobSchedules
                          .map((jobschedule, index) => {
                            return `driver_filtertedschedule_${index}`;
                          })
                          .join()}
                        jobSchedules={filteredDriverJobSchedule}
                        onViewSchedule={(selectedScheduleId) => {
                          this.setSelectedJobSchedule(selectedScheduleId);
                        }}
                      />
                    )}

                  {!_.isEmpty(driverScheduleSorted) &&
                    _.isNull(filteredDriverJobSchedule) &&
                    !!driverJobSchedules && (
                      <DriverJobScheduleList
                        key={driverJobSchedules
                          .map((jobschedule, index) => {
                            return `driver_schedule_${index}`;
                          })
                          .join()}
                        jobSchedules={driverScheduleSorted}
                        onViewSchedule={(selectedScheduleId) => {
                          this.setSelectedJobSchedule(selectedScheduleId);
                        }}
                      />
                    )}
                </div>
              ) : (
                <IonLabel className="ewp-h4 bold light ion-padding">
                  No Job Assigned
                </IonLabel>
              )}
            </IonSlide>

            <IonSlide
              className="driver-sched-slide-view-schedule"
              tabIndex={this.pages.viewSchedule}
            >
              {pageIndex === this.pages.viewSchedule &&
                !_.isNull(selectedJobSchedule) && (
                  <DriverJobScheduleDetail
                    jobSchedule={selectedJobSchedule}
                    onSuccessRefresh={this.onSuccessRefresh}
                    uploadSiteDockets={this.toUploadSiteDocketsPage}
                    onSuccessJobCompleted={this.onSuccessJobCompleted}
                    sendDriverNotes={this.toSendDriverNotesPage}
                  />
                )}
            </IonSlide>
            <IonSlide
              className="driver-sched-slide-dockets"
              tabIndex={this.pages.docketsPage}
            >
              {pageIndex === this.pages.docketsPage &&
                !_.isNull(selectedJobSchedule) && (
                  <DriverJobScheduleSiteDocket
                    {...this.props}
                    jobSchedule={selectedJobSchedule}
                  />
                )}
            </IonSlide>
            <IonSlide
              className="driver-sched-slide-dockets"
              tabIndex={this.pages.sendDriverNotesPage}
            >
              {pageIndex === this.pages.sendDriverNotesPage &&
                !_.isNull(selectedJobSchedule) && (
                  <DriverSendNotes
                    {...this.props}
                    jobSchedule={selectedJobSchedule}
                    onSuccessSend={() => {
                      this.onSuccessRefresh(
                        selectedJobSchedule.docId as string
                      );
                      this.goToPage(this.pages.viewSchedule);
                      this.setState({ pageIndex: this.pages.viewSchedule });
                    }}
                  />
                )}
            </IonSlide>
          </IonSlides>
        </IonContent>
        <EWPUserAccountFooter
          activePage="jobSchedule"
          onMaintenanceClick={() => {
            this.props.history.push(routes.DRIVER_MAINTENANCE);
          }}
          onDriverClick={() => {
            this.props.history.push(routes.DRIVER_ACCOUNT);
          }}
          onJobScheduleClick={() => {}}
          onLogout={async () => {
            await services.driverLogout();
          }}
        />
        <IonLoading
          spinner="circular"
          translucent={true}
          mode="ios"
          isOpen={_.isNull(driverJobSchedules)}
          message={MSGS_COMMON.loading}
        />
      </IonPage>
    );
  };
}

export default withIonLifeCycle(DriverJobSchedule);
