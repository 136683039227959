import moment from "moment";
import Calendar from "react-calendar";
import React from "react";
import { IonIcon } from "@ionic/react";
import { caretBack, caretForward, playBack, playForward } from "ionicons/icons";

import "./EWPMiniCalendar.scss";
import { EWPDivider } from "../EWPDivider/EWPDivider";

interface EWPMiniCalendarProps {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
  maxDate?: Date;
  minDate?: Date;
}
export const EWPMiniCalendar = (props: EWPMiniCalendarProps) => {
  const { selectedDate, maxDate, minDate, onDateChange } = props;
  return (
    <div className="ewp-mini-calendar-container">
      <Calendar
        className="ewp-custom-mini-calendar"
        value={selectedDate}
        onChange={(date: Date | Date[]) => {
          onDateChange(date as Date);
        }}
        showNavigation={true}
        locale="en-GB"
        minDetail="year"
        maxDate={maxDate}
        minDate={minDate}
        prevLabel={<IonIcon mode="md" icon={caretBack} />}
        nextLabel={<IonIcon mode="md" icon={caretForward} />}
        prev2Label={<IonIcon mode="md" icon={playBack} />}
        next2Label={<IonIcon mode="md" icon={playForward} />}
        formatShortWeekday={(local: string, date: Date) => {
          const weekday = moment(date).format("ddd")[0];
          return weekday;
        }}
        tileClassName="ewp-profile-mini-calendar-tile"
      />
      <EWPDivider />
    </div>
  );
};
