import { RouteComponentProps } from "react-router";

import { User } from "../models/global";

export type UserType = 1 | 2;

export type Role = "drivers" | "admins";

export type AdminAccess = 1 | 2;

export type FaultDefectStatus = 1 | 2 | 3 | 4;
export type FaultDefectUrgency = 1 | 2;

export interface AdminAccessInterface {
  id: AdminAccess;
  access: string;
  key: string;
}

export const ADMIN_ACCESS: { [key: string]: AdminAccessInterface } = {
  admin: {
    id: 1 as AdminAccess,
    access: "Admin",
    key: "admin",
  },
  mechanic: {
    id: 2 as AdminAccess,
    access: "Mechanic",
    key: "mechanic",
  },
};

export const ADMIN_ACCESS_KEYS = {
  [ADMIN_ACCESS.admin.id]: ADMIN_ACCESS.admin.key,
  [ADMIN_ACCESS.mechanic.id]: ADMIN_ACCESS.mechanic.key,
};

export interface FaultDefectStatusInterface {
  id: FaultDefectStatus;
  status: string;
  key: string;
}

export const FAULT_DEFECT_STATUS: {
  [key: string]: FaultDefectStatusInterface;
} = {
  open: {
    id: 1 as FaultDefectStatus,
    status: "Open",
    key: "open",
  },
  inProgress: {
    id: 2 as FaultDefectStatus,
    status: "In Progress",
    key: "inProgress",
  },
  closed: {
    id: 3 as FaultDefectStatus,
    status: "Closed",
    key: "closed",
  },
  waitingOnParts: {
    id: 4 as FaultDefectStatus,
    status: "Waiting on Parts",
    key: "waitingOnParts",
  },
};

export const FAULT_DEFECT_STATUS_KEYS = {
  [FAULT_DEFECT_STATUS.open.id]: FAULT_DEFECT_STATUS.open.key,
  [FAULT_DEFECT_STATUS.inProgress.id]: FAULT_DEFECT_STATUS.inProgress.key,
  [FAULT_DEFECT_STATUS.closed.id]: FAULT_DEFECT_STATUS.closed.key,
};

export interface FaultDefectUrgencyInterface {
  id: FaultDefectUrgency;
  urgency: string;
  key: string;
}

export const FAULT_DEFECT_URGENCY: {
  [key: string]: FaultDefectUrgencyInterface;
} = {
  urgent: {
    id: 1 as FaultDefectUrgency,
    urgency: "Urgent",
    key: "urgent",
  },
  nonUrgent: {
    id: 2 as FaultDefectUrgency,
    urgency: "Non-Urgent",
    key: "nonUrgent",
  },
};

export const FAULT_DEFECT_URGENCY_KEYS = {
  [FAULT_DEFECT_URGENCY.urgent.id]: FAULT_DEFECT_URGENCY.urgent.key,
  [FAULT_DEFECT_URGENCY.nonUrgent.id]: FAULT_DEFECT_URGENCY.nonUrgent.key,
};

export interface AuthUser extends User {
  docId: string;
  role: Role;
}

export const USER_ROLE = {
  drivers: "drivers" as Role,
  admins: "admins" as Role,
};

export interface EWPProps extends RouteComponentProps {
  authUser: AuthUser;
  noInternetConnection?: boolean;
}

export interface DetailIdParam {
  detailId: string;
  pageParam?: string;
}

export type HomeSubPages =
  | "job-schedule"
  | "driver-register"
  | "truck-register"
  | "client-register"
  | "Admin";

export interface EWPTableProps {
  headers: {
    label: string;
    key: string;
    sortable: boolean;
  }[];
  columns: string[];
}

export interface TextEditorEdit {
  title: string;
  description: string;
  listOfAttachment?: any[];
}
