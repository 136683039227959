import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { IonIcon, IonLabel, IonToast } from "@ionic/react";

import "./TruckList.scss";
import * as routes from "../../../constants/routes";

import { EWPDataTable } from "../../EWPDataTable/EWPDataTable";
import { EWPTitleSearchWithButton } from "../../EWPTitleSearchWithButton/EWPTitleSearchWithButton";
import { EWPTableProps, EWPProps, ADMIN_ACCESS } from "../../../config/global";
import { Truck, TruckView } from "../../../models/drivers";
import { EWPCOLORS } from "../../../constants/config";
import { JOBS_WITH_KEYS, JobType } from "../../../models/jobSchedule";
import { warning } from "ionicons/icons";
import { Admin } from "../../../models";

interface TruckListProps extends EWPProps {
  trucks: TruckView[] | null;
}

interface SortKeys {
  truckName: "asc" | "desc";
  [key: string]: "asc" | "desc";
}

export const TruckList = (props: TruckListProps) => {
  const { trucks } = props;
  const [filteredTruckData, setFilteredTruckData] = useState(
    null as null | TruckView[]
  );
  const [sortLabelsAndDirection, setSortLabelsAndDirection] = useState({
    truckName: "desc",
    name: "asc",
  } as SortKeys);

  const [initialized, setInitialized] = useState(false);

  const [error, setError] = useState("");
  const showArchived = (toggled: boolean) => {
    if (toggled) {
      // const filteredTruckData = _.filter(trucks, (truck: any) => {
      //   return truck.archived === true;
      // });
      setFilteredTruckData(trucks);
    } else {
      setFilteredTruckData(null);
    }
  };

  const onRowRedirect = (rowData: any) => {
    if (!_.isEmpty(rowData) && !!rowData.docId) {
      props.history.push(
        routes.HOME_TRUCK_REGISTER_DETAILS.replace(
          ":truckId",
          rowData.docId || ""
        )
      );
    }
  };

  const sort = (key: string) => {
    const keyToSortByNumber = ["name"]; //! REFACTOR SOON
    const sortBy = sortLabelsAndDirection[key] === "desc" ? "asc" : "desc";
    console.log("SORTING", key, sortBy);
    const toBeSort =
      !!filteredTruckData && !_.isEmpty(filteredTruckData)
        ? filteredTruckData
        : _.filter(trucks, (truck: any) => {
            return !truck.archived;
          });
    const updateSort = {} as SortKeys;
    updateSort[key] = sortBy;
    const sortedData = _.orderBy(
      toBeSort.map((sortData) => {
        return {
          ...sortData,
          size: parseInt(sortData.size as string) as number,
        };
      }),
      (data) => {
        const dataValue = data[key as keyof TruckView];
        let regex =
          !_.isEmpty(dataValue) && _.isString(dataValue)
            ? data[key as keyof TruckView]!.toString().match(/\d+/)
            : null;

        return keyToSortByNumber.indexOf(key) !== -1
          ? !_.isNull(regex)
            ? regex.toString().padStart(16, "0")
            : -1
          : key;
      },
      sortBy
    );
    setFilteredTruckData(sortedData);

    setSortLabelsAndDirection({
      ...sortLabelsAndDirection,
      ...updateSort,
    });
  };

  const getTableDef = () => {
    let headers = [
      { label: "Truck Name", key: "name", sortable: true, direction: "asc" },
      {
        label: "Size",
        key: "size",
        sortable: true,
      },
      { label: "Type", key: "type", sortable: true },
      { label: "RMS ID", key: "rmsTransportNumber", sortable: true },
      { label: "RMS Expiry", key: "rmsExpiryDateStringView", sortable: true },
      { label: "Rego Expiry", key: "regoExpiryDateStringView", sortable: true },
      { label: "COI Expiry", key: "coiExpiryDateStringView", sortable: true },
      { label: "Depot", key: "depotName", sortable: true },
    ];

    let columns = [
      "name",
      "size",
      "type",
      "rmsTransportNumber",
      "rmsExpiryDateStringView",
      "regoExpiryDateStringView",
      "coiExpiryDateStringView",
      "depotName",
    ];

    return { headers, columns } as EWPTableProps;
  };

  const renderCell = (value: string, rowData: TruckView) => {
    const columnName = value as keyof Truck;
    switch (value) {
      case "name":
        return (
          <IonLabel
            className={`ewp-h5 bold light ${rowData.archived ? "danger" : ""}`}
          >
            {rowData[columnName]}
          </IonLabel>
        );
      case "size":
        return (
          <IonLabel
            className={`ewp-paragraph ${rowData.archived ? "danger" : ""}`}
          >
            {rowData[columnName]}
          </IonLabel>
        );
      case "type":
        return (
          <IonLabel
            className={`ewp-paragraph ${rowData.archived ? "danger" : ""}`}
          >
            {JOBS_WITH_KEYS[rowData[columnName] as JobType].name}
          </IonLabel>
        );
      case "rmsTransportNumber":
        return (
          <IonLabel
            className={`ewp-paragraph ${rowData.archived ? "danger" : ""}`}
          >
            {rowData[columnName]}
          </IonLabel>
        );
      case "regoExpiryDateStringView":
        return (
          <IonLabel
            className={`ewp-paragraph ${rowData.archived ? "danger" : ""}`}
          >
            {rowData[columnName]}
          </IonLabel>
        );
      case "coiExpiryDateStringView":
        return (
          <IonLabel
            className={`ewp-paragraph ${rowData.archived ? "danger" : ""}`}
          >
            {rowData[columnName]}
          </IonLabel>
        );
      case "depotName":
        return (
          <IonLabel
            className={`ewp-paragraph global-flex ion-justify-content-between ${
              rowData.archived ? "danger" : ""
            }`}
          >
            {!!rowData[columnName] && !_.isEmpty(rowData[columnName])
              ? rowData[columnName]
              : "N/A"}
            {rowData.hasDueExpiry ? (
              <IonIcon color={EWPCOLORS.danger} icon={warning} />
            ) : (
              rowData.hasWarningExpiry && (
                <IonIcon color={EWPCOLORS.warning} icon={warning} />
              )
            )}
          </IonLabel>
        );
      default:
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
    }
  };

  useEffect(() => {
    if (!initialized && !_.isNull(trucks) && !_.isEmpty(trucks)) {
      sort("name");
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, trucks]);

  console.log("trucks --- ", { trucks, sortLabelsAndDirection });
  return (
    <>
      <div className="truck-list-container">
        <div className="truck-register-header-container">
          <EWPTitleSearchWithButton
            {...props}
            toggleButtonFunction={showArchived}
            toggleButtonLabel="View Archived"
            pageTitle="Truck Register"
            {...((props.authUser as unknown as Admin).access !==
              ADMIN_ACCESS.mechanic.id && {
              actionButtonLabel: "New Truck",
              actionCallBack: () => {
                props.history.push(routes.HOME_ADD_TRUCK);
              },
            })}
          />
        </div>
        <div className="truck-list-table-container">
          <EWPDataTable
            tableDef={getTableDef()}
            tableData={
              !_.isNull(trucks)
                ? _.filter(trucks, (truck) => !truck.archived)
                : null
            }
            tableFilteredData={filteredTruckData}
            renderCell={renderCell}
            sortLabelsAndDirection={sortLabelsAndDirection}
            onSort={sort}
            onRowRedirect={onRowRedirect}
            isComponentPage={true}
          />
        </div>
      </div>
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />
    </>
  );
};
