import React, { useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
} from "@ionic/react";

import "./ArchivedBanner.scss";
import { EWPCOLORS } from "../../constants/config";
import { EWPProps } from "../../config/global";
import { ClientContactPersonDetailedView } from "../../models";
import { TruckView } from "../../models/drivers";
import { ArchiveOrRestoreTruckDialog } from "../ArchiveOrRestoreTruckDialog/ArchiveOrRestoreTruckDialog";
import { DriverListView } from "../Web/DriverList/DriverList";
import _ from "lodash";

interface ArchivedBannerProps extends EWPProps {
  mode: "truck" | "driver" | "client";
  data: TruckView | ClientContactPersonDetailedView | DriverListView;
}

export const ArchivedBanner = (props: ArchivedBannerProps) => {
  const { authUser, data, mode } = props;
  const [showArchivedRestoreDialog, setShowArchivedRestoreDialog] =
    useState(false);

  return (
    <IonCard className="archived-banner-indicator ion-no-padding ion-no-margin">
      <IonCardContent className="ion-no-padding">
        <IonItem lines="none" className="archived-banner-indicator-item">
          <IonIcon className="archived-banner-indicator-icon" />
          <IonLabel className="archived-banner-indicator-label ewp-paragraph white">
            This {mode} has been archived.
            {authUser !== undefined && (
              <>
                <IonButton
                  className="archived-banner-indicator-button ion-no-margin ion-text-capitalize"
                  fill="clear"
                  color={EWPCOLORS.tertiary}
                  onClick={() => setShowArchivedRestoreDialog(true)}
                >
                  Click Here
                </IonButton>
                to restore this {mode}
              </>
            )}
          </IonLabel>
        </IonItem>
      </IonCardContent>

      {data !== undefined && !_.isEmpty(data) && (
        <ArchiveOrRestoreTruckDialog
          mode={mode}
          restore={
            (
              data as
                | TruckView
                | ClientContactPersonDetailedView
                | DriverListView
            ).archived
          }
          isOpen={showArchivedRestoreDialog}
          data={data}
          onDidDismiss={() => {
            setShowArchivedRestoreDialog(false);
          }}
          onSuccess={() => {
            setShowArchivedRestoreDialog(false);
          }}
        />
      )}
    </IonCard>
  );
};
