import * as _ from "lodash";
import moment from "moment";
import { isPlatform } from "@ionic/react";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useEffect } from "react";
import { Geolocation } from "@capacitor/geolocation";

import * as capacitorStorage from "./localStorageCapacitor";
import { fb } from "../firebase";
import { storage } from "../firebase";
import { SiteDocket } from "../models";
import { JOB_TIME_SHIFTS } from "../constants/config";
import { LOCAL_STORAGE } from "../config/index";
import { View } from "react-big-calendar";

let { json2excel } = require("js2excel");

export interface TimezoneIgnoredTimeStamp {
  year: number;
  month: number;
  date: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
}

export const timezoneIgnoredTimestamp = (date: Date) => {
  return {
    year: date.getFullYear(),
    month: date.getMonth(),
    date: date.getDate(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
    milliseconds: date.getMilliseconds(),
  };
};

export const getDateFromTimezoneIgnoredTimestamp = (
  timezoneIgnoredTimestamp: TimezoneIgnoredTimeStamp
) => {
  return new Date(
    timezoneIgnoredTimestamp.year,
    timezoneIgnoredTimestamp.month,
    timezoneIgnoredTimestamp.date,
    timezoneIgnoredTimestamp.hours,
    timezoneIgnoredTimestamp.minutes,
    timezoneIgnoredTimestamp.seconds,
    timezoneIgnoredTimestamp.milliseconds
  );
};

export const getRequestHeaders = async () => {
  const id_token = await capacitorStorage.getItem(LOCAL_STORAGE.fbIdToken);
  const user_id = await capacitorStorage.getItem(LOCAL_STORAGE.userId);
  return {
    "Content-Type": "application/json",
    id_token,
    user_id,
  };
};

export const getXeroRequestHeaders = async () => {
  const access_token = await capacitorStorage.getItem(
    LOCAL_STORAGE.XERO.access_token
  );
  const id_token = await capacitorStorage.getItem(LOCAL_STORAGE.fbIdToken);
  const user_id = await capacitorStorage.getItem(LOCAL_STORAGE.userId);
  return {
    "Content-Type": "application/json",
    access_token: `Bearer ${access_token}`,
    id_token,
    user_id,
  };
};

export const formatString = (str: string) => {
  return str.trim();
};

export const validatePassword = (password: string) => {
  return password.trim().length >= 8;
};

export const hasOnlyNumbers = (value: string) => {
  return /^\d+$/.test(value) || value.length === 0;
};

export const encodeQueryData = (data: any) => {
  return Object.keys(data)
    .map(function (key: any) {
      return [key, data[key]].map(encodeURIComponent).join("=");
    })
    .join("&");
};

export const toDateTimeFromSecs = (secs: number) => {
  return new Date(secs * 1000);
};

export const useEffectOnlyOnce = (func: () => void) => useEffect(func, []);

export const isMobile = () =>
  (isPlatform("ios") || isPlatform("android")) &&
  !isPlatform("mobileweb") &&
  (!isPlatform("desktop") || (isPlatform("desktop") && isPlatform("hybrid")));

export const isTablet = () => isPlatform("ipad") || isPlatform("tablet");
export const isToday = (date: Date) => {
  const dateToday = new Date();
  const month = dateToday.getMonth();
  const monthDay = dateToday.getDate();
  const year = dateToday.getFullYear();

  const sameMonth = date.getMonth() === month;
  const sameMonthDay = date.getDate() === monthDay;
  const sameYear = date.getFullYear() === year;

  return sameMonth && sameMonthDay && sameYear;
};

export const isDayShift = (onSiteTime: Date) => {
  const timeShift = parseInt(moment(onSiteTime).format("HH"));
  const isDayShift = timeShift < JOB_TIME_SHIFTS.night;
  return isDayShift;
};

export const getJobStatus = (
  jobCompleted: boolean,
  siteDockets: SiteDocket[]
) => {
  if (jobCompleted && !_.isEmpty(siteDockets)) {
    return "completed";
  } else {
    return "incomplete";
  }
};

export const isHistoricalDate = (date: Date) => {
  return moment(moment(date).format("YYYY-MM-DD")).isBefore(
    moment(new Date()).format("YYYY-MM-DD")
  );
};

export const isSameDate = (date: Date, date2: Date) => {
  return moment(date).isSame(moment(date2), "day");
};

export const daysSinceDate = (dateSince: Date, dateFrom = new Date()) => {
  const daySince = moment(dateSince);
  const targetDate = moment(dateFrom);
  const activeSinceDays = targetDate.diff(daySince, "days");
  return activeSinceDays;
};

export const dateTomorrow = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const formatPhoneNumberToInternational = (
  phoneNumber: string | undefined
) => {
  if (!!phoneNumber) {
    const formattedNumber = parsePhoneNumberFromString(phoneNumber);
    return formattedNumber!.formatInternational();
  }
};

export const validURL = (str: string) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const uploadAttachments = async (
  file: string[],
  uploaderName: string,
  uploaderId: string,
  folderPathName: string
) => {
  const ref = storage.ref();

  const uploadedRefs: string[] = [];
  const uploadTasks: Promise<any>[] = [];

  file.forEach((attachmentRef: any) => {
    uploadTasks.push(
      new Promise(async (resolve) => {
        const attachmentPath = `${folderPathName}/${uploaderName
          .replace(" ", "")
          .toLowerCase()}_${uploaderId}/${uploaderName}_${new Date().valueOf()}_${
          attachmentRef.name
        }`;

        try {
          ref
            .child(attachmentPath)
            .put(attachmentRef)
            .then(async (result: any) => {
              const downloadUrl = await result.ref.getDownloadURL();
              uploadedRefs.push(downloadUrl);
              resolve(true);
            })
            .catch((error: any) => {
              resolve(error);
            });
        } catch (errorUnknown) {
          const error = errorUnknown as any;
          resolve(error);
        }
      })
    );
  });
  await Promise.all(uploadTasks);
  return uploadedRefs;
};

export const removedUploadedAttachements = async (url: string[]) => {
  try {
    await Promise.resolve(
      url.forEach((url) => {
        new Promise(async (resolve) => {
          try {
            const refFromUrl = storage.refFromURL(url);
            const deletedUrl = await refFromUrl.delete();
            resolve(deletedUrl);
          } catch (errorUnknown) {
            const error = errorUnknown as any;
            console.log("ERROR ON REMOVING ATTACHMENTS", error);
            resolve(null);
          }
        });
      })
    );
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("ERROR ON REMOVING ATTACHMENTS", error);
  }
};

export const getNotebookHoles = () => {
  let holes = [];

  for (let x = 0; x < 24; x++) {
    holes.push(x);
  }
  return holes;
};

export const getSiteVisFormattedDate = (date: Date) => {
  return moment(date).format("ddd, DD MMM YYYY");
};

export const isNullAndLoadingORNotEmpty = (obj: any) => {
  return _.isNull(obj) || (!_.isEmpty(obj) && !_.isNull(obj));
};

export const getServerTimestamp = (
  date: null | Date = null
): firebase.firestore.Timestamp => {
  if (!_.isNull(date)) {
    return fb.firestore.Timestamp.fromDate(date);
  }
  return fb.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
};

export const getCurrentLocation = async (): Promise<Coordinates> => {
  return new Promise((resolve) => {
    Geolocation.getCurrentPosition()
      .then((result) => {
        resolve({
          lat: result.coords.latitude,
          lng: result.coords.longitude,
        });
      })
      .catch(() => {
        resolve({ lat: -28.1104171, lng: 153.4342987 });
      });
  });
};

interface Coordinates {
  lat: number;
  lng: number;
}
export const getDistanceFrom = (from: Coordinates, to: Coordinates) => {
  if (from.lat === to.lat && from.lng === to.lng) {
    return 0;
  } else {
    var radlat1 = (Math.PI * from.lat) / 180;
    var radlat2 = (Math.PI * to.lat) / 180;
    var theta = from.lng - to.lng;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344; // kilomteres unit
    return dist;
  }
};

export const checkIfCanVerifyMobile = async (
  callBack: (remainingTime: number) => void
) => {
  let recentMobileVerification = parseInt(
    (await capacitorStorage.getItem(LOCAL_STORAGE.recentMobileVerification)) ||
      "0"
  );

  const currentTime = new Date().valueOf();
  const timeLimit = 60000;
  const timePassed = currentTime - recentMobileVerification;
  const remainingTime =
    (timeLimit - timePassed) / 1000 > 1 ? (timeLimit - timePassed) / 1000 : 0;

  let coolDown = _.clone(remainingTime);
  let intervalId: any = null;

  if (!!remainingTime) {
    // this.setState({ mobileVerificationCooldown: remainingTime });
    callBack(remainingTime);

    intervalId = setInterval(() => {
      const nextRemainingTime = coolDown - 1;
      if (nextRemainingTime <= 0) {
        coolDown = 0;
        callBack(0);
        clearInterval(intervalId);
      } else {
        callBack(nextRemainingTime); // ~~ is roundoff
        coolDown = nextRemainingTime;
      }
    }, 1000);
  }
};

export const setRecentMobileVerification = () => {
  capacitorStorage.setItem(
    LOCAL_STORAGE.recentMobileVerification,
    new Date().valueOf().toString()
  );
};

export const download = (data: any[], fileName: string) => {
  try {
    json2excel({
      data,
      name: fileName,
      formateDate: "yyyy/mm/dd",
    });
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.error("export error", e);
  }
};

export const getDateRange = (date: Date, view: View) => {
  if (view === "day") {
    const startDate = _.clone(date);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    const endDate = _.clone(date);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(59);
    return { startDate, endDate };
  } else if (view === "week" || view === "work_week") {
    const startDate = moment(date).startOf("week").toDate();

    startDate.setDate(startDate.getDate() - (startDate.getDay() - 1));
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    const endDate = moment(date).endOf("week").toDate();
    // endDate.setDate(startDate.getDate() + 6);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(59);
    return { startDate, endDate };
  } else {
    //month
    const startDate = _.clone(date);
    startDate.setDate(1);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    const endDate = _.clone(date);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(0);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(59);
    return { startDate, endDate };
  }
};
