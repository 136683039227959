import _ from "lodash";
import React from "react";

import { IonContent, IonLabel } from "@ionic/react";

import "./Job.scss";
import { EWPProps } from "../../../config/global";
import { EWPDivider } from "../../EWPDivider/EWPDivider";
import { JobScheduleFor } from "../../../pages/Home/Home";
import { JobScheduleForm } from "../../JobScheduleForm/JobScheduleForm";
import { JobScheduleView } from "../../../models";

interface JobProps extends EWPProps {
  jobFor: JobScheduleFor;
  isEdit?: boolean;
  jobScheduleData?: JobScheduleView;
}

class Job extends React.Component<JobProps> {
  render = () => {
    const { jobScheduleData, isEdit } = this.props;

    return (
      <IonContent className="job-container">
        <div className="job-header-container">
          <IonLabel className="job-header ewp-h2">
            {!!isEdit ? "Edit Job" : "Create New Job"}
          </IonLabel>
        </div>
        <EWPDivider />
        <div className="job-schedule-form-container">
          <JobScheduleForm
            {...this.props}
            {...(!!jobScheduleData &&
              !_.isEmpty(jobScheduleData) && { jobSchedule: jobScheduleData })}
            {...(!_.isEmpty(isEdit) && { isEdit })}
          />
        </div>
      </IonContent>
    );
  };
}
export default Job;
