import * as _ from "lodash";
import moment from "moment";
import React, { createContext, useEffect, useReducer, useRef } from "react";

import * as api from "../api";
import * as capacitorStorage from "../functions/localStorageCapacitor";
import reducer from "./AppReducer";
import { AuthUser } from "../config/global";
import { XeroInvoice } from "../models/xero";
import { LOCAL_STORAGE } from "../constants/localStorage";

export interface GlobalStateModel {
  xeroInvoices: XeroInvoice[] | null;
  isXeroAuthenticated: boolean | null;
  xeroError: null | string;
  refreshInvoices: () => void;
  authenticateXero: () => void;
}

export const initialState = {
  xeroInvoices: null,
  xeroError: null,
  isXeroAuthenticated: null,
  refreshInvoices: () => {},
  authenticateXero: () => {},
} as GlobalStateModel;

export const GlobalContext = createContext(initialState);

interface GlobalProviderProps {
  children: any;
  authUser: AuthUser;
}

export const GlobalProvider = (props: GlobalProviderProps) => {
  const { children, authUser } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  let xeroTimer: null | NodeJS.Timeout = null;
  let xeroRefreshIntervalTimer: null | NodeJS.Timeout = null;
  const xeroInvoicesRef: { current: undefined | null | XeroInvoice[] } =
    useRef(undefined);
  xeroInvoicesRef.current = state.xeroInvoices;

  const isXeroAuthenticatedRef: { current: undefined | null | boolean } =
    useRef(undefined);
  isXeroAuthenticatedRef.current = state.isXeroAuthenticated;

  useEffect(() => {
    if (!_.isEmpty(authUser) && _.isNull(state.isXeroAuthenticated)) {
      initializeXero();
      // clearXeroSession();
    } else if (_.isEmpty(authUser) || _.isNull(authUser)) {
      if (!_.isNull(xeroTimer)) {
        clearInterval(xeroTimer);
      }
      if (!_.isNull(xeroRefreshIntervalTimer)) {
        clearInterval(xeroRefreshIntervalTimer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, state.isXeroAuthenticated]);

  const initializeXero = () => {
    dispatch({
      type: "UPDATE_XERO_ERROR",
      payload: null,
    });
    try {
      // console.log("will start interval --"); // should be called within 2 secs to handle redirect from re-auth
      const timerId = setInterval(async () => {
        const xeroExpiry = await capacitorStorage.getItem(
          LOCAL_STORAGE.XERO.expires_at
        );
        const xeroRefreshToken = await capacitorStorage.getItem(
          LOCAL_STORAGE.XERO.refresh_token
        );

        const xeroRefreshExpiry = await capacitorStorage.getItem(
          LOCAL_STORAGE.XERO.refresh_token_expiry
        );

        if (_.isEmpty(xeroExpiry) || _.isNull(xeroExpiry)) {
          // console.log("no session found - will clear");
          dispatch({
            type: "UPDATE_XERO_AUTHENTICATION",
            payload: false,
          });
          clearXeroSession();
        } else {
          // console.log("session found - will proceed");
          if (
            parseInt(`${parseInt(xeroExpiry) - 30}000`) > new Date().valueOf()
          ) {
            // console.log("session found - user is authenticated");
            dispatch({
              type: "UPDATE_XERO_AUTHENTICATION",
              payload: true,
            });
            if (
              xeroInvoicesRef.current === undefined ||
              _.isNull(xeroInvoicesRef.current)
            ) {
              // console.log("no invoices yet will proceed to get invoices");
              getInvoices();
            }
          } else if (
            !_.isEmpty(xeroRefreshToken) &&
            !_.isNull(xeroRefreshToken) &&
            !_.isEmpty(xeroRefreshExpiry) &&
            !_.isNull(xeroRefreshExpiry) &&
            parseInt(xeroRefreshExpiry) > new Date().valueOf()
          ) {
            // console.log("user is due for refresh token will refresh");
            // refresh
            refreshToken(xeroRefreshToken);
            // get invoice here after refresh token
          } else {
            // console.log("should refresh entirely");
            dispatch({
              type: "UPDATE_XERO_AUTHENTICATION",
              payload: false,
            });
            dispatch({
              type: "UPDATE_XERO_INVOICES",
              payload: null,
            });
          }
        }
      }, 2000);

      xeroTimer = timerId;
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      dispatch({
        type: "UPDATE_XERO_ERROR",
        payload: `Init Xero: ${error.message || error}`,
      });
      dispatch({
        type: "UPDATE_XERO_AUTHENTICATION",
        payload: false,
      });
      dispatch({
        type: "UPDATE_XERO_INVOICES",
        payload: null,
      });
    }
  };

  const authenticateXero = async () => {
    dispatch({
      type: "UPDATE_XERO_ERROR",
      payload: null,
    });
    try {
      const result = await api.authenticateXero();
      // console.log("GOT RESULT FROM XERO --- ", JSON.stringify(result, null, 1));
      window.location.replace(result.consentUrl);
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      // console.log(
      //   "GOT ERROR FROM AUTHENTICATE ZERO",
      //   JSON.stringify(error, null, 1)
      // );
      dispatch({
        type: "UPDATE_XERO_ERROR",
        payload: `Authenticate Xero: ${error.message || error}`,
      });
      dispatch({
        type: "UPDATE_XERO_AUTHENTICATION",
        payload: false,
      });
      dispatch({
        type: "UPDATE_XERO_INVOICES",
        payload: null,
      });
    }
  };

  const refreshToken = async (xeroRefreshToken: string) => {
    // refresh
    dispatch({
      type: "UPDATE_XERO_ERROR",
      payload: null,
    });
    // console.log("WILL NOW REFRESH TOKEN -- ");
    try {
      const tokenSet = await api.refreshXeroToken(xeroRefreshToken);
      const {
        access_token,
        expires_at,
        id_token,
        scope,
        session_state,
        token_type,
        refresh_token,
      } = tokenSet.tokenSet;
      await capacitorStorage.setItem(
        LOCAL_STORAGE.XERO.access_token,
        access_token
      );

      await capacitorStorage.setItem(
        LOCAL_STORAGE.XERO.refresh_token,
        refresh_token
      );

      await capacitorStorage.setItem(
        LOCAL_STORAGE.XERO.refresh_token_expiry,
        moment(new Date()).add(59, "days").toDate().valueOf().toString()
      );

      await capacitorStorage.setItem(LOCAL_STORAGE.XERO.expires_at, expires_at);

      await capacitorStorage.setItem(LOCAL_STORAGE.XERO.id_token, id_token);
      await capacitorStorage.setItem(LOCAL_STORAGE.XERO.scope, scope);
      await capacitorStorage.setItem(
        LOCAL_STORAGE.XERO.session_state,
        session_state
      );
      await capacitorStorage.setItem(LOCAL_STORAGE.XERO.token_type, token_type);
      // console.log("done refresh token will proceed to get invoice");

      // get invoice here
      getInvoices();
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      // console.log("error on refresh -- ", error);

      dispatch({
        type: "UPDATE_XERO_ERROR",
        payload: `Refresh Xero: ${error.message || error}`,
      });
      dispatch({
        type: "UPDATE_XERO_AUTHENTICATION",
        payload: false,
      });
      dispatch({
        type: "UPDATE_XERO_INVOICES",
        payload: null,
      });

      setTimeout(() => {
        clearXeroSession();
      }, 1000);
    }
  };

  const clearXeroSession = async () => {
    // if (!_.isNull(xeroInvoicesRef.current)) {
    // console.log("will now clear xero session");
    await capacitorStorage.removeItem(LOCAL_STORAGE.XERO.access_token);

    await capacitorStorage.removeItem(LOCAL_STORAGE.XERO.refresh_token);

    await capacitorStorage.removeItem(LOCAL_STORAGE.XERO.refresh_token_expiry);

    await capacitorStorage.removeItem(LOCAL_STORAGE.XERO.expires_at);

    await capacitorStorage.removeItem(LOCAL_STORAGE.XERO.id_token);
    await capacitorStorage.removeItem(LOCAL_STORAGE.XERO.scope);
    await capacitorStorage.removeItem(LOCAL_STORAGE.XERO.session_state);
    await capacitorStorage.removeItem(LOCAL_STORAGE.XERO.token_type);
    dispatch({
      type: "CLEAR_XERO_SESSION",
      payload: null,
    });
    // }
  };

  const getInvoices = async (refresh = false) => {
    dispatch({
      type: "UPDATE_XERO_ERROR",
      payload: null,
    });

    if (
      xeroInvoicesRef.current === undefined ||
      _.isNull(xeroInvoicesRef.current) ||
      refresh
    ) {
      // console.log("will now get invoices");
      try {
        const result = await api.getAuthorizedXeroConnections();
        const invoices = await api.xeroInvoices(result[0].tenantId);

        const invoiceList = invoices.Invoices as XeroInvoice[];
        // console.log("");

        const filteredInvoice = _.filter(
          invoiceList,
          (invoice) => invoice.Type === "ACCREC"
        );

        dispatch({
          type: "UPDATE_XERO_INVOICES",
          payload: filteredInvoice,
        });

        // console.log("GOT INVOICES -- ", filteredInvoice.length);
        if (_.isNull(xeroRefreshIntervalTimer)) {
          refreshInvoicesInterval();
        }
      } catch (errorUnknown) {
        // console.log("error on getting invoices", errorUnknown);
        const error = errorUnknown as any;
        dispatch({
          type: "UPDATE_XERO_ERROR",
          payload: `Get Xero Invoices: ${error.message || error}`,
        });
        dispatch({
          type: "UPDATE_XERO_AUTHENTICATION",
          payload: false,
        });
        dispatch({
          type: "UPDATE_XERO_INVOICES",
          payload: null,
        });

        setTimeout(() => {
          clearXeroSession();
        }, 1000);
      }
    }
  };

  const refreshInvoicesInterval = () => {
    if (!_.isNull(xeroRefreshIntervalTimer)) {
      clearInterval(xeroRefreshIntervalTimer);
    }

    xeroRefreshIntervalTimer = setInterval(() => {
      if (
        !_.isNull(isXeroAuthenticatedRef.current) &&
        isXeroAuthenticatedRef.current !== undefined &&
        !!isXeroAuthenticatedRef.current
      ) {
        getInvoices(true);
      }
    }, 60000 * 5); // refresh invoice every minute
  };

  const refreshInvoicesManual = () => {
    // dispatch({
    //   type: "UPDATE_XERO_INVOICES",
    //   payload: null,
    // });
    if (
      !_.isNull(isXeroAuthenticatedRef.current) &&
      isXeroAuthenticatedRef.current !== undefined &&
      !!isXeroAuthenticatedRef.current
    ) {
      getInvoices(true);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        xeroInvoices: state.xeroInvoices,
        authenticateXero: authenticateXero,
        isXeroAuthenticated: state.isXeroAuthenticated,
        xeroError: state.xeroError,
        refreshInvoices: refreshInvoicesManual,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
