import React from "react";
import { IonButton } from "@ionic/react";

export const EWPFooterLink = ({
  title,
  routerLink,
  onClick,
}: {
  title: string;
  routerLink: string;
  onClick?: () => void;
}) => (
  <IonButton
    fill="clear"
    // routerLink={routerLink}
    mode="ios"
    className="ewp-footer-links ewp-paragraph"
    {...(onClick !== undefined
      ? {
          onClick,
        }
      : { routerLink })}
  >
    {title}
  </IonButton>
);
