import React from "react";
import { IonButton } from "@ionic/react";

export const EWPLink = ({
  title,
  routerLink,
  onClick,
  color,
  className,
  underlined,
}: {
  title: string;
  routerLink?: string;
  color: string;
  underlined?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  className?: string;
}) => (
  <IonButton
    mode="ios"
    fill="clear"
    color={color}
    {...(!!routerLink && { routerLink: routerLink })}
    onClick={!!onClick ? onClick : () => {}}
    className={`${className} ewp-links`}
  >
    {underlined ? <u>{title}</u> : title}
  </IonButton>
);
