import * as _ from "lodash";
import Bugsnag from "@bugsnag/js";
import { getServerTimestamp } from "../functions/common";
import { auth, firestore } from "../firebase";
import { getDriver, getTruck } from "./driver";
import {
  Client,
  ClientContactPerson,
  ClientContactPersonDetailedView,
  ClientNotes,
  ContactPerson,
} from "../models";
import {
  CLIENTS,
  CLIENT_CONTACT_PERSON,
  CLIENT_NOTES,
  CONTACT_PEOPLE,
} from "../constants/dbCollections";

export const createClient = async (
  name: string,
  abn: string,
  preferredTruckIds: string[],
  preferredDriverIds: string[],
  contactFirstName: string,
  contactLastName: string,
  contactPhoneNumber: string,
  contactEmailAddress: string
) => {
  const createdBy = auth.currentUser;
  if (!_.isNull(createdBy)) {
    const client = await firestore.collection(CLIENTS).add({
      name,
      abn,
      preferredTruckIds,
      preferredDriverIds,
      createdBy: createdBy.uid,
      createdDt: getServerTimestamp(),
      updatedDt: getServerTimestamp(),
    });

    const contactPerson = await firestore.collection(CONTACT_PEOPLE).add({
      firstName: contactFirstName,
      lastName: contactLastName,
      phoneNumber: contactPhoneNumber,
      emailAddress: contactEmailAddress,
      createdBy: createdBy.uid,
      createdDt: getServerTimestamp(),
      updatedDt: getServerTimestamp(),
    });

    await firestore.collection(CLIENT_CONTACT_PERSON).add({
      clientId: client.id,
      contactPersonId: contactPerson.id,
      createdBy: createdBy.uid,
      createdDt: getServerTimestamp(),
      updatedDt: getServerTimestamp(),
    });
  } else {
    throw new Error("No user logged in");
  }
};

export const deleteContactPerson = async (docId: string, clientId: string) => {
  try {
    if (!docId) return;
    const clientContactPersonQuery = await firestore
      .collection(CLIENT_CONTACT_PERSON)
      .where("clientId", "==", clientId)
      .where("contactPersonId", "==", docId)
      .get();
    if (!clientContactPersonQuery.empty) {
      await firestore
        .collection(CLIENT_CONTACT_PERSON)
        .doc(clientContactPersonQuery.docs[0].id)
        .delete();
      await firestore.collection(CONTACT_PEOPLE).doc(docId).delete();
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN updateTruck: ", e);
    Bugsnag.notify(new Error(e));
  }
};
export const updateContactPerson = async (
  docId: string,
  contactFirstName: string,
  contactLastName: string,
  contactPhoneNumber: string,
  contactEmailAddress: string
) => {
  const updatedBy = auth.currentUser;
  if (!_.isNull(updatedBy)) {
    await firestore.collection(CONTACT_PEOPLE).doc(docId).update({
      firstName: contactFirstName,
      lastName: contactLastName,
      phoneNumber: contactPhoneNumber,
      emailAddress: contactEmailAddress,
      updatedBy: updatedBy.uid,
      updatedDt: getServerTimestamp(),
    });
  } else {
    throw new Error("No user logged in");
  }
};

export const updateClient = async (
  docId: string,
  name: string,
  abn: string,
  preferredTruckIds: string[],
  preferredDriverIds: string[]
) => {
  const updatedBy = auth.currentUser;
  if (!_.isNull(updatedBy)) {
    await firestore.collection(CLIENTS).doc(docId).update({
      name,
      abn,
      preferredTruckIds,
      preferredDriverIds,
      updatedBy: updatedBy.uid,
      updatedDt: getServerTimestamp(),
    });
  } else {
    throw new Error("No user logged in");
  }
};

export const getClientsContacts = async (
  clientId: string
): Promise<ContactPerson[]> => {
  const clientContactsRef = await firestore
    .collection(CLIENT_CONTACT_PERSON)
    .where("clientId", "==", clientId)
    .get();

  if (!clientContactsRef.empty) {
    const contacts = await Promise.all(
      clientContactsRef.docs.map((clientContact) => {
        const clientContactsData = clientContact.data() as ClientContactPerson;

        return firestore
          .collection(CONTACT_PEOPLE)
          .doc(clientContactsData.contactPersonId)
          .get();
      })
    );

    return _.compact(
      contacts.map((contact) => {
        if (contact.exists) {
          return { docId: contact.id, ...contact.data() } as ContactPerson;
        } else {
          return null;
        }
      })
    );
  } else {
    return [];
  }
};

export const getClientsContactsRealTime = (
  clientId: string,
  callback: (contactPerson: ContactPerson[], error?: string) => void
) => {
  try {
    const unsubscribe = firestore
      .collection(CLIENT_CONTACT_PERSON)
      .where("clientId", "==", clientId)
      .onSnapshot(
        async (snapshot) => {
          if (!snapshot.empty) {
            const contactPersonView = (await Promise.all(
              snapshot.docs.map((clientContact) => {
                return new Promise(async (resolve) => {
                  const clientContactdata = {
                    docId: clientContact.id,
                    ...clientContact.data(),
                  } as ClientContactPerson;

                  const contactPersonQuery = await firestore
                    .collection(CONTACT_PEOPLE)
                    .doc(clientContactdata.contactPersonId)
                    .get();

                  if (contactPersonQuery.exists) {
                    resolve({
                      ...contactPersonQuery.data(),
                      docId: contactPersonQuery.id,
                    } as ContactPerson);
                  } else {
                    resolve(null);
                  }
                });
              })
            )) as ContactPerson[];

            callback(_.compact(contactPersonView));
          } else {
            callback([]);
          }
        },
        (error) => {
          callback([], error.message);
          Bugsnag.notify(new Error(error.message));
        }
      );
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERRR IN getClientsContactsRealTime: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const getClients = async (): Promise<Client[]> => {
  const query = await firestore.collection(CLIENTS).get();

  if (!query.empty) {
    return query.docs.map((client) => {
      return {
        docId: client.id,
        ...client.data(),
      } as Client;
    });
  } else {
    return [] as Client[];
  }
};

export const createContactPerson = async (
  clientId: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  emailAddress: string
) => {
  const createdBy = auth.currentUser;
  if (!_.isNull(createdBy)) {
    const contactPerson = await firestore.collection(CONTACT_PEOPLE).add({
      firstName,
      lastName,
      phoneNumber,
      emailAddress,
      createdBy: createdBy.uid,
      createdDt: getServerTimestamp(),
      updatedDt: getServerTimestamp(),
    });

    await firestore.collection(CLIENT_CONTACT_PERSON).add({
      clientId,
      contactPersonId: contactPerson.id,
      createdBy: createdBy.uid,
      createdDt: getServerTimestamp(),
      updatedDt: getServerTimestamp(),
    });
  } else {
    throw new Error("No user logged in");
  }
};

export const getContactPeople = async (): Promise<ContactPerson[]> => {
  try {
    const query = await firestore.collection(CONTACT_PEOPLE).get();

    if (!query.empty) {
      return query.docs.map((contactPerson) => {
        return {
          docId: contactPerson.id,
          ...contactPerson.data(),
        } as ContactPerson;
      });
    } else {
      return [] as ContactPerson[];
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN getContactPerson: ", e);
    Bugsnag.notify(new Error(e));
    return [] as ContactPerson[];
  }
};

export const getClientContactDetailedView = (
  callback: (
    clientContactView: ClientContactPersonDetailedView[],
    error?: string
  ) => void
) => {
  try {
    const unsubscribe = firestore
      .collection(CLIENTS)
      .onSnapshot(async (clientsQuery) => {
        if (!clientsQuery.empty) {
          const result = await Promise.all(
            clientsQuery.docs.map((client) => {
              return new Promise(async (resolve) => {
                const clientData = {
                  docId: client.id,
                  ...client.data(),
                } as ClientContactPersonDetailedView;

                const clientContacts = await getClientsContacts(client.id);
                clientData.contactPeople = clientContacts;

                if (!_.isEmpty(clientData.preferredDriverIds)) {
                  const preferredDrivers = await Promise.all(
                    clientData.preferredDriverIds.map((driverId) => {
                      return getDriver(driverId);
                    })
                  );
                  clientData.preferredDrivers = preferredDrivers;
                } else {
                  clientData.preferredDrivers = [];
                }

                if (!_.isEmpty(clientData.preferredTruckIds)) {
                  const preferredTrucks = await Promise.all(
                    clientData.preferredTruckIds.map((truckId) => {
                      return getTruck(truckId);
                    })
                  );
                  clientData.preferredTrucks = preferredTrucks;
                } else {
                  clientData.preferredTrucks = [];
                }

                resolve(clientData);
              }) as Promise<ClientContactPersonDetailedView>;
            })
          );
          callback(result);
        } else {
          callback([]);
        }
      });

    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const createClientNotes = async (data: ClientNotes) => {
  try {
    const createdBy = auth.currentUser;
    if (!_.isNull(createdBy)) {
      await firestore.collection(CLIENT_NOTES).add({
        ...data,
        createdBy: createdBy.uid,
        createdDate: getServerTimestamp(),
      });
    } else {
      throw new Error("No user logged in");
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("Error -- createNotes -- ", error);
    Bugsnag.notify(new Error(error));
  }
};

export const getClientNotes = async (
  clientId: string,
  callback: (clientNotes: ClientNotes[], error?: string) => void
) => {
  if (!!clientId) {
    try {
      const unsubscribe = firestore
        .collection(CLIENT_NOTES)
        .where("clientId", "==", clientId)
        .onSnapshot(
          (snapshot) => {
            if (snapshot.docs.length > 0) {
              callback(
                snapshot.docs.map((clientNote) => {
                  return {
                    ...clientNote.data(),
                    docId: clientNote.id,
                  } as ClientNotes;
                })
              );
            } else {
              callback([]);
            }
          },
          (error) => {
            callback([], error.message);
          }
        );
      return unsubscribe;
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      callback([], error);
      Bugsnag.notify(new Error(error));
    }
  } else {
    callback([], "Error: no id/s provided");
  }
};

export const updateClientNoteEntry = async (
  docId: string,
  updatedNoteEntry: ClientNotes
) => {
  try {
    const updatedBy = auth.currentUser;
    if (!_.isNull(updatedBy)) {
      await firestore
        .collection(CLIENT_NOTES)
        .doc(docId)
        .update({
          ...updatedNoteEntry,
          updatedBy: updatedBy.uid,
          updatedDate: getServerTimestamp(),
        });
    } else {
      throw new Error("No user logged in");
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("Error - updateClientNotes -- ", error);
    Bugsnag.notify(new Error(error));
  }
};
