import * as _ from "lodash";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export const formatPhoneNumber = (phoneNumber: string) => {
  const formattedNumber = parsePhoneNumberFromString(phoneNumber);
  return formattedNumber ? formattedNumber.number : null;
};

export const getParsedMobileNumber = (phoneNumber: string) => {
  const formattedNumber = parsePhoneNumberFromString(phoneNumber);
  return formattedNumber;
};

//customize to check AUS number
export const validatePhoneNumber = (phoneNumber: string) => {
  let phoneNumberToValidate = _.clone(phoneNumber);
  if (phoneNumberToValidate.indexOf("+61") === 0) {
    return (
      !_.isEmpty(parsePhoneNumberFromString(phoneNumber)) &&
      phoneNumber.length === 12
    );
  } else if (phoneNumberToValidate.indexOf("0") === 0) {
    console.log("WILL REPLACE AND GO");
    return (
      !_.isEmpty(parsePhoneNumberFromString(phoneNumber.replace("0", "+61"))) &&
      phoneNumber.length === 10
    );
  } else {
    return false;
  }
};
