import * as _ from "lodash";
import React, { useState, useEffect } from "react";

import "./EWPNumberFieldWithCountryCode.scss";
import {
  IonItem,
  IonButton,
  IonThumbnail,
  IonInput,
  IonLabel,
} from "@ionic/react";

import countriesJson from "../../constants/countries.json";
import { getParsedMobileNumber } from "../../functions/phoneNumber";
import { isMobile } from "../../functions/common";
import { EWPCOLORS } from "../../constants/config";

interface EWPNumberFieldWithCountryCodeProps {
  onChange: (phoneNumber: string) => void;
  phoneNumber?: string;
  errorMessage?: string;
}
interface Country {
  code: string;
  flag: string;
  callingCode: string;
  name: string;
}
export const EWPNumberFieldWithCountryCode = (
  props: EWPNumberFieldWithCountryCodeProps
) => {
  const { errorMessage } = props;

  const [selectedCountry] = useState({
    code: "AU",
    flag: countriesJson.AU.flag,
    callingCode: countriesJson.AU.callingCode,
    name: countriesJson.AU.name.common,
  } as Country);
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileClass, setMobileClass] = useState("mobile");

  const onChange = (selectedCountry: Country, mobileNumber: string) => {
    const { callingCode } = selectedCountry;
    if (mobileNumber.indexOf("0") === 0) {
      const phoneNumber = `+${callingCode}${mobileNumber.slice(1)}`;
      props.onChange(phoneNumber);
    } else {
      const phoneNumber = `+${callingCode}${mobileNumber}`;
      props.onChange(phoneNumber);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const { phoneNumber } = props;

    if (!mobileNumber && !!phoneNumber && mobileNumber !== phoneNumber) {
      const formattedNumber = getParsedMobileNumber(phoneNumber);
      if (!!formattedNumber && !_.isEmpty(formattedNumber)) {
        setMobileNumber(formattedNumber.nationalNumber as string);
        props.onChange(phoneNumber);
      }
    }
  });

  return (
    <>
      <IonItem
        className={`ewp-number-field-with-country-code-user-input-item ${
          !_.isEmpty(errorMessage) && "item-has-error-class"
        }`}
        lines="none"
        mode="md"
      >
        <IonButton
          className="ewp-number-field-with-country-code-country-select"
          expand="block"
          size="large"
          color={EWPCOLORS.tertiary}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <IonThumbnail className="ewp-number-field-with-country-code-flag-thumbnail">
            <img src={selectedCountry.flag} alt="country-flag" />
          </IonThumbnail>
          <span className="ewp-number-field-with-country-code-country-code ewp-body">
            {`+${selectedCountry.callingCode || ""}`}
          </span>
        </IonButton>
        <IonItem
          className={`ewp-number-field-with-country-code-label-input-container ion-no-padding ${
            !_.isEmpty(errorMessage) && "item-has-error-class"
          }`}
          lines="none"
          mode="md"
        >
          <IonLabel
            className={`ewp-number-field-with-country-code-label-input-label  ${
              isMobile() && mobileClass
            } `}
            position="floating"
          >
            Mobile Number
          </IonLabel>
          <IonInput
            mode="md"
            className={`ewp-number-field-with-country-code-input ${
              !_.isEmpty(errorMessage) && "has-error-class"
            }`}
            name="mobileNumber"
            inputmode="tel"
            type="tel"
            value={mobileNumber}
            onIonChange={(event) => {
              setMobileNumber(event.detail.value || "");
              onChange(selectedCountry, event.detail.value || "");
            }}
            {...(isMobile() && {
              onIonFocus: () => {
                setMobileClass("mobile has-focus");
              },
              onIonBlur: () => {
                setMobileClass(
                  !_.isEmpty(mobileNumber) ? "mobile has-value" : "mobile"
                );
              },
            })}
          />
        </IonItem>
      </IonItem>
      {!_.isEmpty(errorMessage) && (
        <div className="ewp-input-error-container">
          <IonLabel className="ewp-input-error-msg">{errorMessage}</IonLabel>
        </div>
      )}
    </>
  );
};
