import React from "react";
import { E164Number } from "libphonenumber-js";

import "./EWPNumberEmail.scss";
import { IonLabel, IonIcon, IonSkeletonText } from "@ionic/react";
import { formatPhoneNumberToInternational } from "../../functions/common";

interface NumberEmailProps {
  phoneNumber: string | E164Number;
  email: string;
  className?: string;
  loading?: boolean;
}
export const EWPNumberEmail = (props: NumberEmailProps) => {
  const { className } = props;
  return !props.loading ? (
    <div {...(className !== undefined ? { className } : "")}>
      <IonLabel className="ewp-number-email-number-label ewp-paragraph ion-text-capitalize">
        <IonIcon className="ewp-number-email-phone-icon" />
        {formatPhoneNumberToInternational(props.phoneNumber + "") ||
          "Not provided"}
      </IonLabel>
      <IonLabel className="ewp-paragraph">
        <IonIcon className="ewp-number-email-message-icon" />
        {props.email}
      </IonLabel>
    </div>
  ) : (
    <div {...(className !== undefined ? { className } : "")}>
      <IonSkeletonText
        animated
        style={{ width: "150px" }}
        className="ewp-paragraph"
      />
      <IonSkeletonText
        animated
        style={{ width: "130px" }}
        className="ewp-paragraph"
      />
    </div>
  );
};
