import { IonButton, IonIcon, IonLabel } from "@ionic/react";
import React, { useState } from "react";
import moment from "moment";

import "./EWPDatePicker.scss";
import { EWPCalendar } from "../EWPCalendar/EWPCalendar";
import { EWPCOLORS } from "../../constants/config";

interface EWPDatePickerProps {
  selectedDate: Date | null;
  datePickerLabel: string;
  onDateChange: (selectedDate: Date) => void;
  className?: string;
  maxDate?: Date;
  minDate?: Date;
  errorMsg?: string;
  format?: string;
  label?: string;
}
export const EWPDatePicker = (props: EWPDatePickerProps) => {
  const {
    selectedDate,
    onDateChange,
    className,
    maxDate,
    minDate,
    datePickerLabel,
    errorMsg,
    format,
    label,
  } = props;
  // const [dateValue, setDateValue] = useState(selectedDate);
  const [calendarAnchor, setCalendarAnchor] = useState(
    null as Event | undefined | null
  );

  return (
    <>
      <div className={`${className} ewp-datepicker-container`}>
        {!!label && (
          <IonLabel
            color={EWPCOLORS.primary}
            className="ewp-datepicker-label ewp-paragraph primary"
          >
            {label}
          </IonLabel>
        )}
        <IonButton
          className={`${className} ewp-datepicker-action-button ${
            !!errorMsg && "has-error"
          } ${
            !!label ? "has-label" : ""
          } ewp-datepicker-calendar-button ewp-web-white-button ewp-paragraph ion-text-capitalize ion-no-margin`}
          onClick={(event) => {
            setCalendarAnchor(event.nativeEvent);
          }}
        >
          <div className="ewp-datepicker-calendar-text">
            {!!selectedDate
              ? moment(selectedDate).format(
                  !!format ? format : "ddd, MMMM D, YYYY"
                )
              : datePickerLabel}
          </div>
          <IonIcon className="ewp-datepicker-calendar-icon" />
        </IonButton>
        <div className="ewp-input-error-container">
          <IonLabel className="ewp-input-error-msg ewp-paragraph ion-no-margin ion-no-padding">
            {errorMsg}
          </IonLabel>
        </div>
      </div>
      <EWPCalendar
        calendarAnchor={calendarAnchor}
        selectedDate={!!selectedDate ? (selectedDate as Date) : new Date()}
        removeCalendarAnchor={() => {
          setCalendarAnchor(null);
        }}
        onDateChange={onDateChange}
        {...(!!maxDate && { maxDate })}
        {...(!!minDate && { minDate })}
      />
    </>
  );
};
