import { TimezoneIgnoredTimeStamp } from "../functions/common";
import { Client, ContactPerson } from "./client";
import { Driver, Truck } from "./drivers";
import { Notes, User } from "./global";

export type JobType = 1 | 2 | 3 | -1;
export type NotificationStatusId = 1 | 2 | 3;

export interface JobInterface {
  id: JobType;
  name: string;
}

export interface NotificationStatusInterface {
  id: NotificationStatusId;
  name: string;
  key: string;
}

export const NOTIFICATION_STATUS: {
  [key: string]: NotificationStatusInterface;
} = {
  notSent: { id: 1, name: "Send Driver Notification", key: "notSent" },
  pending: { id: 2, name: "Notification Pending Acceptance", key: "pending" },
  accepted: { id: 3, name: "Driver Notification Accepted", key: "accepted" },
};

export const NOTIFICATION_STATUS_WITH_KEYS = {
  [NOTIFICATION_STATUS.notSent.id]: NOTIFICATION_STATUS.notSent,
  [NOTIFICATION_STATUS.pending.id]: NOTIFICATION_STATUS.pending,
  [NOTIFICATION_STATUS.accepted.id]: NOTIFICATION_STATUS.accepted,
};

export const JOBS: { [key: string]: JobInterface } = {
  ewp: { id: 1, name: "EWP" },
  ubm: { id: 2, name: "UBM" },
  utility: { id: 3, name: "Utility" },
  noTruck: { id: -1, name: "N/A" },
};

export const JOBS_WITH_KEYS = {
  [JOBS.ewp.id]: JOBS.ewp,
  [JOBS.ubm.id]: JOBS.ubm,
  [JOBS.utility.id]: JOBS.utility,
  [JOBS.noTruck.id]: JOBS.noTruck,
};

export interface SiteDocket {
  uploadDate: Date | firebase.firestore.Timestamp;
  siteDocketName: string;
  siteDocketDate: Date | firebase.firestore.Timestamp;
  siteDocketDateNoTimeZone: Date | TimezoneIgnoredTimeStamp;
  attachmentURL: string;
  notApplicable?: boolean;
  brokenFile?: boolean;
}

export interface XeroInvoiceInfo {
  id: string;
  invoiceNumber: string;
  xeroStatus: string;
  amountDue: string;
  amountPaid: string;
  invoiceDate: string;
  dueDate: string;
}
export interface JobSchedule {
  docId?: string;
  // isRemote: boolean;
  monthRange?: string[];
  reminderSent?: boolean;
  startDate: firebase.firestore.Timestamp;
  startDateNoTimeZone: TimezoneIgnoredTimeStamp;
  endDate: firebase.firestore.Timestamp;
  endDateNoTimeZone: TimezoneIgnoredTimeStamp;
  onsiteTime: firebase.firestore.Timestamp;
  onsiteTimeNoTimeZone: TimezoneIgnoredTimeStamp;
  finishTime?: TimezoneIgnoredTimeStamp;
  clientId: string;
  contactPersonId: string;
  truckId: string;
  depotId: string;
  jobType: JobType;
  truckSize: string;
  driverId?: string;
  address: string;
  notes?: string;
  notifyDriver: boolean;
  attachments?: string[];
  siteDockets?: SiteDocket[];
  notificationStatusId: NotificationStatusId;
  notificationReadAt?: firebase.firestore.Timestamp;
  notificationReadAtNoTimeZone?: TimezoneIgnoredTimeStamp;
  jobCompletedAt?: firebase.firestore.Timestamp;
  jobCompletedAtNoTimeZone?: TimezoneIgnoredTimeStamp;
  travelJobTime?: string;
  distanceJob?: string;
  travelBackTime?: string;
  stopPoints?: string[];
  bookingContactName?: string;
  bookingContactNumber?: string;
  invoiced?: boolean;
  invoiceChecked?: boolean;
  invoiceNumber?: string;
  invoiceNotApplicable?: boolean;
  createdBy: string;
  createdDt: firebase.firestore.Timestamp;
  driverNotes?: NotifiedDriverNotes;
  softDeleted?: boolean;
  cancelled?: boolean;
  pencilledIn?: boolean;
  cancelledBy?: string;
  updatedBy?: string;
  updatedDt?: firebase.firestore.Timestamp;
  checkedBy?: string;
  checkedDt?: firebase.firestore.Timestamp;
}

export interface NotifiedDriverNotes {
  createdBy: string;
  createdDate: firebase.firestore.Timestamp;
  description: string;
  adminSeen: boolean;
  seenDate?: firebase.firestore.Timestamp | undefined;
  updateDate?: firebase.firestore.Timestamp;
}

export interface JobAdminNotes {
  docId?: string;
  jobScheduleId: string;
  notes: Notes;
  attachments?: string[];
  updatedDate?: firebase.firestore.Timestamp;
  createdBy: string;
  createdDate: firebase.firestore.Timestamp;
}
export interface JobAdminNotesView extends JobAdminNotes {
  adminDetails: User;
}
export interface Depot {
  docId?: string;
  name: string;
  address: string;
  contactPerson: string;
  contactNumber: string;
  archived: boolean;
  isArchivedBy: string;
  createdBy: string;
  createdDt: firebase.firestore.Timestamp;
  updatedBy?: string;
  updatedDt?: firebase.firestore.Timestamp;
}

export interface JobScheduleView extends JobSchedule {
  clientDetails: Client;
  contactPersonDetails: ContactPerson;
  driverDetails: Driver;
  depotDetails: Depot;
  truckDetails: Truck;
  truckHasBooking?: boolean;
  cancelledByName?: string;
  checkedByName?: string;
  notesCounter: number;
}

export interface BookedTrucksView extends JobSchedule {
  truckDetails: Truck;
}

export interface JobScheduleViewExport {
  Remote: string;
  StartDate: string;
  FinishDate: string;
  Shift: string;
  OnSiteTime: string;
  Client: string;
  ContactPerson: string;
  Depot: string;
  JobType: string;
  Truck: string;
  Driver: string;
  Address: string;
  Notes: string;
  TravelJobTime: string;
  DistanceToJob: string;
  TravelBackTime: string;
  RecommendedStopPoints: string;
}
