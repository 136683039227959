import * as _ from "lodash";
import * as React from "react";
import { IonReactRouter } from "@ionic/react-router";
import { IonRouterOutlet, IonLoading, IonToast, IonModal } from "@ionic/react";
import { Route, Redirect } from "react-router-dom";
import { Capacitor } from "@capacitor/core";

import * as routes from "../constants/routes";
import * as services from "../services";
import * as capacitorStorage from "../functions/localStorageCapacitor";
import { auth, firestore } from "../firebase/index";
import AccountSettings from "../pages/AccountSettings/AccountSettings";
import DriverAccount from "../pages/Mobile/DriverAccount/DriverAccount";
import DriverJobSchedule from "../pages/Mobile/DriverJobSchedule/DriverJobSchedule";
import DriverMaintenance from "../pages/Mobile/DriverMaintenance/DriverMaintenance";
import ExternalAction from "../pages/ExternalAction/ExternalAction";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Home from "../pages/Home/Home";
import Landing from "../pages/Mobile/Landing/Landing";
import Login from "../pages/Login/Login";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import ResetPasswordSuccess from "../pages/ResetPasswordSuccess/ResetPasswordSuccess";
import SignIn from "../pages/Mobile/SignIn/SignIn";
import SignUp from "../pages/Mobile/SignUp/SignUp";
import { isMobile } from "../functions/common";
import { Admin } from "../models/global";
import { AuthenticatedRoute } from "./AuthenticatedRoute";
import { UnauthenticatedRoute } from "./UnauthenticatedRoute";
import { DRIVERS } from "../constants/dbCollections";
import { LOCAL_STORAGE } from "../config";
import { AuthUser, USER_ROLE } from "../config/global";
import { EWPCOLORS } from "../constants/config";
import { GlobalProvider } from "../context/GlobalState";
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications";
import { EWPSplashScreen } from "../components/Mobile/SplashScreen/SplashScreen";

class RouterComponent extends React.Component<any, any> {
  state = {
    loading: true,
    authUser: {} as AuthUser,
    notifications: [] as {
      id: string;
      title: string;
      body: string;
    }[],
    error: "",
    showFakeSplashScreen: false,
    unsubscribeUser: () => {},
    unsubscribeDriver: null as (() => void) | null,
  };

  componentDidMount = () => {
    if (!isMobile()) {
      this.deployAuthStateListener();
    } else {
      this.setState({
        showFakeSplashScreen: true,
      });
      this.deployMobileAuthStateListener();
    }
  };

  deployAuthStateListener = () => {
    auth.onAuthStateChanged(async (authUser) => {
      this.setState({
        loading: true,
      });
      if (!!authUser) {
        this.refreshToken();
        console.log("USER LOGGED IN?");

        //create non removable licence if not existing
        services.createNonRemovableExpiry();
        services.createNonRemovableTruckExpiry();

        const userRole = await services.getUserRole(authUser.uid);
        const validRole = isMobile()
          ? userRole === USER_ROLE.drivers
          : userRole === USER_ROLE.admins; //TEMPORARY FIX FOR ROLE AUTH
        if (!!userRole && validRole) {
          firestore
            .collection(userRole)
            .doc(authUser.uid)
            .onSnapshot(async (snapshot) => {
              const userInfo = { docId: snapshot.id, ...snapshot.data() };
              if (!_.isEmpty(userInfo)) {
                const oldAuthUser = {
                  ...this.state.authUser,
                } as unknown as Admin;
                const newAuthUser = {
                  ...userInfo,
                  role: userRole,
                } as unknown as Admin;
                if (userRole === USER_ROLE.admins) {
                  delete oldAuthUser.lastSignInDt;
                  delete newAuthUser.lastSignInDt;
                }

                const isUpdatedUser =
                  Object.keys(oldAuthUser).length !==
                    Object.keys(newAuthUser).length &&
                  !_.isEmpty(
                    _.compact(
                      Object.keys(oldAuthUser).map((key) => {
                        return (
                          (oldAuthUser as any)[key].toString() !==
                          (newAuthUser as any)[key].toString()
                        );
                      })
                    )
                  );

                if (_.isEmpty(this.state.authUser) || isUpdatedUser) {
                  this.setState({
                    authUser: { ...userInfo, role: userRole },
                    loading: false,
                  });
                }
              } else {
                await services.signOut();
              }
            });
        } else {
          this.setState({
            error: isMobile()
              ? "There is no Driver account associated with this number"
              : "There is no Admin account associated with this email address.",
            errorCallback: () => {
              services.signOut();
            },
          });
        }
      } else {
        console.log("no authUser");
        this.setState({ loading: false, authUser: null });
      }
    });
  };

  deployMobileAuthStateListener = async () => {
    //get session using interval
    const currentDriver = await capacitorStorage
      .getItem(LOCAL_STORAGE.loggedInDriver)
      .catch((error) => {
        this.setState({
          error,
        });
      });

    if (
      (!_.isEmpty(currentDriver) && _.isEmpty(this.state.authUser)) ||
      (_.isEmpty(currentDriver) && !_.isEmpty(this.state.authUser))
    ) {
      this.setState({ loading: true });
    }

    if (!_.isEmpty(currentDriver)) {
      if (_.isNull(this.state.unsubscribeDriver)) {
        const unsubscribeDriver = firestore
          .collection(DRIVERS)
          .doc(currentDriver as string)
          .onSnapshot(
            async (snapshot) => {
              const userInfo = { docId: snapshot.id, ...snapshot.data() };

              if (!_.isEmpty(userInfo)) {
                this.setState({
                  authUser: { ...userInfo, role: "drivers" } as AuthUser,
                  loading: false,
                });
              }
            },
            (error) => {
              this.setState({ error });
            }
          );
        this.setState({ unsubscribeDriver });
      }
    } else {
      this.setState({ loading: false, authUser: null });
      if (!_.isNull(this.state.unsubscribeDriver)) {
        this.state.unsubscribeDriver();
        this.setState({ unsubscribeDriver: null });
      }
    }

    setTimeout(() => {
      this.deployMobileAuthStateListener();
    }, 2000);
  };

  checkAndUpdateDeviceId = async (user: AuthUser, fcmId: string) => {
    if (user.fcmId !== fcmId) {
      // update if not up to date
      await services.updateAccountDetails(user.docId, user.role, { fcmId });
    }
  };

  initializePushNotif = async (userData: AuthUser) => {
    if (Capacitor.isPluginAvailable("PushNotifications")) {
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === "granted") {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();

          // On succcess, we should be able to receive notifications
          PushNotifications.addListener(
            "registration",
            async (token: Token) => {
              // console.log("GOT THE TOKEN FOR NOTIF", token.value);
              await this.checkAndUpdateDeviceId(
                userData as AuthUser,
                token.value
              );
            }
          );

          // Some issue with your setup and push will not work
          PushNotifications.addListener("registrationError", (error: any) => {
            console.log(
              "Error on notif registration: " + JSON.stringify(error)
            );
          });

          // Show us the notification payload if the app is open on our device
          PushNotifications.addListener(
            "pushNotificationReceived",
            (notification: PushNotificationSchema) => {
              let notif = this.state.notifications;
              notif.push({
                id: notification.id,
                title: notification.title || "",
                body: notification.body || "",
              });

              this.setState({
                notifications: notif,
              });
            }
          );

          // Method called when tapping on a notification
          PushNotifications.addListener(
            "pushNotificationActionPerformed",
            (notification: ActionPerformed) => {
              let notif = this.state.notifications;
              notif.push({
                id: notification.notification.data.id,
                title: notification.notification.data.title,
                body: notification.notification.data.body,
              });
              this.setState({
                notifications: notif,
              });
            }
          );
        }
      });
    }
  };

  refreshToken = async () => {
    if (!!auth.currentUser) {
      const idToken = await auth.currentUser.getIdToken(true).catch((error) => {
        console.log("error - getIdToken - ", error);
      });

      setTimeout(() => {
        this.refreshToken();
      }, 1000 * 60 * 60 - 10000); // this would refresh token every 59mins and 50 secs

      // if expired do something
      if (!idToken) {
        this.setState({
          authUser: null,
          loading: false,
        });
      } else {
        const currentToken = await capacitorStorage
          .getItem(LOCAL_STORAGE.fbIdToken)
          .catch((error) => {
            console.log("error - capacitorStorage getItem - ", error);
          });

        // refresh token if not updated
        if (currentToken !== idToken) {
          await capacitorStorage.setItem(LOCAL_STORAGE.fbIdToken, idToken);
          await capacitorStorage.setItem(
            LOCAL_STORAGE.userId,
            auth.currentUser.uid
          );
        }
      }
    }
  };

  render = () => {
    const { loading, authUser, error, showFakeSplashScreen } = this.state;
    return (
      <>
        <IonReactRouter>
          {!loading && (
            <IonRouterOutlet>
              {!isMobile() ? (
                <>
                  <GlobalProvider authUser={authUser}>
                    <AuthenticatedRoute
                      authUser={authUser}
                      component={Home}
                      path={routes.HOME}
                      exact
                    />
                    <AuthenticatedRoute
                      authUser={authUser}
                      component={Home}
                      path={routes.HOME_SUB_PAGE_DETAIL}
                      exact
                    />
                    <AuthenticatedRoute
                      authUser={authUser}
                      component={Home}
                      path={routes.HOME_ADD_NEW_PAGE}
                      exact
                    />
                    <AuthenticatedRoute
                      authUser={authUser}
                      component={Home}
                      path={routes.HOME_EDIT_PAGE}
                      exact
                    />
                    <AuthenticatedRoute
                      authUser={authUser}
                      component={AccountSettings}
                      path={routes.ACCOUNT_SETTINGS}
                      exact
                    />
                  </GlobalProvider>
                  <UnauthenticatedRoute
                    authUser={authUser}
                    component={Login}
                    path={routes.SIGN_IN}
                    exact
                  />
                  <UnauthenticatedRoute
                    authUser={authUser}
                    component={ResetPassword}
                    path={routes.RESET_PASSWORD}
                    exact
                  />
                  <UnauthenticatedRoute
                    authUser={authUser}
                    component={ForgotPassword}
                    path={routes.FORGOT_PASSWORD}
                    exact
                  />
                  <Route
                    exact
                    path={routes.EXTERNAL_ACCOUNT_ACTION}
                    component={ExternalAction}
                  />
                  <Route
                    exact
                    path={routes.RESET_SUCCESS}
                    component={ResetPasswordSuccess}
                  />
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to={routes.SIGN_IN} />}
                  />
                </>
              ) : (
                <>
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to={routes.DRIVER_SIGN_IN} />}
                  />

                  <AuthenticatedRoute
                    authUser={authUser}
                    component={DriverAccount}
                    path={routes.DRIVER_ACCOUNT}
                    exact
                  />
                  <AuthenticatedRoute
                    authUser={authUser}
                    component={DriverMaintenance}
                    path={routes.DRIVER_MAINTENANCE}
                    exact
                  />

                  <AuthenticatedRoute
                    authUser={authUser}
                    component={DriverJobSchedule}
                    path={routes.DRIVER_JOB_SCHEDULE}
                    exact
                  />

                  <UnauthenticatedRoute
                    authUser={authUser}
                    component={Landing}
                    path={routes.DRIVER_LANDING}
                    exact
                  />

                  <UnauthenticatedRoute
                    authUser={authUser}
                    component={SignIn}
                    path={routes.DRIVER_SIGN_IN}
                    exact
                  />

                  <UnauthenticatedRoute
                    authUser={authUser}
                    component={SignUp}
                    path={routes.DRIVER_SIGNUP}
                    exact
                  />
                </>
              )}
            </IonRouterOutlet>
          )}

          <IonLoading
            translucent={true}
            isOpen={loading}
            mode="ios"
            message={"Loading ..."}
          />
          <IonToast
            isOpen={!_.isEmpty(error)}
            onDidDismiss={() => this.setState({ error: "" })}
            message={error}
            duration={1000}
            color={EWPCOLORS.danger}
          />

          <IonModal isOpen={showFakeSplashScreen}>
            <EWPSplashScreen
              onDidDismiss={() => {
                this.setState({
                  showFakeSplashScreen: false,
                });
              }}
            />
          </IonModal>
        </IonReactRouter>
      </>
    );
  };
}

export default RouterComponent;
