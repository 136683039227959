import * as _ from "lodash";
import moment from "moment";
import React from "react";
import {
  IonSlides,
  IonSlide,
  IonContent,
  IonItem,
  IonLabel,
} from "@ionic/react";

import "./DriverMainPage.scss";
import * as routes from "../../../constants/routes";
import * as services from "../../../services";
import { auth } from "../../../firebase";
import { daysSinceDate } from "../../../functions/common";
import { Driver } from "../../../models/drivers";
import { DriverDashboard } from "../../../components/Web/DriverDashboard/DriverDashboard";
import { DriverForm } from "../../../components/DriverForm/DriverForm";
import {
  DriverList,
  DriverListView,
} from "../../../components/Web/DriverList/DriverList";
import { EWPDivider } from "../../../components/EWPDivider/EWPDivider";
import { EWPFormPage } from "../../../components/EWPFormPage/EWPFormPage";
import { EWPProfilePhoto } from "../../../components/EWPProfilePhoto/EWPProfilePhoto";
import { EWPProps, DetailIdParam } from "../../../config/global";
import { NON_REMOVEABLE_DRIVER_EXPIRIES } from "../../../constants/config";

class DriverMainPage extends React.Component<EWPProps> {
  sliderRef: React.RefObject<HTMLIonSlidesElement>;
  state = {
    driverId: "",
    drivers: null as null | Driver[],
    driver: null as DriverListView | null,
    editData: {},
    toUploadProfilePicture: {} as any,
    temporaryProfilePicture: {} as any,
    // userID: "", //todo: enable this once user model is okay
    removeProfilePictureFlag: false,
    photoUploading: false,
    previousProfilePhoto: auth.currentUser!.photoURL!,
    unsubscribeDrivers: () => {},
  };

  constructor(props: EWPProps) {
    super(props);
    this.sliderRef = React.createRef();
  }
  componentDidMount = () => {
    this.getDrivers();
    if (!!this.sliderRef && !!this.sliderRef.current) {
      this.sliderRef.current.lockSwipes(true);
    }

    const { detailId } = this.props.match.params as DetailIdParam;
    if (!!detailId && !_.isEmpty(detailId)) {
      this.goToPage("next");
    }
  };

  getDrivers = () => {
    const unsubscribeDrivers = services.getDriversRealTime((drivers, error) => {
      const { detailId } = this.props.match.params as DetailIdParam;
      if (!_.isEmpty(detailId)) {
        const driverItem =
          _.find(drivers, (driver) => driver.docId === detailId) ||
          ({} as Driver);

        this.getDriverListView(driverItem);
      }
      this.setState({
        drivers,
        error,
      });
    });

    this.setState({
      unsubscribeDrivers,
    });
  };

  componentWillUnmount = () => {
    this.state.unsubscribeDrivers();
  };

  goToPage = (page: "next" | "prev") => {
    if (!!this.sliderRef && !!this.sliderRef.current) {
      this.sliderRef.current.lockSwipes(false);
      if (page === "next") this.sliderRef.current.slideNext();
      else this.sliderRef.current.slidePrev();
      this.sliderRef.current.lockSwipes(true);
    }
  };
  onEditProfilePic = async (file: any) => {
    if (!!file && file.target.files.length > 0) {
      const attachment = file.target.files[0];

      this.setState({
        toUploadProfilePicture: attachment,
        temporaryProfilePicture: URL.createObjectURL(attachment),
      });
    } else {
      // if null activate remove flag
      this.setState({
        temporaryProfilePicture: "",
        removeProfilePictureFlag: true,
      });
    }
  };

  getDriverListView = async (driver: Driver) => {
    if (!_.isEmpty(driver)) {
      try {
        const depot = await services.getDepot(driver.depotId);
        services.getDriverExpiryReminders((driverExpiryList, error) => {
          // setDriverExpiryList(driverExpiryList);
          const licenceNonRemovable = _.find(
            driver.driverExpiries,
            (expiry) =>
              expiry.expiryId === NON_REMOVEABLE_DRIVER_EXPIRIES[0].docId
          );

          const whiteCardNonRemovable = _.find(
            driver.driverExpiries,
            (expiry) =>
              expiry.expiryId === NON_REMOVEABLE_DRIVER_EXPIRIES[1].docId
          );
          const ewpTicketNonRemovable = _.find(
            driver.driverExpiries,
            (expiry) =>
              expiry.expiryId === NON_REMOVEABLE_DRIVER_EXPIRIES[2].docId
          );

          // const isDueForReminder =
          // !_.isEmpty(licenceNonRemovable) &&
          // daysSinceDate(new Date(), licenceNonRemovable!.expirationDate.toDate()) <=
          // licenceNonRemovable!.expiryReminder * 7
          const toExpire = _.find(driverExpiryList, (driverExpiry) => {
            const existingExpiry = _.find(
              driver.driverExpiries || [],
              (expiry) => expiry.expiryId === driverExpiry.docId
            );

            return (
              !_.isEmpty(existingExpiry) &&
              !_.isNull(existingExpiry!.expirationDate) &&
              daysSinceDate(
                new Date(),
                existingExpiry!.expirationDate.toDate()
              ) <=
                driverExpiry!.expiryReminder * 7
            );
          });

          const expired = _.find(driverExpiryList, (driverExpiry) => {
            const existingExpiry = _.find(
              driver.driverExpiries || [],
              (expiry) => expiry.expiryId === driverExpiry.docId
            );

            return (
              !_.isEmpty(existingExpiry) &&
              !_.isNull(existingExpiry!.expirationDate) &&
              daysSinceDate(
                new Date(),
                existingExpiry!.expirationDate.toDate()
              ) <= 0
            );
          });

          this.setState({
            driver: {
              ...driver,
              driverName: `${driver.firstName} ${driver.lastName}`,
              depotName: `${
                !!depot.name && !_.isEmpty(depot.name) ? depot.name : "N/A"
              }`,
              licenceExpiry:
                !_.isEmpty(licenceNonRemovable) &&
                !_.isNull(licenceNonRemovable!.expirationDate)
                  ? moment(licenceNonRemovable!.expirationDate.toDate()).format(
                      "DD MMM YYYY"
                    )
                  : "N/A",
              whiteCardExpiry:
                !_.isEmpty(whiteCardNonRemovable) &&
                !_.isNull(whiteCardNonRemovable!.expirationDate)
                  ? moment(
                      whiteCardNonRemovable!.expirationDate.toDate()
                    ).format("DD MMM YYYY")
                  : "N/A",
              ewpTicketExpiry:
                !_.isEmpty(ewpTicketNonRemovable) &&
                !_.isNull(ewpTicketNonRemovable!.expirationDate)
                  ? moment(
                      ewpTicketNonRemovable!.expirationDate.toDate()
                    ).format("DD MMM YYYY")
                  : "N/A",
              hasFirstAid: driver.hasFirstAid || false,
              hasDefibrillator: driver.hasDefibrillator || false,
              hasWarningExpiry: !_.isEmpty(toExpire),
              hasDueExpiry: !_.isEmpty(expired),
            },
          });
        });
      } catch (eUnknown) {
        const e = eUnknown as any;
        console.log("SILENT ERROR ", e);
        this.setState({ driver: {} as DriverListView });
      }
    } else {
      this.setState({ driver: {} as DriverListView });
    }
  };

  render = () => {
    const { detailId, pageParam } = this.props.match.params as DetailIdParam;

    // const { authUser } = this.props; //todo: enable this for photouploading
    const {
      driver,
      drivers,
      previousProfilePhoto,
      temporaryProfilePicture,
      removeProfilePictureFlag,
      // photoUploading, //todo: enable this for photouploading
    } = this.state;

    const profileToDisplay = !removeProfilePictureFlag
      ? !_.isEmpty(temporaryProfilePicture)
        ? temporaryProfilePicture
        : previousProfilePhoto
      : "";

    const initials = !_.isEmpty(driver)
      ? (driver!.firstName[0] + driver!.lastName[0]).toUpperCase()
      : "";

    return (
      <IonContent className="driver-register-container">
        <IonSlides ref={this.sliderRef}>
          <IonSlide className="driver-register-slide">
            {!detailId && !_.isNull(drivers) && (
              <DriverList
                {...this.props}
                drivers={drivers}
                key={drivers
                  .map((driver, index) => {
                    return `driver_list_${index}`;
                  })
                  .join()}
              />
            )}
          </IonSlide>
          <IonSlide className="driver-register-slide">
            {!!detailId && pageParam === "edit" && !_.isNull(driver) ? (
              <EWPFormPage
                {...this.props}
                backURL={routes.HOME_DRIVER_REGISTER_DETAILS.replace(
                  ":driverId",
                  detailId
                )}
                pageTitle={"Edit Driver"}
              >
                <div className="driver-register-form-page-container">
                  <div className="driver-form-container">
                    <IonItem
                      lines="none"
                      className="driver-form-header-container ion-no-padding"
                    >
                      <EWPProfilePhoto
                        imgSrc={profileToDisplay || ""}
                        onEditProfilePic={this.onEditProfilePic}
                        initials={initials}
                      />
                      <IonLabel className="driver-form-name-label ewp-h2 ion-text-capitalize">
                        {`${driver.firstName} ${driver.lastName}`}
                      </IonLabel>
                    </IonItem>
                    <EWPDivider />
                    <div className="driver-form-details-container">
                      <DriverForm
                        {...this.props}
                        onSuccess={() => {
                          this.props.history.push(
                            routes.HOME_DRIVER_REGISTER_DETAILS.replace(
                              ":driverId",
                              detailId
                            )
                          );
                        }}
                        onDidDismiss={() => {}}
                        editData={driver}
                      />
                    </div>
                  </div>
                </div>
              </EWPFormPage>
            ) : (
              !!detailId && <DriverDashboard {...this.props} driver={driver} />
            )}
          </IonSlide>
        </IonSlides>
      </IonContent>
    );
  };
}

export default DriverMainPage;
