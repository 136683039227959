import React from "react";

import "./Login.scss";

import { IonPage, IonContent } from "@ionic/react";
import { LoginForm } from "../../components/LoginForm/LoginForm";
import { EWPProps } from "../../config/global";
import EWPHeader from "../../components/EWPHeader/EWPHeader";

class Login extends React.Component<EWPProps> {
  render = () => {
    return (
      <IonPage>
        <EWPHeader {...this.props} />
        <IonContent>
          <LoginForm {...this.props} />
        </IonContent>
      </IonPage>
    );
  };
}

export default Login;
