import * as _ from "lodash";
import "react-awesome-lightbox/build/style.css";
import Lightbox from "react-awesome-lightbox";

import React, { useState, useEffect } from "react";
import {
  close,
  chevronForwardOutline,
  chevronBackOutline,
  closeCircleOutline,
  documentSharp,
  warningOutline,
} from "ionicons/icons";
import {
  IonIcon,
  IonLabel,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonImg,
} from "@ionic/react";

import "./AttachmentDialog.scss";
import { EWPCOLORS } from "../../../constants/config";
import { EWPCommonTooltip } from "../EWPCommonTooltip/EWPCommonTooltip";

interface AttachmentDialogProps {
  showModal: boolean;
  attachments: {
    attachmentUrl: string;
    photoName?: string;
    title?: string;
    notApplicable?: boolean;
    brokenFile?: boolean;
  }[];
  isTitleHTML?: boolean;
  selectedIndex?: number;
  title?: string;
  onModalDidDismiss: () => void;
}

export const AttachmentDialog = (props: AttachmentDialogProps) => {
  const {
    showModal,
    attachments,
    isTitleHTML,
    selectedIndex,
    title,
    onModalDidDismiss,
  } = props;
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState(0);

  const [tooltipCoords, setTooltipCoords] = useState({
    left: 0,
    top: 0,
  });

  const [tooltipMessage, setTooltipMessage] = useState("");

  // console.log("got attachments--", attachments);
  useEffect(
    () => {
      if (
        selectedIndex !== selectedAttachmentIndex &&
        !_.isNull(selectedIndex) &&
        selectedIndex !== undefined
      ) {
        setSelectedAttachmentIndex(selectedIndex);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props]
  );

  return (
    <IonModal
      className="attachment-dialog"
      isOpen={showModal}
      onDidDismiss={onModalDidDismiss}
    >
      <IonHeader className="attachment-dialog-header">
        <div className="attachment-dialog-header-container">
          {!isTitleHTML ? (
            <IonLabel className="attachment-dialog-title-html ewp-h4 ion-no-padding">
              {`${
                !_.isEmpty(title)
                  ? title
                  : (attachments[selectedAttachmentIndex].title as string)
              }
              ${
                !!attachments[selectedAttachmentIndex].photoName
                  ? ` - ${
                      attachments[selectedAttachmentIndex].photoName as string
                    }`
                  : ""
              }
              `}
            </IonLabel>
          ) : (
            <div
              className="attachment-dialog-title-html ewp-h4"
              dangerouslySetInnerHTML={{
                __html: `${
                  !_.isEmpty(title)
                    ? title
                    : (attachments[selectedAttachmentIndex].title as string)
                } - 
                ${
                  !!attachments[selectedAttachmentIndex].photoName
                    ? ` - ${
                        attachments[selectedAttachmentIndex].photoName as string
                      }`
                    : ""
                }
                `,
              }}
            />
          )}

          <IonButton
            className="attachment-dialog-header-close ion-no-padding ion-no-margin"
            color={EWPCOLORS.light}
            fill="clear"
            slot="end"
            onClick={onModalDidDismiss}
          >
            <IonIcon color={EWPCOLORS.light} icon={close} />
          </IonButton>
        </div>
      </IonHeader>
      <IonContent scrollY={false} className=" ion-no-padding ion-no-margin">
        <div className="attachment-dialog-content">
          {!_.isEmpty(attachments) && (
            <>
              <IonButton
                fill="clear"
                color={EWPCOLORS.light}
                className="attachment-prev-button ion-no-padding"
                disabled={selectedAttachmentIndex <= 0}
                onClick={() => {
                  const prevIndex = selectedAttachmentIndex - 1;
                  if (prevIndex >= 0) {
                    setSelectedAttachmentIndex(prevIndex);
                  }
                }}
              >
                <IonIcon
                  className="attachment-next-prev-icon"
                  mode="ios"
                  color={EWPCOLORS.tertiary}
                  icon={chevronBackOutline}
                />
              </IonButton>
              <div className="img-download-container">
                {(attachments[selectedAttachmentIndex].attachmentUrl || "")
                  .split("?")[0]
                  .split(".")
                  [
                    (attachments[selectedAttachmentIndex].attachmentUrl || "")
                      .split("?")[0]
                      .split(".").length - 1
                  ].toLowerCase() === "pdf" ? (
                  <div className="file-download-icon">
                    <IonIcon
                      className="attachment-image pdf"
                      icon={documentSharp}
                      // data-tooltip-content={
                      //   attachments[selectedAttachmentIndex].photoName
                      // }
                      // data-tooltip-id="attachment-tooltip-id"
                    />
                    <a
                      className="view-file-link ewp-h2 bold success"
                      target="blank"
                      href={attachments[selectedAttachmentIndex].attachmentUrl}
                    >
                      View File
                    </a>

                    {/* <Tooltip
                      id="attachment-tooltip"
                      // anchorSelect=".attachment-image"
                    /> */}
                  </div>
                ) : attachments[selectedAttachmentIndex].notApplicable ||
                  _.isNull(
                    attachments[selectedAttachmentIndex].attachmentUrl
                  ) ? (
                  <>
                    <IonIcon
                      className="attachment-image pdf"
                      icon={closeCircleOutline}
                      color={EWPCOLORS.danger}
                    />
                  </>
                ) : attachments[selectedAttachmentIndex].brokenFile ? (
                  <>
                    <IonLabel className="ewp-h3 bold danger ion-padding-top">
                      Broken File
                    </IonLabel>
                    <IonIcon
                      className="attachment-image pdf"
                      icon={warningOutline}
                      color={EWPCOLORS.danger}
                    />
                  </>
                ) : (
                  // <IonImg
                  //   className="attachment-image"
                  //   src={attachments[selectedAttachmentIndex].attachmentUrl}
                  // />
                  <div className="attachment-image">
                    <Lightbox
                      image={attachments[selectedAttachmentIndex].attachmentUrl}
                      showTitle={false}
                      // zoomStep={1}
                      // doubleClickZoom={6}
                    />
                  </div>
                )}
                {!attachments[selectedAttachmentIndex].notApplicable &&
                  !attachments[selectedAttachmentIndex].brokenFile &&
                  !_.isNull(
                    attachments[selectedAttachmentIndex].attachmentUrl
                  ) && (
                    <IonButton
                      mode="ios"
                      disabled={
                        attachments[selectedAttachmentIndex].notApplicable
                      }
                      className="download-button ewp-h5 white"
                      onClick={() => {
                        var url =
                          attachments[selectedAttachmentIndex].attachmentUrl;
                        var filename = decodeURIComponent(
                          url.substring(url.lastIndexOf("/") + 1).split("?")[0]
                        );

                        console.log("FILENAME", filename, url);
                        var xhr = new XMLHttpRequest();
                        xhr.responseType = "blob";
                        xhr.onload = function () {
                          var a = document.createElement("a");
                          a.href = window.URL.createObjectURL(xhr.response);
                          a.download = filename;
                          a.style.display = "none";
                          document.body.appendChild(a);
                          a.click();
                        };
                        xhr.open("GET", url);
                        xhr.send();
                      }}
                    >
                      Download
                    </IonButton>
                  )}
              </div>
              <IonButton
                fill="clear"
                color={EWPCOLORS.light}
                className="attachment-next-button ion-no-padding"
                disabled={
                  selectedAttachmentIndex + 1 >= props.attachments.length
                }
                onClick={() => {
                  const nextIndex = selectedAttachmentIndex + 1;
                  if (nextIndex <= props.attachments.length) {
                    setSelectedAttachmentIndex(nextIndex);
                  }
                }}
              >
                <IonIcon
                  className="attachment-next-prev-icon"
                  mode="ios"
                  color={EWPCOLORS.tertiary}
                  icon={chevronForwardOutline}
                />
              </IonButton>
              <div className="attachment-image-list">
                {props.attachments.map((attachment, index) => {
                  const fileSplit = (attachment.attachmentUrl || "")
                    .split("?")[0]
                    .split(".");
                  const fileTypeAttachment =
                    fileSplit[fileSplit.length - 1].toLowerCase();

                  const isSelected = selectedAttachmentIndex === index;

                  return (
                    <IonButton
                      className="attachment-image-item-button ion-no-padding ion-no-margin"
                      fill="clear"
                      key={`attachment-image-${index}`}
                      onClick={() => {
                        if (!isSelected) setSelectedAttachmentIndex(index);
                      }}
                      color={EWPCOLORS.light}
                    >
                      {fileTypeAttachment === "pdf" ? (
                        <div
                          className="attachment-file-link-container"
                          onMouseOver={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                          ) => {
                            // handleMouseOver(e, attachment.attachmentUrl || "");

                            const rect =
                              e.currentTarget.getBoundingClientRect();
                            setTooltipCoords({
                              left: rect.left,
                              top: rect.top,
                            });
                            const fileName = attachment.attachmentUrl || "";

                            const decodedURL = decodeURIComponent(fileName);
                            let urlObj = new URL(decodedURL);
                            let path = urlObj.pathname;

                            let parts = path.split("/");
                            let encodedFileName = parts[parts.length - 1];

                            // Decode the file name
                            let fileNameList =
                              decodeURIComponent(encodedFileName).split("_");
                            fileNameList.splice(0, 2);

                            setTooltipMessage(fileNameList.join("_") || "");
                          }}
                          onMouseOut={() => {
                            setTooltipMessage("");
                          }}
                        >
                          {/* <a target="blank" href={attachment.attachmentUrl}>
                          View File
                        </a> */}
                          <IonIcon
                            size="large"
                            icon={documentSharp}
                            color={
                              isSelected ? EWPCOLORS.dark : EWPCOLORS.light
                            }
                          />
                        </div>
                      ) : attachment.notApplicable ||
                        _.isNull(attachment.attachmentUrl) ? (
                        <>
                          <IonIcon
                            size="large"
                            icon={closeCircleOutline}
                            color={EWPCOLORS.danger}
                          />
                        </>
                      ) : attachment.brokenFile ? (
                        <IonIcon
                          size="large"
                          icon={warningOutline}
                          color={EWPCOLORS.danger}
                        />
                      ) : (
                        <IonImg
                          className="attachment-image-item"
                          src={attachment.attachmentUrl}
                        />
                      )}
                    </IonButton>
                  );
                })}

                <EWPCommonTooltip
                  left={tooltipCoords.left}
                  top={tooltipCoords.top}
                  content={tooltipMessage}
                />
              </div>
            </>
          )}
        </div>
      </IonContent>
    </IonModal>
  );
};
