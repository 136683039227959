import _ from "lodash";
import Bugsnag from "@bugsnag/js";
import { close, navigateCircle } from "ionicons/icons";
import React, { useState } from "react";
import {
  IonModal,
  IonContent,
  IonLabel,
  IonButton,
  IonIcon,
  IonCard,
  IonCardContent,
  IonItem,
  IonRippleEffect,
  IonLoading,
  IonToast,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";

import "./ArchiveOrRestoreTruckDialog.scss";
import * as services from "../../services";
import { EWPNumberEmail } from "../EWPNumberEmail/EWPNumberEmail";
import { DriverListView } from "../Web/DriverList/DriverList";
import { TruckView } from "../../models/drivers";
import {
  ClientContactPersonDetailedView,
  Depot,
  JOBS_WITH_KEYS,
} from "../../models";
import { EWPCOLORS } from "../../constants/config";
import { MSGS_COMMON } from "../../constants/messages";

interface ArchiveOrRestoreTruckDialogProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  onSuccess: () => void;
  data: TruckView | ClientContactPersonDetailedView | DriverListView | Depot;
  restore?: boolean;
  mode: "client" | "truck" | "driver" | "depot";
}

export const ArchiveOrRestoreTruckDialog = (
  props: ArchiveOrRestoreTruckDialogProps
) => {
  const { isOpen, onDidDismiss, onSuccess, restore, data, mode } = props;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const clientData = data as ClientContactPersonDetailedView;
  const truckData = data as TruckView;
  const driverData = data as DriverListView;
  const depotData = data as Depot;
  const archiveOrRestoreSite = async () => {
    const { data, restore } = props;

    if (!!data) {
      setLoading(true);
      try {
        if (!!restore) {
          // set Restore
          if (mode === "truck") {
            await services.setTruckArchived(truckData.docId as string, false);
            setSuccess("Truck successfully Restored!");
          } else if (mode === "driver") {
            await services.setDriverArchived(driverData.docId as string, false);
            setSuccess("Driver successfully Restored!");
          }
        } else {
          if (mode === "truck") {
            await services.setTruckArchived(truckData.docId as string, true);
            setSuccess("Truck successfully Archived!");
          } else if (mode === "driver") {
            await services.setDriverArchived(driverData.docId as string, true);
            setSuccess("Driver successfully Archived!");
          } else if (mode === "depot") {
            //todo: depot firebase delete depot
            console.log("this is depotData --- ", depotData);
            await services.deleteDepot(depotData.docId as string);
            setSuccess("Depot successfully Deleted!");
          }
        }
        setLoading(false);
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        setError(error);
        setLoading(false);
        Bugsnag.notify(new Error(error));
      }
    }
  };

  return (
    <IonModal
      className="archive-restore-dialog"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <IonContent
        scrollY={false}
        className="archive-restore-content ion-no-padding"
      >
        <IonButton
          className="archive-restore-close-button-dialog ion-no-padding ion-no-margin"
          color={EWPCOLORS.light}
          fill="clear"
          onClick={onDidDismiss}
        >
          <IonIcon color={EWPCOLORS.light} icon={close} />
        </IonButton>
        <div className="archive-restore-title-container">
          <IonLabel className="ewp-h1 ion-text-center">
            {`Are you sure you want to ${
              !!restore ? "Restore" : mode === "depot" ? "Delete" : "Archive"
            } this ${_.startCase(mode)}`}
            ?
          </IonLabel>
          <IonLabel className="archive-restore-subtitle ewp-paragraph ion-text-center">
            {!!restore
              ? `This will re-activate the ${_.startCase(mode)}`
              : `Remember, if you ${
                  mode === "depot" ? "delete" : "archive"
                } this ${_.startCase(
                  mode
                )}, they will not be able to be a part of data list for creating schedule.`}
          </IonLabel>
        </div>

        <IonCard
          className="archive-restore-card ion-no-padding ion-no-margin"
          color={EWPCOLORS.tertiary}
        >
          <IonCardContent className="archive-restore-card-content ion-no-padding">
            <IonItem lines="none" className="archive-restore-card-item">
              <IonIcon
                {...(mode === "depot" && { icon: navigateCircle })}
                {...(mode === "depot" && { color: EWPCOLORS.primary })}
                className={`archive-restore-icon ${mode} ion-no-padding`}
              />
              <div className="archive-restore-dialog-detail-container">
                <IonLabel className="ewp-h2 ion-text-start">
                  {mode === "depot"
                    ? depotData.name
                    : mode === "client"
                    ? clientData.name
                    : mode === "driver"
                    ? `${driverData.firstName} ${driverData.lastName}`
                    : truckData.name}
                </IonLabel>
                <IonLabel className="ewp-h4 primary ion-text-start">
                  {mode === "depot"
                    ? depotData.address
                    : mode === "driver"
                    ? ""
                    : mode === "client"
                    ? `ABN ${clientData.abn}`
                    : `${JOBS_WITH_KEYS[truckData.type].name}`}
                </IonLabel>
              </div>
              {mode === "truck" && (
                <IonGrid className="archived-restore-dialog-truck-grid ion-no-padding ion-no-margin">
                  <IonRow className="archived-restore-dialog-truck-row ion-no-padding ion-no-margin">
                    <IonCol
                      size="5"
                      className="archived-restore-dialog-truck-col ion-no-padding ion-no-margin"
                    >
                      <IonLabel className="archived-restore-dialog-truck-detail-label ewp-paragraph ion-no-padding ion-no-margin">
                        <b>Make:</b> {truckData.make}
                      </IonLabel>
                      <IonLabel className="archived-restore-dialog-truck-detail-label ewp-paragraph ion-no-padding ion-no-margin">
                        <b>Model:</b> {truckData.model}
                      </IonLabel>
                      <IonLabel className="archived-restore-dialog-truck-detail-label ewp-paragraph ion-no-padding ion-no-margin">
                        <b>Year:</b> {truckData.year}
                      </IonLabel>
                    </IonCol>
                    <IonCol
                      size="5"
                      className="archived-restore-dialog-truck-col ion-no-padding ion-no-margin"
                    >
                      <IonLabel className="archived-restore-dialog-truck-detail-label ewp-paragraph ion-no-padding ion-no-margin">
                        <b>Rego:</b> {truckData.regoNumber}
                      </IonLabel>
                      <IonLabel className="archived-restore-dialog-truck-detail-label ewp-paragraph ion-no-padding ion-no-margin">
                        <b>VIN:</b> {truckData.vinNumber}
                      </IonLabel>
                      <IonLabel className="archived-restore-dialog-truck-detail-label ewp-paragraph ion-no-padding ion-no-margin">
                        <b>Depot:</b> {truckData.depotName}
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )}
              {mode === "driver" && (
                <EWPNumberEmail
                  className="archived-restore-driver-details"
                  phoneNumber={
                    !_.isNull(driverData) ? driverData.phoneNumber || "" : ""
                  }
                  email={!_.isNull(driverData) ? driverData.emailAddress : ""}
                  loading={_.isNull(driverData)}
                />
              )}
            </IonItem>
          </IonCardContent>
        </IonCard>
        <IonItem
          lines="none"
          className="archive-restore-action-buttons-item ewp-item-no-horizontal-padding"
        >
          <IonButton
            mode="ios"
            slot="start"
            className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
            fill="clear"
            color={EWPCOLORS.medium}
            onClick={onDidDismiss}
          >
            Cancel
          </IonButton>

          <IonButton
            mode="ios"
            slot="end"
            className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
            color={!!restore ? EWPCOLORS.success : EWPCOLORS.danger}
            onClick={archiveOrRestoreSite}
          >
            Yes,{" "}
            {!!restore ? "Restore" : mode === "depot" ? "Delete " : "Archive"}
            <IonRippleEffect />
          </IonButton>
        </IonItem>
      </IonContent>

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => {
          setError("");
        }}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={1000}
        onDidDismiss={() => {
          setSuccess("");
          onSuccess();
        }}
        color={EWPCOLORS.success}
      />
    </IonModal>
  );
};
