import * as _ from "lodash";
import moment from "moment";
import "moment/locale/en-gb";
import React, { Children, useContext, useEffect, useState } from "react";
import {
  checkmarkCircle,
  ellipseOutline,
  alertCircle,
  closeCircle,
  close,
  documentText,
  fileTrayFull,
} from "ionicons/icons";
import {
  Calendar,
  momentLocalizer,
  ToolbarProps,
  DateLocalizer,
  EventProps,
  NavigateAction,
  View,
} from "react-big-calendar";
import {
  IonLabel,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonModal,
  IonButton,
  IonLoading,
  IonToast,
} from "@ionic/react";

import "./JobScheduleCalendar.scss";
import {
  download,
  getDateFromTimezoneIgnoredTimestamp,
  getDateRange,
  getJobStatus,
  isDayShift,
  isTablet,
  useEffectOnlyOnce,
} from "../../functions/common";
import { EWPDatePicker } from "../EWPDatePicker/EWPDatePicker";
import { EWPMiniCalendar } from "../EWPMiniCalendar/EWPMiniCalendar";
import {
  JobScheduleCalendarToolBar,
  JobSubFilter,
} from "./JobScheduleCalendarToolBar/JobScheduleCalendarToolBar";
import {
  JobScheduleView,
  JobScheduleViewExport,
  JOBS_WITH_KEYS,
  JobType,
  NotificationStatusId,
  NOTIFICATION_STATUS,
  NOTIFICATION_STATUS_WITH_KEYS,
} from "../../models/jobSchedule";
import { EWPCOLORS } from "../../constants/config";
import { MSGS_COMMON } from "../../constants/messages";
import { JobScheduleWeekly } from "./JobScheduleWeekly/JobScheduleWeekly";
import { GlobalContext } from "../../context/GlobalState";
import { EWPToolTip } from "../EWPToolTip/EWPToolTip";
import { XeroInvoice } from "../../models/xero";

interface JobScheduleCalendarProps {
  jobSchedules: JobScheduleView[] | null;
  lastDataUpdate: number;
  onChangeDates: (startDate: Date, endDate: Date) => void;
  showSelectedScheduleDetail: (scheduleId: string) => void;
}
const localizer = momentLocalizer(moment);

const formats = {
  weekdayFormat: (
    date: Date,
    culture: string | undefined,
    localizer: DateLocalizer | undefined
  ) => (localizer as DateLocalizer).format(date, "dddd", culture as string),
  eventTimeRangeFormat: () => {
    return ""; //removes the time label inside the react-big-calendar time slots
  },
};

export interface EWPRbCalendarEventInterface {
  allDay?: boolean;
  title?: string;
  start: Date;
  end: Date;
  resource: {
    truckId: string;
    jobSchduleId: string;
    truckName: string;
    notificationStatus: NotificationStatusId;
    jobStatus: "completed" | "incomplete";
    onSiteTime: Date;
    shift: string;
    clientName: string;
    depotName: string;
    driverName: string | null;
    jobType: JobType;
    // isRemoteJob: boolean;
    cancelled: boolean;
    invoiced: boolean;
    invoiceNumber: string;
    pencilledIn: boolean;
    notesCounter: number;
  };
}

//enable this if models is already okay, this is to check the job shift
// const timeShift = parseInt(moment(selectedOnSiteTime).format("HH"));
// const isDayShift = timeShift < JOB_TIME_SHIFTS.night;

export const JobScheduleCalendar = (props: JobScheduleCalendarProps) => {
  // const [selectedEvent, setSelectedEvent] = useState(
  //   null as EWPRbCalendarEventInterface | null
  // );
  const { jobSchedules, lastDataUpdate, onChangeDates } = props;
  const { xeroInvoices } = useContext(GlobalContext);
  const [toolTipAnchor, setToolTipAnchor] = useState(
    null as Event | undefined | null
  );

  const [tooltipInvoiceData, setTooltipInvoiceData] = useState(
    null as null | XeroInvoice | string
  );

  const [noAnchor, setNoAnchor] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarJobSchedules, setCalendarSchedules] = useState(
    null as EWPRbCalendarEventInterface[] | null
  );
  const [filteredCalendarSchedules, setFilteredCalendarSchedules] = useState(
    null as EWPRbCalendarEventInterface[] | null
  );
  const [calendarView, setCalendarView] = useState("work_week" as View);
  const [selectedJobType, setSelectedJobType] = useState(0);
  const [selectedSubOptions, setSelectedSubOptions] = useState(
    [] as JobSubFilter[]
  );
  const [calendarToolBarLabel, setCalendarToolbarLabel] = useState("");
  const [showExport, setShowExport] = useState(false);
  const [selectedExportDate, setSelectedExportDate] = useState(new Date());

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const initialRange = getDateRange(
    new Date(),
    "week" // default
  );
  const [startRange, setStartRange] = useState(initialRange.startDate);

  const [endRange, setEndRange] = useState(initialRange.endDate);

  const [lastDataReload, setLastDataReload] = useState(0);

  useEffectOnlyOnce(() => {
    // console.log("ONLY ONCE CALLED!");
    setCalendarView("work_week");
    setCalendarToolbarLabel(
      `${moment(startRange).format("MMMM DD")} - ${moment(endRange).format(
        startRange.getMonth() === endRange.getMonth() ? "DD" : "MMMM DD"
      )}`
    );
  });

  useEffect(() => {
    if (_.isNull(calendarJobSchedules) || lastDataReload !== lastDataUpdate) {
      getCalendarSchedules(jobSchedules);
      setLastDataReload(lastDataUpdate);
    }
  }, [calendarJobSchedules, lastDataReload, lastDataUpdate, jobSchedules]);

  const getCalendarSchedules = (jobSchedules: JobScheduleView[] | null) => {
    // const { jobSchedules } = props;
    // console.log(
    //   "CALENDAR -- jobSchedules -- ",
    //   _.orderBy(jobSchedules || [], (sched) => sched.createdDt.toDate())
    // );
    if (!!jobSchedules) {
      const formattedCalendarSchdules = jobSchedules.map((jobSchedule) => {
        // const startDate = jobSchedule.startDate.toDate();
        const startDate = getDateFromTimezoneIgnoredTimestamp(
          jobSchedule.startDateNoTimeZone
        );

        // const endDate = jobSchedule.endDate.toDate();
        const endDate = getDateFromTimezoneIgnoredTimestamp(
          jobSchedule.endDateNoTimeZone
        );

        const dateMin = _.clone(new Date());
        dateMin.setHours(0);
        dateMin.setMinutes(0);
        dateMin.setSeconds(0);
        dateMin.setMilliseconds(0);

        const dateMax = _.clone(endDate);
        dateMax.setHours(23);
        dateMax.setMinutes(59);
        dateMax.setSeconds(59);
        dateMax.setMilliseconds(59);

        const truckName = jobSchedule.truckDetails.name;
        // const onSiteTime = jobSchedule.onsiteTime.toDate();
        const onSiteTime = getDateFromTimezoneIgnoredTimestamp(
          jobSchedule.onsiteTimeNoTimeZone
        );
        const shift = isDayShift(onSiteTime) ? "day" : "night";
        const clientName = jobSchedule.clientDetails.name;
        const driverName = !_.isEmpty(jobSchedule.driverDetails)
          ? `${jobSchedule.driverDetails.firstName} ${jobSchedule.driverDetails.lastName}`
          : null;
        const depotName = jobSchedule.depotDetails.name;
        const jobStatus =
          moment(dateMin).isAfter(moment(dateMax)) ||
          !!jobSchedule.jobCompletedAtNoTimeZone
            ? getJobStatus(
                !_.isEmpty(jobSchedule.jobCompletedAtNoTimeZone),
                jobSchedule.siteDockets || []
              )
            : "";
        // const isRemoteJob = jobSchedule.isRemote;
        const notificationStatus = jobSchedule.notificationStatusId;
        return {
          start: startDate,
          end: endDate,
          resource: {
            truckId: jobSchedule.truckId,
            jobSchduleId: jobSchedule.docId as string,
            truckName,
            notificationStatus,
            onSiteTime,
            shift,
            jobStatus,
            clientName,
            depotName,
            driverName,
            jobType: jobSchedule.jobType,
            cancelled: !!jobSchedule.cancelled,
            invoiced: !!jobSchedule.invoiced,
            invoiceNumber: jobSchedule.invoiceNumber || "",
            pencilledIn: jobSchedule.pencilledIn,
            notesCounter: jobSchedule.notesCounter,
            // isRemoteJob,
          },
        };
      }) as EWPRbCalendarEventInterface[];
      setCalendarSchedules(
        _.orderBy(
          formattedCalendarSchdules,
          formattedCalendarSchdules.map(
            (schedule) => schedule.resource.truckName
          ),
          "asc"
        )
      );
    }
  };

  const renderCalendarEvents = (view: string, event: EventProps) => {
    const calendarEvent = event.event as EWPRbCalendarEventInterface;
    const invoiceData =
      !_.isNull(xeroInvoices) && !!calendarEvent.resource.invoiceNumber
        ? _.find(
            xeroInvoices,
            (invoice) =>
              invoice.InvoiceID === calendarEvent.resource.invoiceNumber
          )
        : null;
    switch (view) {
      case "month":
        return (
          <div
            className={`job-schedule-calendar-event-label-container ${
              calendarEvent.resource.shift
            } ${calendarEvent.resource.jobStatus} ${
              calendarEvent.resource.cancelled
                ? "cancelled"
                : calendarEvent.resource.pencilledIn
                ? "pencilled"
                : ""
            }`}
          >
            <div
              className={`job-schedule-calendar-status-job-schedule ${
                calendarEvent.resource.shift
              } ${calendarEvent.resource.jobStatus} ${
                calendarEvent.resource.cancelled
                  ? "cancelled"
                  : calendarEvent.resource.pencilledIn
                  ? "pencilled"
                  : ""
              }`}
            />
            <IonIcon
              className="ewp-invoice-icon"
              icon={documentText}
              color={
                calendarEvent.resource.invoiced
                  ? EWPCOLORS.primary
                  : EWPCOLORS.light
              }
            />
            {calendarEvent.resource.notesCounter > 0 && (
              <div className="admin-notes-container">
                <IonIcon icon={fileTrayFull} />
                <IonLabel className="notes-counter ewp-paragraph bold white">
                  {/* !just red tick for month, too small {calendarEvent.resource.notesCounter} */}
                </IonLabel>
              </div>
            )}
            <div>
              <IonLabel className="job-schedule-calendar-event-label ewp-paragraph">
                <b>{calendarEvent.resource.truckName}</b>
                {` ${moment(calendarEvent.resource.onSiteTime).format(
                  "HH:mm"
                )} - ${calendarEvent.resource.clientName} - `}
              </IonLabel>
              <IonLabel
                className={`ewp-paragraph ${
                  _.isNull(calendarEvent.resource.driverName) && "danger"
                }`}
              >
                {_.isNull(calendarEvent.resource.driverName)
                  ? "No Driver Assigned"
                  : calendarEvent.resource.driverName}
              </IonLabel>
            </div>
            <span className="tooltiptext">
              <b>{calendarEvent.resource.truckName}</b>
              {` ${moment(calendarEvent.resource.onSiteTime).format(
                "HH:mm"
              )} - ${calendarEvent.resource.depotName} - ${
                _.isNull(calendarEvent.resource.driverName)
                  ? "No Driver Assigned"
                  : calendarEvent.resource.driverName
              }`}
            </span>
          </div>
        );
      case "week":
        return (
          <div
            className={`job-schedule-calendar-event-week-view-container ${
              calendarEvent.resource.shift
            } ${calendarEvent.resource.jobStatus} ${
              calendarEvent.resource.cancelled
                ? "cancelled"
                : calendarEvent.resource.pencilledIn
                ? "pencilled"
                : ""
            }`}
          >
            <IonGrid className="job-schedule-calendar-week-view-grid ion-no-padding ion-no-margin">
              <IonRow className="job-schedule-calendar-week-view-row ion-no-padding ion-no-margin">
                <IonCol
                  size="10"
                  className="job-schedule-calendar-week-view-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="ewp-event-week-label ewp-h4 bold">
                    {calendarEvent.resource.truckName}
                  </IonLabel>
                  <IonLabel className="ewp-rbc-time-label ewp-paragraph">
                    {`${moment(calendarEvent.resource.onSiteTime).format(
                      "HH:mm"
                    )}`}{" "}
                    |{" "}
                    {`${moment(calendarEvent.start).format(
                      "DD MMM"
                    )} - ${moment(calendarEvent.end).format("DD MMM YYYY")}`}
                  </IonLabel>
                  <IonLabel className="ewp-event-week-label ewp-paragraph">
                    {/* //todo: change this once models are ready */}
                    {calendarEvent.resource.clientName}
                  </IonLabel>
                  <IonLabel
                    className={`ewp-event-week-label ewp-paragraph ${
                      _.isNull(calendarEvent.resource.driverName) && "danger"
                    }`}
                  >
                    {/* //todo: change this once models are ready */}
                    {_.isNull(calendarEvent.resource.driverName)
                      ? "No Driver Assigned"
                      : calendarEvent.resource.driverName}
                  </IonLabel>
                  {/* <IonLabel className="ewp-rbc-time-label ewp-paragraph bold">
                    {`${moment(calendarEvent.start).format(
                      "DD MMM YYYY"
                    )} - ${moment(calendarEvent.end).format("DD MMM YYYY")}`}
                  </IonLabel> */}
                </IonCol>
                <IonCol
                  size="2"
                  className="job-schedule-calendar-week-view-col ion-no-padding ion-no-margin"
                >
                  <div className="job-schedule-calendar-status-container">
                    {/* {calendarEvent.resource.isRemoteJob && (
                      <IonIcon
                        className={`ewp-remote-icon ${calendarEvent.resource.shift}`}
                        icon={location}
                      />
                    )} */}
                    <IonIcon
                      className={`ewp-notif-status-icon ${
                        calendarEvent.resource.shift
                      } ${
                        NOTIFICATION_STATUS_WITH_KEYS[
                          calendarEvent.resource.notificationStatus
                        ].key
                      }`}
                      icon={
                        calendarEvent.resource.notificationStatus ===
                        NOTIFICATION_STATUS.accepted.id
                          ? checkmarkCircle
                          : calendarEvent.resource.notificationStatus ===
                            NOTIFICATION_STATUS.notSent.id
                          ? ellipseOutline
                          : calendarEvent.resource.notificationStatus ===
                            NOTIFICATION_STATUS.pending.id
                          ? alertCircle
                          : closeCircle
                      }
                    />
                    <div className="invoice-container">
                      <IonIcon
                        className="ewp-invoice-icon week"
                        icon={documentText}
                        color={
                          invoiceData !== undefined && !_.isNull(invoiceData)
                            ? invoiceData.Status !== "PAID"
                              ? moment(invoiceData.DueDateString)
                                  .toDate()
                                  .valueOf() < new Date().valueOf()
                                ? EWPCOLORS.danger
                                : EWPCOLORS.warning
                              : EWPCOLORS.primary
                            : EWPCOLORS.light
                        }
                        onMouseOver={(event) => {
                          if (!!calendarEvent.resource.invoiceNumber) {
                            setNoAnchor(true);
                            setToolTipAnchor(event.nativeEvent);
                            setTooltipInvoiceData(
                              invoiceData !== undefined &&
                                !_.isNull(invoiceData)
                                ? invoiceData
                                : calendarEvent.resource.invoiceNumber
                            );
                          }
                        }}
                      />
                    </div>
                    {calendarEvent.resource.notesCounter > 0 && (
                      <div className="admin-notes-container">
                        <IonIcon icon={fileTrayFull} />
                        <IonLabel className="notes-counter ewp-paragraph bold white">
                          {calendarEvent.resource.notesCounter}
                        </IonLabel>
                      </div>
                    )}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        );
      default:
        return null;
    }
  };

  const onNavigate = (navigate: NavigateAction) => {
    console.log("NAVIGATED- --", navigate);
    let newDate = new Date(); // "TODAY"
    if (navigate === "TODAY") {
      setSelectedDate(newDate);
    }
    if (navigate === "NEXT") {
      if (calendarView === "month") {
        newDate = moment(selectedDate).add(1, "month").toDate();
      } else if (calendarView === "week" || calendarView === "work_week") {
        newDate = moment(selectedDate).add(1, "week").toDate();
      } else if (calendarView === "day") {
        newDate = moment(selectedDate).add(1, "day").toDate();
      } else {
        newDate = new Date();
      }
    }
    if (navigate === "PREV") {
      if (calendarView === "month") {
        newDate = moment(selectedDate).subtract(1, "month").toDate();
      } else if (calendarView === "week" || calendarView === "work_week") {
        newDate = moment(selectedDate).subtract(1, "week").toDate();
      } else if (calendarView === "day") {
        newDate = moment(selectedDate).subtract(1, "day").toDate();
      } else {
        newDate = new Date();
      }
    }

    const date = _.cloneDeep(newDate);
    const { startDate, endDate } = getDateRange(date, calendarView);
    onChangeDates(startDate, endDate);
    setStartRange(startDate);
    setEndRange(endDate);

    setSelectedDate(newDate);
    console.log("NAVIGATED --- ", {
      startDate,
      endDate,
      calendarView,
    });
    if (calendarView === "work_week") {
      setCalendarToolbarLabel(
        `${moment(startDate).format("MMMM DD")} - ${moment(endDate).format(
          startDate.getMonth() === endDate.getMonth() ? "DD" : "MMMM DD"
        )}`
      );
    }
  };

  const downloadSchedules = async (exportDate: Date) => {
    try {
      setLoading(true);
      const dateMin = _.clone(exportDate);
      dateMin.setHours(0);
      dateMin.setMinutes(0);
      dateMin.setSeconds(0);
      dateMin.setMilliseconds(0);

      const dateMax = _.clone(exportDate);
      dateMax.setHours(23);
      dateMax.setMinutes(59);
      dateMax.setSeconds(59);
      dateMax.setMilliseconds(59);

      const { jobSchedules } = props;

      const filteredExportJobSchedules = _.orderBy(
        _.filter(
          jobSchedules,
          (schedule) =>
            moment(
              getDateFromTimezoneIgnoredTimestamp(schedule.startDateNoTimeZone)
            ).isSameOrBefore(dateMax) &&
            moment(
              getDateFromTimezoneIgnoredTimestamp(schedule.startDateNoTimeZone)
            ).isSameOrAfter(dateMin)
        ),
        "startDate",
        "asc"
      ) as JobScheduleView[];

      if (filteredExportJobSchedules.length > 0) {
        const formattedJobSchedules = filteredExportJobSchedules.map(
          (jobSchedule) => {
            const row = {} as Partial<JobScheduleViewExport>;
            // row.Remote = `${jobSchedule.isRemote ? "Yes" : "No"}`;
            row.StartDate = `${moment(
              getDateFromTimezoneIgnoredTimestamp(
                jobSchedule.startDateNoTimeZone
              )
            ).format("ddd, DD/MM/YYY")}`;
            row.StartDate = `${moment(
              getDateFromTimezoneIgnoredTimestamp(jobSchedule.endDateNoTimeZone)
            ).format("ddd, DD/MM/YYY")}`;
            row.OnSiteTime = `${moment(
              getDateFromTimezoneIgnoredTimestamp(
                jobSchedule.onsiteTimeNoTimeZone
              )
            ).format("hh:mm a")}`;
            row.Client = jobSchedule.clientDetails.name;
            row.ContactPerson = `${jobSchedule.contactPersonDetails.firstName} ${jobSchedule.contactPersonDetails.lastName}`;
            row.Depot = jobSchedule.depotDetails.name;
            row.JobType = JOBS_WITH_KEYS[jobSchedule.jobType].name;
            row.Truck = jobSchedule.truckDetails.name;
            row.Driver = `${jobSchedule.driverDetails.firstName} ${jobSchedule.driverDetails.lastName}`;
            row.Address = jobSchedule.address;
            row.Notes = jobSchedule.notes;
            row.TravelBackTime = jobSchedule.travelBackTime;
            row.DistanceToJob = jobSchedule.distanceJob;
            row.TravelBackTime = jobSchedule.travelBackTime;
            row.RecommendedStopPoints = !!jobSchedule.stopPoints
              ? jobSchedule.stopPoints
                  .map((stopPoint, index) => {
                    return `${index + 1}. ${stopPoint}`;
                  })
                  .toString()
              : "";

            return row as JobScheduleViewExport;
          }
        );

        download(
          formattedJobSchedules,
          `JOB_SCHEDULES-${moment(new Date()).format("MMM D, YYYY").toString()}`
        );

        setLoading(false);
        setSuccess("Download Success!");
      } else {
        setLoading(false);
        setError("No Schedule to Download!");
      }
    } catch (eUnknown) {
      const e = eUnknown as any;
      setLoading(false);
      setError(e);
    }
  };

  const onView = (view: View) => {
    const date = _.cloneDeep(selectedDate);
    const { startDate, endDate } = getDateRange(date, view);
    console.log("ONVIEW CLICKED -- ", {
      startDate,
      endDate,
      view,
    });
    onChangeDates(startDate, endDate);
    setCalendarView(view);
  };

  const filterJobs = (jobType: number | null, subOptions: JobSubFilter[]) => {
    if ((_.isNull(jobType) || jobType === 0) && subOptions.length === 0) {
      setFilteredCalendarSchedules(null);
    } else {
      let newDataList = _.cloneDeep(calendarJobSchedules || []);

      if (jobType !== 0) {
        newDataList = _.filter(
          newDataList,
          (schdule) => schdule.resource.jobType === jobType
        );
      }

      if (subOptions.length > 0) {
        if (subOptions.indexOf("dayJob") !== -1) {
          newDataList = _.filter(newDataList, (schdule) =>
            isDayShift(schdule.resource.onSiteTime)
          );
        }
        if (subOptions.indexOf("nightJob") !== -1) {
          newDataList = _.filter(
            newDataList,
            (schdule) => !isDayShift(schdule.resource.onSiteTime)
          );
        }
        if (subOptions.indexOf("completeJob") !== -1) {
          newDataList = _.filter(
            newDataList,
            (schdule) => schdule.resource.jobStatus === "completed"
          );
        }
        if (subOptions.indexOf("incompleteJob") !== -1) {
          newDataList = _.filter(
            newDataList,
            (schdule) => schdule.resource.jobStatus === "incomplete"
          );
        }
        if (subOptions.indexOf("cancelledJob") !== -1) {
          newDataList = _.filter(
            newDataList,
            (schdule) => schdule.resource.cancelled
          );
        }
        if (subOptions.indexOf("pencilledJob") !== -1) {
          newDataList = _.filter(
            newDataList,
            (schdule) => schdule.resource.pencilledIn
          );
        }
      }

      setFilteredCalendarSchedules(newDataList);
    }
  };
  return (
    <>
      <IonCard
        className={`job-schedule-calendar-card ${
          isTablet() ? "ipad" : "web"
        } ion-no-margin`}
      >
        <div>
          <JobScheduleCalendarToolBar
            onNavigate={(navigate) => {
              onNavigate(navigate);
            }}
            view={calendarView}
            jobType={selectedJobType}
            label={
              calendarView !== "day"
                ? calendarToolBarLabel
                : moment(selectedDate).format("dddd, MMM DD, YYYY")
            }
            onClickJobType={(jobType) => {
              setSelectedJobType(jobType);
              filterJobs(jobType, selectedSubOptions);
            }}
            onChangeSubFilters={(subFilters) => {
              setSelectedSubOptions(subFilters);
              filterJobs(selectedJobType, subFilters);
            }}
            onView={(view) => {
              onView(view);
            }}
            onClickExport={() => {
              setShowExport(true);
            }}
          />
        </div>
        {calendarView !== "day" ? (
          calendarView === "work_week" ? (
            <JobScheduleWeekly
              calendarJobSchedules={
                !_.isNull(filteredCalendarSchedules)
                  ? filteredCalendarSchedules
                  : calendarJobSchedules
              }
              lastDataUpdate={lastDataReload}
              startDate={startRange}
              endDate={endRange}
              onClick={props.showSelectedScheduleDetail}
            />
          ) : (
            <Calendar
              className="job-schedule-calendar"
              date={selectedDate}
              view={calendarView}
              localizer={localizer}
              events={_.orderBy(
                !_.isNull(filteredCalendarSchedules)
                  ? filteredCalendarSchedules
                  : calendarJobSchedules,
                (resource) => {
                  return resource.resource.truckName;
                },
                "asc"
              )}
              // startAccessor="start"
              startAccessor={(event) => {
                // console.log("CHECCKING DATE --- ", event.start);
                return event.start;
              }}
              endAccessor={(event) => {
                const dateMin = _.cloneDeep(event.start);
                dateMin.setHours(0);
                dateMin.setMinutes(0);
                dateMin.setSeconds(0);
                dateMin.setMilliseconds(0);
                const numOfDaysOfJob = moment(event.end).diff(
                  moment(dateMin),
                  "days"
                );

                return numOfDaysOfJob >= 0
                  ? moment(event.end).add(1, "day").toDate()
                  : event.end;
                // return event.end;
              }}
              // no data manupulation here, just a workaround use to fix the library bug.. see github issue.
              // calendar has issue with 00:00 if the start/end is the same, RBC see the date as 0 day instead of 1 https://github.com/jquense/react-big-calendar/issues/363

              onSelectEvent={(event: EWPRbCalendarEventInterface, e) => {
                props.showSelectedScheduleDetail(event.resource.jobSchduleId);
              }}
              components={{
                toolbar: (event: ToolbarProps) => {
                  setCalendarView(event.view as View);
                  setCalendarToolbarLabel(event.label);
                  //override the toolbar library not to use the default
                  return <></>;
                },
                event: (event: EventProps) => {
                  return renderCalendarEvents(calendarView, event);
                },
                dateCellWrapper: (props: any) => {
                  return React.cloneElement(Children.only(props.children), {
                    style: {
                      ...props.children.style,
                      backgroundColor: moment(
                        props.value.setHours(0, 0, 0, 0)
                      ).isBefore(moment(new Date().setHours(0, 0, 0, 0)))
                        ? "#e6e5e9"
                        : "",
                    },
                  });
                },
              }}
              // onRangeChange={(range, view) => {
              //   console.log("RANGE CHANGED --- ", {
              //     range,
              //     view,
              //   });
              // }}
              // onNavigate={(date, view) => {
              //   console.log("NAVIGATED --- ", date, view);
              // }}
              formats={formats}
            />
          )
        ) : (
          <div className="job-schedule-custom-calendar-day-view-container">
            <IonGrid className="job-schedule-custom-calendar-day-view-grid ion-no-padding ion-no-margin">
              <IonRow className="job-schedule-custom-calendar-day-view-row ion-no-padding ion-no-margin">
                <IonCol
                  size="8"
                  className="job-schedule-custom-calendar-day-view-event-col ion-no-padding ion-no-margin"
                >
                  <IonCard
                    className={`job-schedule-custom-calendar-day-view-card ${
                      isTablet() && "ipad"
                    } ion-no-padding ion-no-margin`}
                  >
                    {_.filter(
                      _.orderBy(
                        !_.isNull(filteredCalendarSchedules)
                          ? filteredCalendarSchedules
                          : calendarJobSchedules,
                        "resource.truckName",
                        "asc"
                      ),
                      (schedule) =>
                        moment(schedule.start, "DD MMM YYYY").format("LL") ===
                          moment(selectedDate, "DD MMM YYYY").format("LL") ||
                        (moment(
                          moment(selectedDate, "DD MMM YYYY").format("LL")
                        ).isSameOrAfter(
                          moment(schedule.start, "DD MMM YYYY").format("LL")
                        ) &&
                          moment(
                            moment(selectedDate, "DD MMM YYYY").format("LL")
                          ).isSameOrBefore(
                            moment(schedule.end, "DD MMM YYYY").format("LL")
                          ))
                    ).map((event) => {
                      const invoiceData =
                        !_.isNull(xeroInvoices) &&
                        !!event.resource.invoiceNumber
                          ? _.find(
                              xeroInvoices,
                              (invoice) =>
                                invoice.InvoiceID ===
                                event.resource.invoiceNumber
                            )
                          : null;
                      return (
                        <div
                          key={`daily-key-job-sched-${event.resource.jobSchduleId}`}
                          className={`job-schedule-calendar-event-day-view-container ${
                            event.resource.shift
                          } ${event.resource.jobStatus} ${
                            event.resource.cancelled
                              ? "cancelled"
                              : event.resource.pencilledIn
                              ? "pencilled"
                              : ""
                          }`}
                          onClick={() => {
                            props.showSelectedScheduleDetail(
                              event.resource.jobSchduleId
                            );
                          }}
                        >
                          <IonGrid className="job-schedule-calendar-day-view-grid ion-no-padding ion-no-margin">
                            <IonRow className="job-schedule-calendar-day-view-row ion-no-padding ion-no-margin">
                              <IonCol
                                size="11"
                                className="job-schedule-calendar-day-view-col ion-no-padding ion-no-margin"
                              >
                                <div className="">
                                  <IonLabel className="ewp-event-day-label truckName ewp-h4 bold">
                                    {event.resource.truckName}
                                  </IonLabel>
                                  <IonLabel className="ewp-event-day-label ewp-paragraph">
                                    {`${moment(
                                      event.resource.onSiteTime
                                    ).format("HH:mm")} - ${
                                      event.resource.clientName
                                    } - `}
                                  </IonLabel>
                                  <IonLabel
                                    className={`ewp-paragraph ${
                                      _.isNull(event.resource.driverName) &&
                                      "danger"
                                    }`}
                                  >{`${
                                    _.isNull(event.resource.driverName)
                                      ? "No Driver Assigned"
                                      : event.resource.driverName
                                  } - `}</IonLabel>
                                  <IonLabel className="ewp-event-day-label ewp-paragraph bold">
                                    {`${moment(event.start).format(
                                      "DD MMM YYYY"
                                    )} - ${moment(event.end).format(
                                      "DD MMM YYYY"
                                    )}`}
                                  </IonLabel>
                                </div>
                              </IonCol>
                              <IonCol
                                size="1"
                                className="job-schedule-calendar-day-view-col ion-no-padding ion-no-margin"
                              >
                                <div className="job-schedule-calendar-status-container">
                                  {/* {event.resource.isRemoteJob && (
                                    <IonIcon
                                      className={`ewp-remote-icon ${event.resource.shift}`}
                                      icon={location}
                                    />
                                  )} */}
                                  <IonIcon
                                    className={`ewp-notif-status-icon ${
                                      event.resource.shift
                                    } ${
                                      NOTIFICATION_STATUS_WITH_KEYS[
                                        event.resource.notificationStatus
                                      ].key
                                    }`}
                                    icon={
                                      event.resource.notificationStatus ===
                                      NOTIFICATION_STATUS.accepted.id
                                        ? checkmarkCircle
                                        : event.resource.notificationStatus ===
                                          NOTIFICATION_STATUS.notSent.id
                                        ? ellipseOutline
                                        : event.resource.notificationStatus ===
                                          NOTIFICATION_STATUS.pending.id
                                        ? alertCircle
                                        : closeCircle
                                    }
                                  />
                                  <div className="invoice-container">
                                    <IonIcon
                                      className="ewp-invoice-icon day"
                                      icon={documentText}
                                      color={
                                        invoiceData !== undefined &&
                                        !_.isNull(invoiceData)
                                          ? invoiceData.Status !== "PAID"
                                            ? moment(invoiceData.DueDateString)
                                                .toDate()
                                                .valueOf() <
                                              new Date().valueOf()
                                              ? EWPCOLORS.danger
                                              : EWPCOLORS.warning
                                            : EWPCOLORS.primary
                                          : EWPCOLORS.light
                                      }
                                      onMouseOver={(mouseEvent) => {
                                        if (!!event.resource.invoiceNumber) {
                                          setToolTipAnchor(
                                            mouseEvent.nativeEvent
                                          );
                                          setTooltipInvoiceData(
                                            invoiceData !== undefined &&
                                              !_.isNull(invoiceData)
                                              ? invoiceData
                                              : event.resource.invoiceNumber
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                  {event.resource.notesCounter > 0 && (
                                    <div className="admin-notes-container">
                                      <IonIcon icon={fileTrayFull} />
                                      <IonLabel className="notes-counter ewp-paragraph bold white">
                                        {event.resource.notesCounter}
                                      </IonLabel>
                                    </div>
                                  )}
                                </div>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </div>
                      );
                    })}
                  </IonCard>
                </IonCol>
                <IonCol
                  size="4"
                  className="job-schedule-custom-calendar-day-view-calendar-col ion-no-padding ion-no-margin"
                >
                  <EWPMiniCalendar
                    selectedDate={selectedDate}
                    onDateChange={(date) => {
                      console.log(" DATE CHANGED --- ", date);
                      const newDate = _.cloneDeep(date);
                      const { startDate, endDate } = getDateRange(
                        newDate,
                        calendarView
                      );
                      onChangeDates(startDate, endDate);
                      setSelectedDate(date);
                    }}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        )}
      </IonCard>
      {!!showExport && (
        <IonModal
          className="job-schedule-calendar-export-dialog"
          isOpen={showExport}
          onDidDismiss={() => {
            setShowExport(false);
          }}
        >
          <div className="export-dialog-container">
            <IonIcon
              className="export-dialog-close ion-no-padding ion-no-margin"
              icon={close}
              color={EWPCOLORS.light}
              onClick={() => setShowExport(false)}
            />
            <IonLabel className="export-dialog-header ewp-h1 ion-no-padding ion-no-margin">
              Export Job Schedule
            </IonLabel>
            <IonLabel className="export-dialog-sub-header ewp-paragraph light ion-no-padding ion-no-margin">
              To export your Job Schedule, simply choose a date and click
              Export.
            </IonLabel>
            <EWPDatePicker
              className="export-date-picker"
              datePickerLabel="Choose Date"
              selectedDate={selectedExportDate}
              onDateChange={(selectedDate: Date) => {
                setSelectedExportDate(selectedDate);
              }}
              errorMsg={""}
            />
            <div className="export-dialog-button-container">
              <IonButton
                color={EWPCOLORS.dark}
                mode="ios"
                fill="clear"
                className="export-dialog-button cancel mb-body dark bold ion-no-padding ion-no-margin"
                onClick={() => setShowExport(false)}
              >
                Cancel
              </IonButton>
              <IonButton
                color={EWPCOLORS.primary}
                mode="ios"
                className="export-dialog-button export mb-body ion-no-padding ion-no-margin"
                onClick={() => {
                  downloadSchedules(selectedExportDate);
                }}
              >
                Export
              </IonButton>
            </div>
          </div>
        </IonModal>
      )}

      {!_.isNull(toolTipAnchor) &&
        !_.isEmpty(toolTipAnchor) &&
        !_.isNull(tooltipInvoiceData) && (
          <EWPToolTip
            anchor={toolTipAnchor}
            text=""
            noAnchor={noAnchor}
            removeAnchor={() => {
              setToolTipAnchor(null);
              setNoAnchor(false);
            }}
          >
            {typeof tooltipInvoiceData !== "string" ? (
              <IonGrid className="xero-invoice-detail-container">
                <IonRow>
                  <IonCol size="8" className="ion-text-start">
                    <IonLabel className="ewp-h5">
                      <b>Invoice: </b>
                      {`${tooltipInvoiceData.InvoiceNumber}${
                        !_.isEmpty(tooltipInvoiceData.Reference)
                          ? ` (Ref: ${tooltipInvoiceData.Reference})`
                          : ""
                      }`}
                    </IonLabel>
                  </IonCol>
                  <IonCol size="4" className="ion-text-end">
                    <IonLabel
                      className={`xero-invoice-status-label ${tooltipInvoiceData.Status} ion-no-margin ion-no-padding`}
                    >
                      {tooltipInvoiceData.Status}
                    </IonLabel>
                    {tooltipInvoiceData.Status !== "PAID" &&
                      moment(tooltipInvoiceData.DueDateString)
                        .toDate()
                        .valueOf() < new Date().valueOf() && (
                        <IonLabel
                          className={`xero-invoice-status-label DUE ion-no-margin ion-no-padding`}
                        >
                          DUE
                        </IonLabel>
                      )}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="6" className="ion-text-start">
                    <IonLabel className="ewp-h5">
                      <b>Due Date: </b>
                      {moment(tooltipInvoiceData.DueDateString).format(
                        "MMM DD, YYYY"
                      )}
                    </IonLabel>
                  </IonCol>
                  <IonCol size="6" className="ion-text-start">
                    <IonLabel className="ewp-h5">
                      <b>Invoice Date: </b>
                      {moment(tooltipInvoiceData.DateString).format(
                        "MMM DD, YYYY"
                      )}
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="6" className="ion-text-start">
                    <IonLabel className="ewp-h5">
                      <b>Amount Due: </b>${tooltipInvoiceData.Total}
                    </IonLabel>
                  </IonCol>
                  <IonCol size="6" className="ion-text-start">
                    <IonLabel className="ewp-h5">
                      <b>Amount Paid: </b>${tooltipInvoiceData.AmountPaid}
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </IonGrid>
            ) : (
              <span className="tooltiptext">
                <b>Non-Xero Invoice Number: </b>
                {` ${tooltipInvoiceData}`}
              </span>
            )}
          </EWPToolTip>
        )}
      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
        }}
        color={EWPCOLORS.success}
      />
    </>
  );
};
