import * as _ from "lodash";
import moment from "moment";
import { close, closeCircleOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  IonLoading,
  IonToast,
  IonImg,
  IonCheckbox,
  IonItem,
  IonAlert,
} from "@ionic/react";

import "./DriverJobScheduleSiteDocket.scss";
import * as services from "../../../services";
import { storage } from "../../../firebase";
import { EWPProps } from "../../../config/global";
import {
  getDateFromTimezoneIgnoredTimestamp,
  isDayShift,
  TimezoneIgnoredTimeStamp,
  timezoneIgnoredTimestamp,
} from "../../../functions/common";
import { JobScheduleView, SiteDocket } from "../../../models/jobSchedule";
import { EWPCOLORS } from "../../../constants/config";
import { MSGS_COMMON } from "../../../constants/messages";
import { MobileAttachmentDialog } from "../MobileAttachmentDialog/MobileAttachmentDialog";
import Bugsnag from "@bugsnag/js";
import { Camera, CameraResultType } from "@capacitor/camera";

interface DriverJobScheduleSiteDocketProps extends EWPProps {
  jobSchedule: JobScheduleView | null;
}

export interface JobSchduleSiteDocket {
  jobScheduleId: string;
  jobScheduleDate: Date;
  siteDockets: SiteDocket[];
}

export const DriverJobScheduleSiteDocket = (
  props: DriverJobScheduleSiteDocketProps
) => {
  const { jobSchedule } = props;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const [showSiteDocketAlert, setShowSiteDocketAlert] = useState(false);
  const [docketEvent, setDocketEvent] = useState(
    null as null | React.MouseEvent | CustomEvent
  );

  const [jobSiteDockets, setJobSiteDockets] = useState(
    null as null | JobSchduleSiteDocket[]
  );

  const [selectedSiteDocket, setSelectedSiteDocket] = useState(
    {} as JobSchduleSiteDocket
  );
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);
  const [selectedDocketIndex, setSelectedDocketIndex] = useState(0);

  // console.log("ON SITE DOCKETS -- ", jobSchedule);
  useEffect(() => {
    if (_.isNull(jobSiteDockets)) {
      getJobScheduleSiteDockets();
    }
  });
  const getJobScheduleSiteDockets = () => {
    if (!!jobSchedule) {
      const dateMin = _.clone(
        getDateFromTimezoneIgnoredTimestamp(jobSchedule.startDateNoTimeZone)
      );
      dateMin.setHours(0);
      dateMin.setMinutes(0);
      dateMin.setSeconds(0);
      dateMin.setMilliseconds(0);

      const jobFinish = moment(
        getDateFromTimezoneIgnoredTimestamp(jobSchedule.endDateNoTimeZone)
      );
      const numOfDaysOfJob = jobFinish.diff(moment(dateMin), "days");

      if (numOfDaysOfJob > 0) {
        let setJobSchedules = [dateMin, jobFinish.toDate()] as Date[];
        for (let dayCounter = 1; dayCounter < numOfDaysOfJob; dayCounter++) {
          setJobSchedules.push(
            _.cloneDeep(moment(dateMin)).add(dayCounter, "days").toDate()
          );
        }
        const formattedJobSchedules = _.orderBy(setJobSchedules);
        // console.log("jobSchedule.siteDockets --- ", jobSchedule.siteDockets);
        const jobScheduleSiteDockets = formattedJobSchedules.map((date) => {
          return {
            jobScheduleId: jobSchedule.docId as string,
            jobScheduleDate: date,
            siteDockets: _.filter(
              jobSchedule.siteDockets || [],
              (siteDocket) => {
                return moment(
                  // toDateTimeFromSecs(
                  //   (siteDocket.siteDocketDate as firebase.firestore.Timestamp)
                  //     .seconds
                  // )
                  getDateFromTimezoneIgnoredTimestamp(
                    siteDocket.siteDocketDateNoTimeZone as TimezoneIgnoredTimeStamp
                  )
                ).isSame(moment(date), "days");
              }
            ),
          } as JobSchduleSiteDocket;
        });
        // console.log("jobScheduleSiteDockets ---- ", jobScheduleSiteDockets);
        setJobSiteDockets(jobScheduleSiteDockets);
      } else {
        //single day job
        setJobSiteDockets([
          {
            jobScheduleId: jobSchedule.docId as string,
            jobScheduleDate: jobFinish.toDate(),
            siteDockets: _.filter(
              jobSchedule.siteDockets || [],
              (siteDocket) => {
                return moment(
                  // toDateTimeFromSecs(
                  //   (siteDocket.siteDocketDate as firebase.firestore.Timestamp)
                  //     .seconds
                  // )
                  getDateFromTimezoneIgnoredTimestamp(
                    siteDocket.siteDocketDateNoTimeZone as TimezoneIgnoredTimeStamp
                  )
                ).isSame(moment(jobFinish.toDate()), "days");
              }
            ),
          },
        ]);
      }
    }
  };

  const addSiteDocket = async (
    event: React.MouseEvent | CustomEvent,
    siteDocket: JobSchduleSiteDocket,
    index: number,
    siteDocketName: string,
    notApplicable = false
  ) => {
    event.preventDefault();
    try {
      // Take a photo
      const clonedJobSiteDockets = _.cloneDeep(jobSiteDockets);
      const clonedSelectedSiteDockets = _.cloneDeep(siteDocket);
      const result = await Camera.requestPermissions();
      if (result.camera !== "denied") {
        const capturedPhoto = !notApplicable
          ? await Camera.getPhoto({
              resultType: CameraResultType.Uri,
              quality: 50,
            })
          : null;
        setLoading(true);
        console.log(
          "GOT SELECTED PHOTO --- ",
          JSON.stringify(capturedPhoto, null, 1)
        );
        if (
          !!clonedJobSiteDockets &&
          !!jobSchedule &&
          ((notApplicable && _.isNull(capturedPhoto)) ||
            (!notApplicable && !_.isNull(capturedPhoto)))
        ) {
          const siteDocketURL = !_.isNull(capturedPhoto)
            ? ((await uploadAttachments(capturedPhoto.webPath || "")) as string)
            : "";
          clonedJobSiteDockets[index].siteDockets.push({
            siteDocketDate: clonedSelectedSiteDockets.jobScheduleDate,
            siteDocketDateNoTimeZone: timezoneIgnoredTimestamp(
              clonedSelectedSiteDockets.jobScheduleDate
            ),
            siteDocketName,
            uploadDate: new Date(),
            attachmentURL: siteDocketURL,
            notApplicable,
          });
          await updateJobSiteDocket(
            {
              siteDocketDate: clonedSelectedSiteDockets.jobScheduleDate,
              siteDocketDateNoTimeZone: timezoneIgnoredTimestamp(
                clonedSelectedSiteDockets.jobScheduleDate
              ),
              siteDocketName,
              uploadDate: new Date(),
              attachmentURL: siteDocketURL,
              notApplicable,
            },
            jobSchedule.docId as string,
            jobSchedule.driverId as string
          );
          setJobSiteDockets(clonedJobSiteDockets);
        } else {
          setError("Something went wrong. Please try again.");
        }
      } else {
        setError("Permission Denied. Please try again.");
      }
      setLoading(false);
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      setLoading(false);
      setError(error.message || error);
    }
  };

  const uploadAttachments = async (attachmentBlobRefs: string) => {
    if (_.isEmpty(attachmentBlobRefs)) return [];
    const ref = storage.ref();
    const { docId, lastName } = props.authUser;

    const uploadPromiseList = new Promise(async (resolve) => {
      const attachmentPath = `siteDockets/${lastName
        .replace(" ", "")
        .toLowerCase()}_${docId}/site_docket_image_${new Date().valueOf()}`;
      try {
        let blob = await fetch(attachmentBlobRefs).then((r) => r.blob());
        ref
          .child(attachmentPath)
          .put(blob)
          .then(async (result) => {
            const downloadUrl = await result.ref.getDownloadURL();
            return resolve(downloadUrl);
          })
          .catch((error) => {
            resolve(null);
          });
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        resolve(null);
        Bugsnag.notify(new Error(error));
      }
    });

    return uploadPromiseList;
  };

  const removeJobSiteDocket = async (
    siteDocket: SiteDocket,
    jobScheduleId: string,
    driverId: string
  ) => {
    try {
      await services.deleteAttachment(siteDocket.attachmentURL);
      await services.removeJobSiteDocket(siteDocket, jobScheduleId, driverId);
      setSuccess("Site Docket Removed!");
      setLoading(false);
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("error - removeJobSiteDocket -- ", error);
      setError(error);
      setLoading(false);
      Bugsnag.notify(new Error(error));
    }
  };

  const updateJobSiteDocketList = async (
    siteDockets: SiteDocket[],
    jobScheduleId: string,
    driverId: string
  ) => {
    try {
      await Promise.all(
        _.compact(
          siteDockets.map((siteDocket) => {
            if (!_.isEmpty(siteDocket.attachmentURL)) {
              return services.deleteAttachment(siteDocket.attachmentURL);
            } else {
              return null;
            }
          })
        )
      );
      await services.updateJobScheduleGeneric(
        jobScheduleId,
        {
          siteDockets: siteDockets.map((siteDocket) => {
            console.log("WILL UPDATE USING", {
              ...siteDocket,
              siteDocketDate: (
                siteDocket.siteDocketDate as firebase.firestore.Timestamp
              ).toDate(),
              uploadDate: (
                siteDocket.uploadDate as unknown as firebase.firestore.Timestamp
              ).toDate(),
            });
            return {
              ...siteDocket,
              siteDocketDate: (
                siteDocket.siteDocketDate as firebase.firestore.Timestamp
              ).toDate(),
              uploadDate: (
                siteDocket.uploadDate as unknown as firebase.firestore.Timestamp
              ).toDate(),
            };
          }),
        },
        driverId
      );
      setSuccess("Site Dockets Updated!");
      setLoading(false);
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("error - updateJobSiteDocketList -- ", error);
      setError(error);
      setLoading(false);
      Bugsnag.notify(new Error(error));
    }
  };

  const updateJobSiteDocket = async (
    siteDocket: SiteDocket,
    jobScheduleId: string,
    driverId: string
  ) => {
    setLoading(true);
    try {
      await services.updateJobSiteDocket(siteDocket, jobScheduleId, driverId);
      setSuccess("Site Docket Successfully Added!");
      setLoading(false);
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("error - updateJobSiteDocket -- ", error);
      setError(error);
      setLoading(false);
      Bugsnag.notify(new Error(error));
    }
  };
  return (
    <div className="driver-job-detail-site-docket">
      {!!jobSchedule && (
        <>
          <div className="driver-job-detail-site-docket-header-container">
            <IonGrid className="driver-job-detail-site-docket-grid ion-no-padding ion-no-margin">
              <IonRow className="driver-job-detail-site-docket-row ion-no-padding ion-no-margin">
                <IonCol
                  size="2.5"
                  className="driver-job-detail-site-docket-col shift ion-no-margin ion-no-padding"
                >
                  <div
                    className={`driver-job-detail-site-docket-shift ${
                      isDayShift(
                        getDateFromTimezoneIgnoredTimestamp(
                          jobSchedule.onsiteTimeNoTimeZone
                        )
                        // toDateTimeFromSecs(jobSchedule.onsiteTime.seconds)
                      )
                        ? "day"
                        : "night"
                    }`}
                  />
                </IonCol>
                <IonCol
                  size="9.5"
                  className="driver-job-detail-site-docket-col ion-no-margin ion-no-padding"
                >
                  <div className="driver-job-detail-site-docket-header-label-container">
                    <IonLabel className="ewp-h4">
                      {jobSchedule.depotDetails.name}
                    </IonLabel>
                    <IonLabel
                      className={`driver-job-detail-site-docket-header-label ${
                        isDayShift(
                          getDateFromTimezoneIgnoredTimestamp(
                            jobSchedule.onsiteTimeNoTimeZone
                          )
                          // toDateTimeFromSecs(jobSchedule.onsiteTime.seconds)
                        )
                          ? "day"
                          : "night"
                      } ewp-h6 bold`}
                    >
                      {`${moment(
                        getDateFromTimezoneIgnoredTimestamp(
                          jobSchedule.startDateNoTimeZone
                        )
                      ).format("ddd DD MMM YYYY")} - ${moment(
                        // toDateTimeFromSecs(jobSchedule.endDate.seconds)
                        getDateFromTimezoneIgnoredTimestamp(
                          jobSchedule.endDateNoTimeZone
                        )
                      ).format("ddd DD MMM YYYY")}`}
                    </IonLabel>
                    <IonLabel className="job-id-label ewp-h6">
                      <b>Job Id:</b> {jobSchedule.docId}
                    </IonLabel>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          {!_.isNull(jobSiteDockets) &&
            jobSiteDockets.map((jobSiteDocket, jobSiteDocketIndex) => {
              // console.log("CURRENT JOB DOCKETS --- ", jobSiteDocket);

              const isApplicable = _.isEmpty(
                _.find(
                  jobSiteDocket.siteDockets,
                  (siteDocket) => siteDocket.notApplicable
                )
              );
              return (
                <div className="driver-job-detail-site-docket-details-container">
                  <IonLabel className="job-site-docket-date-label ewp-paragraph bold">
                    {moment(jobSiteDocket.jobScheduleDate).format(
                      "ddd DD MMM YYYY"
                    )}
                  </IonLabel>
                  {!_.isEmpty(jobSiteDocket.siteDockets) ? (
                    <>
                      <IonGrid className="site-docket-attachment-grid ion-no-padding ion-no-margin">
                        {isApplicable &&
                          _.chunk(jobSiteDocket.siteDockets, 3).map(
                            (row, index) => {
                              return (
                                <IonRow
                                  key={`row_${index}`}
                                  className="site-docket-attachment-row ion-no-padding ion-no-margin"
                                >
                                  {row.map((siteDocket, siteDocketIndex) => {
                                    return (
                                      <IonCol
                                        key={`site-docket_${siteDocketIndex}`}
                                        size="4"
                                      >
                                        {!!siteDocket.attachmentURL &&
                                        !siteDocket.notApplicable ? (
                                          <div className="site-docket-attachment-img-container">
                                            <IonImg
                                              src={siteDocket.attachmentURL}
                                              className="site-docket-attachment-img ion-no-padding ion-no-margin"
                                              onClick={() => {
                                                setSelectedSiteDocket(
                                                  jobSiteDocket
                                                );
                                                setShowAttachmentDialog(true);
                                                setSelectedDocketIndex(
                                                  siteDocketIndex
                                                );
                                              }}
                                            />
                                            <IonButton
                                              mode="ios"
                                              color={EWPCOLORS.primary}
                                              className="site-docket-delete-attachment ion-no-padding ion-no-margin"
                                              onClick={() => {
                                                setLoading(true);
                                                console.log(
                                                  "selected siteDocket --- ",
                                                  siteDocket
                                                );
                                                //copy of the job site dockets state of this jobSiteDocketIndex
                                                const clonedJobSiteDockets =
                                                  _.cloneDeep(
                                                    jobSiteDockets[
                                                      jobSiteDocketIndex
                                                    ]
                                                  );
                                                //copy of the siteDockets of the jobSiteDocket from state
                                                const clonedSiteDockets =
                                                  _.cloneDeep(
                                                    clonedJobSiteDockets.siteDockets
                                                  );

                                                //remove the site docket by selected index
                                                //returns the new array without the remove site docket
                                                const newSiteDockets = _.remove(
                                                  clonedSiteDockets,
                                                  (removeSiteDocket, index) =>
                                                    index !== siteDocketIndex
                                                );

                                                clonedJobSiteDockets.siteDockets =
                                                  newSiteDockets;
                                                const newJobSiteDockets =
                                                  _.cloneDeep(jobSiteDockets);
                                                newJobSiteDockets[
                                                  jobSiteDocketIndex
                                                ] = clonedJobSiteDockets;

                                                removeJobSiteDocket(
                                                  siteDocket,
                                                  jobSchedule.docId as string,
                                                  jobSchedule.driverId as string
                                                );
                                                setJobSiteDockets(
                                                  newJobSiteDockets
                                                );
                                              }}
                                            >
                                              <IonIcon icon={close} />
                                            </IonButton>
                                          </div>
                                        ) : (
                                          <IonIcon
                                            size="large"
                                            icon={closeCircleOutline}
                                            color={EWPCOLORS.danger}
                                          />
                                        )}
                                      </IonCol>
                                    );
                                  })}
                                </IonRow>
                              );
                            }
                          )}

                        {isApplicable && (
                          <div className="add-add-attachment-button-container">
                            <IonButton
                              mode="ios"
                              disabled={jobSiteDocket.siteDockets.length >= 3}
                              className="add-attachment-button ewp-h5 ion-no-padding ion-no-margin"
                              onClick={(event) => {
                                // addSiteDocket(
                                //   event,
                                //   jobSiteDocket,
                                //   jobSiteDocketIndex
                                // );
                                setDocketEvent(
                                  event as React.MouseEvent | CustomEvent
                                );
                                setSelectedSiteDocket(jobSiteDocket);
                                setSelectedDocketIndex(jobSiteDocketIndex);
                                setShowSiteDocketAlert(true);
                              }}
                            >
                              <IonIcon className="add-attachment-button-icon" />
                              Add Images
                            </IonButton>
                          </div>
                        )}
                        <IonItem
                          lines="none"
                          className="job-site-docket-not-applicable-container"
                        >
                          <IonCheckbox
                            className="job-site-docket-not-applicable"
                            // slot="end"
                            color="danger"
                            checked={!isApplicable}
                            onClick={(event) => {
                              if (
                                _.isEmpty(
                                  _.find(
                                    jobSiteDocket.siteDockets,
                                    (siteDocket) => siteDocket.notApplicable
                                  )
                                )
                              ) {
                                const clonedJobSiteDockets = _.cloneDeep(
                                  jobSiteDockets[jobSiteDocketIndex]
                                );
                                //remove all site docket by selected index
                                clonedJobSiteDockets.siteDockets = [];
                                const newJobSiteDockets =
                                  _.cloneDeep(jobSiteDockets);
                                newJobSiteDockets[jobSiteDocketIndex] =
                                  clonedJobSiteDockets;

                                // jobSiteDockets[
                                //   jobSiteDocketIndex
                                // ].siteDockets.forEach((siteDocket) => {
                                //   console.log(
                                //     "WILL REMOVE THIS SHIT --- ",
                                //     siteDocket
                                //   );
                                //   removeJobSiteDocket(
                                //     siteDocket,
                                //     jobSchedule.docId as string,
                                //     jobSchedule.driverId as string
                                //   );
                                // });
                                updateJobSiteDocketList(
                                  _.flatMap(
                                    newJobSiteDockets.map(
                                      (jobSiteDocketSet) =>
                                        jobSiteDocketSet.siteDockets
                                    )
                                  ),
                                  jobSchedule.docId as string,
                                  jobSchedule.driverId as string
                                );

                                setJobSiteDockets(newJobSiteDockets);

                                addSiteDocket(
                                  event,
                                  jobSiteDocket,
                                  jobSiteDocketIndex,
                                  "",
                                  !(jobSiteDocket.siteDockets[
                                    jobSiteDocketIndex
                                  ] !== undefined
                                    ? !!jobSiteDocket.siteDockets[
                                        jobSiteDocketIndex
                                      ].notApplicable
                                    : false)
                                );
                              } else {
                                console.log("WILL REMOVE NOT APPLICABLE");
                                const clonedJobSiteDockets = _.cloneDeep(
                                  jobSiteDockets[jobSiteDocketIndex]
                                );
                                //remove all site docket by selected index
                                clonedJobSiteDockets.siteDockets = [];
                                const newJobSiteDockets =
                                  _.cloneDeep(jobSiteDockets);
                                newJobSiteDockets[jobSiteDocketIndex] =
                                  clonedJobSiteDockets;
                                updateJobSiteDocketList(
                                  _.flatMap(
                                    newJobSiteDockets.map(
                                      (jobSiteDocketSet) =>
                                        jobSiteDocketSet.siteDockets
                                    )
                                  ),
                                  jobSchedule.docId as string,
                                  jobSchedule.driverId as string
                                );
                                console.log(
                                  "NEW SITE DOCKETS!!! ",
                                  newJobSiteDockets,
                                  "-- old",
                                  jobSiteDockets
                                );
                                setJobSiteDockets(newJobSiteDockets);
                              }
                              console.log("TOGGLED");
                            }}
                            disabled={
                              !moment(
                                jobSiteDocket.jobScheduleDate
                              ).isSameOrBefore(new Date(), "days")
                            }
                          />
                          <IonLabel className="ewp-paragraph bold">
                            Not Applicable
                          </IonLabel>
                        </IonItem>
                      </IonGrid>
                    </>
                  ) : (
                    <>
                      <IonButton
                        mode="ios"
                        fill="clear"
                        disabled={
                          !moment(jobSiteDocket.jobScheduleDate).isSameOrBefore(
                            new Date(),
                            "days"
                          )
                        }
                        className="job-site-docket-add-files-button ion-no-margin ion-no-padding"
                        onClick={(event) => {
                          // TODO: add alert to handle adding name
                          // addSiteDocket(
                          //   event,
                          //   jobSiteDocket,
                          //   jobSiteDocketIndex
                          // );
                          setDocketEvent(
                            event as React.MouseEvent | CustomEvent
                          );
                          setSelectedSiteDocket(jobSiteDocket);
                          setSelectedDocketIndex(jobSiteDocketIndex);
                          setShowSiteDocketAlert(true);
                        }}
                      >
                        <IonIcon className="add-files-icon" />
                        <IonLabel className="add-files-label ewp-h5 light bold ion-no-margin ion-no-padding">
                          Add Files
                        </IonLabel>
                      </IonButton>
                      <IonItem
                        lines="none"
                        className="job-site-docket-not-applicable-container"
                      >
                        <IonCheckbox
                          className="job-site-docket-not-applicable"
                          // slot="end"
                          color="danger"
                          checked={
                            jobSiteDocket.siteDockets[jobSiteDocketIndex] !==
                            undefined
                              ? !!jobSiteDocket.siteDockets[jobSiteDocketIndex]
                                  .notApplicable
                              : false
                          }
                          onClick={(event) => {
                            addSiteDocket(
                              event,
                              jobSiteDocket,
                              jobSiteDocketIndex,
                              "",
                              !(jobSiteDocket.siteDockets[
                                jobSiteDocketIndex
                              ] !== undefined
                                ? !!jobSiteDocket.siteDockets[
                                    jobSiteDocketIndex
                                  ].notApplicable
                                : false)
                            );
                            console.log("TOGGLED");
                          }}
                          disabled={
                            !moment(
                              jobSiteDocket.jobScheduleDate
                            ).isSameOrBefore(new Date(), "days")
                          }
                        />
                        <IonLabel className="ewp-paragraph bold">
                          Not Applicable
                        </IonLabel>
                      </IonItem>
                    </>
                  )}
                </div>
              );
            })}
        </>
      )}
      <MobileAttachmentDialog
        showModal={showAttachmentDialog}
        onDidDismiss={() => {
          setShowAttachmentDialog(false);
        }}
        attachments={{
          attachments: !_.isEmpty(selectedSiteDocket)
            ? selectedSiteDocket.siteDockets.map(
                (siteDocketUrl) => siteDocketUrl.attachmentURL
              )
            : [],
        }}
        selectedIndex={selectedDocketIndex}
      />

      {showSiteDocketAlert && (
        <IonAlert
          isOpen={showSiteDocketAlert}
          backdropDismiss={false}
          translucent={true}
          mode="ios"
          onDidDismiss={() => setShowSiteDocketAlert(false)}
          header={"Please enter a site docket name"}
          inputs={[
            {
              name: "sitedocket_name",
              type: "text",
              value: "",
              disabled: false,
            },
          ]}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                console.log("Confirm Cancel");
                setDocketEvent(null);
                setSelectedSiteDocket({} as JobSchduleSiteDocket);
                setSelectedDocketIndex(0);
              },
            },
            {
              text: "Confirm",
              handler: async (data) => {
                console.log("this is data -- ", data);
                if (!!data && !_.isEmpty(data.sitedocket_name)) {
                  addSiteDocket(
                    docketEvent as React.MouseEvent | CustomEvent,
                    selectedSiteDocket,
                    selectedDocketIndex,
                    data.sitedocket_name
                  );
                } else {
                  setError("Please Enter Site Docket Name");
                }
              },
            },
          ]}
        />
      )}

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => {
          setError("");
        }}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={1000}
        onDidDismiss={() => {
          setSuccess("");
          // onSuccess();
        }}
        color={EWPCOLORS.success}
      />
    </div>
  );
};
