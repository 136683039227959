import * as _ from "lodash";
import qs from "qs";
import React from "react";
import { RouteComponentProps } from "react-router";
import {
  IonPage,
  IonToast,
  IonLoading,
  IonContent,
  IonLabel,
  IonButton,
  IonRippleEffect,
} from "@ionic/react";

import "./ResetPassword.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { validatePassword } from "../../functions/common";
import { FIREBASE_ERROR_CODES, EWPCOLORS } from "../../constants/config";
import {
  MSGS_RESET_PASSWORD,
  MSGS_FIREBASE,
  MSGS_COMMON,
} from "../../constants/messages";
import { EWPPasswordInput } from "../../components/EWPPasswordInput/EWPPasswordInput";

export default class ResetPassword extends React.Component<
  RouteComponentProps
> {
  state = {
    error: "",
    password: "",
    errorNewMsg: "",
    errorConfirmMsg: "",
    confirmPassword: "",
    isSaving: false,
  };

  resetPassword = (event: React.FormEvent) => {
    event.preventDefault();

    this.setState({
      isSaving: true,
    });

    const { password, confirmPassword } = this.state;

    let errorMessage = "";
    if (password.length === 0) {
      errorMessage = MSGS_RESET_PASSWORD.password.required;
      this.setState({ errorNewMsg: errorMessage });
    } else if (confirmPassword.length === 0) {
      errorMessage = MSGS_RESET_PASSWORD.password.retypePasswordRequired;
      this.setState({ errorConfirmMsg: errorMessage });
    } else if (password !== confirmPassword) {
      errorMessage = MSGS_RESET_PASSWORD.password.mismatch;
    } else if (!validatePassword(password)) {
      errorMessage = MSGS_RESET_PASSWORD.password.invalid;
    }

    if (errorMessage) {
      this.setState({
        error: errorMessage,
        isSaving: false,
      });
      return;
    }

    const { history } = this.props;
    const obj = qs.parse(this.props.location.search);

    services
      .doResetPassword(obj.oobCode as any, password)
      .then(() => {
        this.setState({
          isSaving: false,
          errorConfirmMsg: "",
          errorNewMsg: "",
        });
        history.push(routes.RESET_SUCCESS);
      })
      .catch((error) => {
        if (error.code === FIREBASE_ERROR_CODES.invalidActionCode) {
          errorMessage = MSGS_FIREBASE.invalidActionCode;
        } else {
          errorMessage = error.message;
        }

        this.setState({
          error: errorMessage,
          isSaving: false,
        });
      });
  };
  render = () => {
    const {
      isSaving,
      error,
      password,
      confirmPassword,
      errorNewMsg,
      errorConfirmMsg,
    } = this.state;
    return (
      <IonPage>
        <IonContent>
          <div className="reset-password-container">
            <IonLabel className="reset-password-title ewp-h1 ion-no-margin ion-no-padding">
              Reset Password
            </IonLabel>
            <IonLabel className="reset-password-description ewp-paragraph ion-no-margin ion-no-padding">
              Enter a new password for your account.
            </IonLabel>

            <form
              onSubmit={this.resetPassword}
              className="reset-password-form-container"
            >
              <div className="reset-password-new-input-container">
                <EWPPasswordInput
                  passwordLabel="Password"
                  passwordValue={password}
                  onChange={(password: string) => {
                    this.setState({
                      password: password,
                      errorNewMsg: "",
                    });
                  }}
                  errorMessage={errorNewMsg}
                />
              </div>
              <div className="reset-password-confirm-input-container">
                <EWPPasswordInput
                  passwordLabel="Re-Type Password"
                  passwordValue={confirmPassword}
                  onChange={(confirmPassword: string) => {
                    this.setState({
                      confirmPassword: confirmPassword,
                      errorConfirmMsg: "",
                    });
                  }}
                  errorMessage={errorConfirmMsg}
                />
              </div>

              <IonButton
                color={EWPCOLORS.primary}
                type="submit"
                mode="md"
                className="ewp-web-auth-company-rep-button ewp-h3 ion-no-padding ion-no-margin ion-text-capitalize"
              >
                Reset
                <IonRippleEffect />
              </IonButton>
            </form>
          </div>
        </IonContent>
        <IonLoading
          spinner="circular"
          translucent={true}
          mode="ios"
          isOpen={isSaving}
          message={MSGS_COMMON.loading}
        />
        <IonToast
          isOpen={!_.isEmpty(error)}
          onDidDismiss={() => this.setState({ error: "" })}
          message={error}
          duration={1000}
          color={EWPCOLORS.danger}
        />
      </IonPage>
    );
  };
}
