import * as _ from "lodash";
import React from "react";
import {
  IonCard,
  IonCardContent,
  IonItem,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonButton,
} from "@ionic/react";

import "./EWPDriverProfileHeader.scss";
import { isTablet } from "../../functions/common";
import { DriverListView } from "../Web/DriverList/DriverList";
import { EWPLabel } from "../EWPLabel/EWPLabel";
import { EWPNumberEmail } from "../EWPNumberEmail/EWPNumberEmail";
import { EWPCOLORS } from "../../constants/config";

interface EWPDriverProfileHeaderProps {
  driverProfile: DriverListView | null;
  showArchivedDriverDialog?: (() => void) | null;
  componentType?: string;
}
export const EWPDriverProfileHeader = (props: EWPDriverProfileHeaderProps) => {
  const { driverProfile, showArchivedDriverDialog } = props;

  return (
    <IonCard
      className="driver-profile-detail-details-container ion-no-margin"
      color={EWPCOLORS.tertiary}
    >
      <IonCardContent className="ion-no-padding driver-profile-detail-details-card">
        <IonItem lines="none" className="driver-profile-detail-details-item">
          <IonIcon className="driver-profile-detail-profile-icon" />
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol
                size="3.5"
                className="driver-profile-detail-details-item-details ion-no-padding"
              >
                <EWPLabel
                  loading={_.isNull(driverProfile)}
                  text={
                    !_.isNull(driverProfile)
                      ? `${driverProfile.firstName} ${driverProfile.lastName}`
                      : ""
                  }
                  className="ewp-h2 ion-text-capitalize"
                  loadingWidth={60}
                />
              </IonCol>

              <IonCol size="8.5" className="driver-details-col ion-no-padding ">
                <IonItem
                  className="driver-profile-detail-details-action-item-container"
                  lines="none"
                >
                  <div
                    slot="start"
                    className="driver-profile-detail-contact-info"
                  >
                    <EWPNumberEmail
                      phoneNumber={
                        !_.isNull(driverProfile)
                          ? driverProfile.phoneNumber || ""
                          : ""
                      }
                      email={
                        !_.isNull(driverProfile)
                          ? driverProfile.emailAddress
                          : ""
                      }
                      loading={_.isNull(driverProfile)}
                    />
                  </div>
                  {!_.isNull(driverProfile) && !driverProfile.archivedDate && (
                    <IonButton
                      slot="end"
                      className="driver-profile-detail-details-blacklist-button ewp-web-gray-button ewp-h6 ion-text-capitalize"
                      onClick={
                        !_.isNull(showArchivedDriverDialog)
                          ? showArchivedDriverDialog
                          : () => {}
                      }
                      disabled={_.isNull(showArchivedDriverDialog)}
                    >
                      <IonIcon className="driver-profile-detail-blacklist-icon" />
                      <IonLabel className="driver-profile-blacklist-button-label ewp-h6 ion-no-padding ion-no-margin ion-text-capitalize">
                        {!!driverProfile.archived ? "Restore " : "Archive "}
                        Driver
                      </IonLabel>
                    </IonButton>
                  )}
                  <IonButton
                    slot="end"
                    className={`driver-profile-detail-details-email-button ${
                      isTablet() ? "tablet" : ""
                    } ewp-h6 white ion-text-capitalize`}
                    href={
                      !_.isNull(driverProfile)
                        ? `mailto:${driverProfile.emailAddress}`
                        : ""
                    }
                    disabled={_.isNull(driverProfile)}
                  >
                    Email Contact
                  </IonButton>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
};
