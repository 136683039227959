import * as _ from "lodash";
import * as React from "react";
import * as routes from "../constants/routes";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";

import { AuthUser } from "../config/global";
import { HOME_PAGES } from "../constants/navPages";
import { isMobile } from "../functions/common";

interface AuthenticatedRouteProps {
  component: any;
  path: string;
  exact: boolean;
  authUser: AuthUser;
}
export const AuthenticatedRoute = (props: AuthenticatedRouteProps) => {
  const { exact, path, authUser, component } = props;
  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) => {
        return getRender(authUser, path, props, component);
      }}
    />
  );
};

const getRender = (authUser: AuthUser, path: string, props: any, C: any) => {
  if (_.isEmpty(authUser)) {
    if (!isMobile()) {
      return <Redirect to={routes.SIGN_IN} />;
    } else {
      return <Redirect to={routes.DRIVER_SIGN_IN} />;
    }
  } else {
    if (
      props.match.params.subPage === HOME_PAGES.editJobSchedule &&
      _.isEmpty(props.location.state)
    ) {
      return <Redirect to={routes.CREATE_JOB_SCHEDULE} />;
    }
    return <C {...props} {...{ authUser: authUser }} />;
  }
};
