import * as _ from "lodash";
import React from "react";
import { IonPopover, IonLabel } from "@ionic/react";

import "./EWPToolTip.scss";

interface EWPToolTipInterface {
  anchor: null | undefined | Event;
  text: string;
  children?: any;
  noAnchor?: boolean;
  removeAnchor: () => void;
}
export const EWPToolTip = (props: EWPToolTipInterface) => {
  const { anchor, text, noAnchor, removeAnchor, children } = props;

  return (
    <IonPopover
      isOpen={!_.isEmpty(anchor)}
      event={!_.isNull(anchor) && !noAnchor ? anchor : undefined}
      className="ewp-tool-tip ion-padding"
      showBackdrop={false}
      onDidDismiss={() => {
        removeAnchor();
      }}
    >
      <div
        className="ewp-tooltip-container"
        onMouseOut={() => {
          removeAnchor();
        }}
      >
        {children !== undefined ? (
          children
        ) : (
          <IonLabel className="ewp-tooltip-label ewp-paragraph">
            {text}
          </IonLabel>
        )}
      </div>
    </IonPopover>
  );
};
