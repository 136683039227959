import React, { useState } from "react";
import {
  IonLabel,
  IonItem,
  IonIcon,
  IonButton,
  IonSearchbar,
  IonCheckbox,
} from "@ionic/react";

import "./EWPTitleSearchWithButton.scss";
import { EWPProps } from "../../config/global";

interface EWPTitleSearchWithButtonProps extends EWPProps {
  pageTitle: string;
  onSearchChange?: (filter: string) => void;
  toggleButtonFunction?: (isChecked: boolean) => void;
  toggleButtonLabel?: string;
  actionCallBack?: () => void;
  actionButtonLabel?: string;
  disabled?: boolean;
}

export const EWPTitleSearchWithButton = (
  props: EWPTitleSearchWithButtonProps
) => {
  const [isToggleButtonChecked, setIsToggleButtonChecked] = useState(false);

  const {
    pageTitle,
    onSearchChange,
    actionCallBack,
    actionButtonLabel,
    toggleButtonFunction,
    toggleButtonLabel,
    disabled = false,
  } = props;

  return (
    <>
      <IonItem
        className="ewp-title-search-button-container ion-no-margin ion-no-padding"
        lines="none"
      >
        <IonLabel className="ewp-h2 ion-no-padding ion-padding-end">
          {pageTitle}
        </IonLabel>
        {!!toggleButtonFunction && (
          <div className="ewp-title-search-button-toggle-container">
            <IonItem
              lines="none"
              className="ewp-title-search-button-toggle-box-item ion-no-padding"
            >
              <IonCheckbox
                className="ewp-title-search-button-toggle-checkbox"
                slot="end"
                color="success"
                checked={isToggleButtonChecked}
                onIonChange={() => {
                  toggleButtonFunction(!isToggleButtonChecked);
                  setIsToggleButtonChecked(!isToggleButtonChecked);
                }}
                disabled={disabled}
              />
              <IonLabel
                className="ewp-title-search-button-toggle-checkbox-label ewp-h4"
                slot="end"
              >
                {toggleButtonLabel}
              </IonLabel>
            </IonItem>
          </div>
        )}
        {!!onSearchChange && (
          <IonSearchbar
            mode="md"
            className="ewp-title-search-button-search-bar ewp-paragraph ion-text-left ion-no-padding"
            onIonChange={(event: CustomEvent) => {
              onSearchChange(event.detail.value);
            }}
            // disabled={disabled}
          />
        )}
        {actionCallBack !== undefined && (
          <IonButton
            mode="ios"
            className="ewp-title-search-button-create-new ewp-h5 ion-no-padding"
            onClick={actionCallBack}
            disabled={disabled}
          >
            <IonIcon className="add-icon" />
            <IonLabel className="ewp-title-search-button-label ewp-h5 white">
              {actionButtonLabel}
            </IonLabel>
          </IonButton>
        )}
      </IonItem>
    </>
  );
};
