import * as _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLoading,
  IonToast,
} from "@ionic/react";

import "./DriverSendNotes.scss";
import * as services from "../../../services";
import { EWPProps } from "../../../config/global";
import {
  getDateFromTimezoneIgnoredTimestamp,
  getServerTimestamp,
  isDayShift,
} from "../../../functions/common";
import {
  JobScheduleView,
  NotifiedDriverNotes,
  SiteDocket,
} from "../../../models/jobSchedule";
import { EWPCOLORS } from "../../../constants/config";
import { MSGS_COMMON } from "../../../constants/messages";
import { EWPButton } from "../../EWPButton/EWPButton";
import { EWPInput } from "../../EWPInput/EWPInput";

interface DriverSendNotesProps extends EWPProps {
  jobSchedule: JobScheduleView | null;
  onSuccessSend: () => void;
}

export interface JobSchduleSiteDocket {
  jobScheduleId: string;
  jobScheduleDate: Date;
  siteDockets: SiteDocket[];
}

export const DriverSendNotes = (props: DriverSendNotesProps) => {
  const { jobSchedule, onSuccessSend } = props;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const [errorMsgDescription, setErrorMsgDescription] = useState("");
  const [driverNotes, setDriverNotes] = useState(
    jobSchedule && jobSchedule.driverNotes
      ? jobSchedule.driverNotes.description
      : ""
  );

  const [fieldDisabled, setFieldDisabled] = useState(
    jobSchedule && jobSchedule.driverNotes ? true : false
  );

  const submitSendNote = async () => {
    if (jobSchedule && !!driverNotes) {
      setLoading(true);
      const isEdit = !!(jobSchedule && jobSchedule.driverNotes);
      const driverNotesPayload = {
        createdBy: props.authUser.docId,
        createdDate:
          jobSchedule.driverNotes && jobSchedule.driverNotes.createdDate
            ? jobSchedule.driverNotes.createdDate
            : getServerTimestamp(),
        ...(isEdit && { updateDate: getServerTimestamp() }),
        description: driverNotes,
        adminSeen: false,
      } as NotifiedDriverNotes;

      await services.submitDriverNote(
        jobSchedule.docId as string,
        driverNotesPayload
      );
      setSuccess(
        `Note ${
          jobSchedule && jobSchedule.driverNotes ? "Updated" : "Submitted"
        }!`
      );
      setLoading(false);
    } else {
      setLoading(false);
      setErrorMsgDescription("Invalid note description");
    }
  };
  const onDeleteDriverNote = async () => {
    if (jobSchedule && jobSchedule.driverNotes) {
      services.deleteDriverNote(jobSchedule.docId as string);
      setSuccess("Note deleted!");
    }
  };

  return (
    <>
      {!!jobSchedule && (
        <div className="driver-send-notes-page">
          <div className="driver-job-detail-header-container">
            <IonGrid className="driver-job-detail-grid ion-no-padding ion-no-margin">
              <IonRow className="driver-job-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="2.5"
                  className="driver-job-detail-col shift ion-no-margin ion-no-padding"
                >
                  <div
                    className={`driver-job-detail-shift ${
                      isDayShift(
                        getDateFromTimezoneIgnoredTimestamp(
                          jobSchedule.onsiteTimeNoTimeZone
                        )
                        // toDateTimeFromSecs(jobSchedule.onsiteTime.seconds)
                      )
                        ? "day"
                        : "night"
                    }`}
                  />
                </IonCol>
                <IonCol
                  size="7"
                  className="driver-job-detail-col ion-no-margin ion-no-padding"
                >
                  <div className="driver-job-detail-header-label-container">
                    <IonLabel className="ewp-h4">
                      {jobSchedule.depotDetails.name}
                    </IonLabel>
                    <IonLabel
                      className={`driver-job-detail-header-label ${
                        isDayShift(
                          getDateFromTimezoneIgnoredTimestamp(
                            jobSchedule.onsiteTimeNoTimeZone
                          )
                        )
                          ? "day"
                          : "night"
                      } ewp-h6 bold`}
                    >
                      {`${moment(
                        getDateFromTimezoneIgnoredTimestamp(
                          jobSchedule.startDateNoTimeZone
                        )
                      ).format("ddd DD MMM YYYY")} - ${moment(
                        getDateFromTimezoneIgnoredTimestamp(
                          jobSchedule.endDateNoTimeZone
                        )
                      ).format("ddd DD MMM YYYY")}`}
                    </IonLabel>
                    <IonLabel className="job-id-label ewp-h6">
                      <b>Job Id:</b> {jobSchedule.docId}
                    </IonLabel>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <div className="driver-send-notes">
            <div className="note-title-button-container">
              <IonLabel className="driver-notes-title label ewp-h4 light bold">
                Send a note
              </IonLabel>
              {jobSchedule.driverNotes && (
                <div>
                  <IonButton
                    onClick={() => {
                      setFieldDisabled(!fieldDisabled);
                    }}
                    className="driver-note-edit-button ewp-h6 ewp-web-white-button ion-text-capitalize ion-no-margin"
                  >
                    {fieldDisabled ? "Edit" : "Cancel"}
                  </IonButton>

                  <IonButton
                    onClick={() => {
                      onDeleteDriverNote();
                    }}
                    className="driver-note-delete-button ewp-h6 ewp-web-white-button ion-text-capitalize ion-no-margin"
                  >
                    Delete
                  </IonButton>
                </div>
              )}
            </div>
            <div className="driver-notes-input-container">
              <EWPInput
                errorMessage={errorMsgDescription}
                inputLabel="Description"
                inputValue={driverNotes}
                inputType="text"
                name="description"
                mode="textArea"
                onInputChange={(description: string) => {
                  setDriverNotes(description);
                  setErrorMsgDescription("");
                }}
                disabled={fieldDisabled}
              />
            </div>
            {jobSchedule &&
            jobSchedule.driverNotes &&
            jobSchedule.driverNotes.seenDate ? (
              <IonLabel className="note-status ewp-paragraph small italic">
                Seen
              </IonLabel>
            ) : (
              jobSchedule &&
              jobSchedule.driverNotes && (
                <IonLabel className="note-status ewp-paragraph small italic">
                  Delivered
                </IonLabel>
              )
            )}
            <div className="driver-notes-submit-button">
              <EWPButton
                title="Send"
                size="large"
                type="button"
                onClick={() => {
                  submitSendNote();
                }}
                disabled={fieldDisabled}
              />
            </div>
            <IonLoading
              spinner="circular"
              translucent={true}
              mode="ios"
              isOpen={loading}
              message={MSGS_COMMON.loading}
            />

            <IonToast
              isOpen={!_.isEmpty(error)}
              message={error}
              duration={2000}
              onDidDismiss={() => {
                setError("");
              }}
              color={EWPCOLORS.danger}
            />

            <IonToast
              isOpen={!_.isEmpty(success)}
              message={success}
              duration={1000}
              onDidDismiss={() => {
                setSuccess("");
                onSuccessSend();
              }}
              color={EWPCOLORS.success}
            />
          </div>
        </div>
      )}
    </>
  );
};
