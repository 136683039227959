import React from "react";

import "./Admin.scss";
import * as services from "../../../services";
import { EWPProps } from "../../../config/global";
import { IonContent } from "@ionic/react";
import { AdminList } from "../../../components/Web/AdminList/AdminList";
import { Depot } from "../../../models";

const dummyTrucks = [
  {
    docId: "thisissampleDocIdfromFireBase1",
    name: "Truck 1",
    isArchived: false,
    expiryReminder: new Date(),
  },
  {
    docId: "thisissampleDocIdfromFireBase1",
    name: "Truck 2",
    isArchived: false,
    expiryReminder: new Date(),
  },
  {
    docId: "thisissampleDocIdfromFireBase1",
    name: "Truck 3",
    isArchived: false,
    expiryReminder: new Date(),
  },
];
const dummyDrivers = [
  {
    docId: "thisissampleDocIdfromFireBase1",
    name: "Driver 1",
    isArchived: false,
    expiryReminder: new Date(),
  },
  {
    docId: "thisissampleDocIdfromFireBase1",
    name: "Driver 2",
    isArchived: false,
    expiryReminder: new Date(),
  },
  {
    docId: "thisissampleDocIdfromFireBase1",
    name: "Driver 3",
    isArchived: false,
    expiryReminder: new Date(),
  },
];

class Admin extends React.Component<EWPProps> {
  state = {
    depots: [] as Depot[],
    unsubscribeDepot: () => {},
  };
  componentDidMount = () => {
    this.getDepotList();
  };

  getDepotList = () => {
    const unsubscribeDepot = services.getDepotsRealTime((depots, error) => {
      this.setState({
        depots,
        error,
      });
    });

    this.setState({
      unsubscribeDepot,
    });
  };

  componentWillUnmount = () => {
    this.state.unsubscribeDepot();
  };

  render = () => {
    const { depots } = this.state;

    return (
      <IonContent className="client-register-container">
        <AdminList
          {...this.props}
          depots={depots}
          trucks={dummyTrucks}
          drivers={dummyDrivers}
        />
      </IonContent>
    );
  };
}

export default Admin;
