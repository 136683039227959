import * as _ from "lodash";
import React from "react";
import { IonLabel, IonList, IonItem, IonIcon } from "@ionic/react";

import "./EWPWebSideNavigator.scss";
import * as routes from "../../constants/routes";
import { ADMIN_ACCESS, EWPProps } from "../../config/global";
import { HOME_PAGES, ACCOUNT_SETTINGS_PAGES } from "../../constants/navPages";
import { EWPCOLORS } from "../../constants/config";
import { construct, search } from "ionicons/icons";
import { Admin } from "../../models";

interface NavDataMapInterface {
  [settings: string]: {
    navList: {
      name: string;
      inactiveIcon: string;
      activeIcon: string;
      routesURL: string;
      params: string;
    }[];
  };
}

const NavDataMap = (isMechanic = false) => {
  return {
    accountSettings: {
      navList: [
        {
          name: "Account Settings",
          activeIcon: "admin-white",
          inactiveIcon: "admin-gray",
          routesURL: routes.ACCOUNT_SETTINGS_PROFILE,
          params: ACCOUNT_SETTINGS_PAGES.profile,
        },
      ],
    },
    homePage: {
      navList: [
        ...(!isMechanic
          ? [
              {
                name: "Job Schedule",
                activeIcon: "job-schedule-white",
                inactiveIcon: "job-schedule-gray",
                routesURL: routes.HOME_JOB_SCHEDULE,
                params: HOME_PAGES.jobSchedule,
              },
              {
                name: "Client Register",
                activeIcon: "company-white",
                inactiveIcon: "company-gray",
                routesURL: routes.HOME_CLIENT_REGISTER,
                params: HOME_PAGES.clientRegister,
              },
              {
                name: "Truck Register",
                activeIcon: "truck-white",
                inactiveIcon: "truck-gray",
                routesURL: routes.HOME_TRUCK_REGISTER,
                params: HOME_PAGES.truckRegister,
              },

              {
                name: "Driver Register",
                activeIcon: "employee-white",
                inactiveIcon: "employee-gray",
                routesURL: routes.HOME_DRIVER_REGISTER,
                params: HOME_PAGES.driverRegister,
              },
              {
                name: "Users",
                activeIcon: "users-white",
                inactiveIcon: "users-gray",
                routesURL: routes.USERS,
                params: HOME_PAGES.users,
              },
              {
                name: "Admin",
                activeIcon: "admin-white",
                inactiveIcon: "admin-gray",
                routesURL: routes.HOME_ADMIN,
                params: HOME_PAGES.admin,
              },
              {
                name: "Search",
                activeIcon: EWPCOLORS.tertiary,
                inactiveIcon: EWPCOLORS.light,
                routesURL: routes.HOME_SEARCH,
                params: HOME_PAGES.search,
              },
            ]
          : [
              {
                name: "Truck Register",
                activeIcon: "truck-white",
                inactiveIcon: "truck-gray",
                routesURL: routes.HOME_TRUCK_REGISTER,
                params: HOME_PAGES.truckRegister,
              },
            ]),
        {
          name: "Faults & Defects",
          activeIcon: EWPCOLORS.tertiary,
          inactiveIcon: EWPCOLORS.light,
          routesURL: routes.HOME_FAULTS_AND_DEFECTS,
          params: HOME_PAGES.faultsAndDefects,
        },
      ],
    },
  } as NavDataMapInterface;
};

interface EWPWebSideNavigatorProps extends EWPProps {
  activeSubPage: string;
  mode: "homePage" | "accountSettings";
}

export const EWPWebSideNavigator = (props: EWPWebSideNavigatorProps) => {
  const { activeSubPage, mode, authUser } = props;
  const activeSideItem =
    activeSubPage === "create-new-job-schedule"
      ? "job-schedule"
      : activeSubPage;
  return (
    <IonList className="ewp-side-nav-list" mode="md">
      {_.filter(
        NavDataMap(
          (authUser as unknown as Admin).access === ADMIN_ACCESS.mechanic.id
        )[mode].navList
      ).map((item, index) => {
        return (
          <IonItem
            key={index}
            lines="none"
            color={activeSideItem === item.params ? "primary" : "tertiary"}
            routerLink={item.routesURL}
            className={`ewp-web-side-nav-icon-text-item ${
              index === 0 && "is-first"
            }`}
          >
            <div className="ewp-web-side-nav-icon-text-container">
              {item.params === HOME_PAGES.search ? (
                <IonIcon
                  className="ewp-web-side-nav-icon"
                  color={
                    activeSideItem === item.params
                      ? item.activeIcon
                      : item.inactiveIcon
                  }
                  icon={search}
                />
              ) : item.params === HOME_PAGES.faultsAndDefects ? (
                <IonIcon
                  className="ewp-web-side-nav-icon"
                  color={
                    activeSideItem === item.params
                      ? item.activeIcon
                      : item.inactiveIcon
                  }
                  icon={construct}
                />
              ) : (
                <IonIcon
                  className={`ewp-web-side-nav-icon ${
                    activeSideItem === item.params
                      ? item.activeIcon
                      : item.inactiveIcon
                  }`}
                />
              )}
              <IonLabel
                className={`ewp-web-side-nav-label ewp-h5 ${
                  activeSideItem === item.params ? "white" : "light"
                }`}
              >
                {item.name}
              </IonLabel>
            </div>
          </IonItem>
        );
      })}
    </IonList>
  );
};
