import * as _ from "lodash";
import moment from "moment";
import React, { useState } from "react";

import {
  IonLabel,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonCardHeader,
  IonButton,
  IonSpinner,
} from "@ionic/react";

import "./ServiceHistoryCard.scss";
import { isTablet } from "../../functions/common";
import { AttachmentDialog } from "../Web/AttachmentDialog/AttachmentDialog";
import { EWPProps } from "../../config/global";
import { ServiceHistoryDialog } from "../ServiceHistoryDialog/ServiceHistoryDialog";
import { TruckServiceHistory } from "../../models/drivers";
import { EWPCOLORS } from "../../constants/config";

interface ServiceHistoryCardProps extends EWPProps {
  serviceHistories: TruckServiceHistory[];
  truckId: string;
  disableAddEdit?: boolean;
}
export const ServiceHistoryCard = (props: ServiceHistoryCardProps) => {
  const { serviceHistories, truckId, disableAddEdit } = props;
  const [showServiceHistory, setShowServiceHistory] = useState(false);
  const [selectedServiceHistory, setSelectedServiceHistory] = useState(
    null as null | TruckServiceHistory
  );
  const [showAttachementDialog, setShowAttachmentDialog] = useState(false);

  return (
    <>
      <IonCard
        className={`service-history-card ${
          isTablet() ? "tablet" : ""
        } ion-no-padding ion-no-margin`}
        color={EWPCOLORS.tertiary}
      >
        <IonCardContent className="service-history-card-content ion-no-padding ion-no-margin">
          <IonCardHeader className="service-history-card-header ion-no-padding ion-no-margin">
            <IonLabel className="service-history-card-header-label ewp-h4 bold ion-no-padding ion-no-margin">
              Service History
            </IonLabel>
            {!disableAddEdit && (
              <IonButton
                fill="clear"
                color={EWPCOLORS.primary}
                mode="ios"
                className="ewp-h5 ion-no-padding ion-no-margin"
                onClick={() => {
                  setShowServiceHistory(true);
                }}
              >
                <u>Add Service</u>
              </IonButton>
            )}
          </IonCardHeader>
          <IonGrid className="service-history-card-grid ion-no-padding ion-no-margin">
            <IonRow
              className={`service-history-card-row-content-header ${
                _.isEmpty(serviceHistories) && "no-border-bottom"
              } ion-no-padding ion-no-margin`}
            >
              <IonCol
                className="ion-no-padding ion-text-start"
                size={isTablet() ? "8" : "9"}
              >
                <IonLabel className="ewp-h6 medium bold ion-text-uppercase ion-text-start">
                  SERVICE TYPE
                </IonLabel>
              </IonCol>
              <IonCol
                className="ion-no-padding ion-text-start"
                size={isTablet() ? "3" : "2"}
              >
                <IonLabel className="ewp-h6 medium bold ion-text-uppercase ion-text-start">
                  SERVICE DATE
                </IonLabel>
              </IonCol>
              <IonCol className="ion-no-padding ion-text-start" size="1">
                {/* for button container */}
              </IonCol>
            </IonRow>

            {!!serviceHistories ? (
              _.isEmpty(serviceHistories) ? (
                <IonLabel className="no-data-label ewp-h4 bold light">
                  No Data
                </IonLabel>
              ) : (
                serviceHistories.map((serviceHistory) => {
                  return (
                    <IonRow className="service-history-card-row-content-data ion-no-padding ion-no-margin">
                      <IonCol
                        className="ion-no-padding ion-text-start"
                        size={isTablet() ? "8" : "9"}
                      >
                        <IonLabel className="ewp-h6 medium ion-text-start">
                          {serviceHistory.serviceType}
                        </IonLabel>
                      </IonCol>
                      <IonCol
                        className="ion-no-padding ion-text-start"
                        size={isTablet() ? "3" : "2"}
                      >
                        <IonLabel className="ewp-h6 medium ion-text-start">
                          {moment(serviceHistory.serviceDate.toDate()).format(
                            "DD MMM YYYY"
                          )}
                        </IonLabel>
                      </IonCol>
                      <IonCol
                        className="ion-no-padding ion-text-start"
                        size="1"
                      >
                        <div className="service-history-card-buttons-container">
                          <IonButton
                            fill="clear"
                            mode="ios"
                            className="service-history-attachment-button ewp-web-gray-button ion-no-padding ion-no-margin"
                            onClick={() => {
                              setShowAttachmentDialog(true);
                              setSelectedServiceHistory(serviceHistory);
                            }}
                          >
                            <IonIcon className="service-history-attachment-icon" />
                          </IonButton>
                          {!disableAddEdit && (
                            <IonButton
                              fill="clear"
                              mode="ios"
                              className="service-history-edit-button ewp-web-gray-button ion-no-padding ion-no-margin"
                              onClick={() => {
                                console.log(
                                  "serviceHistory --- ",
                                  serviceHistory
                                );
                                setShowServiceHistory(true);
                                setSelectedServiceHistory(serviceHistory);
                              }}
                            >
                              <IonIcon className="service-history-edit-icon" />
                            </IonButton>
                          )}
                        </div>
                      </IonCol>
                    </IonRow>
                  );
                })
              )
            ) : (
              <div className="ewp-table-spinner-container">
                <IonSpinner className="ewp-spinner" color={EWPCOLORS.primary} />
              </div>
            )}
          </IonGrid>
        </IonCardContent>
      </IonCard>
      {!!selectedServiceHistory &&
        !!selectedServiceHistory.attachments &&
        !_.isEmpty(selectedServiceHistory.attachments) && (
          <AttachmentDialog
            title="Service History Attachments"
            attachments={selectedServiceHistory.attachments.map(
              (attachmentUrl) => {
                return {
                  attachmentUrl,
                };
              }
            )}
            showModal={showAttachementDialog}
            onModalDidDismiss={() => {
              setShowAttachmentDialog(false);
              // setSelectedServiceHistory(null);
            }}
          />
        )}
      {!_.isEmpty(truckId) && (
        <ServiceHistoryDialog
          {...props}
          isOpen={showServiceHistory}
          onDidDismiss={() => {
            setShowServiceHistory(false);
            setSelectedServiceHistory(null);
          }}
          uploaderId={truckId}
          {...(!!selectedServiceHistory && {
            editData: selectedServiceHistory,
          })}
        />
      )}
    </>
  );
};
