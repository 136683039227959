import * as _ from "lodash";
import moment from "moment";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import React, { useState } from "react";
import {
  IonButton,
  IonIcon,
  IonLabel,
  IonModal,
  IonContent,
} from "@ionic/react";

import "./EWPTimePicker.scss";
import { EWPCOLORS } from "../../constants/config";

interface EWPTimePickerProps {
  selectedTime: Date | null;
  onTimeChange: (selectedTime: Date) => void;
  className?: string;
  errorMsg?: string;
}
export const EWPTimePicker = (props: EWPTimePickerProps) => {
  const { selectedTime, onTimeChange, className, errorMsg } = props;

  let midDay = new Date();
  if (!_.isNull(selectedTime)) {
    midDay = selectedTime;
  } else {
    midDay.setHours(12);
    midDay.setMinutes(0);
    midDay.setSeconds(0);
    midDay.setMilliseconds(0);
  }

  const [time, setTime] = useState(midDay);
  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);

  const [hour, setHour] = useState(midDay.getHours().toString());
  const [minute, setMinute] = useState(midDay.getMinutes().toString());

  return (
    <div>
      <IonButton
        className={`${className} ewp-timepicker-action-button ${
          !!errorMsg && "has-error"
        } ewp-timepicker-calendar-button ewp-web-white-button ion-no-padding ion-no-margin`}
        onClick={() => {
          setIsTimePickerOpen(true);
        }}
      >
        <IonLabel className="ewp-timepicker-label ewp-body ion-text-capitalize ion-text-start ion-padding-start">
          {moment(time).format("HH:mm")}
        </IonLabel>
        <IonIcon className="ewp-timepicker-time-icon" />
      </IonButton>
      <div className="ewp-input-error-container">
        <IonLabel className="ewp-input-error-msg ewp-paragraph ion-no-margin ion-no-padding">
          {errorMsg}
        </IonLabel>
      </div>

      <IonModal
        className="time-picker-dialog"
        isOpen={isTimePickerOpen}
        onDidDismiss={() => {
          setIsTimePickerOpen(false);
        }}
      >
        <IonContent scrollY={false} className="time-picker-dialog-content">
          <div className="timer-picker-main-container">
            <div className="time-picker-dialog-fields-container ion-padding">
              <div className="time-picker-dialog-field-container">
                <IonButton
                  fill="clear"
                  onClick={() => {
                    let newHour = _.cloneDeep(parseInt(hour));
                    if (newHour < 23) {
                      newHour++;
                    } else {
                      newHour = 0;
                    }

                    setHour(newHour.toString());
                  }}
                >
                  <IonIcon icon={chevronUpOutline} />
                </IonButton>

                <IonLabel className="ewp-h3 time-picker-dialog-field ion-padding">
                  {hour.length === 1 ? `0${hour}` : hour}
                </IonLabel>
                <IonButton
                  fill="clear"
                  onClick={() => {
                    let newHour = _.cloneDeep(parseInt(hour));
                    if (newHour > 0) {
                      newHour--;
                    } else {
                      newHour = 23;
                    }

                    setHour(newHour.toString());
                  }}
                >
                  <IonIcon icon={chevronDownOutline} />
                </IonButton>
              </div>
              <IonLabel className="ewp-h1 bold">:</IonLabel>
              <div className="time-picker-dialog-field-container">
                <IonButton
                  fill="clear"
                  onClick={() => {
                    let newMinutes = _.cloneDeep(parseInt(minute));
                    if (newMinutes < 60 && newMinutes !== 55) {
                      newMinutes += 5;
                    } else {
                      newMinutes = 0;
                    }

                    setMinute(newMinutes.toString());
                  }}
                >
                  <IonIcon icon={chevronUpOutline} />
                </IonButton>
                <IonLabel className="ewp-h3 time-picker-dialog-field ion-padding">
                  {minute.length === 1 ? `0${minute}` : minute}
                </IonLabel>
                <IonButton
                  fill="clear"
                  onClick={() => {
                    let newMinute = _.cloneDeep(parseInt(minute));
                    if (newMinute > 0) {
                      newMinute -= 5;
                    } else {
                      newMinute = 55;
                    }

                    setMinute(newMinute.toString());
                  }}
                >
                  <IonIcon icon={chevronDownOutline} />
                </IonButton>
              </div>
            </div>
            <div className="time-picker-dialog-action-button-container ion-padding">
              <IonButton
                mode="ios"
                fill="clear"
                className="time-picker-dialog-cancel-button ewp-h4"
                color={EWPCOLORS.dark}
                onClick={() => {
                  setIsTimePickerOpen(false);
                }}
              >
                Cancel
              </IonButton>

              <IonButton
                mode="ios"
                color={EWPCOLORS.primary}
                className="time-picker-dialog-submit-button ewp-h3 small"
                onClick={() => {
                  const newTime = _.cloneDeep(time);
                  newTime.setHours(parseInt(hour));
                  newTime.setMinutes(parseInt(minute));
                  console.log("GOT NEW TIME", newTime);
                  onTimeChange(newTime);
                  setTime(newTime);
                  setIsTimePickerOpen(false);
                }}
              >
                Set
              </IonButton>
            </div>
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
};
