import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import React, { useState } from "react";
import {
  IonItem,
  IonLabel,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonRippleEffect,
} from "@ionic/react";

import "./EWPClientForm.scss";
import * as services from "../../services";
import { formatString, useEffectOnlyOnce } from "../../functions/common";
import { Client } from "../../models";
import { EWPClientFormProps } from "./EWPClientForm.interface";
import { EWPInput } from "../EWPInput/EWPInput";
import {
  DropdownItem,
  EWPWebDropdownSelect,
} from "../EWPWebDropdownSelect/EWPWebDropdownSelect";
import { EWPCOLORS, LIMITS } from "../../constants/config";
import { MSGS_COMMON, MSGS_CONTACT_PERSON } from "../../constants/messages";
import { EWPNumberFieldWithCountryCode } from "../EWPNumberFieldWithCountryCode/EWPNumberFieldWithCountryCode";

export const EWPClientForm = (props: EWPClientFormProps) => {
  const { isEdit, editData, onSuccess } = props;
  let existingData = {
    docId: "",
    name: "",
    abn: "",
  } as Client;

  if (!_.isEmpty(editData) && !!editData) {
    existingData = _.clone(editData);
  }

  const isModalMode = !!props.onModalDidDismiss;

  const [companyName, setCompanyName] = useState(existingData.name);
  const [companyABN, setCompanyABN] = useState(existingData.abn);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  // const [setPreferredTruck] = useState("");
  // const [setPreferredDriver] = useState("");
  const [preferredTrucks, setPreferredTrucks] = useState(
    (existingData.preferredTruckIds || []) as string[]
  );
  const [preferredDrivers, setPreferredDrivers] = useState(
    (existingData.preferredDriverIds || []) as string[]
  );

  const [preferredTruckOptions, setPreferredTruckOptions] = useState(
    null as DropdownItem[] | null
  );

  const [preferredDriverOptions, setPreferredDriverOptions] = useState(
    null as DropdownItem[] | null
  );

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [errorMsgFirstName, setErrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgEmailAddress, setErrorMsgEmailAddress] = useState("");
  const [errorMsgContactNumber] = useState("");
  const [errorMsgPreferredTruck, setErrorMsgPreferredTruck] = useState("");
  const [errorMsgPreferredDriver, setErrorMsgPreferredDriver] = useState("");

  const [errorMsgCompanyName, setErrorMsgCompanyName] = useState("");
  const [errorMsgCompanyABN, setErrorMsgCompanyABN] = useState("");

  const submitForm = async (event: React.FormEvent) => {
    event.preventDefault();
    const formattedCompanyName = formatString(companyName || "");
    const formattedCompanyABN = formatString(companyABN || "");
    const formattedFirstName = formatString(firstName || "");
    const formattedEmailAddress = formatString(emailAddress || "");
    const formattedLastName = formatString(lastName || "");
    const formattedPhoneNumber = formatString((contactNumber as string) || "");
    //! FIX VALIDATION FOR MOBILE NUMBER to use ausie number only?

    let errorMessage = null;
    if (formattedCompanyName.length === 0) {
      errorMessage = MSGS_CONTACT_PERSON.companyName.required;
      setErrorMsgCompanyName(errorMessage);
    }
    if (formattedCompanyABN.length === 0) {
      errorMessage = MSGS_CONTACT_PERSON.companyABN.required;
      setErrorMsgCompanyABN(errorMessage);
    }
    if (_.isEmpty(preferredTrucks)) {
      errorMessage = MSGS_CONTACT_PERSON.preferredTruck;
      setErrorMsgPreferredTruck(errorMessage);
    }
    if (_.isEmpty(preferredDrivers)) {
      errorMessage = MSGS_CONTACT_PERSON.driver;
      setErrorMsgPreferredDriver(errorMessage);
    }

    if (!isEdit) {
      if (formattedFirstName.length === 0) {
        errorMessage = MSGS_CONTACT_PERSON.firstName.required;
        setErrorMsgFirstName(errorMessage);
      }
      // if (formattedLastName.length === 0) {
      //   errorMessage = MSGS_CONTACT_PERSON.lastName.required;
      //   setErrorMsgLastName(errorMessage);
      // }
      if (formattedFirstName.length > LIMITS.name.single) {
        errorMessage = MSGS_CONTACT_PERSON.firstName.max;
        setErrorMsgFirstName(errorMessage);
      }
      if (formattedLastName.length > LIMITS.name.single) {
        errorMessage = MSGS_CONTACT_PERSON.lastName.max;
        setErrorMsgLastName(errorMessage);
      }

      if (
        !_.isEmpty(formattedEmailAddress) &&
        !EmailValidator.validate(formattedEmailAddress)
      ) {
        errorMessage = MSGS_CONTACT_PERSON.email.invalid;
        setErrorMsgEmailAddress(errorMessage);
      }
    }

    if (_.isEmpty(errorMessage)) {
      setSaving(true);
      try {
        if (!isEdit && _.isEmpty(editData)) {
          await services.createClient(
            formattedCompanyName,
            formattedCompanyABN,
            preferredTrucks,
            preferredDrivers,
            formattedFirstName,
            formattedLastName,
            (formattedPhoneNumber || "") as string,
            formattedEmailAddress
          );
          setSaving(false);
          setSuccess("New Client Created");
        } else {
          await services.updateClient(
            editData!.docId || "",
            formattedCompanyName,
            formattedCompanyABN,
            preferredTrucks,
            preferredDrivers
          );
          setSaving(false);
          setSuccess("Client Updated");
        }
      } catch (eUnknown) {
        const e = eUnknown as any;
        setError(e);
        setSaving(false);
      }
    }
  };

  const getTruckOptions = async () => {
    try {
      const trucks = await services.getTrucks();
      setPreferredTruckOptions(
        trucks.map((truck) => {
          return {
            id: truck.docId || "",
            name: truck.name,
          };
        })
      );

      if (!_.isEmpty(editData) && editData!.preferredTruckId) {
        // const preferredTruck = _.find(
        //   trucks,
        //   (truck) => truck.docId === editData!.preferredTruckId
        // );
        // if (!_.isEmpty(preferredTruck)) {
        //   setPreferredTruck(preferredTruck!.docId || "");
        // }
      }
    } catch (eUnknown) {
      const e = eUnknown as any;
      setError(e);
      setPreferredTruckOptions([]);
    }
  };

  const getDriverOptions = async () => {
    try {
      const drivers = await services.getDrivers();
      setPreferredDriverOptions(
        drivers.map((driver) => {
          return {
            id: driver.docId || "",
            name: `${driver.firstName} ${driver.lastName}`,
          };
        })
      );

      if (!_.isEmpty(editData) && editData!.preferredDriverId) {
        // const preferredDriver = _.find(
        //   drivers,
        //   (driver) => driver.docId === editData!.preferredDriverId
        // );
        // if (!_.isEmpty(preferredDriver)) {
        //   setPreferredDriver(preferredDriver!.docId || "");
        // }
      }
    } catch (eUnknown) {
      const e = eUnknown as any;
      setError(e);
      setPreferredTruckOptions([]);
    }
  };

  const clearFields = () => {
    setCompanyName("");
    setCompanyABN("");
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setContactNumber("");
  };

  useEffectOnlyOnce(() => {
    getTruckOptions();
    getDriverOptions();
  });

  return (
    <>
      <form onSubmit={submitForm}>
        <IonLabel
          className={`client-form-personal-details-title ewp-h3 ion-no-padding ${
            !isModalMode ? "ion-text-start" : ""
          }`}
        >
          Client Details
        </IonLabel>
        <IonGrid className="client-form-grid ion-no-padding ion-no-margin">
          {_.chunk(
            [
              <EWPInput
                errorMessage={errorMsgCompanyName}
                inputLabel="Company Name"
                inputValue={companyName}
                inputType="text"
                name="companyName"
                onInputChange={(companyName: string) => {
                  if (!!companyName) setErrorMsgCompanyName("");
                  setCompanyName(companyName);
                }}
              />,
              <EWPInput
                errorMessage={errorMsgCompanyABN}
                inputLabel="ABN"
                inputValue={companyABN}
                inputType="text"
                name="companyABN"
                onInputChange={(companyABN: string) => {
                  if (!!companyABN) setErrorMsgCompanyABN("");
                  setCompanyABN(companyABN);
                }}
              />,
              <EWPWebDropdownSelect
                widthSize={!isModalMode ? "page-form" : "half-dialog-form"}
                disabled={_.isNull(preferredTruckOptions)}
                dropdownItems={preferredTruckOptions || []}
                onSelectItems={(ids) => {
                  setPreferredTrucks(ids);
                  setErrorMsgPreferredTruck("");
                }}
                isSelectMultiple={true}
                placeholder="Choose Preferred Trucks"
                value={preferredTrucks}
                errorMsg={errorMsgPreferredTruck}
                hasSearch={true}
              />,
              <EWPWebDropdownSelect
                widthSize={!isModalMode ? "page-form" : "half-dialog-form"}
                disabled={_.isNull(preferredDriverOptions)}
                dropdownItems={preferredDriverOptions || []}
                onSelectItems={(ids) => {
                  setPreferredDrivers(ids);
                  setErrorMsgPreferredDriver("");
                }}
                isSelectMultiple={true}
                placeholder="Preferred Drivers"
                value={preferredDrivers}
                errorMsg={errorMsgPreferredDriver}
                hasSearch={true}
              />,
            ],
            !isModalMode ? 1 : 2
          ).map((components) => {
            return (
              <IonRow className="client-form-contact-person-details-row ion-no-padding ion-no-margin">
                {components.map((component, index) => {
                  return (
                    <IonCol
                      size={!isModalMode ? "12" : "6"}
                      className={`${
                        isModalMode
                          ? index === 0
                            ? "ewp-client-form-start-col"
                            : "ewp-client-form-end-col"
                          : ""
                      } ion-no-padding`}
                    >
                      {component}
                    </IonCol>
                  );
                })}
              </IonRow>
            );
          })}
        </IonGrid>
        {!isEdit && (
          <>
            <IonLabel
              className={`client-form-personal-details-title ewp-h3 ion-no-padding  ${
                !isModalMode ? "ion-text-start" : ""
              }`}
            >
              Contact Person
            </IonLabel>
            <IonGrid className="client-form-grid ion-no-padding ion-no-margin">
              {_.chunk(
                [
                  <EWPInput
                    errorMessage={errorMsgFirstName}
                    inputLabel="First Name"
                    inputValue={firstName}
                    inputType="text"
                    name="firstName"
                    onInputChange={(firstName: string) => {
                      setFirstName(firstName);
                      if (!!firstName) setErrorMsgFirstName("");
                    }}
                  />,
                  <EWPInput
                    errorMessage={errorMsgLastName}
                    inputLabel="Last Name"
                    inputValue={lastName}
                    inputType="text"
                    name="lastName"
                    onInputChange={(lastName: string) => {
                      setLastName(lastName);
                      if (!!lastName) setErrorMsgLastName("");
                    }}
                  />,

                  <EWPNumberFieldWithCountryCode
                    errorMessage={errorMsgContactNumber}
                    phoneNumber={contactNumber}
                    onChange={(contactNumber: string) => {
                      setContactNumber(contactNumber);
                    }}
                  />,
                  <EWPInput
                    errorMessage={errorMsgEmailAddress}
                    inputLabel="Email"
                    inputValue={emailAddress}
                    inputType="text"
                    name="emailAddress"
                    onInputChange={(emailAddress: string) => {
                      setEmailAddress(emailAddress);
                      if (!!emailAddress) setErrorMsgEmailAddress("");
                    }}
                  />,
                ],
                !isModalMode ? 1 : 2
              ).map((components) => {
                return (
                  <IonRow className="client-form-contact-person-details-row ion-no-padding ion-no-margin">
                    {components.map((component, index) => {
                      return (
                        <IonCol
                          size={!isModalMode ? "12" : "6"}
                          className={`${
                            isModalMode
                              ? index === 0
                                ? "ewp-client-form-start-col"
                                : "ewp-client-form-end-col"
                              : ""
                          } ion-no-padding`}
                        >
                          {component}
                        </IonCol>
                      );
                    })}
                  </IonRow>
                );
              })}
            </IonGrid>
          </>
        )}
        {isModalMode ? (
          <IonItem
            lines="none"
            className="ewp-item-no-horizontal-padding ewp-button-padding-top"
          >
            <IonButton
              mode="ios"
              slot="start"
              className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
              fill="clear"
              color={EWPCOLORS.medium}
              onClick={() => {
                if (props.onModalDidDismiss) {
                  props.onModalDidDismiss();
                  clearFields();
                }
              }}
            >
              Cancel
            </IonButton>
            <IonButton
              mode="md"
              slot="end"
              className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
              color={EWPCOLORS.primary}
              type="submit"
            >
              {isEdit ? "Save" : "Add Client"}
              <IonRippleEffect />
            </IonButton>
          </IonItem>
        ) : (
          <IonButton
            mode="md"
            slot="end"
            className="ewp-modal-save-form-button page-form ewp-h5 ion-no-margin ion-text-capitalize"
            color={EWPCOLORS.primary}
            type="submit"
          >
            {isEdit ? "Save" : "Add Client"}
            <IonRippleEffect />
          </IonButton>
        )}
      </form>
      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={saving}
        message={MSGS_COMMON.saving}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
          if (!!onSuccess) {
            onSuccess();
          }
          if (!!props.onModalDidDismiss) {
            props.onModalDidDismiss();
          }
          clearFields();
        }}
        color={EWPCOLORS.success}
      />
    </>
  );
};
