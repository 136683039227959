import * as _ from "lodash";
import React, { useState } from "react";
import { IonLabel } from "@ionic/react";

import "./ClientList.scss";
import * as routes from "../../../constants/routes";
import { ClientContactPersonDetailedView } from "../../../models";
import { EWPClientForm } from "../../EWPClientForm/EWPClientForm";
import { EWPDataTable } from "../../EWPDataTable/EWPDataTable";
import { EWPTitleSearchWithButton } from "../../EWPTitleSearchWithButton/EWPTitleSearchWithButton";
import { EWPWebDialog } from "../../EWPWebDialog/EWPWebDialog";
import { EWPTableProps, EWPProps } from "../../../config/global";

interface ClientListProps extends EWPProps {
  clients: ClientContactPersonDetailedView[] | null;
}

interface SortKeys {
  clientName: "asc" | "desc";
  [key: string]: "asc" | "desc";
}

export const ClientList = (props: ClientListProps) => {
  const { clients } = props;

  const [filteredClientData, setFilteredClientData] = useState(
    null as null | ClientContactPersonDetailedView[]
  );
  const [sortLabelsAndDirection, setSortLabelsAndDirection] = useState({
    clientName: "asc",
  } as SortKeys);

  const [showCreateNewClient, setShowCreateNewClient] = useState(false);

  const searchClient = (query: string) => {
    if (!_.isEmpty(query)) {
      const filteredClientData = _.filter(
        clients || [],
        (client: ClientContactPersonDetailedView) => {
          const formattedQuery = query.trim().toLowerCase();

          return (
            client.name.toLowerCase().indexOf(formattedQuery) !== -1 ||
            client.abn.toLowerCase().indexOf(formattedQuery) !== -1 ||
            (!_.isEmpty(client.preferredDrivers) &&
              !_.isEmpty(
                _.filter(client.preferredDrivers || [], (driver) => {
                  return (
                    driver.firstName
                      .toLowerCase()
                      .trim()
                      .includes(formattedQuery) ||
                    driver.lastName
                      .toLowerCase()
                      .trim()
                      .includes(formattedQuery)
                  );
                })
              )) ||
            (!_.isEmpty(client.preferredTrucks) &&
              !_.isEmpty(
                _.filter(client.preferredTrucks || [], (truck) => {
                  return truck.name
                    .toLowerCase()
                    .trim()
                    .includes(formattedQuery);
                })
              )) ||
            (!_.isEmpty(client.contactPeople) &&
              (client.contactPeople[0]!.firstName.toLowerCase()
                .trim()
                .includes(formattedQuery) ||
                client.contactPeople[0]!.lastName.toLowerCase()
                  .trim()
                  .includes(formattedQuery)))
          );
        }
      );
      setFilteredClientData(filteredClientData);
    } else {
      setFilteredClientData(null);
    }
  };

  // const showArchived = (toggled: boolean) => {
  //   if (toggled) {
  //     const filteredClientData = _.filter(clients || [], (client: any) => {
  //       return client.isArchived === true;
  //     });
  //     setFilteredClientData(filteredClientData);
  //   } else {
  //     setFilteredClientData(null);
  //   }
  // };

  const onRowRedirect = (rowData: any) => {
    if (!_.isEmpty(rowData) && !!rowData.docId) {
      props.history.push(
        routes.HOME_CLIENT_REGISTER_DETAILS.replace(
          ":clientId",
          rowData.docId || ""
        )
      );
    }
  };

  const sort = (key: string) => {
    console.log("key --", key);
    const sortBy = sortLabelsAndDirection[key] === "desc" ? "asc" : "desc";
    const updateSort = {} as SortKeys;
    updateSort[key] = sortBy;
    const sortedData = _.orderBy(clients, key, sortBy);
    setFilteredClientData(sortedData);

    setSortLabelsAndDirection({
      ...sortLabelsAndDirection,
      ...updateSort,
    });
  };

  const getTableDef = () => {
    let headers = [
      { label: "Client Name", key: "name", sortable: true },
      {
        label: "ABN",
        key: "abn",
        sortable: true,
      },
      { label: "Most Used Trucks", key: "preferredTrucks", sortable: true },
      {
        label: "Most Used Contact Person",
        key: "contactPeople",
        sortable: true,
      },
      { label: "Most Used Drivers", key: "preferredDrivers", sortable: true },
    ];

    let columns = [
      "name",
      "abn",
      "preferredTrucks",
      "contactPeople",
      "preferredDrivers",
    ];

    return { headers, columns } as EWPTableProps;
  };

  const renderCell = (
    value: string,
    rowData: ClientContactPersonDetailedView
  ) => {
    const columnName = value as keyof ClientContactPersonDetailedView;
    switch (value) {
      case "name":
        return (
          <IonLabel className="ewp-h5 bold light">
            {rowData[columnName]}
          </IonLabel>
        );
      case "abn":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "preferredTrucks":
        const preferredTrucks = rowData.preferredTrucks;
        return (
          <IonLabel className="ewp-paragraph">
            {!_.isEmpty(preferredTrucks)
              ? preferredTrucks!.map((truck, index) => {
                  return `${truck.name}${
                    index === 0 && preferredTrucks!.length === 1
                      ? ""
                      : index !== preferredTrucks!.length - 1 &&
                        preferredTrucks!.length > 0
                      ? ", "
                      : ""
                  }  `;
                })
              : "N/A"}
          </IonLabel>
        );
      case "contactPeople":
        const contactPeople = rowData.contactPeople;
        return (
          <IonLabel className="ewp-paragraph">
            {contactPeople.length > 0
              ? `${contactPeople[0].firstName} ${contactPeople[0].lastName}`
              : "N/A"}
          </IonLabel>
        );
      case "preferredDrivers":
        const preferredDrivers = rowData.preferredDrivers;
        return (
          <IonLabel className="ewp-paragraph">
            {!_.isEmpty(preferredDrivers)
              ? preferredDrivers!.map((driver, index) => {
                  return `${driver!.firstName} ${driver!.lastName}${
                    index === 0 && preferredDrivers!.length === 1
                      ? ""
                      : index !== preferredDrivers!.length - 1 &&
                        preferredDrivers!.length > 0
                      ? ", "
                      : ""
                  }  `;
                })
              : "N/A"}
          </IonLabel>
        );
      default:
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
    }
  };

  return (
    <>
      <div className="client-list-container">
        <div className="client-register-header-container">
          <EWPTitleSearchWithButton
            {...props}
            // toggleButtonFunction={showArchived}
            // toggleButtonLabel="View Archived" !HIDE FOR NOW
            pageTitle="Client Register"
            onSearchChange={searchClient}
            actionCallBack={() => {
              // setShowCreateNewClient(true);
              props.history.push(routes.HOME_ADD_CLIENT);
            }}
            actionButtonLabel="New Client"
          />
        </div>
        <div className="client-list-table-container">
          <EWPDataTable
            tableDef={getTableDef()}
            tableData={clients}
            tableFilteredData={filteredClientData}
            renderCell={renderCell}
            sortLabelsAndDirection={sortLabelsAndDirection}
            onSort={sort}
            onRowRedirect={onRowRedirect}
            isComponentPage={true}
          />
        </div>
      </div>
      <EWPWebDialog
        headerTitle="Create New Client"
        isOpen={showCreateNewClient}
        onDidDismiss={() => {
          setShowCreateNewClient(false);
        }}
      >
        <EWPClientForm
          {...(props as any)}
          isEdit={false}
          onModalDidDismiss={() => {
            setShowCreateNewClient(false);
          }}
          editData={{ data: [] }}
        />
      </EWPWebDialog>
    </>
  );
};
