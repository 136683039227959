import * as _ from "lodash";
import Bugsnag from "@bugsnag/js";
import React from "react";
import { IonPage, IonToast, IonContent, IonLoading } from "@ionic/react";

import "./ForgotPassword.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services/index";
import EWPHeader from "../../components/EWPHeader/EWPHeader";
import { EWPButton } from "../../components/EWPButton/EWPButton";
import { EWPInput } from "../../components/EWPInput/EWPInput";
import { EWPLink } from "../../components/EWPLink/EWPLInk";
import { EWPProps } from "../../config/global";
import { EWPPageTitleAndDescription } from "../../components/EWPPageTitleAndDescription/EWPPageTitleAndDescription";
import { WebForgotEmailSent } from "../../components/WebForgotEmailSent/WebForgotEmailSent";
import { EWPCOLORS, FIREBASE_ERROR_CODES } from "../../constants/config";
import {
  MSGS_COMMON,
  MSGS_SIGNUP,
  MSGS_FIREBASE,
  MSGS_GENERIC_ERROR,
} from "../../constants/messages";

class ForgotPassword extends React.Component<EWPProps> {
  state = {
    emailAddress: "",
    error: "",
    isLoading: false,
    isEmailSubmitted: false,
    errorMsgEmaillAddress: "",
  };

  sendForgotPasswordLink = async (event: React.FormEvent) => {
    this.setState({ isLoading: true });

    const { emailAddress } = this.state;

    if (_.isEmpty(emailAddress)) {
      this.setState({
        isLoading: false,
        errorMsgEmaillAddress: MSGS_SIGNUP.email.required,
      });
      return;
    }

    try {
      await services.doSendResetPasswordEmail(emailAddress);

      this.setState({
        isLoading: false,
        isEmailSubmitted: true,
      });
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      this.setState({
        error:
          error.code === FIREBASE_ERROR_CODES.userNotFound
            ? MSGS_FIREBASE.userNotFound
            : MSGS_GENERIC_ERROR,
        isLoading: false,
        emailAddress: "",
      });
      Bugsnag.notify(new Error(error.message));
    }
  };

  onTryAgain = () => {
    this.setState({ emailAddress: "", isEmailSubmitted: false });
  };

  render = () => {
    const {
      emailAddress,
      error,
      isLoading,
      errorMsgEmaillAddress,
      isEmailSubmitted,
    } = this.state;
    return (
      <IonPage>
        <EWPHeader {...this.props} />

        <IonContent>
          <div className="forgot-password-container">
            {isEmailSubmitted ? (
              <WebForgotEmailSent
                emailAddress={emailAddress}
                onTryAgain={this.onTryAgain}
              />
            ) : (
              <>
                <EWPPageTitleAndDescription
                  className="forgot-password-title"
                  title="Forgot Password"
                  description="Enter your email to help us identify you."
                />
                <div className="forgot-password-input-form-container">
                  <EWPInput
                    className="forgot-password-email-input"
                    inputLabel="Email"
                    inputValue={emailAddress}
                    errorMessage={errorMsgEmaillAddress}
                    name="emailAddress"
                    inputType="text"
                    onInputChange={(emailAddress: string) => {
                      this.setState({
                        emailAddress: emailAddress,
                        error: "",
                      });
                    }}
                  />
                  <EWPButton
                    title="Next"
                    className="forgot-password-next-button"
                    onClick={this.sendForgotPasswordLink}
                  />
                  <div className="forgot-password-link-container">
                    <EWPLink
                      title="Go back to login page"
                      underlined={true}
                      color={EWPCOLORS.light}
                      className="forgot-password-goback-link ewp-paragraph"
                      routerLink={routes.SIGN_IN}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </IonContent>

        <IonLoading
          spinner="circular"
          translucent={true}
          mode="ios"
          isOpen={isLoading}
          message={MSGS_COMMON.loading}
        />

        <IonToast
          isOpen={!_.isEmpty(error)}
          onDidDismiss={() => this.setState({ error: "" })}
          message={error}
          duration={1000}
          color={EWPCOLORS.danger}
        />
      </IonPage>
    );
  };
}

export default ForgotPassword;
