import * as _ from "lodash";
import React from "react";
import { IonSlides, IonSlide, IonContent, IonToast } from "@ionic/react";

import "./Client.scss";
import * as routes from "../../../constants/routes";
import * as services from "../../../services";
import { ClientList } from "../../../components/Web/ClientList/ClientList";
import { ClientDashboard } from "../../../components/Web/ClientDashboard/ClientDashboard";
import { ClientContactPersonDetailedView } from "../../../models";
import { EWPFormPage } from "../../../components/EWPFormPage/EWPFormPage";
import { EWPFormPageTitleDescription } from "../../../components/EWPFormPageTitleDescription/EWPFormPageTitleDescription";
import { EWPClientForm } from "../../../components/EWPClientForm/EWPClientForm";
import { EWPProps, DetailIdParam } from "../../../config/global";
import { EWPCOLORS } from "../../../constants/config";

class Client extends React.Component<EWPProps> {
  sliderRef: React.RefObject<HTMLIonSlidesElement>;

  state = {
    clientId: "",
    clients: null as ClientContactPersonDetailedView[] | null,
    unsubscribeClients: () => {},

    error: "",
  };

  constructor(props: EWPProps) {
    super(props);
    this.sliderRef = React.createRef();
  }
  componentDidMount = () => {
    this.getClientContactsDetailedView();
    if (!!this.sliderRef && !!this.sliderRef.current) {
      this.sliderRef.current.lockSwipes(true);
    }

    const { detailId } = this.props.match.params as DetailIdParam;
    if (!!detailId && !_.isEmpty(detailId)) {
      this.goToPage("next");
    }
  };

  getClientContactsDetailedView = () => {
    const unsubscribeClients = services.getClientContactDetailedView(
      (clients, error) => {
        this.setState({
          clients,
          error,
        });
      }
    );
    this.setState({
      unsubscribeClients,
    });
  };

  componentWillUnmount = () => {
    // this.state.unsubscribeClients();
  };

  goToPage = (page: "next" | "prev") => {
    if (!!this.sliderRef && !!this.sliderRef.current) {
      this.sliderRef.current.lockSwipes(false);
      if (page === "next") this.sliderRef.current.slideNext();
      else this.sliderRef.current.slidePrev();
      this.sliderRef.current.lockSwipes(true);
    }
  };
  render = () => {
    const { clients, error } = this.state;
    const { detailId, pageParam } = this.props.match.params as DetailIdParam;

    let client = {} as ClientContactPersonDetailedView;
    if (!_.isEmpty(detailId)) {
      client =
        _.find(clients, (client) => client.docId === detailId) ||
        ({} as ClientContactPersonDetailedView);
    }

    return (
      <IonContent className="client-register-container">
        <IonSlides ref={this.sliderRef}>
          <IonSlide className="client-register-slide">
            {!detailId && !pageParam ? (
              <ClientList {...this.props} clients={clients} />
            ) : (
              <EWPFormPage
                {...this.props}
                backURL={routes.HOME_CLIENT_REGISTER}
                pageTitle={"Create New Client"}
              >
                <div className="client-register-form-page-container">
                  <div className="client-register-form-input-container">
                    <EWPClientForm
                      {...this.props}
                      isEdit={false}
                      onSuccess={() => {
                        this.props.history.push(routes.HOME_CLIENT_REGISTER);
                      }}
                    />
                  </div>
                </div>
              </EWPFormPage>
            )}
          </IonSlide>
          <IonSlide className="client-register-slide">
            {!!detailId && pageParam === "edit"
              ? !_.isEmpty(clients) && (
                  <EWPFormPage
                    {...this.props}
                    backURL={routes.HOME_CLIENT_REGISTER_DETAILS.replace(
                      ":clientId",
                      detailId
                    )}
                    pageTitle={"Edit Client"}
                  >
                    <div className="client-register-form-page-container">
                      <EWPFormPageTitleDescription
                        icon="client"
                        title={client.name || ""}
                        subtitle={`ABN ${client.abn || ""}`}
                      />
                      <div className="client-register-form-input-container">
                        <EWPClientForm
                          {...this.props}
                          isEdit={true}
                          editData={client}
                          onSuccess={() => {
                            this.props.history.push(
                              routes.HOME_CLIENT_REGISTER
                            );
                          }}
                        />
                      </div>
                    </div>
                  </EWPFormPage>
                )
              : !!detailId && (
                  <ClientDashboard
                    {...this.props}
                    client={!_.isNull(clients) ? client : null}
                  />
                )}
          </IonSlide>
        </IonSlides>
        <IonToast
          isOpen={!_.isEmpty(error)}
          message={error}
          duration={2000}
          onDidDismiss={() => {
            this.setState({
              error: "",
            });
          }}
          color={EWPCOLORS.danger}
        />
      </IonContent>
    );
  };
}

export default Client;
