import _ from "lodash";
import Bugsnag from "@bugsnag/js";
import { close } from "ionicons/icons";
import React, { useState } from "react";
import {
  IonModal,
  IonContent,
  IonLabel,
  IonButton,
  IonIcon,
  IonCard,
  IonCardContent,
  IonItem,
  IonRippleEffect,
  IonLoading,
  IonToast,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";

import "./UncancelJobSchedule.scss";
import * as api from "../../api";
import * as services from "../../services";
import { JobScheduleView, JOBS_WITH_KEYS } from "../../models";
import { EWPCOLORS } from "../../constants/config";
import {
  driverJobScheduleUpdated,
  MSGS_COMMON,
} from "../../constants/messages";
import { getDateFromTimezoneIgnoredTimestamp } from "../../functions/common";

interface UncancelJobScheduleProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  onSuccess: () => void;
  onCancel?: () => void;
  notify: boolean;
  jobSchedule: JobScheduleView;
  isCancelled?: boolean;
}

export const UncancelJobSchedule = (props: UncancelJobScheduleProps) => {
  const {
    notify,
    isOpen,
    onDidDismiss,
    onSuccess,
    onCancel,
    jobSchedule,
    isCancelled,
  } = props;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const softDeleteJob = async () => {
    const { jobSchedule } = props;

    if (!!jobSchedule) {
      setLoading(true);
      try {
        if (!!isCancelled) {
          await services.uncancelJobSchedule(jobSchedule.docId || "");
        }
        if (notify && !_.isEmpty(jobSchedule.driverDetails)) {
          try {
            await api.sendSMS(
              jobSchedule.driverDetails!.phoneNumber as string,
              driverJobScheduleUpdated(
                getDateFromTimezoneIgnoredTimestamp(
                  jobSchedule.startDateNoTimeZone
                ),
                "updated",
                jobSchedule.clientDetails.name
              )
            );
          } catch (eUnknown) {
            const e = eUnknown as any;
            console.log("ERROR SENDING NOTIF", e);
            setError(`Sending notifications failed: ${e}`);
          }
        }

        setLoading(false);
        setSuccess(`Job successfully Updated!`);
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        setError(error);
        setLoading(false);
        Bugsnag.notify(new Error(error));
      }
    }
  };

  console.log("got details", jobSchedule);

  return (
    <IonModal
      className="delete-job-sched-dialog"
      isOpen={isOpen}
      showBackdrop={true}
      onDidDismiss={onDidDismiss}
    >
      <IonContent
        scrollY={false}
        className="delete-job-sched-content ion-no-padding"
      >
        <IonButton
          className="delete-job-sched-close-button-dialog ion-no-padding ion-no-margin"
          color={EWPCOLORS.light}
          fill="clear"
          onClick={onDidDismiss}
        >
          <IonIcon color={EWPCOLORS.light} icon={close} />
        </IonButton>
        <div className="delete-job-sched-title-container">
          <IonLabel className="ewp-h1 ion-text-center">
            {`Are you sure you want to ${
              !!isCancelled ? "uncancel" : "delete"
            } this ${_.startCase("job")}`}
            ?
          </IonLabel>
          <IonLabel className="delete-job-sched-subtitle ewp-paragraph ion-text-center">
            {`Remember, if you ${
              !!isCancelled ? "uncancel" : "delete"
            } this ${_.startCase("job")}, ${
              !!isCancelled
                ? "it will now be avaiable in job schedule."
                : "they will not be available in job schedule anymore."
            }`}
          </IonLabel>
        </div>

        <IonCard
          className="delete-job-sched-card ion-no-padding ion-no-margin"
          color={EWPCOLORS.tertiary}
        >
          <IonCardContent className="delete-job-sched-card-content ion-no-padding">
            <IonItem lines="none" className="delete-job-sched-card-item">
              <IonIcon className={`delete-job-sched-icon ion-no-padding`} />
              <div className="delete-job-sched-dialog-detail-container">
                <IonLabel className="ewp-h2 ion-text-start">
                  {!!jobSchedule.truckDetails &&
                  !_.isEmpty(jobSchedule.truckDetails)
                    ? jobSchedule.truckDetails.name
                    : "No truck assigned"}
                </IonLabel>
                <IonLabel className="ewp-h4 primary ion-text-start">
                  {`${
                    !!jobSchedule.truckDetails &&
                    !_.isEmpty(jobSchedule.truckDetails)
                      ? JOBS_WITH_KEYS[jobSchedule.truckDetails.type].name
                      : "N/A"
                  } ${
                    !!jobSchedule.truckDetails &&
                    !_.isEmpty(jobSchedule.truckDetails)
                      ? jobSchedule.truckDetails.size
                      : ""
                  }`}
                </IonLabel>
              </div>
              <IonGrid className="delete-job-sched-dialog-truck-grid ion-no-padding ion-no-margin">
                <IonRow className="delete-job-sched-dialog-truck-row ion-no-padding ion-no-margin">
                  <IonCol
                    size="10"
                    className="delete-job-sched-dialog-truck-col ion-no-padding ion-no-margin"
                  >
                    <IonLabel className="delete-job-sched-dialog-truck-detail-label ewp-paragraph ion-no-padding ion-no-margin">
                      <b>Driver:</b>{" "}
                      {!_.isEmpty(jobSchedule.driverDetails)
                        ? `${jobSchedule.driverDetails.firstName} ${jobSchedule.driverDetails.lastName}`
                        : "Not Allocated"}
                    </IonLabel>
                    <IonLabel className="delete-job-sched-dialog-truck-detail-label ewp-paragraph ion-no-padding ion-no-margin">
                      <b>Address:</b> {jobSchedule.address}
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          </IonCardContent>
        </IonCard>
        <IonItem
          lines="none"
          className="delete-job-sched-action-buttons-item ewp-item-no-horizontal-padding"
        >
          <IonButton
            mode="ios"
            slot="start"
            className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
            fill="clear"
            color={EWPCOLORS.medium}
            onClick={onCancel !== undefined ? onCancel : onDidDismiss}
          >
            Cancel
          </IonButton>

          <IonButton
            mode="ios"
            slot="end"
            className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
            color={EWPCOLORS.primary}
            onClick={softDeleteJob}
          >
            Yes, {!!isCancelled ? "Uncancel this Job" : "Delete"}
            <IonRippleEffect />
          </IonButton>
        </IonItem>
      </IonContent>

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => {
          setError("");
        }}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={1000}
        onDidDismiss={() => {
          setSuccess("");
          onSuccess();
        }}
        color={EWPCOLORS.success}
      />
    </IonModal>
  );
};
