import * as _ from "lodash";
import React, { useState } from "react";
import { IonLabel, IonLoading, IonToast } from "@ionic/react";

import "./TruckForm.scss";
import * as services from "../../services";
import { Truck } from "../../models/drivers";
import { EWPButton } from "../EWPButton/EWPButton";
import { EWPInput } from "../EWPInput/EWPInput";
import { EWPCOLORS } from "../../constants/config";
import {
  DropdownItem,
  EWPWebDropdownSelect,
} from "../EWPWebDropdownSelect/EWPWebDropdownSelect";
import { formatString, useEffectOnlyOnce } from "../../functions/common";
import { JobType, JOBS } from "../../models";
import { MSGS_COMMON, MSGS_TRUCK } from "../../constants/messages";

interface TruckFormInterface {
  editData?: Truck;
  onSuccess: () => void;
}
export const TruckForm = (props: TruckFormInterface) => {
  const { editData, onSuccess } = props;
  let existingData = {
    docId: "",
    name: "",
    size: "",
    type: null as JobType | null,
    make: "",
    model: "",
    year: "",
    regoNumber: "",
    vinNumber: "",
    rmsTransportNumber: "",
    depotId: "",
    description: "",
  } as Truck;

  if (!_.isEmpty(editData) && !!editData) {
    existingData = {
      ...editData,
    } as Truck;
  }

  const jobTypesOption = Object.values(JOBS).map((jobType) => {
    return {
      id: jobType.id.toString(),
      name: jobType.name,
    };
  });

  const [name, setName] = useState(existingData.name);
  const [size, setSize] = useState(existingData.size);
  const [selectedType, setSelectedType] = useState(
    !_.isNull(existingData.type) ? existingData.type : ""
  );
  const [make, setMake] = useState(existingData.make);
  const [model, setModel] = useState(existingData.model);
  const [year, setYear] = useState(existingData.year);
  const [regoNumber, setRegoNumber] = useState(existingData.regoNumber);
  const [vinNumber, setVinNumber] = useState(existingData.vinNumber);
  const [rmsTransportNumber, setRMSTransportNumber] = useState(
    existingData.rmsTransportNumber || ""
  );
  const [selectedDepotId, setSelectedDepotId] = useState(existingData.depotId);
  const [description, setDescription] = useState(existingData.description);

  const [depotOptions, setDepotOptions] = useState([] as DropdownItem[]);

  const [errorMsgName, setErrorMsgName] = useState("");
  const [errorMsgSize, setErrorMsgSize] = useState("");
  const [errorMsgType, setErrorMsgType] = useState("");
  const [errorMsgMake, setErrorMsgMake] = useState("");
  const [errorMsgModel, setErrorMsgModel] = useState("");
  const [errorMsgYear, setErrorMsgYear] = useState("");
  const [errorMsgRegoNumber, setErrorMsgRegoNumber] = useState("");
  const [errorMsgVinNumber, setErrorMsgVinNumber] = useState("");
  const [errorMsgRMSTransportNumber, setErrorMsgRMSTransportNumber] =
    useState("");
  const [errorMsgDepotId, setErrorMsgDepotId] = useState("");
  const [errorMsgDescription, setErrorMsgDescription] = useState("");

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const save = async () => {
    const formattedName = formatString(name);
    const formattedSize = formatString(size);
    const formattedMake = formatString(make);
    const formattedModel = formatString(model);
    const formattedYear = formatString(year);
    const formattedRegoNumber = formatString(regoNumber);
    const formattedVinNumber = formatString(vinNumber);
    const formattedRMSTransportNumber = formatString(rmsTransportNumber || "");
    const formattedDescription = formatString(description);

    let errorMessage = null;
    if (_.isEmpty(formattedName)) {
      errorMessage = MSGS_TRUCK.name;
      setErrorMsgName(errorMessage);
    }
    if (_.isEmpty(formattedSize)) {
      errorMessage = MSGS_TRUCK.size;
      setErrorMsgSize(errorMessage);
    }
    if (!selectedType) {
      errorMessage = MSGS_TRUCK.type;
      setErrorMsgType(errorMessage);
    }
    if (_.isEmpty(formattedMake)) {
      errorMessage = MSGS_TRUCK.make;
      setErrorMsgMake(errorMessage);
    }
    if (_.isEmpty(formattedModel)) {
      errorMessage = MSGS_TRUCK.model;
      setErrorMsgModel(errorMessage);
    }
    if (_.isEmpty(formattedYear)) {
      errorMessage = MSGS_TRUCK.year;
      setErrorMsgYear(errorMessage);
    }
    if (_.isEmpty(formattedRegoNumber)) {
      errorMessage = MSGS_TRUCK.regoNumber;
      setErrorMsgRegoNumber(errorMessage);
    }
    if (_.isEmpty(formattedVinNumber)) {
      errorMessage = MSGS_TRUCK.vinNumber;
      setErrorMsgVinNumber(errorMessage);
    }
    if (_.isEmpty(formattedRMSTransportNumber)) {
      errorMessage = MSGS_TRUCK.rmsTransportNumber;
      setErrorMsgRMSTransportNumber(errorMessage);
    }
    if (_.isEmpty(selectedDepotId)) {
      errorMessage = MSGS_TRUCK.depotId;
      setErrorMsgDepotId(errorMessage);
    }
    if (_.isEmpty(formattedDescription)) {
      errorMessage = MSGS_TRUCK.description;
      setErrorMsgDescription(errorMessage);
    }

    if (_.isEmpty(errorMessage)) {
      setSaving(true);
      try {
        if (!_.isEmpty(props.editData)) {
          await services.updateTruck(
            editData!.docId || "",
            formattedName,
            formattedSize,
            selectedType as JobType,
            formattedMake,
            formattedModel,
            formattedYear,
            formattedRegoNumber,
            formattedVinNumber,
            formattedRMSTransportNumber,
            selectedDepotId,
            formattedDescription
          );
          setSuccess("Truck Updated");
        } else {
          await services.createTruck(
            formattedName,
            formattedSize,
            selectedType as JobType,
            formattedMake,
            formattedModel,
            formattedYear,
            formattedRegoNumber,
            formattedVinNumber,
            formattedRMSTransportNumber,
            selectedDepotId,
            formattedDescription
          );
          setSuccess("New Truck Added");
        }

        setSaving(false);
      } catch (eUnknown) {
        const e = eUnknown as any;
        setSaving(false);
        setError(e);
      }
    }
  };

  useEffectOnlyOnce(() => {
    getDepotOptions();
  });

  const getDepotOptions = async () => {
    try {
      const depots = await services.getDepots();
      setDepotOptions(
        depots.map((depot) => {
          return {
            id: depot.docId,
            name: depot.name,
          } as DropdownItem;
        })
      );
    } catch (eUnknown) {
      const e = eUnknown as any;
      setError(e);
    }
  };

  return (
    <>
      <div className="truck-form-container">
        <IonLabel className="truck-form-header ewp-h3 ion-text-start">
          Truck Details
        </IonLabel>
        <div className="truck-form-input-container">
          <EWPInput
            errorMessage={errorMsgName}
            inputLabel="Truck Name"
            inputValue={name}
            inputType="text"
            name="name"
            onInputChange={(name: string) => {
              setErrorMsgName("");
              setName(name);
            }}
          />
        </div>
        <div className="truck-form-input-container">
          <EWPInput
            errorMessage={errorMsgSize}
            inputLabel="Size"
            inputValue={size}
            inputType="text"
            name="size"
            onInputChange={(size: string) => {
              setErrorMsgSize("");
              setSize(size);
            }}
          />
        </div>
        <div className="truck-form-input-container truck-form-select-container">
          <EWPWebDropdownSelect
            dropdownItems={jobTypesOption}
            onSelectItem={(id) => {
              setSelectedType(parseInt(id as string) as JobType);
              setErrorMsgType("");
            }}
            placeholder="Type"
            value={selectedType.toString()}
            errorMsg={errorMsgType}
            hasSearch={true}
          />
        </div>
        <div className="truck-form-input-container">
          <EWPInput
            errorMessage={errorMsgMake}
            inputLabel="Make"
            inputValue={make}
            inputType="text"
            name="make"
            onInputChange={(make: string) => {
              setErrorMsgMake("");
              setMake(make);
            }}
          />
        </div>
        <div className="truck-form-input-container">
          <EWPInput
            errorMessage={errorMsgModel}
            inputLabel="Model"
            inputValue={model}
            inputType="text"
            name="model"
            onInputChange={(model: string) => {
              setErrorMsgModel("");
              setModel(model);
            }}
          />
        </div>
        <div className="truck-form-input-container">
          <EWPInput
            errorMessage={errorMsgYear}
            inputLabel="Year"
            inputValue={year}
            inputType="number"
            name="year"
            onInputChange={(year: string) => {
              setErrorMsgYear("");
              setYear(year);
            }}
          />
        </div>
        <div className="truck-form-input-container">
          <EWPInput
            errorMessage={errorMsgRegoNumber}
            inputLabel="Rego Number"
            inputValue={regoNumber}
            inputType="text"
            name="regoNumber"
            onInputChange={(regoNumber: string) => {
              setErrorMsgRegoNumber("");
              setRegoNumber(regoNumber);
            }}
          />
        </div>
        <div className="truck-form-input-container">
          <EWPInput
            errorMessage={errorMsgVinNumber}
            inputLabel="VIN Number"
            inputValue={vinNumber}
            inputType="text"
            name="vinNumber"
            onInputChange={(vinNumber: string) => {
              setErrorMsgVinNumber("");
              setVinNumber(vinNumber);
            }}
          />
        </div>
        <div className="truck-form-input-container">
          <EWPInput
            errorMessage={errorMsgRMSTransportNumber}
            inputLabel="RMS ID"
            inputValue={rmsTransportNumber}
            inputType="text"
            name="rmsTransportNumber"
            onInputChange={(rmsTransportNumber: string) => {
              setErrorMsgRMSTransportNumber("");
              setRMSTransportNumber(rmsTransportNumber);
            }}
          />
        </div>

        <div className="truck-form-input-container truck-form-select-container">
          <EWPWebDropdownSelect
            dropdownItems={depotOptions}
            disabled={_.isEmpty(depotOptions)}
            onSelectItem={(id) => {
              setErrorMsgDepotId("");
              setSelectedDepotId(id as string);
            }}
            placeholder="Choose Depot"
            value={selectedDepotId}
            errorMsg={errorMsgDepotId}
            hasSearch={true}
          />
        </div>
        <div className="truck-form-input-container">
          <EWPInput
            errorMessage={errorMsgDescription}
            inputLabel="Description"
            inputValue={description}
            inputType="text"
            name="description"
            mode="textArea"
            onInputChange={(description: string) => {
              setErrorMsgDescription("");
              setDescription(description);
            }}
          />
        </div>

        <EWPButton
          className="truck-form-save-button"
          title={!_.isEmpty(editData) ? "Save Changes" : "Add Truck"}
          onClick={save}
        />
      </div>

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={saving}
        message={MSGS_COMMON.saving}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
          onSuccess();
        }}
        color={EWPCOLORS.success}
      />
    </>
  );
};
