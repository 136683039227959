export const LANDING = "/landing";
export const SIGN_UP = "/sign-up";
export const EDIT_ACCOUNT = "/edit-account";
export const CHANGE_PASSWORD = "/change-password";
export const SIGN_IN = "/sign-in";
export const HOME = "/home/:subPage";
export const HOME_SUB_PAGE_DETAIL = "/home/:subPage/:pageParam/:detailId";
export const HOME_ADD_NEW_PAGE = "/home/:subPage/:pageParam/";
export const HOME_EDIT_PAGE = "/home/:subPage/:pageParam/:detailId";

export const HOME_CLIENT_REGISTER = "/home/client-register";
export const HOME_ADD_CLIENT = "/home/client-register/new";
export const HOME_CLIENT_REGISTER_DETAILS =
  "/home/client-register/view/:clientId";
export const HOME_EDIT_CLIENT = "/home/client-register/edit/:clientId";

export const HOME_TRUCK_REGISTER = "/home/truck-register";
export const HOME_ADD_TRUCK = "/home/truck-register/new";
export const HOME_TRUCK_REGISTER_DETAILS = "/home/truck-register/view/:truckId";
export const HOME_EDIT_TRUCK = "/home/truck-register/edit/:truckId";

export const HOME_DRIVER_REGISTER = "/home/driver-register";
export const HOME_FAULTS_AND_DEFECTS = "/home/faults-and-defects";
export const HOME_ADD_DRIVER = "/home/driver-register/new";
export const HOME_DRIVER_REGISTER_DETAILS =
  "/home/driver-register/view/:driverId";
export const HOME_EDIT_DRIVER = "/home/driver-register/edit/:driverId";
export const USERS = "/home/users";

export const HOME_SEARCH = "/home/search";

export const HOME_JOB_SCHEDULE = "/home/job-schedule";
export const HOME_JOB_SCHEDULE_DETAIL = "/home/job-schedule/:jobScheduleId";
export const HOME_ADMIN = "/home/admin";
export const CREATE_JOB_SCHEDULE = "/home/create-new-job-schedule";
export const EDIT_JOB_SCHEDULE = "/home/edit-job-schedule";
export const CREATE_NEW_TRUCK = "/home/create-new-truck";

export const EXTERNAL_ACCOUNT_ACTION = "/account-action";
export const FORGOT_PASSWORD = "/forgot-password";
export const FORGOT_PASSWORD_EMAIL_SENT = "/forgot-password-email-sent";
export const RECOVER_EMAIL = "/recover-email";
export const RESET_PASSWORD = "/reset-password";
export const RESET_SUCCESS = "/reset-success";
export const VERIFY_EMAIL = "/verify-email";

export const ACCOUNT_SETTINGS = "/account-settings/:subPage";
export const ACCOUNT_SETTINGS_PROFILE = "/account-settings/profile";

export const DRIVER_LANDING = "/driver-landing";
export const DRIVER_SIGN_IN = "/driver-sign-in";
export const DRIVER_JOB_SCHEDULE = "/driver-job-schedule";
export const DRIVER_ACCOUNT = "/driver-account";
export const DRIVER_MAINTENANCE = "/driver-maintenance";
export const DRIVER_SIGNUP = "/driver-sign-up";

export const XERO_AUTH_CALLBACK = "/home/job-schedule";
