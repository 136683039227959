import * as _ from "lodash";
import React, { useState } from "react";
import {
  IonAvatar,
  IonButton,
  IonIcon,
  IonLabel,
  IonModal,
  IonSpinner,
} from "@ionic/react";

import "./EWPProfilePhoto.scss";
import { EWPCOLORS } from "../../constants/config";
import { Camera, CameraResultType } from "@capacitor/camera";

interface EWPProfilePhotoProps {
  imgSrc: null | string;
  initials: null | string;
  onEditProfilePic?: (file: any) => void;
  isMobile?: boolean;
}
export const EWPProfilePhoto = (props: EWPProfilePhotoProps) => {
  const { imgSrc, initials, onEditProfilePic, isMobile } = props;

  const ref: React.RefObject<HTMLInputElement> = React.createRef();
  const [showProfilePhotoDialog, setShowProfilePhotoDialog] = useState(false);

  const onShowProfilePhotoDialog = () => {
    setShowProfilePhotoDialog(true);
  };
  const onPhotoDialogDidDismiss = () => {
    setShowProfilePhotoDialog(false);
  };
  const onClickUploadPhoto = async () => {
    if (isMobile && onEditProfilePic !== undefined) {
      // Take a photo
      const capturedPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        quality: 50,
      });

      onEditProfilePic(capturedPhoto.webPath || "");
      setShowProfilePhotoDialog(false);
    } else {
      if (!!ref) {
        ref.current!.click(); // ref for input field file
        setShowProfilePhotoDialog(false);
      }
    }
  };
  const onRemovedPhoto = () => {
    if (onEditProfilePic !== undefined) {
      onEditProfilePic(null);
    }
    setShowProfilePhotoDialog(false);
  };
  return (
    <>
      <div className="ewp-upload-photo-container">
        {onEditProfilePic !== undefined && (
          <>
            <IonButton
              className={`edit-button ${
                isMobile ? "mobile" : ""
              } ion-no-margin ion-no-padding`}
              shape="round"
              color="primary"
              onClick={onShowProfilePhotoDialog}
            >
              <IonIcon className="edit-icon" />
            </IonButton>
            <input
              hidden
              type="file"
              ref={ref}
              accept="image/*"
              onChange={onEditProfilePic}
            />
          </>
        )}
        {_.isNull(imgSrc) || _.isNull(initials) ? (
          <IonLabel
            className={`ewp-upload-photo-placeholder-initials ${
              isMobile ? "mobile" : ""
            }`}
          >
            <IonSpinner
              className={`ewp-upload-photo-card-supervisor-picture-spinner ${
                isMobile ? "mobile" : ""
              }`}
              color={isMobile ? EWPCOLORS.light : EWPCOLORS.primary}
              name="circular"
            />
          </IonLabel>
        ) : _.isEmpty(imgSrc) ? (
          <IonLabel
            className={`ewp-upload-photo-placeholder-initials ${
              isMobile ? "mobile" : ""
            }`}
          >
            <span
              className={`ewp-upload-photo-placeholder-initials-text ion-text-uppercase ${
                isMobile ? "mobile" : ""
              }`}
            >
              {initials}
            </span>
          </IonLabel>
        ) : (
          <IonAvatar
            className={`ewp-upload-photo-avatar ${isMobile ? "mobile" : ""}`}
            slot="start"
          >
            <img id="profilePic" src={imgSrc} alt="profilePic" />
          </IonAvatar>
        )}
      </div>
      <IonModal
        className="ewp-upload-photo-dialog"
        isOpen={showProfilePhotoDialog}
        onDidDismiss={onPhotoDialogDidDismiss}
      >
        <div className="ewp-upload-photo-action-buttons-container">
          <IonLabel className="ewp-upload-photo-change-photo-title ewp-h1">
            Change Profile Photo
          </IonLabel>
          <div className="ewp-upload-photo-button-upload-container">
            <IonButton
              fill="clear"
              mode="ios"
              color={EWPCOLORS.primary}
              className="ewp-h4 ion-no-margin"
              onClick={onClickUploadPhoto}
            >
              Upload Photo
            </IonButton>
          </div>
          <div className="ewp-upload-photo-button-removed-container">
            <IonButton
              fill="clear"
              mode="ios"
              color={EWPCOLORS.danger}
              className="ewp-h4 ion-no-margin"
              onClick={onRemovedPhoto}
            >
              Removed Current Photo
            </IonButton>
          </div>
          <div className="ewp-upload-photo-button-cancel-container">
            <IonButton
              fill="clear"
              mode="ios"
              color={EWPCOLORS.medium}
              className="ion-no-margin"
              onClick={onPhotoDialogDidDismiss}
            >
              Cancel
            </IonButton>
          </div>
        </div>
      </IonModal>
    </>
  );
};
