export const HOME_PAGES = {
  jobSchedule: "job-schedule",
  createNewJobSchedule: "create-new-job-schedule",
  editJobSchedule: "edit-job-schedule",
  clientRegister: "client-register",
  truckRegister: "truck-register",
  driverRegister: "driver-register",
  users: "users",
  admin: "admin",
  search: "search",
  faultsAndDefects: "faults-and-defects",
};

export const ACCOUNT_SETTINGS_PAGES = {
  profile: "profile",
};
