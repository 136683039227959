import * as _ from "lodash";
import React, { Component } from "react";
import {
  IonPage,
  IonContent,
  withIonLifeCycle,
  IonLoading,
  IonToast,
} from "@ionic/react";

import "./SignIn.scss";
import * as services from "../../../services";
import * as routes from "../../../constants/routes";
import { EWPHeader } from "../../../components/Mobile/EWPHeader/EWPHeader";
import { EWPButton } from "../../../components/EWPButton/EWPButton";
import { EWPProps } from "../../../config/global";
import { EWPPageTitleAndDescription } from "../../../components/EWPPageTitleAndDescription/EWPPageTitleAndDescription";
import { EWPPasswordInput } from "../../../components/EWPPasswordInput/EWPPasswordInput";
import { EWPCOLORS } from "../../../constants/config";
import { MSGS_SIGNUP, MSGS_COMMON } from "../../../constants/messages";
import { EWPFooterLink } from "../../../components/common/EWPFooterLink";
import { Keyboard, KeyboardInfo } from "@capacitor/keyboard";
import { Network } from "@capacitor/network";
// import { EWPFooterLink } from "../../../components/common/EWPFooterLink";
class SignIn extends Component<EWPProps> {
  state = {
    safeToView: false,
    segment: "tradesmen" as "tradesmen" | "companyrep",
    error: "",
    password: "",
    passwordVisibility: false,
    isLoading: false,
    errorMsgEmaillAddress: "",
    errorMsgPassword: "",
    keyboardHeight: 0,
  };

  ionViewDidEnter = () => {
    setTimeout(() => {
      this.setState({ safeToView: true });
    });
  };

  componentDidMount = () => {
    this.listenForKeyboard();
  };

  listenForKeyboard = () => {
    Keyboard.addListener("keyboardDidShow", (info: KeyboardInfo) => {
      this.setState({ keyboardHeight: info.keyboardHeight });
    });

    Keyboard.addListener("keyboardWillHide", () => {
      this.setState({ keyboardHeight: 0 });
    });
  };

  login = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      this.setState({ isLoading: true });

      const { password } = this.state;
      let errorMessage = "";
      if (password.length === 0) {
        errorMessage = MSGS_SIGNUP.password.required;
        this.setState({ errorMsgPassword: errorMessage });
      }

      if (!_.isEmpty(errorMessage)) {
        this.setState({
          isLoading: false,
          error: errorMessage,
        });
        return;
      }
      const result = await services.driverLogin(password);

      this.setState({
        isLoading: false,
        ...(!_.isEmpty(result.error) && { error: result.error }),
      });
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      const networkConnection = await Network.getStatus();
      let errorMessage = error;
      if (!networkConnection.connected) {
        errorMessage = "No internet connection detected.";
      }
      this.setState({
        isLoading: false,
        error: errorMessage,
      });
    }
  };

  render() {
    const {
      safeToView,
      error,
      isLoading,
      password,
      errorMsgPassword,
      keyboardHeight,
    } = this.state;

    return (
      <>
        <IonPage
          className={`driver-sign-in-page ${safeToView ? "safe-to-view" : ""}`}
        >
          <EWPHeader title="Login" onBackRoute={routes.DRIVER_SIGN_IN} />
          <IonContent
            className={`sign-in-content ${
              keyboardHeight !== 0 ? "has-keyboard" : ""
            }`}
          >
            <EWPPageTitleAndDescription
              className="sign-in-title-container"
              title="Login to EWP"
              // description="If you are a Tradesmen, please log on using the form below"
            />

            <form className="sign-in-form" onSubmit={this.login}>
              <div>
                <EWPPasswordInput
                  passwordLabel="Pin"
                  passwordValue={password}
                  onChange={(password: string) =>
                    this.setState({ password: password, errorMsgPassword: "" })
                  }
                  errorMessage={errorMsgPassword}
                />
                <div className="forgot-password">
                  <span className="forgot-password-spiel sv-body ion-text-center">
                    Forgot password? Contact an admin to have it reset.
                  </span>
                </div>
              </div>
              <div className="ion-text-center">
                <EWPButton title="Login" routerLink="#" type="submit" />
                <EWPFooterLink
                  title="Sign up"
                  routerLink={routes.DRIVER_SIGNUP}
                />
              </div>
            </form>
          </IonContent>
          <IonLoading
            spinner="circular"
            translucent={true}
            mode="ios"
            isOpen={isLoading}
            message={MSGS_COMMON.loading}
          />
          <IonToast
            isOpen={!_.isEmpty(error)}
            onDidDismiss={() => this.setState({ error: "" })}
            message={error}
            duration={1000}
            color={EWPCOLORS.danger}
          />
        </IonPage>
      </>
    );
  }
}

export default withIonLifeCycle(SignIn);
