import * as _ from "lodash";
import React from "react";
import { IonLabel, IonSpinner } from "@ionic/react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
} from "@material-ui/core";

import "./EWPDataTable.scss";
import { EWPTableProps } from "../../config/global";
import { EWPCOLORS } from "../../constants/config";

interface SortLabelsAndDirection {
  [key: string]: "asc" | "desc";
}

export const EWPDataTable = ({
  tableDef,
  tableData,
  tableFilteredData,
  renderCell,
  sortLabelsAndDirection,
  onSort,
  onRowRedirect,
}: {
  tableDef: EWPTableProps;
  tableData: any[] | null;
  tableFilteredData: any[] | null;
  renderCell: (keyValue: string, rowData: any) => void;
  sortLabelsAndDirection?: SortLabelsAndDirection;
  onSort?: (keyValue: string) => void;
  onRowRedirect?: (rowData: any) => void;
  emptyStateTitle?: string;
  emptyStateDescription?: string;
  isComponentPage?: boolean;
}) => {
  const sortLabel = (key: string) => {
    if (!_.isEmpty(sortLabelsAndDirection)) {
      return (
        <TableSortLabel
          active={true}
          direction={
            !!sortLabelsAndDirection ? sortLabelsAndDirection[key] : "asc"
          }
          onClick={() => {
            if (!!onSort) onSort(key);
          }}
        />
      );
    }
  };
  return (
    <>
      {_.isNull(tableData) ? (
        <div className="ewp-table-spinner-container">
          <IonSpinner className="ewp-spinner" color={EWPCOLORS.primary} />
        </div>
      ) : !_.isEmpty(tableData) ? (
        <Table className="ewp-table" stickyHeader={true}>
          <TableHead className="ewp-table-header">
            <TableRow>
              {tableDef.headers.map((header) => (
                <TableCell key={header.key}>
                  <IonLabel className="ewp-h6 white bold headerFont ion-text-uppercase">
                    {header.label}
                    {header.sortable && sortLabel(header.key)}
                  </IonLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(!!tableFilteredData ? tableFilteredData : tableData).map(
              (rowData: any, index: number) => {
                return (
                  !_.isEmpty(rowData) && (
                    <TableRow
                      hover
                      className={`ewp-table-row-data ${
                        onRowRedirect !== undefined && "clickable"
                      }`}
                      key={index}
                    >
                      {tableDef.columns.map((value: string) => {
                        if (!!value && !!renderCell) {
                          return (
                            <TableCell
                              scope="row"
                              key={value}
                              onClick={() => {
                                if (
                                  !!onRowRedirect &&
                                  value !== "emailAddress"
                                ) {
                                  onRowRedirect(rowData);
                                }
                              }}
                            >
                              {!_.isEmpty(rowData[value].toString())
                                ? (renderCell(value, rowData) as any)
                                : ("" as any)}
                            </TableCell>
                          );
                        }
                        return "";
                      })}
                    </TableRow>
                  )
                );
              }
            )}
          </TableBody>
        </Table>
      ) : (
        <IonLabel>No Data</IonLabel>
      )}
    </>
  );
};
