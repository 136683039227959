import * as _ from "lodash";
import React from "react";
import { chevronBackOutline, ellipsisHorizontal } from "ionicons/icons";
import {
  IonToolbar,
  IonHeader,
  IonButton,
  IonIcon,
  IonLabel,
} from "@ionic/react";

import "./EWPHeader.scss";
import * as routes from "../../../constants/routes";
import { EWPCOLORS } from "../../../constants/config";

interface EWPHeaderProps {
  title: string;
  subtitle?: string;
  color?: "primary" | "secondary" | "tertiary" | string;
  onBackRoute?: string | (() => void);
  children?: any;
  hideBackButton?: boolean;
  onOptions?: null | (() => void);
}

export const EWPHeader: React.FunctionComponent<EWPHeaderProps> = ({
  title,
  subtitle,
  color = "tertiary",
  onBackRoute,
  children,
  hideBackButton = false,
  onOptions = null,
}: EWPHeaderProps) => (
  <IonHeader className="ewp-header-mobile" mode="md">
    <IonToolbar
      className="ewp-header-mobile-toolbar ion-no-padding"
      color={color}
      mode="ios"
    >
      <div className="ewp-header-mobile-back-button-wrapper">
        {!hideBackButton && (
          <IonButton
            {...(!!onBackRoute
              ? _.isString(onBackRoute)
                ? { routerLink: onBackRoute as string }
                : { onClick: onBackRoute }
              : { routerLink: routes.LANDING })}
            mode="ios"
            className={`ewp-header-mobile-back-button ion-no-padding ion-no-margin ${
              color === EWPCOLORS.primary ? "dark" : ""
            } `}
          >
            <IonIcon
              icon={chevronBackOutline}
              className="ewp-button-back-icon"
              mode="ios"
            />
          </IonButton>
        )}
      </div>

      <div className="ewp-header-mobile-title-container">
        <div className="ewp-title-container">
          <IonLabel
            className={`ewp-h2 ${
              color === "primary" && "white"
            } ion-text-center`}
          >
            {title}
          </IonLabel>
        </div>
        {!!subtitle && (
          <IonLabel
            className={`ewp-header-mobile-subtitle ewp-body ${
              color === "primary" ? "dark" : ""
            } ion-text-center`}
          >
            {subtitle}
          </IonLabel>
        )}
      </div>

      {!!onOptions && (
        <div className="ewp-header-mobile-option-button-wrapper">
          <IonButton
            slot="end"
            className="ewp-header-mobile-option-button ion-no-padding ion-no-margin"
            onClick={onOptions}
          >
            <IonIcon icon={ellipsisHorizontal} />
          </IonButton>
        </div>
      )}
    </IonToolbar>
    {children && (
      <IonToolbar className="ewp-subheader-toolbar">{children}</IonToolbar>
    )}
  </IonHeader>
);
