import { Storage } from "@capacitor/storage";

export const setItem = async (key: string, value: string) => {
  await Storage.set({
    key,
    value,
  });
};

export const getItem = async (key: string) => {
  const { value } = await Storage.get({ key });
  return value === "undefined" || value === undefined ? null : value;
};

export const removeItem = async (key: string) => {
  await Storage.remove({ key });
};

export const keys = async () => {
  const { keys } = await Storage.keys();
  return keys;
};

export const clear = async () => {
  await Storage.clear();
};
