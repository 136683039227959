import _ from "lodash";
import React, { useEffect, useState } from "react";

import "./EWPCommonTooltip.scss";
import ReactDOM from "react-dom";

interface EWPCommonTooltipProps {
  left: number;
  top: number;
  content: string;
}
export const EWPCommonTooltip = (props: EWPCommonTooltipProps) => {
  const { left, top, content } = props;

  const [tooltipContent, setTooltipContent] = useState<string | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{
    left: string;
    top: string;
  }>({ left: "0px", top: "0px" });

  useEffect(() => {
    handleMouseOver(left, top, content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [left, top, content]);

  const handleMouseOver = _.debounce(
    (left: number, top: number, name: string) => {
      if (!_.isEmpty(name)) {
        setTooltipPosition({
          left: `${left + 50}px`,
          top: `${top + 50}px`,
        });

        setTooltipContent(name);
      } else {
        handleMouseOut();
      }
    },
    250
  );

  const handleMouseOut = () => {
    setTooltipContent(null);
  };

  return (
    <React.Fragment>
      {tooltipContent &&
        ReactDOM.createPortal(
          <span
            className="attachment-file-common-tooltip"
            style={tooltipPosition}
          >
            {tooltipContent}
          </span>,
          document.body
        )}
    </React.Fragment>
  );
};
