import * as _ from "lodash";
import moment from "moment";
import React from "react";
import {
  IonLabel,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCardHeader,
  IonSpinner,
} from "@ionic/react";

import "./ChangeLogsCard.scss";
import { isTablet } from "../../functions/common";
import {
  DriverChangeLogDetails,
  TruckChangeLogDetails,
} from "../../models/drivers";
import { EWPCOLORS } from "../../constants/config";

interface ChangeLogsCardProps {
  changeLogs: (DriverChangeLogDetails | TruckChangeLogDetails)[] | null;
  mode: "driver" | "truck";
}
export const ChangeLogsCard = (props: ChangeLogsCardProps) => {
  const { changeLogs, mode } = props;

  return (
    <>
      <IonCard
        className={`change-logs-card ${
          isTablet() ? "tablet" : ""
        } ion-no-padding ion-no-margin`}
        color={EWPCOLORS.tertiary}
      >
        <IonCardContent className="change-logs-card-content ion-no-padding ion-no-margin">
          <IonCardHeader className="change-logs-card-header ion-no-padding ion-no-margin">
            <IonLabel className="change-logs-card-header-label ewp-h4 bold ion-no-padding ion-no-margin">
              {`${_.capitalize(mode)} Change Log`}
            </IonLabel>
          </IonCardHeader>
          <IonGrid className="change-logs-card-grid ion-no-padding ion-no-margin">
            <IonRow
              className={`change-logs-card-row-content-header ${
                _.isEmpty(changeLogs) && "no-border-bottom"
              } ion-no-padding ion-no-margin`}
            >
              <IonCol className="ion-no-padding ion-text-start" size="4">
                <IonLabel className="ewp-h6 medium bold ion-text-uppercase ion-text-start">
                  Admin
                </IonLabel>
              </IonCol>
              <IonCol className="ion-no-padding ion-text-start" size="5">
                <IonLabel className="ewp-h6 medium bold ion-text-uppercase ion-text-start">
                  Description
                </IonLabel>
              </IonCol>
              <IonCol className="ion-no-padding ion-text-start" size="3">
                <IonLabel className="ewp-h6 medium bold ion-text-uppercase ion-text-start">
                  Date Changed
                </IonLabel>
              </IonCol>
            </IonRow>

            {!!changeLogs ? (
              _.isEmpty(changeLogs) ? (
                <IonLabel className="no-data-label ewp-h4 light bold">
                  No Data
                </IonLabel>
              ) : (
                changeLogs.map((changeLog, rowIndex) => {
                  return (
                    <IonRow
                      key={`change-log-row-${rowIndex}`}
                      className="change-logs-card-row-content-data ion-no-padding ion-no-margin"
                    >
                      <IonCol
                        className="ion-no-padding ion-text-start"
                        size="4"
                      >
                        <IonLabel className="ewp-h6 medium ion-text-start">
                          {changeLog.person}
                        </IonLabel>
                      </IonCol>
                      <IonCol
                        className="ion-no-padding ion-text-start"
                        size="5"
                      >
                        <IonLabel className="ewp-h6 medium ion-text-start">
                          {changeLog.description}
                        </IonLabel>
                      </IonCol>
                      <IonCol
                        className="ion-no-padding ion-text-start"
                        size="3"
                      >
                        <IonLabel className="ewp-h6 medium ion-text-start">
                          {!!changeLog.updatedDt
                            ? moment(changeLog.updatedDt.toDate()).format(
                                "DD MMM YYYY"
                              )
                            : moment(new Date()).format("DD MMM YYYY")}
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                  );
                })
              )
            ) : (
              <div className="ewp-table-spinner-container">
                <IonSpinner className="ewp-spinner" color={EWPCOLORS.primary} />
              </div>
            )}
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  );
};
