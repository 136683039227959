import * as _ from "lodash";
import Bugsnag from "@bugsnag/js";
import { add, close, closeOutline } from "ionicons/icons";
import React, { useEffect, useState, DragEvent } from "react";
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonRippleEffect,
  IonRow,
  IonThumbnail,
  IonToast,
} from "@ionic/react";

import "./DriverDashboard.scss";
import * as routes from "../../../constants/routes";
import * as services from "../../../services";
import { storage } from "../../../firebase";
import { AttachmentDialog } from "../AttachmentDialog/AttachmentDialog";
import { ArchivedBanner } from "../../ArchivedBanner/ArchivedBanner";
import { ArchiveOrRestoreTruckDialog } from "../../ArchiveOrRestoreTruckDialog/ArchiveOrRestoreTruckDialog";
import { DriverListView } from "../DriverList/DriverList";
import { EWPDatePicker } from "../../EWPDatePicker/EWPDatePicker";
import { EWPDriverProfileHeader } from "../../EWPDriverProfileHeader/EWPDriverProfileHeader";
import { EWPNoteWithAttachment } from "../../EWPNoteWithAttachment/EWPNoteWithAttachment";
import { EWPJobHistoryCard } from "../../EWPJobHistoryCard/EWPJobHistoryCard";
import { EWPProps, TextEditorEdit } from "../../../config/global";
import { EWPTitleDetailPage } from "../../EWPTitleDetailPage/EWPTitleDetailPage";
import { EWPWebDialog } from "../../EWPWebDialog/EWPWebDialog";
import { ExpiriesCard } from "../../ExpiriesCard/ExpiriesCard";
import { EWPInput } from "../../EWPInput/EWPInput";
import { EWPInudctionForm } from "../../EWPInudctionForm/EWPInudctionForm";
import { EWPNoteCard } from "../EWPNoteCard/EWPNoteCard";
import { InductionCard } from "../../InductionCard/InductionCard";
import { JobScheduleFor } from "../../../pages/Home/Home";
import { VOCCard } from "../../VOCCard/VOCCard";
import { JobScheduleView, Notes } from "../../../models";
import {
  getServerTimestamp,
  removedUploadedAttachements,
  uploadAttachments,
} from "../../../functions/common";
import {
  DriverChangeLogDetails,
  DriverNotes,
  DriverVOCView,
  ExpiryReference,
  ExpiryWithDate,
  Inductions,
} from "../../../models/drivers";
import { EWPCOLORS, LIMITS } from "../../../constants/config";
import {
  MSGS_COMMON,
  MSGS_DRIVER,
  MSGS_NOTE_ENTRY,
} from "../../../constants/messages";
import { ChangeLogsCard } from "../../ChangeLogsCard/ChangeLogsCard";
import { EWPCommonTooltip } from "../EWPCommonTooltip/EWPCommonTooltip";

interface DriverDashboardProps extends EWPProps {
  driver: DriverListView | null;
}
export const DriverDashboard = (props: DriverDashboardProps) => {
  const { driver } = props;

  const [driverVOCs, setDriverVOCs] = useState(null as null | DriverVOCView[]);
  const [driverInductions, setDriverInductions] = useState(
    null as null | Inductions[]
  );
  const [showAddNote, setShowAddNote] = useState(false);
  // const [selectedExpiry, setSelectedExpiry] = useState(
  //   null as null | ExpiryWithDate
  // );
  const [listOfAttachment, setListOfAttachment] = useState([] as any[]);
  const [removedAttachments, setRemovedAttachments] = useState([] as string[]);

  const [toEditInduction, setToEditInduction] = useState({} as Inductions);
  const [editDriverNoteData, setEditDriverNoteData] = useState(
    {} as DriverNotes
  ); //todo: update interface for driver
  const [driverNotes, setDriverNotes] = useState(null as null | DriverNotes[]);
  const [noteTitle, setNoteTitle] = useState("");
  const [noteDescription, setNoteDescription] = useState("");

  const [removedUrl, setRemovedUrl] = useState([] as any[]);
  const [willEditNoteEntry, setwillEditNoteEntry] = useState(false);
  const [selectedEditIndex, setSelectedEditIndex] = useState(
    null as null | number
  );
  const [changeLogs, setChangeLogs] = useState(
    null as null | DriverChangeLogDetails[]
  );

  const [showArchivedRestoreDialog, setShowArchivedRestore] = useState(false);
  const [showUpdateExpiryDialog, setShowUpdateExpiryDialog] = useState(false);
  const [showDriverInductionDialog, setShowDriverInductionDialog] =
    useState(false);

  const [expiryId, setExpiryId] = useState("");
  const [expiryName, setExpiryName] = useState("");
  const [errorMsgExpiryName, setErrorMsgExpiryName] = useState("");
  const [expiryDate, setExpiryDate] = useState(null as Date | null);
  const [errorMsgExpiryDate, setErrorMsgExpiryDate] = useState("");

  const [jobHistory, setJobHistory] = useState(
    null as null | JobScheduleView[]
  );

  const [driverExpiryWithDate, setDriverExpiryWithDate] = useState(
    null as null | ExpiryWithDate[]
  );

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const [lastUpdated, setLastUpdated] = useState(null as null | number);

  const [selectedExpries, setSelectedExpries] = useState(
    null as null | ExpiryReference
  );
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);

  const ref: React.RefObject<HTMLInputElement> = React.createRef();

  const [tooltipCoords, setTooltipCoords] = useState({
    left: 0,
    top: 0,
  });

  const [tooltipMessage, setTooltipMessage] = useState("");

  const onClickUploadPhoto = () => {
    console.log("CLICK UPLOAD -- ", listOfAttachment);
    if (!!listOfAttachment) {
      if (
        listOfAttachment.length < LIMITS.serviceTypeAttachments.count &&
        !!ref
      ) {
        ref.current!.click(); // ref for input field file
      } else {
        setError(MSGS_DRIVER.vocAttachmentsExceed);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      !_.isEmpty(driver) &&
      (_.isNull(driverExpiryWithDate) ||
        (!_.isNull(driverExpiryWithDate) &&
          lastUpdated !==
            (!!driver!.updatedDt
              ? driver!.updatedDt!.toDate().valueOf()
              : null)))
    ) {
      if (!_.isEmpty(driver)) {
        if (_.isNull(jobHistory)) {
          getJobHistory();
        }

        getChangeLogs(driver!.docId || "");

        getDriverExpiryList();
        setLastUpdated(
          !!driver!.updatedDt ? driver!.updatedDt!.toDate().valueOf() : null
        );
      } else {
        setJobHistory([]);
      }
    }
    if (!_.isNull(driver) && _.isNull(driverVOCs)) {
      getDriverVOCs();
    }
    if (_.isNull(driverNotes)) {
      getDriverNotes();
    }

    if (!_.isNull(driver) && _.isNull(driverInductions)) {
      getInductions();
    }
    // eslint-disable-next-line
  });

  const getChangeLogs = (driverId: string) => {
    services.getDriverChangeLogs(driverId, (changeLogs, error) => {
      setChangeLogs(changeLogs);
      setError(error || "");
    });
  };

  const onAttachExpiryPhoto = async (file: any) => {
    if (
      listOfAttachment.length + file.target.files.length <=
      LIMITS.serviceTypeAttachments.count
    ) {
      if (!!file && file.target.files.length) {
        const attachments = file.target.files;
        const fileSizes = Object.values(attachments).map((attachment: any) => {
          return attachment.size <= LIMITS.serviceTypeAttachments.size;
        });
        const hasExceededFileSize = _.includes(fileSizes, false);
        if (!hasExceededFileSize) {
          const toBeUploadedAttachments = _.concat(
            Object.values(attachments),
            listOfAttachment
          );
          setListOfAttachment(toBeUploadedAttachments as []);
        } else {
          setError(MSGS_DRIVER.attachmentFileSizeExceed);
        }
      }
    } else {
      setError(MSGS_DRIVER.vocAttachmentsExceed);
    }
  };

  const onDrop = (event: DragEvent) => {
    event.preventDefault();
    if (listOfAttachment.length <= LIMITS.serviceTypeAttachments.count) {
      if (event.dataTransfer.files) {
        // Use DataTransferItemList interface to access the file(s)
        if (
          event.dataTransfer.files.length <= LIMITS.serviceTypeAttachments.count
        ) {
          const files = Object.values(event.dataTransfer.files);
          const fileSizes = Object.values(files).map((attachment: any) => {
            return attachment.size <= LIMITS.serviceTypeAttachments.size;
          });
          const hasExceededFileSize = _.includes(fileSizes, false);
          if (!hasExceededFileSize) {
            files.forEach((file) => {
              const imageType = /image.*/;
              if (
                file &&
                (file.type.match(imageType) || file.type === "application/pdf")
              ) {
                const attachments = Object.values(event.dataTransfer.files);
                const toBeUploadedAttachments = _.concat(
                  attachments,
                  listOfAttachment
                );
                setListOfAttachment(toBeUploadedAttachments as []);
              } else {
                setError(MSGS_DRIVER.invalidFileType);
              }
            });
          } else {
            setError(MSGS_DRIVER.attachmentFileSizeExceed);
          }
        } else {
          setError(MSGS_DRIVER.vocAttachmentsExceed);
        }
      }
    } else {
      setError(MSGS_DRIVER.vocAttachmentsExceed);
    }
  };

  const onRemoveExpiryAttachment = (attachment: any) => {
    const updatedListOfAttachment = _.remove(listOfAttachment, (item) => {
      return item !== attachment;
    });

    if (typeof attachment === "string") {
      setRemovedAttachments([...removedAttachments, attachment]);
    }
    setListOfAttachment(updatedListOfAttachment);
  };

  const onAttachPhoto = async (file: any) => {
    if (!!file && file.target.files.length > 0) {
      const attachments = file.target.files;
      const toBeUploadedAttachments = _.concat(
        Object.values(attachments),
        listOfAttachment
      );
      setListOfAttachment(toBeUploadedAttachments);
    }
  };

  const onRemoveAttachment = (attachment: any) => {
    let updatedEditData = _.clone(editDriverNoteData);

    const updatedListOfEditDataAttachment = _.remove(
      updatedEditData.attachments as string[],
      (item) => {
        return item !== attachment;
      }
    );
    updatedEditData = {
      ...updatedEditData,
      attachments: updatedListOfEditDataAttachment,
    };

    const updatedListOfAttachment = _.remove(listOfAttachment, (item) => {
      return item !== attachment;
    });

    setListOfAttachment(updatedListOfAttachment);
    setEditDriverNoteData(updatedEditData);
    setRemovedUrl([...removedUrl, attachment]);
  };
  // eslint-disable-next-line

  const getDriverNotes = () => {
    if (_.isNull(driverNotes) && !!driver) {
      services.getDriverNotes(driver.docId as string, (driverNote, error) => {
        setDriverNotes(driverNote);
        setError(error || "");
      });
    }
  };

  const onSaveDriverNotes = async () => {
    setLoading(true);
    const { docId, lastName } = props.authUser;
    const noteEntry = {
      title: noteTitle,
      description: noteDescription,
    } as Notes;
    let errorMsg = "";

    if (!_.isEmpty(editDriverNoteData)) {
      let updatedEditData = _.clone(editDriverNoteData);
      const updatedTile = !_.isEmpty(noteTitle)
        ? noteTitle
        : updatedEditData!.notes!.title;
      const updatedDescription = !_.isEmpty(noteDescription)
        ? noteDescription
        : updatedEditData!.notes!.description;
      const updatedNotes = {
        title: updatedTile,
        description: updatedDescription,
      };
      try {
        let attachmentUrlList = [] as string[];
        if (listOfAttachment.length > 0) {
          const urlList = await uploadAttachments(
            listOfAttachment,
            lastName,
            docId,
            "driverNotes"
          );
          attachmentUrlList = urlList;
        }
        const updateAttachments = _.concat(
          updatedEditData.attachments,
          attachmentUrlList
        ) as string[];
        updatedEditData = {
          ...updatedEditData,
          notes: updatedNotes,
          updatedDate: getServerTimestamp(),
          attachments: updateAttachments,
          updatedBy: docId,
        };
        await services.updateDriverNoteEntry(
          updatedEditData.docId!,
          updatedEditData
        );
        //remove the file in our storage
        await Promise.resolve(
          removedUrl.forEach((url) => {
            new Promise(async (resolve) => {
              try {
                const refFromUrl = storage.refFromURL(url);
                const deletedUrl = await refFromUrl.delete();
                resolve(deletedUrl);
              } catch (errorUnknown) {
                const error = errorUnknown as any;
                resolve(null);
                Bugsnag.notify(new Error(error));
              }
            });
          })
        );
        setSuccess(MSGS_COMMON.success);
        setListOfAttachment([]);
        setEditDriverNoteData({} as DriverNotes);
        setwillEditNoteEntry(false);
        setSelectedEditIndex(null);
        setRemovedUrl([]);
        // this.setState({
        //   success: MSGS_COMMON.success,
        //   willEditDailyEntry: false,
        //   listOfAttachment: [],
        //   editDiaryEntryData: {} as driverNotes,
        //   selectedEditIndex: null,
        //   removedUrl: [],
        // });
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        setError(error);
        Bugsnag.notify(new Error(error));
      }
    } else {
      if (!noteEntry.title) {
        errorMsg = MSGS_NOTE_ENTRY.required;
      } else if (!noteEntry.description) {
        errorMsg = MSGS_NOTE_ENTRY.required;
      }
      if (errorMsg) {
        setError(errorMsg);
      } else {
        try {
          let attachmentUrlList = [] as string[];
          if (listOfAttachment.length > 0) {
            const urlList = await uploadAttachments(
              listOfAttachment,
              lastName,
              docId,
              "driverNotes"
            );
            attachmentUrlList = urlList;
          }
          const driverNoteEntry = {
            driverId: driver!.docId as string,
            createdBy: docId,
            attachments: attachmentUrlList,
            notes: noteEntry,
          } as DriverNotes;
          await services.createDriverNotes(driverNoteEntry);
          setSuccess(MSGS_COMMON.success);
          setListOfAttachment([]);
          setRemovedUrl([]);
          setShowAddNote(false);
        } catch (errorUnknown) {
          const error = errorUnknown as any;
          setError(error);
          Bugsnag.notify(new Error(error));
        }
      }
    }
  };
  const onEditNoteEntry = (
    noteEntry: DriverNotes,
    selectedEditIndex: number
  ) => {
    const editNoteEntryData = _.cloneDeep(noteEntry);
    setwillEditNoteEntry(true);
    setEditDriverNoteData(editNoteEntryData);
    setSelectedEditIndex(selectedEditIndex);
  };

  const getDriverVOCs = () => {
    if (!!driver && !_.isEmpty(driver)) {
      setDriverVOCs(null);
      services.getDriverVOCsViewRealtime(
        (driverVOCs) => {
          setDriverVOCs(driverVOCs);
        },
        driver.docId as string,
        null
      );
    }
  };

  const getInductions = () => {
    if (!!driver && !_.isEmpty(driver)) {
      setDriverInductions(null);
      services.getInductionsRealTime(driver.docId || "", (inductions) => {
        setDriverInductions(inductions);
      });
    }
  };

  const getDriverExpiryList = () => {
    services.getDriverExpiryReminders((driverExpiryList, error) => {
      // setDriverExpiryList(driverExpiryList);
      const driverExpiryDates = driverExpiryList.map((expiry) => {
        const expiryReference = _.find(
          driver!.driverExpiries || [],
          (driverExpiry) => {
            return driverExpiry.expiryId === expiry.docId;
          }
        );
        return {
          expiryId: expiry.docId || "",
          name: expiry.name,
          expiryReminder: expiry.expiryReminder,
          expiryDate: !_.isEmpty(expiryReference)
            ? expiryReference!.expirationDate
            : null,
        };
      });
      setDriverExpiryWithDate(driverExpiryDates);
      setError(error || "");
    });
  };

  const getJobHistory = () => {
    if (_.isNull(jobHistory)) {
      services.getJobSchedulesForDriversRealTimeView(
        driver!.docId || "",
        (jobSchedules, error) => {
          setJobHistory(
            _.filter(jobSchedules, (job) => !_.isEmpty(job.siteDockets))
          );
          setError(error || "");
        }
      );
    }
  };

  const updateDriverExpiriesReference = async () => {
    if (!_.isEmpty(driver)) {
      let attachmentUrlList = [] as string[];
      const newFileAttach = _.compact(
        listOfAttachment.map((item: any) => {
          return typeof item !== "string" && item;
        })
      ) as any[];
      const prevousFileAttach = _.compact(
        listOfAttachment.map((item: any) => {
          return typeof item === "string" && item;
        })
      );
      if (newFileAttach.length > 0) {
        const urlList = await uploadAttachments(
          newFileAttach,
          driver!.firstName,
          expiryId,
          "driverExpiries"
        );
        attachmentUrlList = urlList;
      }
      const updatedAttachment = prevousFileAttach.concat(
        attachmentUrlList
      ) as string[];
      const updatedExpiries = _.clone(
        driver!.driverExpiries || []
      ) as ExpiryReference[];
      _.remove(updatedExpiries, (expiry) => expiry.expiryId === expiryId);

      // if (!_.isNull(expiryDate)) {
      updatedExpiries.push({
        expiryId,
        expirationDate: !_.isNull(expiryDate)
          ? getServerTimestamp(expiryDate)
          : null,
        attachments: updatedAttachment,
      });
      await services.addDriverChangeLogs(
        driver!.docId || "",
        `Expiry updated - "${expiryName}"`,
        props.authUser.docId
      );
      // }
      try {
        setLoading(true);

        await services.updateDriverExpiries(
          driver!.docId || "",
          updatedExpiries
        );
        await removedUploadedAttachements(removedAttachments);
        setLoading(false);
        setSuccess("Update Success");
        setTimeout(() => {
          setShowUpdateExpiryDialog(false);
        }, 1000);
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        setLoading(false);
        setError(error);
        Bugsnag.notify(new Error(error));
      }
    }
  };

  const editData =
    !_.isEmpty(editDriverNoteData) && willEditNoteEntry
      ? {
          title: editDriverNoteData!.notes!.title,
          description: editDriverNoteData!.notes!.description,
          listOfAttachment: editDriverNoteData.attachments,
        }
      : {};

  return (
    <>
      <div className="driver-dashboard-container">
        <EWPTitleDetailPage
          pageTitle="Driver"
          {...props}
          createButtonLabel="Create Job For Driver"
          newButtonLabel="Edit Driver"
          onClickCreateButton={() => {
            props.history.push(routes.CREATE_JOB_SCHEDULE, {
              driverId: driver!.docId || "",
            } as JobScheduleFor);
          }}
          backURL={routes.HOME_DRIVER_REGISTER}
          disableCreate={_.isNull(driver) || driver.archived}
          onClickEditButton={() => {
            if (!_.isNull(driver)) {
              props.history.push(
                routes.HOME_EDIT_DRIVER.replace(
                  ":driverId",
                  driver!.docId || ""
                )
              );
            }
          }}
        />
        <div className="driver-dashboard-detail-card-container">
          {!!driver && driver.archived && (
            <div className="archived-banner-container">
              <ArchivedBanner {...props} mode="driver" data={driver} />
            </div>
          )}
          <EWPDriverProfileHeader
            driverProfile={driver}
            showArchivedDriverDialog={() => {
              setShowArchivedRestore(true);
            }}
          />
        </div>
        <div className="driver-dashboard-card-container">
          <IonGrid
            className={`driver-dashboard-grid ${
              !!driver && driver.archived ? "disabled-component" : ""
            } ion-no-padding ion-no-margin`}
          >
            <IonRow className="driver-dashboard-row ion-no-padding ion-no-margin">
              <IonCol
                className="driver-dashboard-expires-col ion-no-padding ion-no-margin"
                size="6"
              >
                <ExpiriesCard
                  expiriesData={driverExpiryWithDate}
                  {...props}
                  cardLabel={"Certifications that Expire"}
                  cardColumnName={"Certification Name"}
                  onClickViewAttachment={(driverExpiryId) => {
                    const driverExpiry = _.find(
                      driver!.driverExpiries,
                      (expiryWithDate) =>
                        expiryWithDate.expiryId === driverExpiryId
                    );
                    console.log("driverExpiry --- ", driverExpiry);
                    if (
                      !!driverExpiry &&
                      !_.isEmpty(driverExpiry.attachments)
                    ) {
                      setSelectedExpries(driverExpiry);
                    } else {
                      setError("No Attachments");
                    }
                    setShowAttachmentDialog(true);
                  }}
                  hasAttachment={(driverExpiryId) => {
                    const driverExpiry = _.find(
                      driver!.driverExpiries,
                      (expiryWithDate) =>
                        expiryWithDate.expiryId === driverExpiryId
                    );
                    return (
                      !!driverExpiry && !_.isEmpty(driverExpiry.attachments)
                    );
                  }}
                  onClickEdit={(expiryId) => {
                    //show edit dialog here
                    const expiry = _.find(
                      driverExpiryWithDate,
                      (expiryWithDate) => expiryWithDate.expiryId === expiryId
                    );
                    const driverExpiry = _.find(
                      driver!.driverExpiries,
                      (expiryWithDate) => expiryWithDate.expiryId === expiryId
                    );
                    if (!!expiry && !_.isEmpty(expiry)) {
                      setExpiryId(expiryId);
                      setExpiryName(expiry!.name);
                      setExpiryDate(
                        !_.isNull(expiry!.expiryDate)
                          ? expiry!.expiryDate.toDate()
                          : null
                      );
                      setListOfAttachment(
                        !!driverExpiry && !!driverExpiry.attachments
                          ? driverExpiry.attachments
                          : ([] as any[])
                      );
                    }
                    setShowUpdateExpiryDialog(true);
                  }}
                />
              </IonCol>
              <IonCol
                className="driver-dashboard-voc-col ion-no-padding ion-no-margin"
                size="6"
              >
                {!!driver && (
                  <VOCCard
                    vocData={driverVOCs}
                    driverId={driver.docId as string}
                    {...props}
                    mode="driver"
                  />
                )}
              </IonCol>
            </IonRow>
            <IonRow className="driver-dashboard-row ion-no-padding ion-no-margin">
              <IonCol
                className="driver-dashboard-induction-col ion-no-padding ion-no-margin"
                size="6"
              >
                <IonCard
                  color={EWPCOLORS.tertiary}
                  className="driver-dashboard-induction-card ion-no-padding ion-no-margin"
                >
                  <InductionCard
                    {...props}
                    inductionData={driverInductions}
                    onAdd={() => {
                      setShowDriverInductionDialog(true);
                    }}
                    onClickEdit={(induction) => {
                      setToEditInduction(induction);
                      setShowDriverInductionDialog(true);
                      //show edit dialog here
                    }}
                  />
                </IonCard>
              </IonCol>

              <IonCol
                className="driver-dashboard-change-logs-col ion-no-padding ion-no-margin"
                size="6"
              >
                {!!driver && (
                  <ChangeLogsCard changeLogs={changeLogs} mode="driver" />
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <div className="driver-dashboard-job-history-card-container">
          <EWPJobHistoryCard {...props} jobHistories={jobHistory} />
        </div>
        {!!driverNotes && (
          <div>
            {showAddNote ? (
              <IonCard
                className="driver-dashboard-note-card ion-no-padding ion-no-margin"
                color={EWPCOLORS.tertiary}
              >
                <IonCardHeader className="driver-dashboard-note-card-header ion-no-padding ion-no-margin">
                  <IonLabel className="driver-dashboard-note-card-headerlabel ewp-h4 bold ion-no-padding ion-no-margin">
                    Notes
                  </IonLabel>
                </IonCardHeader>
                <div className="driver-dashboard-note-container">
                  <EWPNoteWithAttachment
                    onChangeTitle={(titleData) => {
                      setNoteTitle(titleData);
                    }}
                    onChangeBody={(bodyData) => {
                      setNoteDescription(bodyData);
                    }}
                    listOfAttachment={listOfAttachment} //callback here to display attachment
                    onAttachPhoto={onAttachPhoto}
                    onRemoveAttachment={onRemoveAttachment}
                  />

                  <IonItem
                    lines="none"
                    className="driver-dashboard-note-action-buttons-item ewp-item-no-horizontal-padding"
                    color={EWPCOLORS.tertiary}
                  >
                    <IonButton
                      mode="ios"
                      slot="start"
                      className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
                      fill="clear"
                      color={EWPCOLORS.medium}
                      onClick={() => {
                        setShowAddNote(false);
                      }}
                    >
                      Cancel
                    </IonButton>
                    <IonButton
                      slot="end"
                      mode="ios"
                      className="driver-dashboard-note-save-button ewp-h6 ion-no-margin ion-no-padding"
                      color={EWPCOLORS.primary}
                      onClick={() => {
                        onSaveDriverNotes();
                      }}
                    >
                      Save
                    </IonButton>
                  </IonItem>
                </div>
              </IonCard>
            ) : (
              _.isEmpty(driverNotes) && (
                <div className="driver-add-note-button-container">
                  <IonButton
                    mode="ios"
                    className="driver-add-note-button ewp-h3 ion-no-margin ion-no-padding"
                    color={EWPCOLORS.primary}
                    onClick={() => {
                      setShowAddNote(true);
                    }}
                  >
                    Add New Driver Note
                  </IonButton>
                </div>
              )
            )}
          </div>
        )}

        {!!driverNotes &&
          !_.isEmpty(driverNotes) &&
          driverNotes.map((driverNote, index) => {
            return index === selectedEditIndex ? (
              <IonCard
                key={`driver-notes-card-${index}`}
                className="driver-dashboard-note-card ion-no-padding ion-no-margin"
                color={EWPCOLORS.tertiary}
              >
                <IonCardHeader className="driver-dashboard-note-card-header ion-no-padding ion-no-margin">
                  <IonLabel className="driver-dashboard-note-card-headerlabel ewp-h4 bold ion-no-padding ion-no-margin">
                    Edit Note
                  </IonLabel>
                </IonCardHeader>
                <div className="driver-dashboard-note-container">
                  <EWPNoteWithAttachment
                    onChangeTitle={(titleData) => {
                      setNoteTitle(titleData);
                    }}
                    onChangeBody={(bodyData) => {
                      setNoteDescription(bodyData);
                    }}
                    listOfAttachment={listOfAttachment} //callback here to display attachment
                    onAttachPhoto={onAttachPhoto}
                    onRemoveAttachment={onRemoveAttachment}
                    editData={editData as TextEditorEdit}
                  />

                  <IonItem
                    lines="none"
                    className="driver-dashboard-note-action-buttons-item ewp-item-no-horizontal-padding"
                    color={EWPCOLORS.tertiary}
                  >
                    <IonButton
                      mode="ios"
                      slot="start"
                      className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
                      fill="clear"
                      color={EWPCOLORS.medium}
                      onClick={() => {
                        setSelectedEditIndex(null);
                        setEditDriverNoteData({} as DriverNotes);
                      }}
                    >
                      Cancel
                    </IonButton>
                    <IonButton
                      slot="end"
                      mode="ios"
                      className="driver-dashboard-note-save-button ewp-h6 ion-no-margin ion-no-padding"
                      color={EWPCOLORS.primary}
                      onClick={() => {
                        onSaveDriverNotes();
                      }}
                    >
                      Save
                    </IonButton>
                  </IonItem>
                </div>
              </IonCard>
            ) : (
              !loading && (
                <div className="note-card-container">
                  <EWPNoteCard
                    onEdit={() => {
                      onEditNoteEntry(driverNote, index);
                    }}
                    noteEntry={driverNote as DriverNotes}
                    index={index}
                  />
                  {index + 1 === driverNotes.length && (
                    <IonButton
                      className="note-entry-action-button ewp-web-gray-button ewp-h6 dark ion-text-capitalize ion-no-margin"
                      onClick={() => {
                        setShowAddNote(true);
                      }}
                    >
                      <IonIcon className="note-entry-add-icon" />
                      Add New Entry
                    </IonButton>
                  )}
                </div>
              )
            );
          })}
      </div>

      {driver !== undefined && !_.isEmpty(driver) && (
        <EWPWebDialog
          headerTitle={`${
            !_.isEmpty(toEditInduction) ? "Edit" : "Add"
          } Induction`}
          isOpen={showDriverInductionDialog}
          onDidDismiss={() => {
            setShowDriverInductionDialog(false);
            setToEditInduction({} as Inductions);
          }}
        >
          <EWPInudctionForm
            driverId={driver!.docId || ""}
            isEdit={!_.isEmpty(toEditInduction)}
            onModalDidDismiss={() => {
              setShowDriverInductionDialog(false);
              setToEditInduction({} as Inductions);
            }}
            editData={toEditInduction}
          />
        </EWPWebDialog>
      )}

      {/* {showUpdateExpiryDialog && ( */}
      <EWPWebDialog
        headerTitle="Edit Expiries"
        isOpen={showUpdateExpiryDialog}
        onDidDismiss={() => {
          setShowUpdateExpiryDialog(false);
          setExpiryId("");
          setExpiryName("");
          setExpiryDate(null);
        }}
      >
        <div className="expiries-history-form-container">
          <div className="expiries-history-form-input-container">
            <div className="expiries-form-inputs">
              <EWPInput
                className="expiries-history-expiries-type-input"
                errorMessage={errorMsgExpiryName}
                inputLabel="Certification Name"
                inputValue={expiryName}
                inputType="text"
                name="expiriesName"
                readOnly={true}
                onInputChange={(expiriesName: string) => {
                  setExpiryName(expiriesName);
                  if (!!expiriesName) setErrorMsgExpiryName("");
                }}
              />
              <EWPDatePicker
                className="expiries-date-input-date"
                datePickerLabel="Expiry Date"
                selectedDate={expiryDate}
                format="DD MMM, YYYY"
                onDateChange={(expiriesDate: Date) => {
                  setExpiryDate(expiriesDate);
                  setErrorMsgExpiryDate("");
                }}
                errorMsg={errorMsgExpiryDate}
              />
              {!_.isNull(expiryDate) && (
                <IonButton
                  className="remove-expiry"
                  fill="clear"
                  color={EWPCOLORS.light}
                  onClick={() => {
                    setExpiryDate(null);
                  }}
                >
                  <IonIcon icon={closeOutline} color={EWPCOLORS.light} />
                </IonButton>
              )}
            </div>
            <div className="driver-exp-dialog-upload-container">
              <div className="driver-exp-dialog-title-button-container">
                <IonLabel className="driver-exp-dialog-upload-title-label ewp-h3 ion-no-padding ion-no-margin">
                  Upload Images
                </IonLabel>
                {!_.isEmpty(listOfAttachment) && (
                  <IonButton
                    mode="ios"
                    className="driver-exp-dialog-attachments-add-another-button ewp-h6 ion-no-margin ion-no-padding"
                    color={EWPCOLORS.gray}
                    onClick={onClickUploadPhoto}
                  >
                    <IonIcon
                      className="driver-exp-dialog-small-add-icon"
                      icon={add}
                    />
                    Add Another
                  </IonButton>
                )}
              </div>
              {!_.isEmpty(listOfAttachment) ? (
                <div className="web-upload-list-attachment-container">
                  <IonGrid className="ion-no-margin ion-no-padding">
                    {_.chunk(listOfAttachment, 8).map(
                      (row: any, index: any) => {
                        return (
                          <IonRow
                            key={`row_list-attachment-driver-dashboard-${index}`}
                            className="ion-no-margin ion-no-padding"
                          >
                            {row.map((attachment: any, colIndex: any) => {
                              const fileUrl =
                                typeof attachment === "string"
                                  ? attachment
                                  : "";
                              const fileSplit = fileUrl
                                .split("?")[0]
                                .split(".");
                              const fileTypeAttachment =
                                fileSplit[fileSplit.length - 1].toLowerCase();
                              return (
                                <IonCol
                                  key={`col_list-attachment-driver-${index}-${colIndex}`}
                                  size="1.5"
                                  className="ion-no-margin ion-no-padding"
                                >
                                  <div className="web-upload-attachment-container">
                                    <IonButton
                                      className="remove-attachment-button ion-no-margin ion-no-padding"
                                      shape="round"
                                      color="primary"
                                      onClick={() =>
                                        onRemoveExpiryAttachment(attachment)
                                      }
                                    >
                                      <IonIcon
                                        className="web-upload-attachment-close-icon ion-no-margin ion-no-padding"
                                        icon={close}
                                      />
                                    </IonButton>
                                    {typeof attachment !== "string" &&
                                    !!attachment.type.match("image/*") ? (
                                      <IonThumbnail
                                        className="web-upload-attachment-avatar ion-no-margin ion-no-padding"
                                        slot="start"
                                      >
                                        <img
                                          id="attachmentPhoto"
                                          src={
                                            typeof attachment === "string"
                                              ? attachment
                                              : URL.createObjectURL(attachment)
                                          }
                                          alt="attachmentPhoto"
                                        />
                                      </IonThumbnail>
                                    ) : fileTypeAttachment !== "pdf" &&
                                      typeof attachment === "string" ? (
                                      <IonThumbnail
                                        className="web-upload-attachment-avatar ion-no-margin ion-no-padding"
                                        slot="start"
                                      >
                                        <img
                                          id="attachmentPhoto"
                                          src={
                                            typeof attachment === "string"
                                              ? attachment
                                              : URL.createObjectURL(attachment)
                                          }
                                          alt="attachmentPhoto"
                                        />
                                      </IonThumbnail>
                                    ) : (
                                      <div
                                        className="attachment-file-link-container"
                                        onMouseOver={(
                                          e: React.MouseEvent<
                                            HTMLDivElement,
                                            MouseEvent
                                          >
                                        ) => {
                                          // handleMouseOver(e, attachment.attachmentUrl || "");

                                          const rect =
                                            e.currentTarget.getBoundingClientRect();
                                          setTooltipCoords({
                                            left: rect.left,
                                            top: rect.top,
                                          });
                                          if (typeof attachment === "string") {
                                            const fileName = attachment;

                                            const decodedURL =
                                              decodeURIComponent(fileName);
                                            let urlObj = new URL(decodedURL);
                                            let path = urlObj.pathname;

                                            let parts = path.split("/");
                                            let encodedFileName =
                                              parts[parts.length - 1];

                                            // Decode the file name
                                            let fileNameList =
                                              decodeURIComponent(
                                                encodedFileName
                                              ).split("_");
                                            fileNameList.splice(0, 2);

                                            setTooltipMessage(
                                              fileNameList.join("_") || ""
                                            );
                                          } else {
                                            setTooltipMessage(attachment.name);
                                          }
                                        }}
                                        onMouseOut={() => {
                                          setTooltipMessage("");
                                        }}
                                      >
                                        <a
                                          target="blank"
                                          href={
                                            typeof attachment === "string"
                                              ? attachment
                                              : URL.createObjectURL(attachment)
                                          }
                                        >
                                          View File
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </IonCol>
                              );
                            })}
                          </IonRow>
                        );
                      }
                    )}
                  </IonGrid>
                </div>
              ) : (
                <IonItem
                  lines="none"
                  className="driver-exp-dialog-upload-item ewp-body ion-no-margin"
                  onClick={onClickUploadPhoto}
                  onDragOver={(event) => {
                    event.preventDefault();
                  }}
                  onDrop={onDrop}
                >
                  <div className="web-upload-icon-label-container">
                    <IonIcon className="driver-exp-dialog-upload-icon" />
                    <IonLabel className="driver-exp-dialog-upload-label ewp-paragraph ion-no-margin ion-no-padding">
                      Drop files here to upload or <u>choose file</u>
                    </IonLabel>
                  </div>
                </IonItem>
              )}
              <input
                hidden
                type="file"
                ref={ref}
                accept="image/*,.pdf"
                onChange={onAttachExpiryPhoto}
                multiple
              />
            </div>
          </div>
          <IonItem
            lines="none"
            className="ewp-item-no-horizontal-padding ewp-button-padding-top"
          >
            <IonButton
              mode="ios"
              slot="start"
              className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
              fill="clear"
              color={EWPCOLORS.medium}
              onClick={() => {
                setShowUpdateExpiryDialog(false);
              }}
            >
              Cancel
            </IonButton>
            <IonButton
              mode="md"
              slot="end"
              className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
              color={EWPCOLORS.primary}
              type="submit"
              onClick={() => {
                updateDriverExpiriesReference();
              }}
            >
              Save
              <IonRippleEffect />
            </IonButton>
          </IonItem>
        </div>
      </EWPWebDialog>
      {/* )} */}
      {driver !== undefined && !_.isEmpty(driver) && (
        <ArchiveOrRestoreTruckDialog
          mode="driver"
          restore={(driver as DriverListView).archived}
          isOpen={showArchivedRestoreDialog}
          data={driver as any}
          onDidDismiss={() => {
            setShowArchivedRestore(false);
          }}
          onSuccess={() => {
            setShowArchivedRestore(false);
          }}
        />
      )}
      {!!selectedExpries &&
        !!selectedExpries.attachments &&
        !_.isEmpty(selectedExpries.attachments) && (
          <AttachmentDialog
            title="Expiries"
            attachments={selectedExpries.attachments.map((attachmentUrl) => {
              return {
                attachmentUrl,
              };
            })}
            showModal={showAttachmentDialog}
            onModalDidDismiss={() => {
              setShowAttachmentDialog(false);
            }}
          />
        )}

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={2000}
        onDidDismiss={() => {
          setSuccess("");
          setLoading(false);
        }}
        color={EWPCOLORS.success}
      />

      <EWPCommonTooltip
        left={tooltipCoords.left}
        top={tooltipCoords.top}
        content={tooltipMessage}
      />

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading || _.isNull(driverVOCs)}
        message={MSGS_COMMON.loading}
      />
    </>
  );
};
