import * as _ from "lodash";
import Bugsnag from "@bugsnag/js";

import { getServerTimestamp } from "../functions/common";
import { auth, firestore } from "../firebase";
import {
  FaultDefect,
  FaultDefectListView,
  FaultDefectNotes,
  User,
} from "../models";
import { Truck } from "../models/drivers";
import {
  FAULT_AND_DEFECTS,
  FAULT_AND_DEFECTS_NOTES,
  TRUCKS,
} from "../constants/dbCollections";
import {
  FAULT_DEFECT_STATUS,
  FAULT_DEFECT_URGENCY,
  USER_ROLE,
} from "../config/global";
import { newUrgentDefect } from "../api";
import moment from "moment";

export const createFaultAndDefects = async (
  truckId: string,
  description: string,
  kilometers: string,
  hours: string,
  attachments = [] as string[],
  createdBy: string,
  createdByRole = USER_ROLE.drivers
) => {
  try {
    //!createdBy is null - check this out - this is for mobile
    // const createdBy = auth.currentUser;
    if (!_.isNull(createdBy) && !_.isEmpty(createdBy)) {
      await firestore.collection(FAULT_AND_DEFECTS).add({
        truckId,
        description,
        kilometers,
        hours,
        status: FAULT_DEFECT_STATUS.open.id,
        attachments,
        createdByRole,
        createdBy,
        createdDt: getServerTimestamp(new Date()),
      });
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN createFaultAndDefects: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const updateFaultAndDefects = async (
  docId: string,
  updateData: Partial<FaultDefect>,
  userUpdatedUrgency = false,
  faultDefectData = {} as FaultDefectListView,
  urgencyUpdatedByName = ""
) => {
  try {
    const updatedBy = auth.currentUser;
    if (!_.isNull(updatedBy)) {
      await firestore
        .collection(FAULT_AND_DEFECTS)
        .doc(docId)
        .update({
          ...updateData,
          updatedBy: updatedBy.uid,
          ...(userUpdatedUrgency && { urgencyUpdatedBy: updatedBy.uid }),
          updatedDt: getServerTimestamp(new Date()),
        });

      if (
        userUpdatedUrgency &&
        !_.isEmpty(faultDefectData) &&
        !_.isEmpty(urgencyUpdatedByName) &&
        !_.isEmpty(updateData.urgency) &&
        updateData.urgency! === FAULT_DEFECT_URGENCY.urgent.id
      ) {
        await newUrgentDefect(
          faultDefectData,
          faultDefectData.truck.name,
          `${faultDefectData.createdByUser.firstName} ${faultDefectData.createdByUser.lastName}`,
          moment(faultDefectData.createdDt.toDate()).format(
            "MMM DD, YYYY hh:mmA"
          ),
          urgencyUpdatedByName
        );
      }
    } else {
      throw new Error("No user logged in");
    }
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERROR IN updateFaultAndDefects: ", e);
    Bugsnag.notify(new Error(e));
  }
};

export const getFaultAndDefectsRealTime = (
  callback: (faultAndDefects: FaultDefectListView[], error?: string) => void
) => {
  try {
    const unsubscribe = firestore
      .collection(FAULT_AND_DEFECTS)
      .onSnapshot(async (faultAndDefects) => {
        if (!faultAndDefects.empty) {
          const faultAndDefectsFormattedView = (await Promise.all(
            faultAndDefects.docs.map((faultAndDefect) => {
              return new Promise(async (resolve) => {
                const faultDefectData = {
                  ...faultAndDefect.data(),
                  docId: faultAndDefect.id,
                } as FaultDefect;

                const truckQuery = await firestore
                  .collection(TRUCKS)
                  .doc(faultDefectData.truckId)
                  .get();

                const userQuery = await firestore
                  .collection(faultDefectData.createdByRole)
                  .doc(faultDefectData.createdBy)
                  .get();

                let updatedByUser;
                let urgencyUpdatedByUser;
                if (faultDefectData.updatedBy) {
                  updatedByUser = await firestore
                    .collection(USER_ROLE.admins)
                    .doc(faultDefectData.updatedBy)
                    .get();
                }

                if (!_.isEmpty(faultDefectData.urgencyUpdatedBy)) {
                  urgencyUpdatedByUser = await firestore
                    .collection(USER_ROLE.admins)
                    .doc(faultDefectData.urgencyUpdatedBy)
                    .get();
                }

                if (truckQuery.exists && userQuery.exists) {
                  resolve({
                    ...faultDefectData,
                    truck: truckQuery.data() as Truck,
                    createdByUser: userQuery.data() as User,
                    updatedByUser:
                      updatedByUser && updatedByUser.exists
                        ? (updatedByUser.data() as User)
                        : {},
                    urgencyUpdatedByUser:
                      urgencyUpdatedByUser && urgencyUpdatedByUser.exists
                        ? (urgencyUpdatedByUser.data() as User)
                        : {},
                  });
                } else {
                  resolve(null);
                }
              });
            })
          )) as (FaultDefectListView | null)[];
          callback(
            _.orderBy(_.compact(faultAndDefectsFormattedView), (defect) =>
              defect.createdDt.toDate()
            )
          );
        } else {
          callback([]);
        }
      });
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    console.log("ERRR IN getFaultAndDefectsRealTime: ", e);
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const createFaultDefectNotes = async (data: FaultDefectNotes) => {
  try {
    const createdBy = auth.currentUser;
    if (!_.isNull(createdBy)) {
      await firestore.collection(FAULT_AND_DEFECTS_NOTES).add({
        ...data,
        createdBy: createdBy.uid,
        createdDate: getServerTimestamp(),
      });
    } else {
      throw new Error("No user logged in");
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("Error -- createFaultDefectNotes -- ", error);
    Bugsnag.notify(new Error(error));
  }
};

export const getFaultDefectNotes = async (
  faultDefectId: string,
  callback: (FaultDefectNotes: FaultDefectNotes[], error?: string) => void
) => {
  if (!!faultDefectId) {
    try {
      const unsubscribe = firestore
        .collection(FAULT_AND_DEFECTS_NOTES)
        .where("faultDefectId", "==", faultDefectId)
        .onSnapshot(
          (snapshot) => {
            if (snapshot.docs.length > 0) {
              callback(
                snapshot.docs.map((faultDefectNote) => {
                  return {
                    ...faultDefectNote.data(),
                    docId: faultDefectNote.id,
                  } as FaultDefectNotes;
                })
              );
            } else {
              callback([]);
            }
          },
          (error) => {
            callback([], error.message);
          }
        );
      return unsubscribe;
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      callback([], error);
      Bugsnag.notify(new Error(error));
    }
  } else {
    callback([], "Error: no id/s provided");
  }
};

export const updateFaultDefectNotes = async (
  docId: string,
  updatedNoteEntry: FaultDefectNotes
) => {
  try {
    const updatedBy = auth.currentUser;
    if (!_.isNull(updatedBy)) {
      await firestore
        .collection(FAULT_AND_DEFECTS_NOTES)
        .doc(docId)
        .update({
          ...updatedNoteEntry,
          updatedBy: updatedBy.uid,
          updatedDate: getServerTimestamp(),
        });
    } else {
      throw new Error("No user logged in");
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("Error - updateFaultDefectNotes-- ", error);
    Bugsnag.notify(new Error(error));
  }
};
