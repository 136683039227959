import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import Bugsnag from "@bugsnag/js";
import React, { useState } from "react";
import {
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonRippleEffect,
} from "@ionic/react";

import "./RequestPartsDialog.scss";
import * as services from "../../services";
import * as api from "../../api";
import { formatString } from "../../functions/common";
import { FaultDefectListView } from "../../models";
import { EWPInput } from "../EWPInput/EWPInput";
import { EWPWebDialog } from "../EWPWebDialog/EWPWebDialog";
import { EWPProps, FAULT_DEFECT_STATUS } from "../../config/global";
import { EWPCOLORS } from "../../constants/config";
import { MSGS_COMMON } from "../../constants/messages";

interface RequestPartsDialogProps extends EWPProps {
  onDidDismiss: () => void;
  isOpen: boolean;
  faultAndDefectData: FaultDefectListView; // use in upload photo path
  mechanicFullName: string;
}

export const RequestPartsDialog = (props: RequestPartsDialogProps) => {
  const { isOpen, onDidDismiss, faultAndDefectData, mechanicFullName } = props;
  const [requestedParts, setRequestedParts] = useState("");
  const [recipient, setRecipient] = useState("tonyh@gibbsparts.com");

  const [errorMsgRequestedParts, setErrorMsgRequestedParts] = useState("");
  const [errorMsgRecipient, setErrorMsgRecipient] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const clearFields = () => {
    setRequestedParts("");
    setRecipient("tonyh@gibbsparts.com");
  };

  const clearErrorMsgs = () => {
    setErrorMsgRequestedParts("");
    setErrorMsgRecipient("");
  };

  const requestParts = async (event: React.FormEvent) => {
    event.preventDefault();

    const formattedRequestedParts = formatString(requestedParts);
    const formattedRecipient = formatString(recipient);

    let errorMsg = "";
    if (_.isEmpty(formattedRequestedParts)) {
      errorMsg = "Requested parts is required";
      setErrorMsgRequestedParts(errorMsg);
    }

    if (_.isEmpty(formattedRecipient)) {
      errorMsg = "Recipient is required";
      setErrorMsgRecipient(errorMsg);
    }

    if (!EmailValidator.validate(formattedRecipient)) {
      errorMsg = "Invalid recipient email";
      setErrorMsgRecipient(errorMsg);
    }

    if (_.isEmpty(errorMsg)) {
      setLoading(true);
      try {
        await api.requestParts(
          requestedParts,
          faultAndDefectData.truck,
          mechanicFullName,
          recipient
        );

        await services.updateFaultAndDefects(faultAndDefectData.docId || "", {
          status: FAULT_DEFECT_STATUS.waitingOnParts.id,
          requestedParts,
        });

        await setLoading(false);
        setSuccess(MSGS_COMMON.success);
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        //remove the file in our storage
        setLoading(false);
        setError(error);
        Bugsnag.notify(new Error(error));
      }
    }
  };

  return (
    <>
      <EWPWebDialog
        headerTitle="Request Parts"
        isOpen={isOpen}
        small={true}
        onDidDismiss={() => {
          onDidDismiss();
        }}
      >
        <div className="request-parts-form-container">
          <form onSubmit={requestParts}>
            <IonGrid className="ion-no-padding">
              <IonRow className="request-parts-row ion-no-padding ion-no-margin">
                <IonCol
                  size="12"
                  className="request-parts-only-col ion-no-padding"
                >
                  <EWPInput
                    mode="textArea"
                    errorMessage={errorMsgRequestedParts}
                    inputLabel="Requested Parts"
                    inputValue={requestedParts}
                    inputType="text"
                    inputMode="text"
                    name="requestedParts"
                    onInputChange={(requestedParts: string) => {
                      clearErrorMsgs();
                      setRequestedParts(requestedParts);
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow className="request-parts-row ion-no-padding ion-no-margin">
                <IonCol
                  size="12"
                  className="request-parts-only-col ion-no-padding"
                >
                  <EWPInput
                    inputLabel="Recipient Email Address"
                    inputValue={recipient}
                    errorMessage={errorMsgRecipient}
                    name="recipient"
                    inputType="text"
                    onInputChange={(recipient) => {
                      setRecipient(recipient);
                    }}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonItem
              lines="none"
              className="ewp-item-no-horizontal-padding ewp-button-padding-top"
            >
              <IonButton
                mode="ios"
                slot="start"
                className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
                fill="clear"
                color={EWPCOLORS.medium}
                onClick={() => {
                  if (onDidDismiss) {
                    onDidDismiss();
                    clearFields();
                  }
                }}
              >
                Cancel
              </IonButton>
              <IonButton
                mode="md"
                slot="end"
                className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
                color={EWPCOLORS.primary}
                type="submit"
              >
                Submit
                <IonRippleEffect />
              </IonButton>
            </IonItem>
          </form>
        </div>
      </EWPWebDialog>

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
          onDidDismiss();
          clearFields();
        }}
        color={EWPCOLORS.success}
      />
    </>
  );
};
