import { close } from "ionicons/icons";
import React, { useState } from "react";
import { IonModal, IonButton, IonIcon, IonLabel } from "@ionic/react";

import "./EWPTermOfUseAndPrivacy.scss";
import { EWPFooterLink } from "../../common/EWPFooterLink";
import { EWPCOLORS } from "../../../constants/config";

export const EWPTermOfUseAndPrivacy = (props: {
  className?: string;
  signUp?: boolean;
}) => {
  const [showTermOfUseDialog, setShowTermOfUseDialog] = useState(false);
  const [showPrivacyDialog, setShowPrivacyDialog] = useState(false);
  return (
    <div
      className={`ewp-terms-and-privacy ewp-paragraph ${
        props.className !== undefined ? props.className : ""
      }`}
    >
      {!props.signUp ? (
        <>
          <EWPFooterLink
            title="Terms of Use"
            routerLink="#"
            onClick={() => {
              setShowTermOfUseDialog(true);
            }}
          />
          <span className="ewp-divider ewp-paragraph">|</span>
          <EWPFooterLink
            title="Privacy Policy"
            routerLink="#"
            onClick={() => {
              setShowPrivacyDialog(true);
            }}
          />
        </>
      ) : (
        <IonLabel className="ewp-paragraph">
          By signing up to SiteVis you agree to our{" "}
          <IonButton
            fill="clear"
            color={EWPCOLORS.primary}
            onClick={() => {
              setShowPrivacyDialog(true);
            }}
            className="ewp-links ion-text-lowercase"
          >
            <u>privacy policy</u>
          </IonButton>{" "}
          and{" "}
          <IonButton
            fill="clear"
            color={EWPCOLORS.primary}
            onClick={() => {
              setShowTermOfUseDialog(true);
            }}
            className="ewp-links ion-text-lowercase"
          >
            <u>term of service</u>
          </IonButton>
        </IonLabel>
      )}

      <IonModal
        className="iframe-dialog"
        isOpen={showTermOfUseDialog || showPrivacyDialog}
        onDidDismiss={() => {
          setShowTermOfUseDialog(false);
          setShowPrivacyDialog(false);
        }}
        showBackdrop={true}
      >
        <div className="iframe-dialog-header">
          <IonButton
            className="iframe-dialog-header-close ion-no-padding ion-no-margin"
            color={EWPCOLORS.light}
            fill="clear"
            slot="end"
            onClick={() => {
              setShowTermOfUseDialog(false);
              setShowPrivacyDialog(false);
            }}
          >
            <IonIcon color={EWPCOLORS.medium} icon={close} />
          </IonButton>
        </div>
        <div className="iframe-dialog-title-container">
          <iframe
            title={showTermOfUseDialog ? "Term of Service" : "Privacy Policy"}
            src={
              showTermOfUseDialog
                ? "https://sitevis.com.au/terms-of-service/?layout=none"
                : "https://sitevis.com.au/privacy-policy/?layout=none"
            }
            className="iframe"
            scrolling="yes"
          />
        </div>
      </IonModal>
    </div>
  );
};
